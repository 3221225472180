export function formatPhone(phoneStr) {
  const str = String(phoneStr);
  const len = str.length;
  const ddd = str.substring(0, 2);
  const last = str.substring(len - 4, len);
  const first = str.substring(2, len - 4);
  return `(${ddd}) ${first}-${last}`;
}

export function formatDateToDDMMYYYY(dateString) {
  const parts = dateString.split('-');
  return `${parts[2]}/${parts[1]}/${parts[0]}`;
}

export function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function formatDateTime(dateTime) {
  const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric',
    hour12: false,
    timeZone: 'America/Sao_Paulo'
  };
  return new Intl.DateTimeFormat('pt-BR', options).format(dateTime);
}

export function separateDateHour(dateTimeString, split = " ", formatDDMMYYYY = true) {

  const dateTimeParts = dateTimeString.split(split);

  let datePart = dateTimeParts[0];
  const hourPart = dateTimeParts[1].substring(0, 5);

  if (formatDDMMYYYY) {
    const [year, month, day] = datePart.split("-");
    datePart = `${day}/${month}/${year}`;
  }

  return {
    date: datePart,
    hour: hourPart,
  };
}

export function calculateAge(birthdate) {
  const nowDate = new Date();
  const ageDate = nowDate - birthdate;
  return new Date(ageDate).getUTCFullYear() - 1970;
}

export function formatCpf(cpf) {
  const str = String(cpf);
  const len = str.length;
  return `${str.substring(0, 3)}.${str.substring(3, 6)}.${str.substring(
    6,
    9
  )}-${str.substring(9, len)}`;
}
