import {
  LIST_INCIDENCES_REQUEST,
  LIST_INCIDENCES_SUCCESS,
  LIST_INCIDENCES_ERROR,
  RESET_STATE_ALL_APPLICATION,
  GET_INCIDENCES_REQUEST,
  GET_INCIDENCES_SUCCESS,
  GET_INCIDENCES_ERROR,
  RESET_STATE_REDUX,
} from '../actions';

const INIT_STATE = {
  currentIncidence: null,
  currentIncidencesList: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentIncidence: null,
        success: '',
      };
    case GET_INCIDENCES_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_INCIDENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        currentIncidence: action.payload,
        error: '',
      };
    case GET_INCIDENCES_ERROR:
      return {
        ...state,
        loadallIncidences: null,ing: false,
        currentIncidence: null,
        error: action.payload.message,
      };
    case LIST_INCIDENCES_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_INCIDENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        currentIncidencesList: action.payload,
        error: '',
      };
    case LIST_INCIDENCES_ERROR:
      return {
        ...state,
        loading: false,
        currentIncidencesList: null,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
