function collectNodeValues(nodeList, targetProperty) {
  const stack = [...nodeList];
  const values = [];

  while (stack.length > 0) {
    const node = stack.shift();
    values.push(node[targetProperty]);
    if (Array.isArray(node.children_recursive)) {
      stack.unshift(...node.children_recursive);
    } else if (Array.isArray(node.children)) {
      stack.unshift(...node.children);
    } else {
      // eslint-disable-next-line no-continue
      continue;
    }
  }

  return values;
}

export function getChildrenRecursiveIds(currentUser) {
  const school = currentUser.uid.schools;
  return collectNodeValues([school], 'id');
}
