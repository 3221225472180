import {
  LIST_WRITINGS_REQUEST,
  LIST_WRITINGS_SUCCESS,
  LIST_WRITINGS_ERROR,
  GET_WRITINGS_REQUEST,
  GET_WRITINGS_SUCCESS,
  GET_WRITINGS_ERROR,
  ADD_WRITINGS_REQUEST,
  ADD_WRITINGS_SUCCESS,
  ADD_WRITINGS_ERROR,
  EDIT_WRITINGS_REQUEST,
  EDIT_WRITINGS_SUCCESS,
  EDIT_WRITINGS_ERROR,
  EDIT_ACTIVE_WRITINGS_REQUEST,
  EDIT_ACTIVE_WRITINGS_SUCCESS,
  EDIT_ACTIVE_WRITINGS_ERROR,
  SEND_WRITINGS_REQUEST,
  SEND_WRITINGS_SUCCESS,
  SEND_WRITINGS_ERROR,
  ACCEPT_WRITINGS_REQUEST,
  ACCEPT_WRITINGS_SUCCESS,
  ACCEPT_WRITINGS_ERROR,
  RECUSE_WRITINGS_REQUEST,
  RECUSE_WRITINGS_SUCCESS,
  RECUSE_WRITINGS_ERROR,
  EDIT_WRITINGS_VINCULATE_CRITERIONS_REQUEST,
  EDIT_WRITINGS_VINCULATE_CRITERIONS_SUCCESS,
  EDIT_WRITINGS_VINCULATE_CRITERIONS_ERROR,
  IMPORT_WRITINGS_REQUEST,
  IMPORT_WRITINGS_SUCCESS,
  IMPORT_WRITINGS_ERROR,
  SEND_RATINGS_WRITINGS_REQUEST,
  SEND_RATINGS_WRITINGS_SUCCESS,
  SEND_RATINGS_WRITINGS_ERROR,
  REMOVE_IMPORT_WRITINGS_REQUEST,
  REMOVE_IMPORT_WRITINGS_SUCCESS,
  REMOVE_IMPORT_WRITINGS_ERROR,
  LIST_WRITINGS_REPORTS_REQUEST,
  LIST_WRITINGS_REPORTS_SUCCESS,
  LIST_WRITINGS_REPORTS_ERROR,
  ADD_CRITERION_WRITINGS_REQUEST,
  ADD_CRITERION_WRITINGS_SUCCESS,
  ADD_CRITERION_WRITINGS_ERROR,
  EDIT_CRITERION_WRITINGS_REQUEST,
  EDIT_CRITERION_WRITINGS_SUCCESS,
  EDIT_CRITERION_WRITINGS_ERROR,
  SET_WRITINGS_MODAL_VALUES,
} from '../actions';

export const getWritingsRequest = (id) => ({
  type: GET_WRITINGS_REQUEST,
  payload: { id },
});
export const getWritingsSuccess = (writings) => ({
  type: GET_WRITINGS_SUCCESS,
  payload: writings,
});
export const getWritingsError = (message) => ({
  type: GET_WRITINGS_ERROR,
  payload: { message },
});

export const listWritingsRequest = () => ({
  type: LIST_WRITINGS_REQUEST,
});
export const listWritingsSuccess = (writings) => ({
  type: LIST_WRITINGS_SUCCESS,
  payload: writings,
});
export const listWritingsError = (message) => ({
  type: LIST_WRITINGS_ERROR,
  payload: { message },
});

export const addWritingsRequest = (writings) => ({
  type: ADD_WRITINGS_REQUEST,
  payload: { writings },
});
export const addWritingsSuccess = (writings) => ({
  type: ADD_WRITINGS_SUCCESS,
  payload: writings,
});
export const addWritingsError = (message) => ({
  type: ADD_WRITINGS_ERROR,
  payload: { message },
});

export const editWritingsRequest = (writings) => ({
  type: EDIT_WRITINGS_REQUEST,
  payload: { writings },
});
export const editWritingsSuccess = (writings) => ({
  type: EDIT_WRITINGS_SUCCESS,
  payload: { writings },
});
export const editWritingsError = (message) => ({
  type: EDIT_WRITINGS_ERROR,
  payload: { message },
});

export const editActiveWritingsRequest = (id, active) => ({
  type: EDIT_ACTIVE_WRITINGS_REQUEST,
  payload: { id, active },
});
export const editActiveWritingsSuccess = (writings) => ({
  type: EDIT_ACTIVE_WRITINGS_SUCCESS,
  payload: { writings },
});
export const editActiveWritingsError = (message) => ({
  type: EDIT_ACTIVE_WRITINGS_ERROR,
  payload: { message },
});

export const sendWritingsRequest = (id) => ({
  type: SEND_WRITINGS_REQUEST,
  payload: { id },
});
export const sendWritingsSuccess = (id) => ({
  type: SEND_WRITINGS_SUCCESS,
  payload: { id },
});
export const sendWritingsError = (message) => ({
  type: SEND_WRITINGS_ERROR,
  payload: { message },
});

export const editWritingsVinculateCriterionsRequest = (id, criterionList) => ({
  type: EDIT_WRITINGS_VINCULATE_CRITERIONS_REQUEST,
  payload: { id, criterionList },
});
export const editWritingsVinculateCriterionsSuccess = (id, message) => ({
  type: EDIT_WRITINGS_VINCULATE_CRITERIONS_SUCCESS,
  payload: { id, message },
});
export const editWritingsVinculateCriterionsError = (message) => ({
  type: EDIT_WRITINGS_VINCULATE_CRITERIONS_ERROR,
  payload: { message },
});

export const importWritingsRequest = (files) => ({
  type: IMPORT_WRITINGS_REQUEST,
  payload: { files },
});
export const importWritingsSuccess = (message) => ({
  type: IMPORT_WRITINGS_SUCCESS,
  payload: message,
});
export const importWritingsError = (message) => ({
  type: IMPORT_WRITINGS_ERROR,
  payload: { message },
});

export const removeImportWritingsRequest = (writingId, id) => ({
  type: REMOVE_IMPORT_WRITINGS_REQUEST,
  payload: { writingId, id },
});
export const removeImportWritingsSuccess = (writingId, id) => ({
  type: REMOVE_IMPORT_WRITINGS_SUCCESS,
  payload: { writingId, id },
});
export const removeImportWritingsError = (writingId, id) => ({
  type: REMOVE_IMPORT_WRITINGS_ERROR,
  payload: { writingId, id },
});

export const listWritingsReportsRequest = (classId, cycle) => ({
  type: LIST_WRITINGS_REPORTS_REQUEST,
  payload: { classId, cycle },
});
export const listWritingsReportsSuccess = (writingsReports) => ({
  type: LIST_WRITINGS_REPORTS_SUCCESS,
  payload: writingsReports,
});
export const listWritingsReportsError = (message) => ({
  type: LIST_WRITINGS_REPORTS_ERROR,
  payload: { message },
});

export const addCriterionWritingsRequest = (criterionWritings) => ({
  type: ADD_CRITERION_WRITINGS_REQUEST,
  payload: { criterionWritings },
});
export const addCriterionWritingsSuccess = (message) => ({
  type: ADD_CRITERION_WRITINGS_SUCCESS,
  payload: message,
});
export const addCriterionWritingsError = (message) => ({
  type: ADD_CRITERION_WRITINGS_ERROR,
  payload: { message },
});

export const editCriterionWritingsRequest = (criterionWritings) => ({
  type: EDIT_CRITERION_WRITINGS_REQUEST,
  payload: { criterionWritings },
});
export const editCriterionWritingsSuccess = (message) => ({
  type: EDIT_CRITERION_WRITINGS_SUCCESS,
  payload: message,
});
export const editCriterionWritingsError = (message) => ({
  type: EDIT_CRITERION_WRITINGS_ERROR,
  payload: { message },
});

export const recuseWritingsRequest = (writings) => ({
  type: RECUSE_WRITINGS_REQUEST,
  payload: { writings },
});
export const recuseWritingsSuccess = (message) => ({
  type: RECUSE_WRITINGS_SUCCESS,
  payload: message,
});
export const recuseWritingsError = (message) => ({
  type: RECUSE_WRITINGS_ERROR,
  payload: { message },
});

export const acceptWritingsRequest = (writings) => ({
  type: ACCEPT_WRITINGS_REQUEST,
  payload: { writings },
});
export const acceptWritingsSuccess = (message) => ({
  type: ACCEPT_WRITINGS_SUCCESS,
  payload: message,
});
export const acceptWritingsError = (message) => ({
  type: ACCEPT_WRITINGS_ERROR,
  payload: { message },
});

export const setWritingsModalValues = (writingId, studentId) => ({
  type: SET_WRITINGS_MODAL_VALUES,
  payload: { writingId, studentId },
});

export const sendRatingsWritingsRequest = (writingStudents) => ({
  type: SEND_RATINGS_WRITINGS_REQUEST,
  payload: { writingStudents },
});
export const sendRatingsWritingsSuccess = (message) => ({
  type: SEND_RATINGS_WRITINGS_SUCCESS,
  payload: message,
});
export const sendRatingsWritingsError = (message) => ({
  type: SEND_RATINGS_WRITINGS_ERROR,
  payload: { message },
});
