import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  LIST_CRITERIONS_GROUPS_REQUEST,
  GET_CRITERIONS_GROUPS_REQUEST,
  ADD_CRITERIONS_GROUPS_REQUEST,
  EDIT_CRITERIONS_GROUPS_REQUEST,
  EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_REQUEST,
  GET_CRITERIONS_GROUPS_TYPES_REQUEST,
} from '../actions';
import {
  listCriterionsGroupsSuccess,
  listCriterionsGroupsError,
  getCriterionsGroupsSuccess,
  getCriterionsGroupsError,
  addCriterionsGroupsSuccess,
  addCriterionsGroupsError,
  editCriterionsGroupsSuccess,
  editCriterionsGroupsError,
  editCriterionsGroupsVinculateCriterionsSuccess,
  editCriterionsGroupsVinculateCriterionsError,
  getCriterionsGroupsTypesSuccess,
  getCriterionsGroupsTypesError,
} from './actions';

function* getCriterionsGroupsById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `groups/${id}`);
    yield put(getCriterionsGroupsSuccess(response.data.data));
  } catch (error) {
    yield put(getCriterionsGroupsError(error));
  }
}

const addCriterionsGroupsAsync = async (completeCriterion) =>
  api
    .post(`groups`, {
      ...completeCriterion,
    })
    .then((response) => response)
    .catch((error) => error);

const editCriterionsGroupsAsync = async (completeCriterion) =>
  api
    .put(`groups/${completeCriterion.id}`, {
      ...completeCriterion,
    })
    .then((response) => response)
    .catch((error) => error);

function* listCriterionsGroups() {
  try {
    const response = yield call(api.get, `groups`);
    yield put(listCriterionsGroupsSuccess(response.data.data));
  } catch (error) {
    yield put(listCriterionsGroupsError(error));
  }
}

function* addCriterionsGroups({ payload }) {
  try {
    const { criterionGroup } = payload;
    const completeCriterion = { ...criterionGroup };
    const res = yield call(addCriterionsGroupsAsync, completeCriterion);
    if (!res.response) {
      const responseValue = res.data.data;
      yield put(addCriterionsGroupsSuccess(responseValue));
    } else {
      yield put(addCriterionsGroupsError(res.response.data));
    }
  } catch (error) {
    yield put(addCriterionsGroupsError(error));
  }
}

function* editCriterionsGroups({ payload }) {
  try {
    const { criterionGroup } = payload;
    const completeCriterion = { ...criterionGroup };
    const res = yield call(editCriterionsGroupsAsync, completeCriterion);
    if (!res.response) {
      const responseValue = res.data.data;

      yield put(editCriterionsGroupsSuccess(responseValue));
    } else {
      yield put(editCriterionsGroupsError(res.response.data));
    }
  } catch (error) {
    yield put(editCriterionsGroupsError(error));
  }
}

const editCriterionsGroupsVinculateCriterionsAsync = async (criterionList) =>
  api
    .post(`criterions/groups`, {
      ...criterionList,
    })
    .then((response) => response)
    .catch((error) => error);

function* editCriterionsGroupsVinculateCriterions({ payload }) {
  try {
    const { criterionList } = payload;
    const res = yield call(
      editCriterionsGroupsVinculateCriterionsAsync,
      criterionList
    );
    if (!res.response) {
      yield put(
        editCriterionsGroupsVinculateCriterionsSuccess(res.data.message)
      );
    } else {
      yield put(
        editCriterionsGroupsVinculateCriterionsError(res.response.data)
      );
    }
  } catch (error) {
    yield put(editCriterionsGroupsVinculateCriterionsError(error));
  }
}

function* getCriterionsGroupsTypesById() {
  try {
    const response = yield call(api.get, `group-types`);
    yield put(getCriterionsGroupsTypesSuccess(response.data.data[0]?.id));
  } catch (error) {
    yield put(getCriterionsGroupsTypesError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_CRITERIONS_GROUPS_REQUEST, listCriterionsGroups),
    takeLatest(
      GET_CRITERIONS_GROUPS_TYPES_REQUEST,
      getCriterionsGroupsTypesById
    ),
    takeLatest(GET_CRITERIONS_GROUPS_REQUEST, getCriterionsGroupsById),
    takeLatest(ADD_CRITERIONS_GROUPS_REQUEST, addCriterionsGroups),
    takeLatest(EDIT_CRITERIONS_GROUPS_REQUEST, editCriterionsGroups),
    takeLatest(
      EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_REQUEST,
      editCriterionsGroupsVinculateCriterions
    ),
  ]);
}
