import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../../services/api';
import { LIST_CLASS_ASSESSMENT_TYPE_REQUEST } from '../../actions';
import {
  listClassAssessmentTypeSuccess,
  listClassAssessmentTypeError,
} from './actions';

function* listClassAssessmentType() {
  try {
    const response = yield call(api.get, 'classes-assessment-types');
    yield put(listClassAssessmentTypeSuccess(response.data.data));
  } catch (error) {
    yield put(listClassAssessmentTypeError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_CLASS_ASSESSMENT_TYPE_REQUEST, listClassAssessmentType),
  ]);
}
