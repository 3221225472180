import {
  LIST_NEWS_REQUEST,
  LIST_NEWS_SUCCESS,
  LIST_NEWS_ERROR,
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  GET_NEWS_ERROR,
  GET_NEWS_DESCRIPTION_REQUEST,
  GET_NEWS_DESCRIPTION_SUCCESS,
  GET_NEWS_DESCRIPTION_ERROR,
  ADD_NEWS_REQUEST,
  ADD_NEWS_SUCCESS,
  ADD_NEWS_ERROR,
  EDIT_NEWS_REQUEST,
  EDIT_NEWS_SUCCESS,
  EDIT_NEWS_ERROR,
  EDIT_ACTIVE_NEWS_REQUEST,
  EDIT_ACTIVE_NEWS_SUCCESS,
  EDIT_ACTIVE_NEWS_ERROR,
  EDIT_FIXED_NEWS_REQUEST,
  EDIT_FIXED_NEWS_SUCCESS,
  EDIT_FIXED_NEWS_ERROR,
  REMOVE_FILE_NEW_REQUEST,
  REMOVE_FILE_NEW_SUCCESS,
  REMOVE_FILE_NEW_ERROR
} from '../actions';

export const getNewsRequest = (id) => ({
  type: GET_NEWS_REQUEST,
  payload: { id },
});
export const getNewsSuccess = (news) => ({
  type: GET_NEWS_SUCCESS,
  payload: news,
});
export const getNewsError = (message) => ({
  type: GET_NEWS_ERROR,
  payload: { message },
});

export const getNewsDescriptionRequest = (id) => ({
  type: GET_NEWS_DESCRIPTION_REQUEST,
  payload: { id },
});
export const getNewsDescriptionSuccess = (news) => ({
  type: GET_NEWS_DESCRIPTION_SUCCESS,
  payload: news,
});
export const getNewsDescriptionError = (message) => ({
  type: GET_NEWS_DESCRIPTION_ERROR,
  payload: { message },
});

export const listNewsRequest = () => ({
  type: LIST_NEWS_REQUEST,
});
export const listNewsSuccess = (news) => ({
  type: LIST_NEWS_SUCCESS,
  payload: news,
});
export const listNewsError = (message) => ({
  type: LIST_NEWS_ERROR,
  payload: { message },
});

export const addNewsRequest = (news) => ({
  type: ADD_NEWS_REQUEST,
  payload: { news },
});
export const addNewsSuccess = (news) => ({
  type: ADD_NEWS_SUCCESS,
  payload: news,
});
export const addNewsError = (message) => ({
  type: ADD_NEWS_ERROR,
  payload: { message },
});

export const editNewsRequest = (news) => ({
  type: EDIT_NEWS_REQUEST,
  payload: { news },
});
export const editNewsSuccess = (news) => ({
  type: EDIT_NEWS_SUCCESS,
  payload: { news },
});
export const editNewsError = (message) => ({
  type: EDIT_NEWS_ERROR,
  payload: { message },
});

export const editActiveNewsRequest = (id, active) => ({
  type: EDIT_ACTIVE_NEWS_REQUEST,
  payload: { id, active },
});
export const editActiveNewsSuccess = (id, news) => ({
  type: EDIT_ACTIVE_NEWS_SUCCESS,
  payload: { id, news },
});
export const editActiveNewsError = (message) => ({
  type: EDIT_ACTIVE_NEWS_ERROR,
  payload: { message },
});

export const editFixedNewsRequest = (id, fixed) => ({
  type: EDIT_FIXED_NEWS_REQUEST,
  payload: { id, fixed },
});
export const editFixedNewsSuccess = (id, news) => ({
  type: EDIT_FIXED_NEWS_SUCCESS,
  payload: { id, news },
});
export const editFixedNewsError = (message) => ({
  type: EDIT_FIXED_NEWS_ERROR,
  payload: { message },
});
export const removeFileNewRequest = (id) => ({
  type: REMOVE_FILE_NEW_REQUEST,
  payload: { id },
});
export const removeFileNewSuccess = (id) => ({
  type: REMOVE_FILE_NEW_SUCCESS,
  payload: { id },
});
export const removeFileNewError = (id) => ({
  type: REMOVE_FILE_NEW_ERROR,
  payload: { id },
});
