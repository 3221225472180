import {
  LIST_STUDENT_REQUEST,
  LIST_STUDENT_SUCCESS,
  LIST_STUDENT_ERROR,
  LIST_STUDENT_WITH_DELETED_REQUEST,
  LIST_STUDENT_WITH_DELETED_SUCCESS,
  LIST_STUDENT_WITH_DELETED_ERROR,
  GET_STUDENT_REQUEST,
  GET_STUDENT_SUCCESS,
  GET_STUDENT_ERROR,
  EDIT_ACTIVE_STUDENT_REQUEST,
  EDIT_ACTIVE_STUDENT_SUCCESS,
  EDIT_ACTIVE_STUDENT_ERROR,
  RESET_CURRENT_VALUE,
  RESET_STATE_ALL_APPLICATION,
  IMPORT_STUDENT_REQUEST,
  IMPORT_STUDENT_SUCCESS,
  IMPORT_STUDENT_ERROR,
  GET_STUDY_ROUTINE_REQUEST,
  GET_STUDY_ROUTINE_SUCCESS,
  GET_STUDY_ROUTINE_ERROR,
  CHECK_STUDENT_REQUEST,
  CHECK_ALL_STUDENT_REQUEST,
  UNCHECK_ALL_STUDENT_REQUEST,
  DELETE_CLASS_STUDENT_SUCCESS,
  DELETE_CLASS_STUDENT_REQUEST,
  DELETE_CLASS_STUDENT_ERROR,
} from '../actions';

const INIT_STATE = {
  currentStudent: null,
  currentStudentList: null,
  currentStudentWithDeletedList: null,
  loading: false,
  success: '',
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case GET_STUDENT_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentStudent: action.payload,
        error: '',
      };
    case GET_STUDENT_ERROR:
      return {
        ...state,
        loading: false,
        currentStudent: null,
        error: action.payload.message,
      };
    case LIST_STUDENT_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentStudentList: action.payload,
        error: '',
      };
    case LIST_STUDENT_ERROR:
      return {
        ...state,
        loading: false,
        currentStudentList: null,
        error: action.payload.message,
      };
    case LIST_STUDENT_WITH_DELETED_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_STUDENT_WITH_DELETED_SUCCESS:
      return {
        ...state,
        loading: false,
        currentStudentWithDeletedList: action.payload,
        error: '',
      };
    case LIST_STUDENT_WITH_DELETED_ERROR:
      return {
        ...state,
        loading: false,
        currentStudentWithDeletedList: null,
        error: action.payload.message,
      };
    case EDIT_ACTIVE_STUDENT_REQUEST:
      return { ...state, loading: false, error: '', success: '' };
    case EDIT_ACTIVE_STUDENT_SUCCESS: {
      const studentsUpdated = state.currentStudentWithDeletedList.map(
        (student) => {
          if (student.id === action.payload.id) {
            if (action.payload.active) {
              return { ...student, deleted_at: null };
            }
            return { ...student, deleted_at: new Date().toISOString() };
          }
          return student;
        }
      );
      return {
        ...state,
        success: action.payload,
        currentStudentWithDeletedList: studentsUpdated,
        loading: false,
        error: '',
      };
    }
    case EDIT_ACTIVE_STUDENT_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    case RESET_CURRENT_VALUE:
      return {
        ...state,
        loading: false,
        currentStudent: null,
        error: '',
      };
    case IMPORT_STUDENT_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case IMPORT_STUDENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case IMPORT_STUDENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case CHECK_STUDENT_REQUEST:
      return {
        ...state,
        currentStudentList: state.currentStudentList.map((student) => {
          if (student.id === action.payload.id) {
            return { ...student, checked: !student.checked };
          }
          return student;
        }),
      };
    case CHECK_ALL_STUDENT_REQUEST:
      return {
        ...state,
        currentStudentList: state.currentStudentList?.map((student) => {
          return { ...student, checked: true };
        }),
      };
    case UNCHECK_ALL_STUDENT_REQUEST:
      return {
        ...state,
        currentStudentList: state.currentStudentList?.map((student) => {
          return { ...student, checked: false };
        }),
      };
    case GET_STUDY_ROUTINE_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case GET_STUDY_ROUTINE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case GET_STUDY_ROUTINE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case DELETE_CLASS_STUDENT_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case DELETE_CLASS_STUDENT_SUCCESS: {
      const { classStudentId, message } = action.payload;
      const updatedCurrentStudentList = state.currentStudentWithDeletedList.filter((student) => student.class_student_id !== classStudentId);

      return {
        ...state,
        currentStudentWithDeletedList: updatedCurrentStudentList,
        loading: false,
        error: '',
        success: { message },
      };
    }
    case DELETE_CLASS_STUDENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
