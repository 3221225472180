import {
  LIST_EXAM_PRACTICES_REQUEST,
  LIST_EXAM_PRACTICES_SUCCESS,
  LIST_EXAM_PRACTICES_ERROR,
  GET_EXAM_PRACTICES_REQUEST,
  GET_EXAM_PRACTICES_SUCCESS,
  GET_EXAM_PRACTICES_ERROR,
  ADD_EXAM_PRACTICES_REQUEST,
  ADD_EXAM_PRACTICES_SUCCESS,
  ADD_EXAM_PRACTICES_ERROR,
  EDIT_EXAM_PRACTICES_REQUEST,
  EDIT_EXAM_PRACTICES_SUCCESS,
  EDIT_EXAM_PRACTICES_ERROR,
  EDIT_ACTIVE_EXAM_PRACTICES_REQUEST,
  EDIT_ACTIVE_EXAM_PRACTICES_SUCCESS,
  EDIT_ACTIVE_EXAM_PRACTICES_ERROR,
  LIST_EXAM_PRACTICES_SUBJECTS_REQUEST,
  LIST_EXAM_PRACTICES_SUBJECTS_SUCCESS,
  LIST_EXAM_PRACTICES_SUBJECTS_ERROR,
  VINCULATE_EXAM_PRACTICES_SUBJECTS_ERROR,
  VINCULATE_EXAM_PRACTICES_SUBJECTS_REQUEST,
  VINCULATE_EXAM_PRACTICES_SUBJECTS_SUCCESS,
  VINCULATE_EXAM_PRACTICES_QUESTIONS_ERROR,
  VINCULATE_EXAM_PRACTICES_QUESTIONS_REQUEST,
  VINCULATE_EXAM_PRACTICES_QUESTIONS_SUCCESS,
  LIST_EXAM_PRACTICES_REPORTS_REQUEST,
  LIST_EXAM_PRACTICES_REPORTS_SUCCESS,
  LIST_EXAM_PRACTICES_REPORTS_ERROR,
  EXPORT_EXAM_PRACTICES_REPORTS_REQUEST,
  EXPORT_EXAM_PRACTICES_REPORTS_SUCCESS,
  EXPORT_EXAM_PRACTICES_REPORTS_ERROR,
  LIST_EXAM_PRACTICES_TYPES_REQUEST,
  LIST_EXAM_PRACTICES_TYPES_SUCCESS,
  LIST_EXAM_PRACTICES_TYPES_ERROR,
  IMPORT_EXAM_PRACTICES_REQUEST,
  IMPORT_EXAM_PRACTICES_SUCCESS,
  IMPORT_EXAM_PRACTICES_ERROR,
  REMOVE_IMPORT_EXAM_PRACTICES_REQUEST,
  REMOVE_IMPORT_EXAM_PRACTICES_SUCCESS,
  REMOVE_IMPORT_EXAM_PRACTICES_ERROR,
  SEND_EXAM_PRACTICE_REQUEST,
  SEND_EXAM_PRACTICE_SUCCESS,
  SEND_EXAM_PRACTICE_ERROR,
} from '../actions';

export const getExamPracticesRequest = (id) => ({
  type: GET_EXAM_PRACTICES_REQUEST,
  payload: { id },
});
export const getExamPracticesSuccess = (examPractices) => ({
  type: GET_EXAM_PRACTICES_SUCCESS,
  payload: examPractices,
});
export const getExamPracticesError = (message) => ({
  type: GET_EXAM_PRACTICES_ERROR,
  payload: { message },
});

export const listExamPracticesRequest = () => ({
  type: LIST_EXAM_PRACTICES_REQUEST,
});
export const listExamPracticesSuccess = (examPractices) => ({
  type: LIST_EXAM_PRACTICES_SUCCESS,
  payload: examPractices,
});
export const listExamPracticesError = (message) => ({
  type: LIST_EXAM_PRACTICES_ERROR,
  payload: { message },
});

export const addExamPracticesRequest = (examPractices) => ({
  type: ADD_EXAM_PRACTICES_REQUEST,
  payload: { examPractices },
});
export const addExamPracticesSuccess = (examPractices) => ({
  type: ADD_EXAM_PRACTICES_SUCCESS,
  payload: examPractices,
});
export const addExamPracticesError = (message) => ({
  type: ADD_EXAM_PRACTICES_ERROR,
  payload: { message },
});

export const editExamPracticesRequest = (examPractices) => ({
  type: EDIT_EXAM_PRACTICES_REQUEST,
  payload: { examPractices },
});
export const editExamPracticesSuccess = (examPractices) => ({
  type: EDIT_EXAM_PRACTICES_SUCCESS,
  payload: { examPractices },
});
export const editExamPracticesError = (message) => ({
  type: EDIT_EXAM_PRACTICES_ERROR,
  payload: { message },
});

export const editActiveExamPracticesRequest = (id, active) => ({
  type: EDIT_ACTIVE_EXAM_PRACTICES_REQUEST,
  payload: { id, active },
});
export const editActiveExamPracticesSuccess = (id, active) => ({
  type: EDIT_ACTIVE_EXAM_PRACTICES_SUCCESS,
  payload: { id, active },
});
export const editActiveExamPracticesError = (message) => ({
  type: EDIT_ACTIVE_EXAM_PRACTICES_ERROR,
  payload: { message },
});

export const listExamPracticesSubjectsRequest = () => ({
  type: LIST_EXAM_PRACTICES_SUBJECTS_REQUEST,
});
export const listExamPracticesSubjectsSuccess = (subjects) => ({
  type: LIST_EXAM_PRACTICES_SUBJECTS_SUCCESS,
  payload: subjects,
});
export const listExamPracticesSubjectsError = (message) => ({
  type: LIST_EXAM_PRACTICES_SUBJECTS_ERROR,
  payload: { message },
});

export const vinculateExamPracticesSubjectsRequest = (
  subjects,
  isExternalQuestion,
  examPracticeData
) => ({
  type: VINCULATE_EXAM_PRACTICES_SUBJECTS_REQUEST,
  payload: { subjects, isExternalQuestion, examPracticeData },
});
export const vinculateExamPracticesSubjectsSuccess = (
  subjects,
  isExternalQuestion
) => ({
  type: VINCULATE_EXAM_PRACTICES_SUBJECTS_SUCCESS,
  payload: { subjects, isExternalQuestion },
});
export const vinculateExamPracticesSubjectsError = (message) => ({
  type: VINCULATE_EXAM_PRACTICES_SUBJECTS_ERROR,
  payload: { message },
});

export const listExamPracticesReportsRequest = (classId, cycle) => ({
  type: LIST_EXAM_PRACTICES_REPORTS_REQUEST,
  payload: { classId, cycle },
});
export const listExamPracticesReportsSuccess = (examPracticesReports) => ({
  type: LIST_EXAM_PRACTICES_REPORTS_SUCCESS,
  payload: examPracticesReports,
});
export const listExamPracticesReportsError = (message) => ({
  type: LIST_EXAM_PRACTICES_REPORTS_ERROR,
  payload: { message },
});

export const exportExamPracticesReportsRequest = (
  classId,
  className,
  examPracticeId,
  examPracticeName
) => ({
  type: EXPORT_EXAM_PRACTICES_REPORTS_REQUEST,
  payload: { classId, className, examPracticeId, examPracticeName },
});
export const exportExamPracticesReportsSuccess = () => ({
  type: EXPORT_EXAM_PRACTICES_REPORTS_SUCCESS,
});
export const exportExamPracticesReportsError = (message) => ({
  type: EXPORT_EXAM_PRACTICES_REPORTS_ERROR,
  payload: { message },
});

export const listExamPracticesTypesRequest = () => ({
  type: LIST_EXAM_PRACTICES_TYPES_REQUEST,
});
export const listExamPracticesTypesSuccess = (examPracticesTypes) => ({
  type: LIST_EXAM_PRACTICES_TYPES_SUCCESS,
  payload: examPracticesTypes,
});
export const listExamPracticesTypesError = (message) => ({
  type: LIST_EXAM_PRACTICES_TYPES_ERROR,
  payload: { message },
});

export const importExamPracticesRequest = (files) => ({
  type: IMPORT_EXAM_PRACTICES_REQUEST,
  payload: { files },
});
export const importExamPracticesSuccess = (message) => ({
  type: IMPORT_EXAM_PRACTICES_SUCCESS,
  payload: message,
});
export const importExamPracticesError = (message) => ({
  type: IMPORT_EXAM_PRACTICES_ERROR,
  payload: { message },
});

export const removeImportExamPracticesRequest = (examPracticeId, id) => ({
  type: REMOVE_IMPORT_EXAM_PRACTICES_REQUEST,
  payload: { examPracticeId, id },
});
export const removeImportExamPracticesSuccess = (examPracticeId, id) => ({
  type: REMOVE_IMPORT_EXAM_PRACTICES_SUCCESS,
  payload: { examPracticeId, id },
});
export const removeImportExamPracticesError = (examPracticeId, id) => ({
  type: REMOVE_IMPORT_EXAM_PRACTICES_ERROR,
  payload: { examPracticeId, id },
});

export const vinculateExamPracticesQuestionsRequest = (
  questions,
  isLastSubject,
  questionsForPdf,
  answersForEdit
) => ({
  type: VINCULATE_EXAM_PRACTICES_QUESTIONS_REQUEST,
  payload: { questions, isLastSubject, questionsForPdf, answersForEdit },
});
export const vinculateExamPracticesQuestionsSuccess = (
  questions,
  isLastSubject
) => ({
  type: VINCULATE_EXAM_PRACTICES_QUESTIONS_SUCCESS,
  payload: { questions, isLastSubject },
});
export const vinculateExamPracticesQuestionsError = (message) => ({
  type: VINCULATE_EXAM_PRACTICES_QUESTIONS_ERROR,
  payload: { message },
});

export const sendExamPracticeRequest = (id) => ({
  type: SEND_EXAM_PRACTICE_REQUEST,
  payload: { id },
});
export const sendExamPracticeSuccess = (id, sentAt) => ({
  type: SEND_EXAM_PRACTICE_SUCCESS,
  payload: { id, sentAt },
});
export const sendExamPracticeError = (message) => ({
  type: SEND_EXAM_PRACTICE_ERROR,
  payload: { message },
});
