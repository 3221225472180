import { all, call, put, takeLatest } from 'redux-saga/effects';
import { currentUser } from '../../constants/defaultValues';
import { saveData } from '../../helpers/downloadFile';

import api from '../../services/api';
import {
  LIST_EXAM_REQUEST,
  GET_EXAM_REQUEST,
  ADD_EXAM_REQUEST,
  GENERATE_FILES_EXAM_REQUEST,
  EDIT_EXAM_REQUEST,
  EDIT_ACTIVE_EXAM_REQUEST,
} from '../actions';
import {
  listExamSuccess,
  listExamError,
  getExamSuccess,
  getExamError,
  addExamSuccess,
  addExamError,
  generateFilesExamSuccess,
  generateFilesExamError,
  editExamSuccess,
  editExamError,
  editActiveExamSuccess,
  editActiveExamError,
} from './actions';

function* getExamById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `exams/${id}`);
    yield put(getExamSuccess(response.data.data));
  } catch (error) {
    yield put(getExamError(error));
  }
}

function* listExam() {
  try {
    const response = yield call(
      api.get,
      `exams/school/${
        currentUser?.uid?.schools?.id ?? currentUser?.uid?.classes?.school_id
      }`
    );
    yield put(listExamSuccess(response.data.data));
  } catch (error) {
    yield put(listExamError(error));
  }
}

const addExamAsync = async (completeExam) =>
  api
    .post(`exams`, {
      ...completeExam,
    })
    .then((response) => response)
    .catch((error) => error);

const linkExamExternalQuestionsAsync = async (link) =>
  api.post(`exams/external-questions`, {
    ...link,
  });

const editExamAsync = async (completeExam) =>
  api
    .put(`exams/${completeExam.id}`, {
      ...completeExam,
    })
    .then((response) => response)
    .catch((error) => error);

function* addExam({ payload }) {
  try {
    const { exam } = payload;
    const completeExam = { ...exam, actionType: 'edit' };
    const res = yield call(addExamAsync, completeExam);
    if (!res.response) {
      const responseValue = res.data.data;
      yield call(linkExamExternalQuestionsAsync, {
        exam_id: responseValue.id,
        external_questions: exam.external_questions,
      });
      const formatedExam = {
        name: responseValue.name,
        active: responseValue.active,
        id: responseValue.id,
        subareas: [
          {
            id: responseValue.subareas.id,
            name: responseValue.subareas.name,
            subjects: [
              {
                id: responseValue.subareas.subjects[0].id,
                name: responseValue.subareas.subjects[0].name,
              },
            ],
          },
        ],
      };
      yield put(addExamSuccess(formatedExam));
    } else {
      yield put(addExamError(res.response.data));
    }
  } catch (error) {
    yield put(addExamError(error));
  }
}

function* editExam({ payload }) {
  try {
    const { exam } = payload;
    const completeExam = { ...exam, actionType: 'edit' };
    const res = yield call(editExamAsync, completeExam);
    if (!res.response) {
      const responseValue = res.data.data;
      yield call(linkExamExternalQuestionsAsync, {
        exam_id: responseValue.id,
        external_questions: exam.external_questions,
      });
      const formatedExam = {
        name: responseValue.name,
        active: responseValue.active,
        id: responseValue.id,
        subareas: [
          {
            id: responseValue.subareas.id,
            name: responseValue.subareas.name,
            subjects: [
              {
                id: responseValue.subareas.subjects[0].id,
                name: responseValue.subareas.subjects[0].name,
              },
            ],
          },
        ],
      };
      yield put(editExamSuccess(formatedExam));
    } else {
      yield put(editExamError(res.response.data));
    }
  } catch (error) {
    yield put(editExamError(error));
  }
}

function* editActiveExam({ payload }) {
  try {
    const { id, active } = payload;
    const response = yield call(api.put, `exams/${id}`, {
      active,
      actionType: 'activation',
    });
    const responseValue = response.data.data;
    const formatedExam = {
      name: responseValue.name,
      active: responseValue.active,
      id: responseValue.id,
      subareas: [
        {
          id: responseValue.subareas.id,
          name: responseValue.subareas.name,
          subjects: [
            {
              id: responseValue.subareas.subjects[0].id,
              name: responseValue.subareas.subjects[0].name,
            },
          ],
        },
      ],
    };
    yield put(editActiveExamSuccess(id, formatedExam));
  } catch (error) {
    yield put(editActiveExamError(error));
  }
}

const generateFilesExamAsync = async (completeExam) =>
  api
    .post(
      `exams/external-questions/generate-files`,
      {
        class_id: currentUser.uid.schools?.active_class?.id,
        ...completeExam,
      },
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => response)
    .catch((error) => error);

function* generateFilesExam({ payload }) {
  try {
    const { exam } = payload;
    const res = yield call(generateFilesExamAsync, exam);
    if (!res.response) {
      const responseValue = res.data;
      saveData(responseValue, exam.file_name);
      yield put(generateFilesExamSuccess(responseValue));
    } else {
      yield put(generateFilesExamError(res.response.data));
    }
  } catch (error) {
    yield put(generateFilesExamError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_EXAM_REQUEST, listExam),
    takeLatest(GET_EXAM_REQUEST, getExamById),
    takeLatest(ADD_EXAM_REQUEST, addExam),
    takeLatest(GENERATE_FILES_EXAM_REQUEST, generateFilesExam),
    takeLatest(EDIT_EXAM_REQUEST, editExam),
    takeLatest(EDIT_ACTIVE_EXAM_REQUEST, editActiveExam),
  ]);
}
