import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { auth } from '../../helpers/Firebase';
import api from '../../services/api';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CHECK_TOKEN_REQUEST,
  LEAVE_ACCESS,
  RELOAD_PASSWORD_SEND_MAIL_REQUEST,
  LOGIN_SCHOOL,
  LOGIN_CLASS,
  RESET_PASSWORD,
} from '../actions';

import {
  forgotPasswordCheckTokenError,
  forgotPasswordCheckTokenSuccess,
  forgotPasswordError,
  forgotPasswordSuccess,
  loginUserError,
  loginUserSuccess,
  registerUserError,
  registerUserSuccess,
  reloadPasswordSendMailError,
  reloadPasswordSendMailSuccess,
  resetPasswordError,
  resetPasswordSuccess
} from './actions';
import { resetStateAllApplication } from '../common/actions';
import { adminRoot, currentUser } from '../../constants/defaultValues';
import { setCurrentUser } from '../../helpers/Utils';
import { SchoolRole, UserRole } from '../../helpers/authHelper';

const loginWithEmailPasswordAsync = async (email, password) =>
  api
    .post(`backoffice/login`, {
      email,
      password,
    })
    .then((user) => user)
    .catch((error) => error);

const loginWithSchoolIdAsync = async (schoolId) =>
  api
    .post(`backoffice/login/school`, {
      school_id: schoolId,
    })
    .then((user) => user)
    .catch((error) => error);

const loginWithClassIdAsync = async (schoolId, classId) =>
  api
    .post(`backoffice/login/class`, {
      class_id: classId,
      school_id: schoolId,
    })
    .then((user) => user)
    .catch((error) => error);

function getPreviousSchools(schoolId, isAccess, multipleAccess) {
  if (multipleAccess) {
    if (currentUser.role !== UserRole.Admin) {
      return [
        {
          id: currentUser.uid.schools.id,
          name: currentUser.uid.schools.name,
        },
        ...multipleAccess,
      ];
    }
    return currentUser.previousSchools.length > 0
      ? [
          ...currentUser.previousSchools,
          {
            id: currentUser.uid.schools.id,
            name: currentUser.uid.schools.name,
          },
          ...multipleAccess,
        ]
      : [undefined, ...multipleAccess];
  }
  if (isAccess) {
    const index = currentUser.previousSchools.findIndex(
      (school) => school?.id === schoolId
    );
    if (index === -1) {
      return [
        ...currentUser.previousSchools,
        currentUser.uid.schools.id &&
        currentUser.schoolRole !== SchoolRole.Master
          ? {
              id: currentUser.uid.schools.id,
              name: currentUser.uid.schools.name,
            }
          : undefined,
      ];
    }
    const newSchools = [...currentUser.previousSchools];
    newSchools.length = index;
    return newSchools;
  }
  return [];
}

function* loginSchool({ payload }) {
  const { schoolId, history, isAccess, multipleAccess } = payload;
  try {
    const loginUserWithSchool = yield call(loginWithSchoolIdAsync, schoolId);
    if (!loginUserWithSchool.response) {
      if (loginUserWithSchool.data.active) {
        const itemWithSchool = {
          uid: loginUserWithSchool.data,
          role: loginUserWithSchool.data.permission_level,
          previousSchools: getPreviousSchools(
            schoolId,
            isAccess,
            multipleAccess
          ),
          schoolRole: loginUserWithSchool.data.schools.school_type.ordination,
        };
        setCurrentUser(itemWithSchool);
        yield put(loginUserSuccess(itemWithSchool));
        yield put(resetStateAllApplication());
        history.push(`${adminRoot}/dashboard`);
        history.go(0);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* loginClass({ payload }) {
  const { schoolId, classId, history } = payload;
  try {
    const loginUserWithClass = yield call(
      loginWithClassIdAsync,
      schoolId,
      classId
    );
    if (!loginUserWithClass.response) {
      if (loginUserWithClass.data.active) {
        const itemWithClass = {
          uid: {
            ...loginUserWithClass.data,
            classes: loginUserWithClass.data.schools.active_classes.find(
              (classValue) => classValue.id === classId
            ),
          },
          role: loginUserWithClass.data.permission_level,
          previousSchools: currentUser.previousSchools ?? [],
          schoolRole: SchoolRole.EducationInstitutionWithClass,
        };
        setCurrentUser(itemWithClass);
        yield put(loginUserSuccess(itemWithClass));
        yield put(resetStateAllApplication());
        history.push(`${adminRoot}/dashboard`);
        history.go(0);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.response) {
        const item = {
          uid: loginUser.data,
          role: loginUser.data.permission_level,
          schoolRole: 0,
          previousSchools: [],
        };
        setCurrentUser(item);
        api.defaults.headers.Authorization = `Bearer ${item.uid.access_token}`;
        yield put(loginUserSuccess(item));
        if (item.uid.permission_level === 0) {
          yield put(resetStateAllApplication());
          history.push(`${adminRoot}/dashboard`);
          history.go(0);
        }
    } else {
      yield put(loginUserError(loginUser.response.data));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLoginSchoolUser() {
  yield takeEvery(LOGIN_SCHOOL, loginSchool);
}

export function* watchLoginClassUser() {
  yield takeEvery(LOGIN_CLASS, loginClass);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const logoutAsync = async (history) => {
  api
    .post(`backoffice/logout`)
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const forgotPasswordAsync = async (email) => {
  return api
    .post(`backoffice/forgot`, {
      email,
    })
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const res = yield call(forgotPasswordAsync, email);
    if (!res.response) {
      yield put(forgotPasswordSuccess(res.data.message));
    } else {
      yield put(forgotPasswordError(res.response.data.error));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

const forgotPasswordCheckTokenAsync = async (token) => {
  return api
    .post(`backoffice/check-token`, {
      token,
    })
    .then((response) => response)
    .catch((error) => error);
};

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

function* forgotPasswordCheckToken({ payload }) {
  const { token } = payload;
  try {
    const res = yield call(forgotPasswordCheckTokenAsync, token);
    if (!res.response) {
      yield put(forgotPasswordCheckTokenSuccess(res.data.data.email));
    } else {
      yield put(forgotPasswordCheckTokenError(res.response.data));
    }
  } catch (error) {
    yield put(forgotPasswordCheckTokenError(error));
  }
}

export function* watchForgotPasswordCheckToken() {
  yield takeEvery(
    FORGOT_PASSWORD_CHECK_TOKEN_REQUEST,
    forgotPasswordCheckToken
  );
}

const resetPasswordAsync = async (
  email,
  token,
  password,
  passwordConfirmation
) => {
  return api
    .post(`backoffice/reset`, {
      email,
      token,
      password,
      password_confirmation: passwordConfirmation,
    })
    .then((response) => response)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { email, token, password, passwordConfirmation } = payload;
  try {
    const res = yield call(
      resetPasswordAsync,
      email,
      decodeURIComponent(token),
      password,
      passwordConfirmation
    );
    if (!res.response) {
      yield put(resetPasswordSuccess(res.data));
    } else {
      yield put(resetPasswordError(res.response.data));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

const reloadPasswordAsync = async (administratorId) => {
  return api
    .post(`backoffice/reload`, {
      userType: 'coordinator',
      administrator_id: administratorId,
    })
    .then((response) => response)
    .catch((error) => error);
};

function* reloadPassword({ payload }) {
  const { id } = payload;
  try {
    const res = yield call(reloadPasswordAsync, id);
    if (!res.response) {
      yield put(reloadPasswordSendMailSuccess(res.data.message));
      toast.success(res.data.message);
    } else {
      yield put(reloadPasswordSendMailError(res.response.data));
    }
  } catch (error) {
    yield put(reloadPasswordSendMailError(error));
  }
}

function* leaveAccess({ payload }) {
  const { history, isBreadcrumb } = payload;
  try {
    const school =
      currentUser?.uid?.classes?.school_id ?? currentUser.previousSchools.pop();

    if (school && !isBreadcrumb) {
      const loginUserWithSchool = yield call(
        loginWithSchoolIdAsync,
        school.id ?? school
      );
      if (!loginUserWithSchool.response) {
        if (loginUserWithSchool.data.active) {
          const itemWithSchool = {
            uid: loginUserWithSchool.data,
            role: loginUserWithSchool.data.permission_level,
            previousSchools: currentUser.previousSchools,
            schoolRole: loginUserWithSchool.data.schools.school_type.ordination,
          };
          setCurrentUser(itemWithSchool);
          yield put(loginUserSuccess(itemWithSchool));
          yield put(resetStateAllApplication());
          history.push(`${adminRoot}/dashboard`);
          history.go(0);
        }
      }
    } else {
      const itemWithSchool = {
        ...currentUser,
        schoolRole: 0,
        previousSchools: [],
      };
      setCurrentUser(itemWithSchool);
      yield put(loginUserSuccess(itemWithSchool));
      yield put(resetStateAllApplication());
      history.push(`${adminRoot}/dashboard`);
      history.go(0);
    }
  } catch (error) {
    // console.log(error);
  }
}

export function* watchLeaveAccess() {
  yield takeEvery(LEAVE_ACCESS, leaveAccess);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchReloadPassword() {
  yield takeEvery(RELOAD_PASSWORD_SEND_MAIL_REQUEST, reloadPassword);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchReloadPassword),
    fork(watchForgotPasswordCheckToken),
    fork(watchLeaveAccess),
    fork(watchLoginSchoolUser),
    fork(watchLoginClassUser),
  ]);
}
