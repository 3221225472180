import {
  LIST_SUBAREA_REQUEST,
  LIST_SUBAREA_SUCCESS,
  LIST_SUBAREA_ERROR,
  GET_SUBAREA_REQUEST,
  GET_SUBAREA_SUCCESS,
  GET_SUBAREA_ERROR,
  ADD_SUBAREA_REQUEST,
  ADD_SUBAREA_SUCCESS,
  ADD_SUBAREA_ERROR,
  EDIT_SUBAREA_REQUEST,
  EDIT_SUBAREA_SUCCESS,
  EDIT_SUBAREA_ERROR,
  EDIT_ACTIVE_SUBAREA_REQUEST,
  EDIT_ACTIVE_SUBAREA_SUCCESS,
  EDIT_ACTIVE_SUBAREA_ERROR,
  IMPORT_SUBAREA_REQUEST,
  IMPORT_SUBAREA_SUCCESS,
  IMPORT_SUBAREA_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  currentSubarea: null,
  currentSubareaList: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentSubarea: null,
        success: '',
      };
    case GET_SUBAREA_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_SUBAREA_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSubarea: action.payload,
        error: '',
      };
    case GET_SUBAREA_ERROR:
      return {
        ...state,
        loading: false,
        currentSubarea: null,
        error: action.payload.message,
      };
    case LIST_SUBAREA_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_SUBAREA_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSubareaList: action.payload,
        error: '',
      };
    case LIST_SUBAREA_ERROR:
      return {
        ...state,
        loading: false,
        currentSubareaList: null,
        error: action.payload.message,
      };
    case ADD_SUBAREA_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_SUBAREA_SUCCESS: {
      const allSubareas = [...state.currentSubareaList, action.payload];

      return {
        ...state,
        currentSubareaList: allSubareas,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case ADD_SUBAREA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_SUBAREA_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_SUBAREA_SUCCESS: {
      const subareasUpdated = state.currentSubareaList.map((subarea) => {
        if (subarea.id === action.payload.subarea.id) {
          return action.payload.subarea;
        }
        return subarea;
      });

      return {
        ...state,
        currentSubareaList: subareasUpdated,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case EDIT_SUBAREA_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    case EDIT_ACTIVE_SUBAREA_REQUEST:
      return { ...state, loading: false, error: '', success: '' };
    case EDIT_ACTIVE_SUBAREA_SUCCESS: {
      const SubareasUpdated = state.currentSubareaList.map((subarea) => {
        if (subarea.id === action.payload.id) {
          return action.payload.subarea;
        }
        return subarea;
      });

      return {
        ...state,
        currentSubareaList: SubareasUpdated,
        success: action.payload,
        loading: false,
        error: '',
      };
    }
    case EDIT_ACTIVE_SUBAREA_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    case IMPORT_SUBAREA_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case IMPORT_SUBAREA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case IMPORT_SUBAREA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
