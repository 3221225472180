import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  ADD_JOURNEYS_REQUEST,
  EDIT_JOURNEYS_REQUEST,
  GET_JOURNEYS_REQUEST,
  LIST_JOURNEYS_REQUEST
} from '../actions';
import {
  listJourneysSuccess,
  listJourneysError,
  addJourneysError,
  addJourneysSuccess,
  editJourneysError,
  editJourneysSuccess,
  getJourneysError,
  getJourneysSuccess,
} from './actions';

function* listJourneys() {
  try {
    const response = yield call(api.get, 'journeys');
    yield put(listJourneysSuccess(response.data.data));
  } catch (error) {
    yield put(listJourneysError(error));
  }
}

function* getJourneysById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `journeys/${id}`);
    yield put(getJourneysSuccess(response.data.data));
  } catch (error) {
    yield put(getJourneysError(error));
  }
}

const addJourneysAsync = async (completeJourneys) =>
  api
    .post(`journeys`, {
      ...completeJourneys,
    })
    .then((response) => response)
    .catch((error) => error);

const editJourneysAsync = async (completeJourneys) =>
  api
    .put(`journeys/${completeJourneys.id}`, {
      ...completeJourneys,
    })
    .then((response) => response)
    .catch((error) => error);

const addJourneysImageAsync = async (journeys) =>
  api
    .post(`journeys/upload`, {
      ...journeys.file,
    })
    .then((response) => response)
    .catch((error) => error);

function* addJourneys({ payload }) {
  try {
    const { journeys } = payload;
    const completeJourneys = { ...journeys, actionType: 'edit' };
    const res = yield call(addJourneysAsync, completeJourneys);
    if (!res.response) {
      const responseValue = res.data.data;
      if (completeJourneys.file) {
        completeJourneys.file.journey_id = responseValue.id;
        yield call(addJourneysImageAsync, completeJourneys);
      }
      yield put(addJourneysSuccess(responseValue));
    } else {
      yield put(addJourneysError(res.response.data));
    }
  } catch (error) {
    yield put(addJourneysError(error));
  }
}

function* editJourneys({ payload }) {
  try {
    const { journeys } = payload;
    const completeJourneys = { ...journeys, actionType: 'edit' };
    const res = yield call(editJourneysAsync, completeJourneys);
    if (!res.response) {
      const responseValue = res.data.data;
      if (completeJourneys.file) {
        completeJourneys.file.journey_id = responseValue.id;
        yield call(addJourneysImageAsync, completeJourneys);
      }
      yield put(editJourneysSuccess(responseValue));
    } else {
      yield put(editJourneysError(res.response.data));
    }
  } catch (error) {
    yield put(editJourneysError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_JOURNEYS_REQUEST, listJourneys),
    takeLatest(GET_JOURNEYS_REQUEST, getJourneysById),
    takeLatest(ADD_JOURNEYS_REQUEST, addJourneys),
    takeLatest(EDIT_JOURNEYS_REQUEST, editJourneys),
  ]);
}
