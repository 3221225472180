import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  GET_INCIDENCES_REQUEST,
  LIST_INCIDENCES_REQUEST,
} from "../actions";
import {
  listIncidencesSuccess,
  listIncidencesError,
  getIncidencesError,
  getIncidencesSuccess,
} from "./actions";

function* listIncidences() {
  try {
    const response = yield call(api.get, 'incidences');
    yield put(listIncidencesSuccess(response.data.data));
  } catch (error) {
    yield put(listIncidencesError(error));
  }
}

function* getIncidencesById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `incidences/${id}`);
    yield put(getIncidencesSuccess(response.data.data));
  } catch (error) {
    yield put(getIncidencesError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_INCIDENCES_REQUEST, listIncidences),
    takeLatest(GET_INCIDENCES_REQUEST, getIncidencesById),
  ]);
}
