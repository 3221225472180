import {
  LIST_NEW_TYPE_REQUEST,
  LIST_NEW_TYPE_SUCCESS,
  LIST_NEW_TYPE_ERROR,
} from '../../actions';

export const listNewTypesRequest = () => ({
  type: LIST_NEW_TYPE_REQUEST,
});
export const listNewTypesSuccess = (newTypes) => ({
  type: LIST_NEW_TYPE_SUCCESS,
  payload: newTypes,
});
export const listNewTypesError = (message) => ({
  type: LIST_NEW_TYPE_ERROR,
  payload: { message },
});
