import {
  LIST_VIDEO_REQUEST,
  LIST_VIDEO_SUCCESS,
  LIST_VIDEO_ERROR,
  GET_VIDEO_REQUEST,
  GET_VIDEO_SUCCESS,
  GET_VIDEO_ERROR,
  ADD_VIDEO_REQUEST,
  ADD_VIDEO_SUCCESS,
  ADD_VIDEO_ERROR,
  EDIT_VIDEO_REQUEST,
  EDIT_VIDEO_SUCCESS,
  EDIT_VIDEO_ERROR,
  EDIT_ACTIVE_VIDEO_REQUEST,
  EDIT_ACTIVE_VIDEO_SUCCESS,
  EDIT_ACTIVE_VIDEO_ERROR,
  ORDINATE_VIDEO_REQUEST,
  ORDINATE_VIDEO_SUCCESS,
  ORDINATE_VIDEO_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  currentVideo: null,
  currentVideoList: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentVideo: null,
        success: '',
      };
    case GET_VIDEO_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        currentVideo: action.payload,
        error: '',
      };
    case GET_VIDEO_ERROR:
      return {
        ...state,
        loading: false,
        currentVideo: null,
        error: action.payload.message,
      };
    case LIST_VIDEO_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        currentVideoList: action.payload,
        error: '',
      };
    case LIST_VIDEO_ERROR:
      return {
        ...state,
        loading: false,
        currentVideoList: null,
        error: action.payload.message,
      };
    case ADD_VIDEO_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_VIDEO_SUCCESS: {
      const allVideos = [...state.currentVideoList, action.payload];

      return {
        ...state,
        currentVideoList: allVideos,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_VIDEO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_VIDEO_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_VIDEO_SUCCESS: {
      const videosUpdated = state.currentVideoList.map((video) => {
        if (video.id === action.payload.video.id) {
          return action.payload.video;
        }
        return video;
      });

      return {
        ...state,
        currentVideoList: videosUpdated,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case EDIT_VIDEO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_ACTIVE_VIDEO_REQUEST:
      return { ...state, loading: false, error: '', success: '' };
    case EDIT_ACTIVE_VIDEO_SUCCESS: {
      const videosUpdated = state.currentVideoList.map((video) => {
        if (video.id === action.payload.id) {
          return action.payload.video;
        }
        return video;
      });

      return {
        ...state,
        currentVideoList: videosUpdated,
        success: action.payload,
        loading: false,
        error: '',
      };
    }
    case EDIT_ACTIVE_VIDEO_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    case ORDINATE_VIDEO_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ORDINATE_VIDEO_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.payload,
        currentVideoList: null,
        error: '',
      };
    }
    case ORDINATE_VIDEO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
