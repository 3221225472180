import {
  GET_METRICS_REQUEST,
  GET_METRICS_SUCCESS,
  GET_METRICS_ERROR,
  GET_SUBJECT_CATEGORIZATION_REQUEST,
  GET_SUBJECT_CATEGORIZATION_SUCCESS,
  GET_SUBJECT_CATEGORIZATION_ERROR,
  GET_RANK_TIME_REQUEST,
  GET_RANK_TIME_SUCCESS,
  GET_RANK_TIME_ERROR,
  GET_RANK_ACTIVITIES_REQUEST,
  GET_RANK_ACTIVITIES_SUCCESS,
  GET_RANK_ACTIVITIES_ERROR,
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_ERROR,
  DELETE_COMMENTS_REQUEST,
  DELETE_COMMENTS_SUCCESS,
  DELETE_COMMENTS_ERROR,
  GET_WEEKLY_PROGRESS_REQUEST,
  GET_WEEKLY_PROGRESS_SUCCESS,
  GET_WEEKLY_PROGRESS_ERROR,
  GET_COMMUNITY_STATUS_REQUEST,
  GET_COMMUNITY_STATUS_SUCCESS,
  GET_COMMUNITY_STATUS_ERROR,
  RESET_STATE_ALL_APPLICATION,
  GET_STUDENT_COMMITMENT_ROUTINE_REQUEST,
  GET_STUDENT_COMMITMENT_ROUTINE_SUCCESS,
  GET_STUDENT_COMMITMENT_ROUTINE_ERROR
} from '../actions';

const INIT_STATE = {
  metrics: null,
  subjectCategorization: null,
  rankTime: null,
  rankActivities: null,
  comments: null,
  weeklyProgress: null,
  communityStatus: null,
  studentCommitmentRoutine: null,
  metricsLoading: false,
  subjectCategorizationLoading: false,
  rankTimeLoading: false,
  rankActivitiesLoading: false,
  commentsLoading: false,
  communityStatusLoading: false,
  studentCommitmentRoutineLoading: false,
  weeklyProgressLoading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case GET_METRICS_REQUEST:
      return { ...state, metricsLoading: true, error: '' };
    case GET_METRICS_SUCCESS:
      return {
        ...state,
        metricsLoading: false,
        metrics: action.payload,
        error: '',
      };
    case GET_METRICS_ERROR:
      return {
        ...state,
        metricsLoading: false,
        metrics: null,
        error: action.payload.message,
      };

    case GET_SUBJECT_CATEGORIZATION_REQUEST:
      return { ...state, subjectCategorizationLoading: true, error: '' };
    case GET_SUBJECT_CATEGORIZATION_SUCCESS:
      return {
        ...state,
        subjectCategorizationLoading: false,
        subjectCategorization: action.payload,
        error: '',
      };
    case GET_SUBJECT_CATEGORIZATION_ERROR:
      return {
        ...state,
        subjectCategorizationLoading: false,
        subjectCategorization: null,
        error: action.payload.message,
      };
    case GET_RANK_TIME_REQUEST:
      return { ...state, rankTimeLoading: true, error: '' };
    case GET_RANK_TIME_SUCCESS:
      return {
        ...state,
        rankTimeLoading: false,
        rankTime: action.payload,
        error: '',
      };
    case GET_RANK_TIME_ERROR:
      return {
        ...state,
        rankTimeLoading: false,
        rankTime: null,
        error: action.payload.message,
      };

    case GET_RANK_ACTIVITIES_REQUEST:
      return { ...state, rankActivitiesLoading: true, error: '' };
    case GET_RANK_ACTIVITIES_SUCCESS:
      return {
        ...state,
        rankActivitiesLoading: false,
        rankActivities: action.payload,
        error: '',
      };
    case GET_RANK_ACTIVITIES_ERROR:
      return {
        ...state,
        rankActivitiesLoading: false,
        rankActivities: null,
        error: action.payload.message,
      };

    case GET_COMMENTS_REQUEST:
      return { ...state, commentsLoading: true, error: '' };
    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        commentsLoading: false,
        comments: action.payload,
        error: '',
      };
    case GET_COMMENTS_ERROR:
      return {
        ...state,
        commentsLoading: false,
        comments: null,
        error: action.payload.message,
      };
      case DELETE_COMMENTS_REQUEST:
        return { ...state, error: '' };
      case DELETE_COMMENTS_SUCCESS:
        const updatedDownvotedComments = state.comments?.comments?.downvoted_comments?.filter((comment) => {
          return comment.id !== action.payload.id
        });
        const updatedComments = {comments: {...state.comments?.comments, downvoted_comments: updatedDownvotedComments}}
        return {
          ...state,
          comments: updatedComments,
          error: '',
        };
      case DELETE_COMMENTS_ERROR:
        return {
          ...state,
          error: action.payload.message,
        };
    case GET_WEEKLY_PROGRESS_REQUEST:
      return { ...state, weeklyProgressLoading: true, error: '' };
    case GET_WEEKLY_PROGRESS_SUCCESS:
      return {
        ...state,
        weeklyProgressLoading: false,
        weeklyProgress: action.payload,
        error: '',
      };
    case GET_WEEKLY_PROGRESS_ERROR:
      return {
        ...state,
        weeklyProgressLoading: false,
        weeklyProgress: null,
        error: action.payload.message,
      };

    case GET_COMMUNITY_STATUS_REQUEST:
      return { ...state, communityStatusLoading: true, error: '' };
    case GET_COMMUNITY_STATUS_SUCCESS:
      return {
        ...state,
        communityStatusLoading: false,
        communityStatus: action.payload,
        error: '',
      };
    case GET_COMMUNITY_STATUS_ERROR:
      return {
        ...state,
        communityStatusLoading: false,
        communityStatus: null,
        error: action.payload.message,
      };
    case GET_STUDENT_COMMITMENT_ROUTINE_REQUEST:
      return { ...state, studentCommitmentRoutineLoading: true, error: '' };
    case GET_STUDENT_COMMITMENT_ROUTINE_SUCCESS:
      return {
        ...state,
        studentCommitmentRoutineLoading: false,
        studentCommitmentRoutine: action.payload,
        error: '',
      };
    case GET_STUDENT_COMMITMENT_ROUTINE_ERROR:
      return {
        ...state,
        studentCommitmentRoutineLoading: false,
        studentCommitmentRoutine: null,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
