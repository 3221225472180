import {
  LIST_SUBJECT_PERFORMANCE_REQUEST,
  LIST_SUBJECT_PERFORMANCE_SUCCESS,
  LIST_SUBJECT_PERFORMANCE_ERROR,
} from '../actions';

const INIT_STATE = {
  all: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_SUBJECT_PERFORMANCE_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_SUBJECT_PERFORMANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        all: action.payload,
        error: '',
      };
    case LIST_SUBJECT_PERFORMANCE_ERROR:
      return {
        ...state,
        loading: false,
        all: null,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
