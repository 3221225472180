import {
  LIST_SUBJECT_PERFORMANCE_REQUEST,
  LIST_SUBJECT_PERFORMANCE_SUCCESS,
  LIST_SUBJECT_PERFORMANCE_ERROR,
} from '../actions';

export const listSubjectPerformanceRequest = (classId, cycleIs, studentId) => ({
  type: LIST_SUBJECT_PERFORMANCE_REQUEST,
  payload: { classId, cycleIs, studentId },
});
export const listSubjectPerformanceSuccess = (
  allSubjectPerformance,
  isClass
) => ({
  type: LIST_SUBJECT_PERFORMANCE_SUCCESS,
  payload: { allSubjectPerformance, isClass },
});
export const listSubjectPerformanceError = (message) => ({
  type: LIST_SUBJECT_PERFORMANCE_ERROR,
  payload: { message },
});
