import { all, call, put, takeLatest } from 'redux-saga/effects';
import { currentUser } from '../../constants/defaultValues';

import api from '../../services/api';
import {
  LIST_SUBAREA_REQUEST,
  GET_SUBAREA_REQUEST,
  ADD_SUBAREA_REQUEST,
  EDIT_SUBAREA_REQUEST,
  EDIT_ACTIVE_SUBAREA_REQUEST,
  IMPORT_SUBAREA_REQUEST,
} from '../actions';
import {
  listSubareaSuccess,
  listSubareaError,
  getSubareaSuccess,
  getSubareaError,
  addSubareaSuccess,
  addSubareaError,
  editSubareaSuccess,
  editSubareaError,
  editActiveSubareaSuccess,
  editActiveSubareaError,
  importSubareaSuccess,
  importSubareaError,
} from './actions';

function* getSubareaById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `subareas/${id}`);
    yield put(getSubareaSuccess(response.data.data));
  } catch (error) {
    yield put(getSubareaError(error));
  }
}

const addSubareaAsync = async (completeSubarea) =>
  api
    .post(`subareas`, {
      ...completeSubarea,
    })
    .then((response) => response)
    .catch((error) => error);

const editSubareaAsync = async (completeSubarea) =>
  api
    .put(`subareas/${completeSubarea.id}`, {
      ...completeSubarea,
    })
    .then((response) => response)
    .catch((error) => error);

function* listSubarea() {
  try {
    const response = yield call(
      api.get,
      `subareas/school/${
        currentUser?.uid?.schools?.id ?? currentUser?.uid?.classes?.school_id
      }`
    );
    yield put(listSubareaSuccess(response.data.data));
  } catch (error) {
    yield put(listSubareaError(error));
  }
}

function* addSubarea({ payload }) {
  try {
    const { subarea } = payload;
    const completeSubarea = { ...subarea, actionType: 'edit' };
    const res = yield call(addSubareaAsync, completeSubarea);
    if (!res.response) {
      const responseValue = res.data.data;
      const formatedSubarea = {
        name: responseValue.name,
        subject: [
          {
            name: responseValue.subject[0].name,
            id: responseValue.subject[0].id,
          },
        ],
        incidence: responseValue.incidence?.name,
        id: responseValue.id,
        active: responseValue.active,
      };
      yield put(addSubareaSuccess(formatedSubarea));
    } else {
      yield put(addSubareaError(res.response.data));
    }
  } catch (error) {
    yield put(addSubareaError(error));
  }
}

function* editSubarea({ payload }) {
  try {
    const { subarea } = payload;
    const completeSubarea = { ...subarea, actionType: 'edit' };
    const res = yield call(editSubareaAsync, completeSubarea);
    if (!res.response) {
      const responseValue = res.data.data;
      const formatedSubarea = {
        name: responseValue.name,
        subject: [
          {
            name: responseValue.subject[0].name,
            id: responseValue.subject[0].id,
          },
        ],
        incidence: responseValue.incidence?.name,
        id: responseValue.id,
        active: responseValue.active,
      };
      yield put(editSubareaSuccess(formatedSubarea));
    } else {
      yield put(editSubareaError(res.response.data));
    }
  } catch (error) {
    yield put(editSubareaError(error));
  }
}

function* editActiveSubarea({ payload }) {
  try {
    const { id, active } = payload;
    const response = yield call(api.put, `subareas/${id}`, {
      active,
      actionType: 'activation',
    });
    const responseValue = response.data.data;
    const formatedSubarea = {
      name: responseValue.name,
      subject: [
        {
          name: responseValue.subject[0].name,
          id: responseValue.subject[0].id,
        },
      ],
      incidence: responseValue.incidence?.name,
      id: responseValue.id,
      active: responseValue.active,
    };
    yield put(editActiveSubareaSuccess(id, formatedSubarea));
  } catch (error) {
    yield put(editActiveSubareaError(error));
  }
}

const importSubareaAsync = async (files) =>
  api
    .post(`subareas/import`, files)
    .then((response) => response)
    .catch((error) => error);

function* importSubarea({ payload }) {
  try {
    const { files } = payload;
    const res = yield call(importSubareaAsync, files);
    if (!res.response) {
      yield put(importSubareaSuccess(res.data));
    } else {
      yield put(importSubareaError(res.response.data));
    }
  } catch (error) {
    yield put(importSubareaError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_SUBAREA_REQUEST, listSubarea),
    takeLatest(GET_SUBAREA_REQUEST, getSubareaById),
    takeLatest(ADD_SUBAREA_REQUEST, addSubarea),
    takeLatest(EDIT_SUBAREA_REQUEST, editSubarea),
    takeLatest(EDIT_ACTIVE_SUBAREA_REQUEST, editActiveSubarea),
    takeLatest(IMPORT_SUBAREA_REQUEST, importSubarea),
  ]);
}
