import {
  LIST_CLASS_REQUEST,
  LIST_CLASS_SUCCESS,
  LIST_CLASS_ERROR,
  LIST_CLASS_JOURNEY_REQUEST,
  LIST_CLASS_JOURNEY_SUCCESS,
  LIST_CLASS_JOURNEY_ERROR,
  LIST_CLASS_PREREQUISITES_REQUEST,
  LIST_CLASS_PREREQUISITES_SUCCESS,
  LIST_CLASS_PREREQUISITES_ERROR,
  GET_CLASS_REQUEST,
  GET_CLASS_SUCCESS,
  GET_CLASS_ERROR,
  ADD_CLASS_REQUEST,
  ADD_CLASS_SUCCESS,
  ADD_CLASS_ERROR,
  EDIT_CLASS_REQUEST,
  EDIT_CLASS_SUCCESS,
  EDIT_CLASS_ERROR,
  EDIT_ACTIVE_CLASS_REQUEST,
  EDIT_ACTIVE_CLASS_SUCCESS,
  EDIT_ACTIVE_CLASS_ERROR,
  RESET_STATE_REDUX,
  EDIT_CLASS_VINCULATE_SUBAREA_REQUEST,
  EDIT_CLASS_VINCULATE_SUBAREA_SUCCESS,
  EDIT_CLASS_VINCULATE_SUBAREA_ERROR,
  VINCULATE_SUBAREA,
  RESET_VINCULATE_SUBAREA,
  RESET_STATE_ALL_APPLICATION,
  EDIT_CLASS_VINCULATE_SUBJECT_REQUEST,
  EDIT_CLASS_VINCULATE_SUBJECT_SUCCESS,
  EDIT_CLASS_VINCULATE_SUBJECT_ERROR,
  VINCULATE_SUBJECT,
  RESET_VINCULATE_SUBJECT,
  EDIT_CLASS_VINCULATE_PREREQUISITE_REQUEST,
  EDIT_CLASS_VINCULATE_PREREQUISITE_SUCCESS,
  EDIT_CLASS_VINCULATE_PREREQUISITE_ERROR,
  GET_CLASS_WITH_STUDY_TIME_REQUEST,
  GET_CLASS_WITH_STUDY_TIME_SUCCESS,
  GET_CLASS_WITH_STUDY_TIME_ERROR
} from '../actions';

const INIT_STATE = {
  currentClass: null,
  currentClassWithStudyTime: null,
  currentClassList: null,
  currentClassJourneyList: null,
  currentVinculateSubareaList: [],
  currentVinculateSubjectList: [],
  currentClassPrerequisites:[],
  lastSubjectModified: null,
  loading: false,
  success: '',
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentClass: null,
        success: '',
      };
    case GET_CLASS_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentClass: action.payload,
        error: null,
      };
    case GET_CLASS_ERROR:
      return {
        ...state,
        loading: false,
        currentClass: null,
        error: action.payload.message,
      };
    case GET_CLASS_WITH_STUDY_TIME_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_CLASS_WITH_STUDY_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
          currentClassWithStudyTime: action.payload,
        error: null,
      };
    case GET_CLASS_WITH_STUDY_TIME_ERROR:
      return {
        ...state,
        loading: false,
        currentClassWithStudyTime: null,
        error: action.payload.message,
      };
    case LIST_CLASS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentClassList: action.payload,
        error: null,
      };
    case LIST_CLASS_ERROR:
      return {
        ...state,
        loading: false,
        currentClassList: null,
        error: action.payload.message,
      };
    case LIST_CLASS_JOURNEY_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_CLASS_JOURNEY_SUCCESS:
      return {
        ...state,
        loading: false,
        currentClassJourneyList: action.payload,
        error: null,
      };
    case LIST_CLASS_JOURNEY_ERROR:
      return {
        ...state,
        loading: false,
        currentClassJourneyList: null,
        error: action.payload.message,
      };
      case LIST_CLASS_PREREQUISITES_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_CLASS_PREREQUISITES_SUCCESS:
      return {
        ...state,
        loading: false,
        currentClassPrerequisites: action.payload.prerequisites,
        error: null,
      };
    case LIST_CLASS_PREREQUISITES_ERROR:
      return {
        ...state,
        loading: false,
        currentClassPrerequisites: [],
        error: action.payload.message,
      };
    case ADD_CLASS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_CLASS_SUCCESS: {
      const allClasses = [...state.currentClassList, action.payload];
      return {
        ...state,
        currentClassList: allClasses,
        loading: false,
        error: null,
        success: action.payload,
      };
    }
    case ADD_CLASS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_CLASS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_CLASS_SUCCESS: {
      const classesUpdated = state.currentClassList.map((classValue) => {
        if (classValue.id === action.payload.classValue.id) {
          const responseValue = action.payload.classValue;
          responseValue.students_count = classValue.students_count;
          return responseValue;
        }
        return classValue;
      });

      return {
        ...state,
        currentClassList: classesUpdated,
        loading: false,
        error: null,
        success: action.payload,
      };
    }
    case EDIT_CLASS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_ACTIVE_CLASS_REQUEST:
      return { ...state, loading: false, error: '', success: '' };
    case EDIT_ACTIVE_CLASS_SUCCESS: {
      const classesUpdated = state.currentClassList.map((classValue) => {
        if (classValue.id === action.payload.id) {
          const responseValue = action.payload.classValue;
          responseValue.students_count = classValue.students_count;
          return responseValue;
        }
        return classValue;
      });

      return {
        ...state,
        currentClassList: classesUpdated,
        success: action.payload,
        loading: false,
        error: null,
      };
    }
    case EDIT_ACTIVE_CLASS_ERROR:
      return {
        ...state,
        success: '',
        loading: false,
        error: action.payload.message,
      };
    case EDIT_CLASS_VINCULATE_SUBAREA_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_CLASS_VINCULATE_SUBAREA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case EDIT_CLASS_VINCULATE_SUBAREA_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    case VINCULATE_SUBAREA: {
      const newVinculation = [...action.payload.subarea];
      const listUpdated = [...newVinculation];
      return {
        ...state,
        currentVinculateSubareaList: listUpdated,
        lastSubjectModified: action.payload.subject_id,
        error: '',
      };
    }
    case RESET_VINCULATE_SUBAREA: {
      return {
        ...state,
        currentVinculateSubareaList: [],
        lastSubjectModified: null,
        error: '',
      };
    }
    case EDIT_CLASS_VINCULATE_SUBJECT_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_CLASS_VINCULATE_SUBJECT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        success: action.payload,
      };
    }
    case EDIT_CLASS_VINCULATE_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    case VINCULATE_SUBJECT: {
      const newVinculation = [...action.payload.subject];
      const listUpdated = [...newVinculation];
      return {
        ...state,
        currentVinculateSubareaList: listUpdated,
        lastSubjectModified: action.payload.subject_id,
        error: '',
      };
    }
    case RESET_VINCULATE_SUBJECT: {
      return {
        ...state,
        currentVinculateSubareaList: [],
        lastSubjectModified: null,
        error: '',
      };
    }
    case EDIT_CLASS_VINCULATE_PREREQUISITE_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_CLASS_VINCULATE_PREREQUISITE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        success: action.payload,
      };
    }
    case EDIT_CLASS_VINCULATE_PREREQUISITE_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
