import {
  GET_SCHOOL_REQUEST,
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_ERROR,
  GET_SCHOOL_PARENTS_REQUEST,
  GET_SCHOOL_PARENTS_SUCCESS,
  GET_SCHOOL_PARENTS_ERROR,
  GET_SCHOOL_TYPES_REQUEST,
  GET_SCHOOL_TYPES_SUCCESS,
  GET_SCHOOL_TYPES_ERROR,
  LIST_SCHOOL_REQUEST,
  LIST_SCHOOL_SUCCESS,
  LIST_SCHOOL_ERROR,
  ADD_SCHOOL_REQUEST,
  ADD_SCHOOL_SUCCESS,
  ADD_SCHOOL_ERROR,
  EDIT_SCHOOL_REQUEST,
  EDIT_SCHOOL_SUCCESS,
  EDIT_SCHOOL_ERROR,
  EDIT_ACTIVE_SCHOOL_REQUEST,
  EDIT_ACTIVE_SCHOOL_SUCCESS,
  EDIT_ACTIVE_SCHOOL_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  currentSchool: null,
  currentSchoolParents: null,
  currentSchoolList: null,
  schoolTypes: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentSchool: null,
        success: '',
      };
    case GET_SCHOOL_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSchool: action.payload,
        error: '',
      };
    case GET_SCHOOL_ERROR:
      return {
        ...state,
        loading: false,
        currentSchool: null,
        error: action.payload.message,
      };
    case GET_SCHOOL_PARENTS_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_SCHOOL_PARENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSchoolParents: action.payload,
        error: '',
      };
    case GET_SCHOOL_PARENTS_ERROR:
      return {
        ...state,
        loading: false,
        currentSchoolParents: null,
        error: action.payload.message,
      };
    case GET_SCHOOL_TYPES_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_SCHOOL_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        schoolTypes: action.payload,
        error: '',
      };
    case GET_SCHOOL_TYPES_ERROR:
      return {
        ...state,
        loading: false,
        schoolTypes: null,
        error: action.payload.message,
      };
    case LIST_SCHOOL_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSchoolList: action.payload,
        error: '',
      };
    case LIST_SCHOOL_ERROR:
      return {
        ...state,
        loading: false,
        currentSchoolList: null,
        error: action.payload.message,
      };
    case ADD_SCHOOL_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_SCHOOL_SUCCESS: {
      const allSchools = action.payload
        ? [...state.currentSchoolList, action.payload]
        : state.currentSchoolList;

      return {
        ...state,
        currentSchoolList: allSchools,
        loading: false,
        error: '',
        success: action.payload ?? true,
      };
    }
    case ADD_SCHOOL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_SCHOOL_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_SCHOOL_SUCCESS: {
      const schoolsUpdated = state.currentSchoolList.map((school) => {
        if (school.id === action.payload.school.id) {
          return action.payload.school;
        }
        return school;
      });

      return {
        ...state,
        currentSchoolList: schoolsUpdated,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case EDIT_SCHOOL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_ACTIVE_SCHOOL_REQUEST:
      return { ...state, loading: false, error: '', success: '' };
    case EDIT_ACTIVE_SCHOOL_SUCCESS: {
      const schoolsUpdated = state.currentSchoolList.map((school) => {
        if (school.id === action.payload.id) {
          return action.payload.school;
        }
        return school;
      });

      return {
        ...state,
        currentSchoolList: schoolsUpdated,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case EDIT_ACTIVE_SCHOOL_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
