import { all, call, put, takeLatest } from 'redux-saga/effects';

import { apiQuestionDb, apiUrl } from '../../services/api';
import {
  LIST_QUESTION_DB_YEARS_REQUEST,
  LIST_QUESTION_DB_CATEGORIES_REQUEST,
  LIST_QUESTION_DB_INSTITUTIONS_REQUEST,
  LIST_QUESTION_DB_SKILLS_REQUEST,
  LIST_QUESTION_DB_SUBJECTS_REQUEST,
  LIST_QUESTION_DB_QUESTIONS_REQUEST,
  LIST_CATEGORIES_CHILDRENS_REQUEST,
} from '../actions';
import {
  listQuestionDbYearsSuccess,
  listQuestionDbYearsError,
  listQuestionDbCategoriesSuccess,
  listQuestionDbCategoriesError,
  listQuestionDbInstitutionsSuccess,
  listQuestionDbInstitutionsError,
  listQuestionDbSkillsSuccess,
  listQuestionDbSkillsError,
  listQuestionDbSubjectsSuccess,
  listQuestionDbSubjectsError,
  listQuestionDbQuestionsSuccess,
  listQuestionDbQuestionsError,
  listCategoriesChildrensSuccess,
  listCategoriesChildrensError,
} from './actions';

function* listQuestionDbYears() {
  try {
    const response = yield call(apiQuestionDb.get, `years`);
    yield put(listQuestionDbYearsSuccess(response.data.data));
  } catch (error) {
    yield put(listQuestionDbYearsError(error));
  }
}

function* listQuestionDbCategories() {
  try {
    const response = yield call(apiQuestionDb.get, `categories`);
    yield put(listQuestionDbCategoriesSuccess(response.data.data));
  } catch (error) {
    yield put(listQuestionDbCategoriesError(error));
  }
}

function* listCategoriesChildrens({ payload }) {
  const { categoryId, level } = payload;
  try {
    const response = yield call(
      apiQuestionDb.get,
      `categories/${categoryId}/children`
    );
    yield put(listCategoriesChildrensSuccess(response.data.data, level));
  } catch (error) {
    yield put(listCategoriesChildrensError(error));
  }
}

function* listQuestionDbInstitutions() {
  try {
    const response = yield call(apiQuestionDb.get, `institutions`);
    yield put(listQuestionDbInstitutionsSuccess(response.data.data));
  } catch (error) {
    yield put(listQuestionDbInstitutionsError(error));
  }
}

function* listQuestionDbSkills() {
  try {
    const response = yield call(apiQuestionDb.get, `skills`);
    yield put(listQuestionDbSkillsSuccess(response.data.data));
  } catch (error) {
    yield put(listQuestionDbSkillsError(error));
  }
}

function* listQuestionDbSubjects() {
  try {
    const response = yield call(apiQuestionDb.get, `subjects`);
    yield put(listQuestionDbSubjectsSuccess(response.data.data));
  } catch (error) {
    yield put(listQuestionDbSubjectsError(error));
  }
}

const listQuestionsAsync = async (url, endpoint) => {
  if (url) {
    return apiUrl
      .get(url)
      .then((response) => response)
      .catch((error) => error);
  }
  return apiQuestionDb
    .get(`questions/1${endpoint}`)
    .then((response) => response)
    .catch((error) => error);
};

function* listQuestionDbQuestions({ payload }) {
  const { url, endpoint } = payload;
  try {
    const response = yield call(listQuestionsAsync, url, endpoint);
    yield put(
      listQuestionDbQuestionsSuccess(response.data.data, {
        meta: response.data.meta,
        total: response.data.total,
        links: response.data.links,
      })
    );
  } catch (error) {
    yield put(listQuestionDbQuestionsError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_QUESTION_DB_YEARS_REQUEST, listQuestionDbYears),
    takeLatest(LIST_CATEGORIES_CHILDRENS_REQUEST, listCategoriesChildrens),
    takeLatest(LIST_QUESTION_DB_CATEGORIES_REQUEST, listQuestionDbCategories),
    takeLatest(
      LIST_QUESTION_DB_INSTITUTIONS_REQUEST,
      listQuestionDbInstitutions
    ),
    takeLatest(LIST_QUESTION_DB_SKILLS_REQUEST, listQuestionDbSkills),
    takeLatest(LIST_QUESTION_DB_SUBJECTS_REQUEST, listQuestionDbSubjects),
    takeLatest(LIST_QUESTION_DB_QUESTIONS_REQUEST, listQuestionDbQuestions),
  ]);
}
