import {
  LIST_FEEDBACK_REQUEST,
  LIST_FEEDBACK_SUCCESS,
  LIST_FEEDBACK_ERROR,
  ADD_OR_EDIT_FEEDBACK_REQUEST,
  ADD_OR_EDIT_FEEDBACK_SUCCESS,
  ADD_OR_EDIT_FEEDBACK_ERROR,
  REMOVE_FILE_FEEDBACK_REQUEST,
  REMOVE_FILE_FEEDBACK_SUCCESS,
  REMOVE_FILE_FEEDBACK_ERROR,
  REMOVE_FEEDBACK_REQUEST,
  REMOVE_FEEDBACK_SUCCESS,
  REMOVE_FEEDBACK_ERROR
} from '../../actions';

export const listFeedbackRequest = (studentId) => ({
  type: LIST_FEEDBACK_REQUEST,
  payload: { studentId }
});
export const listFeedbackSuccess = (feedbacks) => ({
  type: LIST_FEEDBACK_SUCCESS,
  payload: feedbacks,
});
export const listFeedbackError = (message) => ({
  type: LIST_FEEDBACK_ERROR,
  payload: { message },
});

export const addOrEditFeedbackRequest = (feedback) => ({
  type: ADD_OR_EDIT_FEEDBACK_REQUEST,
  payload: { feedback },
});
export const addOrEditFeedbackSuccess = (feedback) => ({
  type: ADD_OR_EDIT_FEEDBACK_SUCCESS,
  payload: feedback,
});
export const addOrEditFeedbackError = (message) => ({
  type: ADD_OR_EDIT_FEEDBACK_ERROR,
  payload: { message },
});

export const removeFeedbackRequest = (newId) => ({
  type: REMOVE_FEEDBACK_REQUEST,
  payload: { newId },
});
export const removeFeedbackSuccess = (newId) => ({
  type: REMOVE_FEEDBACK_SUCCESS,
  payload: { newId },
});
export const removeFeedbackError = (newId) => ({
  type: REMOVE_FEEDBACK_ERROR,
  payload: { newId },
});

export const removeFileFeedbackRequest = (id) => ({
  type: REMOVE_FILE_FEEDBACK_REQUEST,
  payload: { id },
});
export const removeFileFeedbackSuccess = (id) => ({
  type: REMOVE_FILE_FEEDBACK_SUCCESS,
  payload: { id },
});
export const removeFileFeedbackError = (message) => ({
  type: REMOVE_FILE_FEEDBACK_ERROR,
  payload: { message },
});
