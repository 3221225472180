import {
  LIST_CLASS_TYPE_REQUEST,
  LIST_CLASS_TYPE_SUCCESS,
  LIST_CLASS_TYPE_ERROR,
} from '../../actions';

export const listClassTypeRequest = () => ({
  type: LIST_CLASS_TYPE_REQUEST,
});
export const listClassTypeSuccess = (cycles) => ({
  type: LIST_CLASS_TYPE_SUCCESS,
  payload: cycles,
});
export const listClassTypeError = (message) => ({
  type: LIST_CLASS_TYPE_ERROR,
  payload: { message },
});
