import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../services/api';
import {
  LIST_TEACHER_REQUEST,
  LIST_TEACHER_ACTIVITY_REQUEST,
  CONCLUDE_TEACHER_ACTIVITY_REQUEST,
  IMPORT_TEACHER_ACTIVITY_REQUEST,
  REMOVE_IMPORT_TEACHER_ACTIVITY_REQUEST,
  GET_TEACHER_REQUEST,
  ADD_TEACHER_REQUEST,
  ADD_TEACHER_LINK_REQUEST,
  REMOVE_TEACHER_LINK_REQUEST,
  EDIT_TEACHER_REQUEST,
  EDIT_ACTIVE_TEACHER_REQUEST,
  EDIT_TEACHER_VINCULATE_SUBJECT_REQUEST,
  LIST_TEACHER_SUBJECT_REQUEST,
} from '../actions';
import {
  listTeacherSuccess,
  listTeacherError,
  editTeacherVinculateSubjectError,
  editTeacherVinculateSubjectSuccess,
  listTeacherActivitySuccess,
  listTeacherActivityError,
  concludeTeacherActivitySuccess,
  concludeTeacherActivityError,
  importTeacherActivitySuccess,
  importTeacherActivityError,
  removeImportTeacherActivitySuccess,
  removeImportTeacherActivityError,
  getTeacherSuccess,
  getTeacherError,
  addTeacherSuccess,
  addTeacherError,
  addTeacherLinkSuccess,
  addTeacherLinkError,
  removeTeacherLinkSuccess,
  removeTeacherLinkError,
  editTeacherSuccess,
  editTeacherError,
  editActiveTeacherSuccess,
  editActiveTeacherError,
  listTeacherSubjectSuccess,
  listTeacherSubjectError,
} from './actions';
import { formatDateToYYYYMMDD } from '../../helpers/custom';

function* getTeacherById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `administrators/${id}`);
    yield put(getTeacherSuccess(response.data.data));
  } catch (error) {
    yield put(getTeacherError(error));
  }
}

const addTeachersAsync = async (completeTeacher) =>
  api
    .post(`backoffice/register`, {
      ...completeTeacher,
    })
    .then((response) => response)
    .catch((error) => error);

const editTeachersAsync = async (completeTeacher) =>
  api
    .put(`administrators/${completeTeacher.id}`, {
      ...completeTeacher,
    })
    .then((response) => response)
    .catch((error) => error);

function* listTeacher() {
  try {
    const response = yield call(api.get, 'administrators/permission-level/2');

    yield put(listTeacherSuccess(response.data.data));
  } catch (error) {
    yield put(listTeacherError(error));
  }
}

function* listTeacherSubject({ payload }) {
  try {
    const { schoolId } = payload;
    const response = yield call(
      api.get,
      `administrators/subjects/school/${schoolId}`
    );

    yield put(listTeacherSubjectSuccess(response.data.data));
  } catch (error) {
    yield put(listTeacherSubjectError(error));
  }
}

function* listTeacherActivity({ payload }) {
  try {
    const { subjectId, cycleIs } = payload;
    const body = {
      subject_id: subjectId,
      cycle_is: cycleIs,
    };
    const response = yield call(api.post, 'subareas/activities', body);

    yield put(
      listTeacherActivitySuccess(
        response.data.data.map((subarea) => {
          return {
            ...subarea,
            links: subarea.administrator_subareas.flatMap((a) => a.links),
          };
        })
      )
    );
  } catch (error) {
    yield put(listTeacherActivityError(error));
  }
}

function* concludeTeacherActivity({ payload }) {
  const { subareaId, activityId, concludedAt, studyTimeframe } = payload;
  try {
    yield put(concludeTeacherActivitySuccess(subareaId, activityId, concludedAt));

    const body = {
      activity_id: activityId,
      concluded_at: concludedAt ? formatDateToYYYYMMDD(concludedAt) : null,
      study_timeframe: studyTimeframe
    };
    yield call(api.post, 'administrators/activities/conclude', body);

    toast.success(
      `Atividade ${concludedAt ? 'concluída' : 'removida'} com sucesso!`
    );
  } catch (error) {
    yield put(concludeTeacherActivityError(subareaId, activityId, !concludedAt));
    toast.error(
      `Ocorreu um erro ao ${concludedAt ? 'concluir' : 'remover'} a atividade!`
    );
  }
}

function* addTeacher({ payload }) {
  try {
    const { teacher } = payload;
    const completeTeacher = { ...teacher, actionType: 'edit' };
    const res = yield call(addTeachersAsync, completeTeacher);
    if (!res.response) {
      const responseValue = res.data.data;
      const formatedTeacher = {
        name: responseValue.name,
        active: responseValue.active,
        id: responseValue.id,
        schools: responseValue.schools,
        email: responseValue.email,
      };
      yield put(addTeacherSuccess(formatedTeacher));
    } else {
      yield put(addTeacherError(res.response.data));
    }
  } catch (error) {
    yield put(addTeacherError(error));
  }
}

function* editTeacher({ payload }) {
  try {
    const { teacher } = payload;
    const completeTeacher = { ...teacher, actionType: 'edit' };
    const res = yield call(editTeachersAsync, completeTeacher);
    if (!res.response) {
      const responseValue = res.data.data;
      const formatedTeacher = {
        name: responseValue.name,
        active: responseValue.active,
        id: responseValue.id,
        schools: responseValue.schools,
        email: responseValue.email,
      };
      yield put(editTeacherSuccess(formatedTeacher));
    } else {
      yield put(editTeacherError(res.response.data));
    }
  } catch (error) {
    yield put(editTeacherError(error));
  }
}

function* editActiveTeacher({ payload }) {
  try {
    const { id, active } = payload;
    const response = yield call(api.put, `administrators/${id}`, {
      active,
      actionType: 'activation',
    });
    const responseValue = response.data.data;
    const formatedTeacher = {
      name: responseValue.name,
      active: responseValue.active,
      id: responseValue.id,
      schools: responseValue.schools,
      email: responseValue.email,
    };
    yield put(editActiveTeacherSuccess(id, formatedTeacher));
  } catch (error) {
    yield put(editActiveTeacherError(error));
  }
}

const importTeacherActivityAsync = async (files) =>
  api
    .post(`administrators/subareas/upload`, files)
    .then((response) => response)
    .catch((error) => error);

function* importTeacherActivity({ payload }) {
  try {
    const { files } = payload;
    const res = yield call(importTeacherActivityAsync, files);
    if (!res.response) {
      yield put(importTeacherActivitySuccess(res.data));
      toast.success(`Arquivo(s) incluído(s) com sucesso!`);
    } else {
      yield put(importTeacherActivityError(res.response.data));
      toast.error(`Falha ao incluír Arquivo(s)!`);
    }
  } catch (error) {
    yield put(importTeacherActivityError(error));
    toast.error(`Falha ao incluír Arquivo(s)!`);
  }
}

const removeImportTeacherActivityAsync = async (id) =>
  api
    .delete(`files/${id}`)
    .then((response) => response)
    .catch((error) => error);

function* removeImportTeacherActivity({ payload }) {
  const { subareaId, id } = payload;
  try {
    yield put(removeImportTeacherActivitySuccess(subareaId, id));
    const res = yield call(removeImportTeacherActivityAsync, id);
    if (!res.response) {
      toast.success(`Arquivo removido com sucesso!`);
    } else {
      yield put(removeImportTeacherActivityError(subareaId, id));
      toast.error(`Falha ao remover Arquivo!`);
    }
  } catch (error) {
    yield put(removeImportTeacherActivityError(subareaId, id));
    toast.error(`Falha ao remover Arquivo!`);
  }
}

const editTeacherVinculateSubjectAsync = async (subjectList) =>
  api
    .post(`administrators/subjects`, {
      ...subjectList,
    })
    .then((response) => response)
    .catch((error) => error);

function* editTeacherVinculateSubject({ payload }) {
  try {
    const { subjectList } = payload;
    const res = yield call(editTeacherVinculateSubjectAsync, subjectList);
    if (!res.response) {
      yield put(editTeacherVinculateSubjectSuccess(res.data.message));
    } else {
      yield put(editTeacherVinculateSubjectError(res.response.data));
    }
  } catch (error) {
    yield put(editTeacherVinculateSubjectError(error));
  }
}

const addTeachersLinkAsync = async (links) =>
  api
    .post(`administrators/subareas/links`, {
      ...links,
    })
    .then((response) => response)
    .catch((error) => error);

function* addTeacherLink({ payload }) {
  try {
    const { link, subareaId } = payload;
    const res = yield call(addTeachersLinkAsync, link);
    if (!res.response) {
      const responseValue = res.data.data;
      yield put(addTeacherLinkSuccess(subareaId, responseValue));
    } else {
      yield put(addTeacherLinkError(res.response.data));
    }
  } catch (error) {
    yield put(addTeacherLinkError(error));
  }
}

const removeTeachersLinkAsync = async (id) =>
  api
    .delete(`administrators/subareas/links/${id}`)
    .then((response) => response)
    .catch((error) => error);

function* removeTeacherLink({ payload }) {
  try {
    const { id, subareaId } = payload;
    const res = yield call(removeTeachersLinkAsync, id);
    if (!res.response) {
      yield put(removeTeacherLinkSuccess(subareaId, id));
    } else {
      yield put(removeTeacherLinkError(res.response.data));
    }
  } catch (error) {
    yield put(removeTeacherLinkError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_TEACHER_REQUEST, listTeacher),
    takeEvery(REMOVE_TEACHER_LINK_REQUEST, removeTeacherLink),
    takeLatest(LIST_TEACHER_ACTIVITY_REQUEST, listTeacherActivity),
    takeLatest(CONCLUDE_TEACHER_ACTIVITY_REQUEST, concludeTeacherActivity),
    takeLatest(IMPORT_TEACHER_ACTIVITY_REQUEST, importTeacherActivity),
    takeEvery(
      REMOVE_IMPORT_TEACHER_ACTIVITY_REQUEST,
      removeImportTeacherActivity
    ),
    takeLatest(GET_TEACHER_REQUEST, getTeacherById),
    takeEvery(
      EDIT_TEACHER_VINCULATE_SUBJECT_REQUEST,
      editTeacherVinculateSubject
    ),
    takeLatest(ADD_TEACHER_REQUEST, addTeacher),
    takeEvery(ADD_TEACHER_LINK_REQUEST, addTeacherLink),
    takeLatest(EDIT_TEACHER_REQUEST, editTeacher),
    takeLatest(EDIT_ACTIVE_TEACHER_REQUEST, editActiveTeacher),
    takeLatest(LIST_TEACHER_SUBJECT_REQUEST, listTeacherSubject),
  ]);
}
