import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import {
  LIST_FEEDBACK_REQUEST,
  ADD_OR_EDIT_FEEDBACK_REQUEST,
  REMOVE_FEEDBACK_REQUEST,
  REMOVE_FILE_FEEDBACK_REQUEST,
} from '../../actions';
import {
  listFeedbackSuccess,
  listFeedbackError,
  addOrEditFeedbackSuccess,
  addOrEditFeedbackError,
  removeFeedbackError,
  removeFeedbackSuccess,
  removeFileFeedbackSuccess,
  removeFileFeedbackError
} from './actions';

function* listFeedback({ payload }) {
  const { studentId } = payload;

  try {
    const response = yield call(api.get, `news/feedback/students/${studentId}`);
    yield put(listFeedbackSuccess(response.data.data));
  } catch (error) {
    yield put(listFeedbackError(error));
  }
}

const addOrEditFeedbackAsync = async (completeFeedback) =>
  api
    .post(`news/feedback/students`, {
      ...completeFeedback,
    })
    .then((response) => response)
    .catch((error) => error);

const addFeedbackFilesAsync = async (news) =>
  api
    .post(`news/upload`, {
      ...news.files,
    })
    .then((response) => response)
    .catch((error) => error);

function* addOrEditFeedback({ payload }) {
  try {
    const { feedback } = payload;
    const completeFeedback = { ...feedback };
    const res = yield call(addOrEditFeedbackAsync, completeFeedback);
    if (!res.response) {
      const responseValue = res.data.data;
      if (completeFeedback.files) {
        completeFeedback.files.new_id = responseValue.id;
        yield call(addFeedbackFilesAsync, completeFeedback);
      }
      const formattedFeedback = {
        id: responseValue.id,
        title: responseValue.title,
        subtitle: responseValue.subtitle,
        description: responseValue.description,
        publish_at: responseValue.publish_at,
      };
      yield put(addOrEditFeedbackSuccess(formattedFeedback));
    } else {
      yield put(addOrEditFeedbackError(res.response.data));
    }
  } catch (error) {
    yield put(addOrEditFeedbackError(error));
  }
}

const removeFileFeedbackAsync = async (id) =>
  api
    .delete(`files/${id}`)
    .then((response) => response)
    .catch((error) => error);

function* removeFileFeedback({ payload }) {
  const { id } = payload;
  try {
    const res = yield call(removeFileFeedbackAsync, id);
    if (!res.response) {
      yield put(removeFileFeedbackSuccess(id));
      toast.success(`Arquivo removido com sucesso!`);
    } else {
      yield put(removeFileFeedbackError(res.response.data));
      toast.error(`Falha ao remover Arquivo!`);
    }
  } catch (error) {
    yield put(removeFileFeedbackError(id));
    toast.error(`Falha ao remover Arquivo!`);
  }
}

const removeFeedbackAsync = async (newId) =>
  api
    .delete(`news/${newId}/feedback/students`)
    .then((response) => response)
    .catch((error) => error);

function* removeFeedback({ payload }) {
  try {
    const { newId } = payload;
    const res = yield call(removeFeedbackAsync, newId);
    if (!res.response) {
      yield put(removeFeedbackSuccess(newId));
      toast.success(`Feedback removido com sucesso!`);
    } else {
      yield put(removeFeedbackError(res.response.data));
      toast.error(`Falha ao remover feedback!`);
    }
  } catch (error) {
    yield put(removeFeedbackError(error));
    toast.error(`Falha ao remover feedback!`);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_FEEDBACK_REQUEST, listFeedback),
    takeLatest(ADD_OR_EDIT_FEEDBACK_REQUEST, addOrEditFeedback),
    takeEvery(REMOVE_FEEDBACK_REQUEST, removeFeedback),
    takeEvery(REMOVE_FILE_FEEDBACK_REQUEST, removeFileFeedback),
  ]);
}
