import {
  LIST_SUBJECTS_REQUEST,
  LIST_SUBJECTS_SUCCESS,
  LIST_SUBJECTS_ERROR,
  GET_SUBJECTS_REQUEST,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_ERROR,
  ADD_SUBJECTS_REQUEST,
  ADD_SUBJECTS_SUCCESS,
  ADD_SUBJECTS_ERROR,
  EDIT_SUBJECTS_REQUEST,
  EDIT_SUBJECTS_SUCCESS,
  EDIT_SUBJECTS_ERROR,
  EDIT_SUBJECT_VINCULATE_PREREQUISITE_REQUEST,
  EDIT_SUBJECT_VINCULATE_PREREQUISITE_SUCCESS,
  EDIT_SUBJECT_VINCULATE_PREREQUISITE_ERROR,
  VINCULATE_PREREQUISITE,
  RESET_VINCULATE_PREREQUISITE,
  LIST_ALL_SUBJECTS_REQUEST,
  LIST_ALL_SUBJECTS_SUCCESS,
  LIST_ALL_SUBJECTS_ERROR,
  ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_SUCCESS,
  ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_ERROR,
  EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_SUCCESS,
  EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_ERROR,
  LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_SUCCESS,
  LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_ERROR,
  GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_SUCCESS,
  GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_ERROR,
} from '../actions';

export const getSubjectsRequest = (id) => ({
  type: GET_SUBJECTS_REQUEST,
  payload: { id },
});
export const getSubjectsSuccess = (subjects) => ({
  type: GET_SUBJECTS_SUCCESS,
  payload: subjects,
});
export const getSubjectsError = (message) => ({
  type: GET_SUBJECTS_ERROR,
  payload: { message },
});

export const listSubjectsRequest = (cycleIs = null, studentId = null) => ({
  type: LIST_SUBJECTS_REQUEST,
  payload: { cycleIs, studentId }
});
export const listSubjectsSuccess = (subjects) => ({
  type: LIST_SUBJECTS_SUCCESS,
  payload: subjects,
});
export const listSubjectsError = (message) => ({
  type: LIST_SUBJECTS_ERROR,
  payload: { message },
});

export const addSubjectsRequest = (subjects) => ({
  type: ADD_SUBJECTS_REQUEST,
  payload: { subjects },
});
export const addSubjectsSuccess = (subjects) => ({
  type: ADD_SUBJECTS_SUCCESS,
  payload: subjects,
});
export const addSubjectsError = (message) => ({
  type: ADD_SUBJECTS_ERROR,
  payload: { message },
});

export const editSubjectsRequest = (subjects) => ({
  type: EDIT_SUBJECTS_REQUEST,
  payload: { subjects },
});
export const editSubjectsSuccess = (subjects) => ({
  type: EDIT_SUBJECTS_SUCCESS,
  payload: { subjects },
});
export const editSubjectsError = (message) => ({
  type: EDIT_SUBJECTS_ERROR,
  payload: { message },
});

export const editSubjectVinculatePrerequisiteRequest = (
  id,
  prerequisiteList
) => ({
  type: EDIT_SUBJECT_VINCULATE_PREREQUISITE_REQUEST,
  payload: { id, prerequisiteList },
});
export const editSubjectVinculatePrerequisiteSuccess = (message) => ({
  type: EDIT_SUBJECT_VINCULATE_PREREQUISITE_SUCCESS,
  payload: { message },
});
export const editSubjectVinculatePrerequisiteError = (message) => ({
  type: EDIT_SUBJECT_VINCULATE_PREREQUISITE_ERROR,
  payload: { message },
});

export const vinculatePrerequisite = (prerequisite, prerequisite_id) => ({
  type: VINCULATE_PREREQUISITE,
  payload: { prerequisite, prerequisite_id },
});

export const resetVinculatePrerequisite = (prerequisite) => ({
  type: RESET_VINCULATE_PREREQUISITE,
  payload: prerequisite,
});

export const listAllSubjectsRequest = () => ({
  type: LIST_ALL_SUBJECTS_REQUEST,
});
export const listAllSubjectsSuccess = (subjects) => ({
  type: LIST_ALL_SUBJECTS_SUCCESS,
  payload: subjects,
});
export const listAllSubjectsError = (message) => ({
  type: LIST_ALL_SUBJECTS_ERROR,
  payload: { message },
});

export const addSubjectDiagnosticAssessmentsRequest = (
  diagnosticAssessment
) => ({
  type: ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  payload: { diagnosticAssessment },
});

export const addSubjectDiagnosticAssessmentsSuccess = (
  diagnosticAssessment
) => ({
  type: ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_SUCCESS,
  payload: { diagnosticAssessment },
});

export const addSubjectDiagnosticAssessmentsError = (message) => ({
  type: ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_ERROR,
  payload: { message },
});

export const editSubjectDiagnosticAssessmentsRequest = (
  diagnosticAssessment
) => ({
  type: EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  payload: { diagnosticAssessment },
});

export const editSubjectDiagnosticAssessmentsSuccess = (
  diagnosticAssessment
) => ({
  type: EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_SUCCESS,
  payload: { diagnosticAssessment },
});

export const editSubjectDiagnosticAssessmentsError = (message) => ({
  type: EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_ERROR,
  payload: { message },
});

export const listSubjectDiagnosticAssessmentsRequest = (classId) => ({
  type: LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  payload: { classId },
});

export const listSubjectDiagnosticAssessmentsSuccess = (
  diagnosticAssessments
) => ({
  type: LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_SUCCESS,
  payload: { diagnosticAssessments },
});

export const listSubjectDiagnosticAssessmentsError = (message) => ({
  type: LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_ERROR,
  payload: { message },
});

export const getSubjectDiagnosticAssessmentsRequest = (id) => ({
  type: GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  payload: { id },
});

export const getSubjectDiagnosticAssessmentsSuccess = (
  diagnosticAssessment
) => ({
  type: GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_SUCCESS,
  payload: { diagnosticAssessment },
});

export const getSubjectDiagnosticAssessmentsError = (message) => ({
  type: GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_ERROR,
  payload: { message },
});
