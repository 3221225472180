import {
  LIST_JOURNEY_TRAILS_REQUEST,
  LIST_JOURNEY_TRAILS_SUCCESS,
  LIST_JOURNEY_TRAILS_ERROR,
  GET_JOURNEY_TRAILS_REQUEST,
  GET_JOURNEY_TRAILS_SUCCESS,
  GET_JOURNEY_TRAILS_ERROR,
  ADD_JOURNEY_TRAILS_REQUEST,
  ADD_JOURNEY_TRAILS_SUCCESS,
  ADD_JOURNEY_TRAILS_ERROR,
  EDIT_JOURNEY_TRAILS_REQUEST,
  EDIT_JOURNEY_TRAILS_SUCCESS,
  EDIT_JOURNEY_TRAILS_ERROR,
  ORDINATION_JOURNEY_TRAIL_WEEKS_REQUEST,
  ORDINATION_JOURNEY_TRAIL_WEEKS_SUCCESS,
  ORDINATION_JOURNEY_TRAIL_WEEKS_ERROR
} from '../actions';

export const getJourneyTrailsRequest = (id) => ({
  type: GET_JOURNEY_TRAILS_REQUEST,
  payload: { id },
});
export const getJourneyTrailsSuccess = (journeyTrails) => ({
  type: GET_JOURNEY_TRAILS_SUCCESS,
  payload: journeyTrails,
});
export const getJourneyTrailsError = (message) => ({
  type: GET_JOURNEY_TRAILS_ERROR,
  payload: { message },
});

export const listJourneyTrailsRequest = () => ({
  type: LIST_JOURNEY_TRAILS_REQUEST,
});
export const listJourneyTrailsSuccess = (journeyTrails) => ({
  type: LIST_JOURNEY_TRAILS_SUCCESS,
  payload: journeyTrails,
});
export const listJourneyTrailsError = (message) => ({
  type: LIST_JOURNEY_TRAILS_ERROR,
  payload: { message },
});

export const addJourneyTrailsRequest = (journeyTrails) => ({
  type: ADD_JOURNEY_TRAILS_REQUEST,
  payload: { journeyTrails },
});
export const addJourneyTrailsSuccess = (journeyTrails) => ({
  type: ADD_JOURNEY_TRAILS_SUCCESS,
  payload: journeyTrails,
});
export const addJourneyTrailsError = (message) => ({
  type: ADD_JOURNEY_TRAILS_ERROR,
  payload: { message },
});

export const editJourneyTrailsRequest = (journeyTrails) => ({
  type: EDIT_JOURNEY_TRAILS_REQUEST,
  payload: { journeyTrails },
});
export const editJourneyTrailsSuccess = (journeyTrails) => ({
  type: EDIT_JOURNEY_TRAILS_SUCCESS,
  payload: { journeyTrails },
});
export const editJourneyTrailsError = (message) => ({
  type: EDIT_JOURNEY_TRAILS_ERROR,
  payload: { message },
});

export const ordinationJourneyTrailWeeksRequest = (journeyTrails) => ({
  type: ORDINATION_JOURNEY_TRAIL_WEEKS_REQUEST,
  payload: { journeyTrails },
});
export const ordinationJourneyTrailWeeksSuccess = (message) => ({
  type: ORDINATION_JOURNEY_TRAIL_WEEKS_SUCCESS,
  payload: message,
});
export const ordinationJourneyTrailWeeksError = (message) => ({
  type: ORDINATION_JOURNEY_TRAIL_WEEKS_ERROR,
  payload: { message },
});

