import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../../services/api';
import { LIST_NEW_TYPE_REQUEST } from '../../actions';
import {
  listNewTypesSuccess,
  listNewTypesError,
} from './actions';

function* listNewTypes() {
  try {
    const response = yield call(api.get, 'new-types');
    yield put(listNewTypesSuccess(response.data.data));
  } catch (error) {
    yield put(listNewTypesError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_NEW_TYPE_REQUEST, listNewTypes),
  ]);
}
