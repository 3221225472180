import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  ADD_PREREQUISITES_REQUEST,
  EDIT_PREREQUISITES_REQUEST,
  GET_PREREQUISITES_REQUEST,
  LIST_PREREQUISITES_REQUEST
} from '../actions';
import {
  listPrerequisitesSuccess,
  listPrerequisitesError,
  addPrerequisitesError,
  addPrerequisitesSuccess,
  editPrerequisitesError,
  editPrerequisitesSuccess,
  getPrerequisitesError,
  getPrerequisitesSuccess,
} from './actions';

function* listPrerequisites() {
  try {
    const response = yield call(api.get, 'prerequisites');
    yield put(listPrerequisitesSuccess(response.data.data));
  } catch (error) {
    yield put(listPrerequisitesError(error));
  }
}

function* getPrerequisitesById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `prerequisites/${id}`);
    yield put(getPrerequisitesSuccess(response.data.data));
  } catch (error) {
    yield put(getPrerequisitesError(error));
  }
}

const addPrerequisitesAsync = async (completePrerequisites) =>
  api
    .post(`prerequisites`, {
      ...completePrerequisites,
    })
    .then((response) => response)
    .catch((error) => error);

const editPrerequisitesAsync = async (completePrerequisites) =>
  api
    .put(`prerequisites/${completePrerequisites.id}`, {
      ...completePrerequisites,
    })
    .then((response) => response)
    .catch((error) => error);

const addPrerequisitesImageAsync = async (prerequisites) =>
  api
    .post(`prerequisites/upload`, {
      ...prerequisites.file,
    })
    .then((response) => response)
    .catch((error) => error);

function* addPrerequisites({ payload }) {
  try {
    const { prerequisites } = payload;
    const completePrerequisites = { ...prerequisites, actionType: 'edit' };
    const res = yield call(addPrerequisitesAsync, completePrerequisites);
    if (!res.response) {
      const responseValue = res.data.data;
      if (completePrerequisites.file) {
        completePrerequisites.file.prerequisite_id = responseValue.id;
        yield call(addPrerequisitesImageAsync, completePrerequisites);
      }
      yield put(addPrerequisitesSuccess(responseValue));
    } else {
      yield put(addPrerequisitesError(res.response.data));
    }
  } catch (error) {
    yield put(addPrerequisitesError(error));
  }
}

function* editPrerequisites({ payload }) {
  try {
    const { prerequisites } = payload;
    const completePrerequisites = { ...prerequisites, actionType: 'edit' };
    const res = yield call(editPrerequisitesAsync, completePrerequisites);
    if (!res.response) {
      const responseValue = res.data.data;
      if (completePrerequisites.file) {
        completePrerequisites.file.prerequisite_id = responseValue.id;
        yield call(addPrerequisitesImageAsync, completePrerequisites);
      }
      yield put(editPrerequisitesSuccess(responseValue));
    } else {
      yield put(editPrerequisitesError(res.response.data));
    }
  } catch (error) {
    yield put(editPrerequisitesError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_PREREQUISITES_REQUEST, listPrerequisites),
    takeLatest(GET_PREREQUISITES_REQUEST, getPrerequisitesById),
    takeLatest(ADD_PREREQUISITES_REQUEST, addPrerequisites),
    takeLatest(EDIT_PREREQUISITES_REQUEST, editPrerequisites),
  ]);
}
