import {
  LIST_QUESTION_DB_YEARS_REQUEST,
  LIST_QUESTION_DB_YEARS_SUCCESS,
  LIST_QUESTION_DB_YEARS_ERROR,
  LIST_QUESTION_DB_CATEGORIES_REQUEST,
  LIST_QUESTION_DB_CATEGORIES_SUCCESS,
  LIST_QUESTION_DB_CATEGORIES_ERROR,
  LIST_QUESTION_DB_INSTITUTIONS_REQUEST,
  LIST_QUESTION_DB_INSTITUTIONS_SUCCESS,
  LIST_QUESTION_DB_INSTITUTIONS_ERROR,
  LIST_QUESTION_DB_SKILLS_REQUEST,
  LIST_QUESTION_DB_SKILLS_SUCCESS,
  LIST_QUESTION_DB_SKILLS_ERROR,
  LIST_QUESTION_DB_SUBJECTS_REQUEST,
  LIST_QUESTION_DB_SUBJECTS_SUCCESS,
  LIST_QUESTION_DB_SUBJECTS_ERROR,
  LIST_QUESTION_DB_QUESTIONS_REQUEST,
  LIST_QUESTION_DB_QUESTIONS_SUCCESS,
  LIST_QUESTION_DB_QUESTIONS_ERROR,
  LIST_CATEGORIES_CHILDRENS_REQUEST,
  LIST_CATEGORIES_CHILDRENS_SUCCESS,
  LIST_CATEGORIES_CHILDRENS_ERROR,
  SET_FIRST_CATEGORY,
  RESET_FIRST_CATEGORY,
  SET_EMPTY_CATEGORY,
  CHECK_QUESTION_REQUEST,
  CHECK_INITIAL_QUESTION_REQUEST,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  years: null,
  categories: null,
  institutions: null,
  skills: null,
  subjects: null,
  questions: null,
  selectCategories: [],
  checkedQuestions: [],
  listOptions: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        questions: null,
        selectCategories: [],
        checkedQuestions: [],
        success: '',
      };
    case LIST_QUESTION_DB_YEARS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_QUESTION_DB_YEARS_SUCCESS:
      return {
        ...state,
        loading: false,
        years: action.payload.years,
        error: '',
      };
    case LIST_QUESTION_DB_YEARS_ERROR:
      return {
        ...state,
        loading: false,
        years: null,
        error: action.payload.message,
      };
    case LIST_QUESTION_DB_CATEGORIES_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_QUESTION_DB_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload.categories,
        error: '',
      };
    case LIST_QUESTION_DB_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        categories: null,
        error: action.payload.message,
      };
    case LIST_QUESTION_DB_INSTITUTIONS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_QUESTION_DB_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        institutions: action.payload.institutions,
        error: '',
      };
    case LIST_QUESTION_DB_INSTITUTIONS_ERROR:
      return {
        ...state,
        loading: false,
        institutions: null,
        error: action.payload.message,
      };
    case LIST_QUESTION_DB_SKILLS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_QUESTION_DB_SKILLS_SUCCESS:
      return {
        ...state,
        loading: false,
        skills: action.payload.skills,
        error: '',
      };
    case LIST_QUESTION_DB_SKILLS_ERROR:
      return {
        ...state,
        loading: false,
        skills: null,
        error: action.payload.message,
      };
    case LIST_QUESTION_DB_SUBJECTS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_QUESTION_DB_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: action.payload.subjects,
        error: '',
      };
    case LIST_QUESTION_DB_SUBJECTS_ERROR:
      return {
        ...state,
        loading: false,
        subjects: null,
        error: action.payload.message,
      };
    case LIST_QUESTION_DB_QUESTIONS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_QUESTION_DB_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        listOptions: action.payload.listOptions,
        questions: action.payload.questions,
        error: '',
      };
    case LIST_QUESTION_DB_QUESTIONS_ERROR:
      return {
        ...state,
        loading: false,
        questions: null,
        listOptions: null,
        error: action.payload.message,
      };
    case LIST_CATEGORIES_CHILDRENS_REQUEST:
      return { ...state };
    case LIST_CATEGORIES_CHILDRENS_SUCCESS: {
      const { categories, level } = action.payload;
      const updatedCategories = [...state.selectCategories].splice(
        0,
        level + 1
      );
      updatedCategories[level] = categories;
      return {
        ...state,
        selectCategories: updatedCategories,
      };
    }
    case LIST_CATEGORIES_CHILDRENS_ERROR:
      return {
        ...state,
        selectCategories: [],
      };
    case SET_FIRST_CATEGORY:
      return {
        ...state,
        selectCategories: [
          state.categories?.filter(
            (value) => value.subject.id === action.payload.subjectId
          ),
        ],
      };
    case SET_EMPTY_CATEGORY: {
      const { level } = action.payload;
      const updatedCategories = [...state.selectCategories].splice(0, level);
      return {
        ...state,
        selectCategories: updatedCategories,
      };
    }
    case RESET_FIRST_CATEGORY:
      return {
        ...state,
        selectCategories: [],
      };
    case CHECK_QUESTION_REQUEST: {
      const isUncheck = state.checkedQuestions.find(
        (question) => question.id === action.payload.id
      );
      if (isUncheck) {
        return {
          ...state,
          checkedQuestions: state.checkedQuestions.filter(
            (question) => question.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        checkedQuestions: [
          ...state.checkedQuestions,
          state.questions.find((question) => question.id === action.payload.id),
        ],
      };
    }
    case CHECK_INITIAL_QUESTION_REQUEST: {
      return {
        ...state,
        checkedQuestions: action.payload.questions.map((question) => {
          return {
            ...question,
            isChecked: true,
          };
        }),
      };
    }
    default:
      return { ...state };
  }
};
