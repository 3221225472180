import {
  LIST_CLASS_ASSESSMENT_TYPE_REQUEST,
  LIST_CLASS_ASSESSMENT_TYPE_SUCCESS,
  LIST_CLASS_ASSESSMENT_TYPE_ERROR,
} from '../../actions';

export const listClassAssessmentTypeRequest = () => ({
  type: LIST_CLASS_ASSESSMENT_TYPE_REQUEST,
});
export const listClassAssessmentTypeSuccess = (cycles) => ({
  type: LIST_CLASS_ASSESSMENT_TYPE_SUCCESS,
  payload: cycles,
});
export const listClassAssessmentTypeError = (message) => ({
  type: LIST_CLASS_ASSESSMENT_TYPE_ERROR,
  payload: { message },
});
