import {
  RESET_STUDENT_PASSWORD,
  RESET_STUDENT_PASSWORD_SUCCESS,
  RESET_STUDENT_PASSWORD_ERROR,
  FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_REQUEST,
  FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_SUCCESS,
  FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_ERROR,
  CONFIRM_STUDENT_ACCOUNT_REQUEST,
  CONFIRM_STUDENT_ACCOUNT_SUCCESS,
  CONFIRM_STUDENT_ACCOUNT_ERROR
} from '../../actions';

export const forgotStudentPasswordCheckTokenRequest = (token) => ({
  type: FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_REQUEST,
  payload: { token },
});

export const forgotStudentPasswordCheckTokenSuccess = (email) => ({
  type: FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_SUCCESS,
  payload: { email },
});

export const forgotStudentPasswordCheckTokenError = (message) => ({
  type: FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_ERROR,
  payload: { message },
});

export const resetStudentPassword = ({
  email,
  token,
  password,
  passwordConfirmation,
  history,
}) => ({
  type: RESET_STUDENT_PASSWORD,
  payload: { email, token, password, passwordConfirmation, history },
});
export const resetStudentPasswordSuccess = (message) => ({
  type: RESET_STUDENT_PASSWORD_SUCCESS,
  payload: message,
});
export const resetStudentPasswordError = (message) => ({
  type: RESET_STUDENT_PASSWORD_ERROR,
  payload: { message },
});

export const confirmStudentAccountRequest = (studentId, studentByApp) => ({
  type: CONFIRM_STUDENT_ACCOUNT_REQUEST,
  payload: { studentId, studentByApp },
});

export const confirmStudentAccountSuccess = (message) => ({
  type: CONFIRM_STUDENT_ACCOUNT_SUCCESS,
  payload: { message },
});

export const confirmStudentAccountError = (message) => ({
  type: CONFIRM_STUDENT_ACCOUNT_ERROR,
  payload: { message },
});
