import {
  LIST_CRITERION_REQUEST,
  LIST_CRITERION_SUCCESS,
  LIST_CRITERION_ERROR,
  GET_CRITERION_REQUEST,
  GET_CRITERION_SUCCESS,
  GET_CRITERION_ERROR,
  ADD_CRITERION_REQUEST,
  ADD_CRITERION_SUCCESS,
  ADD_CRITERION_ERROR,
  EDIT_CRITERION_REQUEST,
  EDIT_CRITERION_SUCCESS,
  EDIT_CRITERION_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  currentCriterion: null,
  currentCriterionList: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentCriterion: null,
        success: '',
      };
    case GET_CRITERION_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_CRITERION_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCriterion: action.payload,
        error: '',
      };
    case GET_CRITERION_ERROR:
      return {
        ...state,
        loading: false,
        currentCriterion: null,
        error: action.payload.message,
      };
    case LIST_CRITERION_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_CRITERION_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCriterionList: action.payload,
        error: '',
      };
    case LIST_CRITERION_ERROR:
      return {
        ...state,
        loading: false,
        currentCriterionList: null,
        error: action.payload.message,
      };
    case ADD_CRITERION_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_CRITERION_SUCCESS: {
      const allCriterions = [...state.currentCriterionList, action.payload];

      return {
        ...state,
        currentCriterionList: allCriterions,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case ADD_CRITERION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_CRITERION_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_CRITERION_SUCCESS: {
      const criterionsUpdated = state.currentCriterionList.map((criterion) => {
        if (criterion.id === action.payload.criterion.id) {
          return action.payload.criterion;
        }
        return criterion;
      });

      return {
        ...state,
        currentCriterionList: criterionsUpdated,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case EDIT_CRITERION_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
