import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from './Utils';
import { isAuthGuardActive } from '../constants/defaultValues';
import api from '../services/api';
import { leaveAccess } from '../redux/actions';
import { isObject } from '../utils/isObject';

const ProtectedRoute = ({
  component: Component,
  roles = undefined,
  ...rest
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const setComponent = (props) => {
    if (isAuthGuardActive) {
      const currentUser = getCurrentUser();
      if (currentUser === 'leave') {
        dispatch(leaveAccess(history));
        return;
      }

      if (
        currentUser &&
        (currentUser?.uid?.schools.length === 0 ||
          isObject(currentUser?.uid?.schools))
      ) {
        try {
          const token = currentUser.uid.access_token;
          const decoded = jwt_decode(token);
          const expired = decoded.exp < Date.now() / 1000;
          api.defaults.headers.Authorization = `Bearer ${token}`;

          if (expired) {
            return (
              <Redirect
                to={{
                  pathname: '/user/login',
                  state: { from: props.location },
                }}
              />
            );
          }
        } catch (e) {
          console.log(e.message);
        }

        if (roles) {
          const impersonator = !!currentUser.uid.impersonator;
          if (
            roles.includes(currentUser.uid.permission_level) ||
            impersonator
          ) {
            return <Component {...props} />;
          }
          return (
            <Redirect
              to={{
                pathname: '/unauthorized',
                state: { from: props.location },
              }}
            />
          );
        }
        return <Component {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: '/user/login',
            state: { from: props.location },
          }}
        />
      );
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={setComponent} />;
};
const UserRole = {
  Admin: 0,
  Coordinator: 1,
  Teacher: 2,
  Manager: 3,
  NetworkCoordinator: 4,
};

const SchoolRole = {
  Master: 0,
  EducationSystem: 1,
  EducationNetwork: 2,
  EducationInstitution: 3,
  EducationInstitutionWithClass: 4,
};

const SchoolSlug = {
  EducationSystem: 'system',
  EducationNetwork: 'network',
  EducationInstitution: 'institution',
};

export { ProtectedRoute, UserRole, SchoolRole, SchoolSlug };
