import {
  LIST_ACTIVITY_REQUEST,
  LIST_ACTIVITY_SUCCESS,
  LIST_ACTIVITY_ERROR,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_ERROR,
  GET_ACTIVITY_EXAM_REQUEST,
  GET_ACTIVITY_EXAM_SUCCESS,
  GET_ACTIVITY_EXAM_ERROR,
  ADD_ACTIVITY_REQUEST,
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_ERROR,
  EDIT_ACTIVITY_REQUEST,
  EDIT_ACTIVITY_SUCCESS,
  EDIT_ACTIVITY_ERROR,
  EDIT_ACTIVE_ACTIVITY_REQUEST,
  EDIT_ACTIVE_ACTIVITY_SUCCESS,
  EDIT_ACTIVE_ACTIVITY_ERROR,
  ORDINATE_ACTIVITY_REQUEST,
  ORDINATE_ACTIVITY_SUCCESS,
  ORDINATE_ACTIVITY_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  currentActivity: null,
  currentActivityList: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentActivity: null,
        success: '',
      };
    case GET_ACTIVITY_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        currentActivity: action.payload,
        error: '',
      };
    case GET_ACTIVITY_ERROR:
      return {
        ...state,
        loading: false,
        currentActivity: null,
        error: action.payload.message,
      };
    case GET_ACTIVITY_EXAM_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_ACTIVITY_EXAM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentActivity: action.payload,
        error: '',
      };
    case GET_ACTIVITY_EXAM_ERROR:
      return {
        ...state,
        loading: false,
        currentActivity: null,
        error: action.payload.message,
      };
    case LIST_ACTIVITY_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        currentActivityList: action.payload,
        error: '',
      };
    case LIST_ACTIVITY_ERROR:
      return {
        ...state,
        loading: false,
        currentActivityList: null,
        error: action.payload.message,
      };
    case ADD_ACTIVITY_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_ACTIVITY_SUCCESS: {
      const allActivities = [...state.currentActivityList, action.payload];

      return {
        ...state,
        currentActivityList: allActivities,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_ACTIVITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_ACTIVITY_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_ACTIVITY_SUCCESS: {
      const activitiesUpdated = state.currentActivityList.map((activity) => {
        if (activity.id === action.payload.activity.id) {
          return action.payload.activity;
        }
        return activity;
      });

      return {
        ...state,
        currentActivityList: activitiesUpdated,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case EDIT_ACTIVITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_ACTIVE_ACTIVITY_REQUEST:
      return { ...state, loading: false, error: '', success: '' };
    case EDIT_ACTIVE_ACTIVITY_SUCCESS: {
      const activitiesUpdated = state.currentActivityList.map((activity) => {
        if (activity.id === action.payload.id) {
          return action.payload.activity;
        }
        return activity;
      });

      return {
        ...state,
        currentActivityList: activitiesUpdated,
        success: action.payload,
        loading: false,
        error: '',
      };
    }
    case EDIT_ACTIVE_ACTIVITY_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    case ORDINATE_ACTIVITY_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ORDINATE_ACTIVITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.payload,
        currentActivityList: null,
        error: '',
      };
    }
    case ORDINATE_ACTIVITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
