import {
  LIST_WRITINGS_REQUEST,
  LIST_WRITINGS_SUCCESS,
  LIST_WRITINGS_ERROR,
  GET_WRITINGS_REQUEST,
  GET_WRITINGS_SUCCESS,
  GET_WRITINGS_ERROR,
  ADD_WRITINGS_REQUEST,
  ADD_WRITINGS_SUCCESS,
  ADD_WRITINGS_ERROR,
  EDIT_WRITINGS_REQUEST,
  EDIT_WRITINGS_SUCCESS,
  EDIT_WRITINGS_ERROR,
  ACCEPT_WRITINGS_REQUEST,
  ACCEPT_WRITINGS_SUCCESS,
  ACCEPT_WRITINGS_ERROR,
  RECUSE_WRITINGS_REQUEST,
  RECUSE_WRITINGS_SUCCESS,
  RECUSE_WRITINGS_ERROR,
  EDIT_ACTIVE_WRITINGS_REQUEST,
  EDIT_ACTIVE_WRITINGS_SUCCESS,
  EDIT_ACTIVE_WRITINGS_ERROR,
  SEND_WRITINGS_REQUEST,
  SEND_WRITINGS_SUCCESS,
  SEND_WRITINGS_ERROR,
  EDIT_WRITINGS_VINCULATE_CRITERIONS_REQUEST,
  EDIT_WRITINGS_VINCULATE_CRITERIONS_SUCCESS,
  EDIT_WRITINGS_VINCULATE_CRITERIONS_ERROR,
  IMPORT_WRITINGS_REQUEST,
  IMPORT_WRITINGS_SUCCESS,
  IMPORT_WRITINGS_ERROR,
  SEND_RATINGS_WRITINGS_REQUEST,
  SEND_RATINGS_WRITINGS_SUCCESS,
  SEND_RATINGS_WRITINGS_ERROR,
  REMOVE_IMPORT_WRITINGS_REQUEST,
  REMOVE_IMPORT_WRITINGS_SUCCESS,
  REMOVE_IMPORT_WRITINGS_ERROR,
  LIST_WRITINGS_REPORTS_REQUEST,
  LIST_WRITINGS_REPORTS_SUCCESS,
  LIST_WRITINGS_REPORTS_ERROR,
  ADD_CRITERION_WRITINGS_REQUEST,
  ADD_CRITERION_WRITINGS_SUCCESS,
  ADD_CRITERION_WRITINGS_ERROR,
  EDIT_CRITERION_WRITINGS_REQUEST,
  EDIT_CRITERION_WRITINGS_SUCCESS,
  EDIT_CRITERION_WRITINGS_ERROR,
  SET_WRITINGS_MODAL_VALUES,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  currentWritings: null,
  currentWritingsList: null,
  writingPdfHighlights: null,
  currentStudentId: null,
  currentWritingId: null,
  reports: null,
  loading: false,
  loadingButton: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentWritings: null,
        success: '',
      };
    case GET_WRITINGS_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_WRITINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentWritings: action.payload,
        error: '',
      };
    case GET_WRITINGS_ERROR:
      return {
        ...state,
        loading: false,
        currentWritings: null,
        error: action.payload.message,
      };
    case LIST_WRITINGS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_WRITINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentWritingsList: action.payload,
        error: '',
      };
    case LIST_WRITINGS_ERROR:
      return {
        ...state,
        loading: false,
        currentWritingsList: null,
        error: action.payload.message,
      };
    case ADD_WRITINGS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_WRITINGS_SUCCESS: {
      const allWritings = [...state.currentWritingsList, action.payload];

      return {
        ...state,
        currentWritingsList: allWritings,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_WRITINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_WRITINGS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_WRITINGS_SUCCESS: {
      const writingsUpdated = state.currentWritingsList.map((writings) => {
        if (writings.id === action.payload.writings.id) {
          return action.payload.writings;
        }
        return writings;
      });

      return {
        ...state,
        currentWritingsList: writingsUpdated,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case EDIT_WRITINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_ACTIVE_WRITINGS_REQUEST:
      return { ...state, loading: false, error: '' };
    case EDIT_ACTIVE_WRITINGS_SUCCESS: {
      const writingsUpdated = state.currentWritingsList.map((writings) => {
        if (writings.id === action.payload.writings.id) {
          return action.payload.writings;
        }
        return writings;
      });
      return {
        ...state,
        currentWritingsList: writingsUpdated,
        success: action.payload,
        loading: false,
        error: '',
      };
    }
    case EDIT_ACTIVE_WRITINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case SEND_WRITINGS_REQUEST:
      return { ...state, loading: false, error: '', success: '' };
    case SEND_WRITINGS_SUCCESS: {
      const writingsUpdated = state.currentWritingsList.map((writings) => {
        if (writings.id === action.payload.id) {
          return { ...writings, sent_at: new Date() };
        }
        return writings;
      });

      return {
        ...state,
        currentWritingsList: writingsUpdated,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case SEND_WRITINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_WRITINGS_VINCULATE_CRITERIONS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_WRITINGS_VINCULATE_CRITERIONS_SUCCESS: {
      const writingsUpdated = state.currentWritingsList.map((writings) => {
        if (writings.id === action.payload.id) {
          return { ...writings, criterions: ['temp', 'temp'] };
        }
        return writings;
      });
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        currentWritingsList: writingsUpdated,
        error: null,
      };
    }
    case EDIT_WRITINGS_VINCULATE_CRITERIONS_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    case IMPORT_WRITINGS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case IMPORT_WRITINGS_SUCCESS: {
      return {
        ...state,
        error: '',
        success: action.payload,
      };
    }
    case IMPORT_WRITINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case REMOVE_IMPORT_WRITINGS_REQUEST:
      return { ...state, error: '', success: '' };
    case REMOVE_IMPORT_WRITINGS_SUCCESS: {
      const { writingId, id } = action.payload;
      const updatedWritings = state.currentWritingsList.map((writing) => {
        if (writing.id === writingId) {
          return {
            ...writing,
            files: writing.files.map((file) => {
              if (file.id === id) {
                return { ...file, removed: true };
              }
              return file;
            }),
          };
        }
        return writing;
      });
      return {
        ...state,
        error: '',
        currentWritingsList: updatedWritings,
        success: action.payload.message,
      };
    }
    case REMOVE_IMPORT_WRITINGS_ERROR: {
      const { writingId, id } = action.payload;
      const updatedWritings = state.currentWritingsList.map((writing) => {
        if (writing.id === writingId) {
          return {
            ...writing,
            files: writing.files.map((file) => {
              if (file.id === id) {
                return { ...file, removed: false };
              }
              return file;
            }),
          };
        }
        return writing;
      });
      return {
        ...state,
        currentWritingsList: updatedWritings,
        error: action.payload.message,
        success: '',
      };
    }
    case LIST_WRITINGS_REPORTS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_WRITINGS_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: action.payload,
        error: '',
      };
    case LIST_WRITINGS_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
        reports: null,
        error: action.payload.message,
      };
    case ADD_CRITERION_WRITINGS_REQUEST:
      return { ...state, loadingButton: true, error: '' };
    case ADD_CRITERION_WRITINGS_SUCCESS:
      return {
        ...state,
        loadingButton: false,
        success: { criterionWritings: action.payload },
        error: '',
      };
    case ADD_CRITERION_WRITINGS_ERROR:
      return {
        ...state,
        loadingButton: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_CRITERION_WRITINGS_REQUEST:
      return { ...state, loadingButton: true, error: '' };
    case EDIT_CRITERION_WRITINGS_SUCCESS:
      return {
        ...state,
        loadingButton: false,
        success: { criterionWritings: action.payload },
        error: '',
      };
    case EDIT_CRITERION_WRITINGS_ERROR:
      return {
        ...state,
        loadingButton: false,
        error: action.payload.message,
        success: '',
      };
    case RECUSE_WRITINGS_REQUEST:
      return { ...state, loading: true, error: '' };
    case RECUSE_WRITINGS_SUCCESS:
      return {
        ...state,
        success: { correction: action.payload },
        error: '',
      };
    case RECUSE_WRITINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case ACCEPT_WRITINGS_REQUEST:
      return { ...state, loading: true, error: '' };
    case ACCEPT_WRITINGS_SUCCESS:
      return {
        ...state,
        success: action.payload,
        error: '',
      };
    case ACCEPT_WRITINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case SET_WRITINGS_MODAL_VALUES: {
      return {
        ...state,
        currentStudentId: action.payload.studentId,
        currentWritingId: action.payload.writingId,
      };
    }
    case SEND_RATINGS_WRITINGS_REQUEST:
      return { ...state, error: '', success: '' };
    case SEND_RATINGS_WRITINGS_SUCCESS: {
      return {
        ...state,
        error: '',
        success: { sendRatings: action.payload },
      };
    }
    case SEND_RATINGS_WRITINGS_ERROR:
      return {
        ...state,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
