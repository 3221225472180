import { toast } from 'react-toastify';

export default function getApiErrors(apiErrors) {
  if (apiErrors.errors) {
    Object.keys(apiErrors.errors).forEach((key) => {
      toast.error(`${apiErrors.errors[key]}`);
    });
  } else if (apiErrors.error) {
    toast.error(`${apiErrors.error}`);
  } else {
    toast.error(`${apiErrors.message}`);
  }
}
