import {
  LIST_JOURNEY_TRAILS_REQUEST,
  LIST_JOURNEY_TRAILS_SUCCESS,
  LIST_JOURNEY_TRAILS_ERROR,
  RESET_STATE_ALL_APPLICATION,
  GET_JOURNEY_TRAILS_REQUEST,
  GET_JOURNEY_TRAILS_SUCCESS,
  GET_JOURNEY_TRAILS_ERROR,
  ADD_JOURNEY_TRAILS_REQUEST,
  ADD_JOURNEY_TRAILS_SUCCESS,
  ADD_JOURNEY_TRAILS_ERROR,
  EDIT_JOURNEY_TRAILS_REQUEST,
  EDIT_JOURNEY_TRAILS_SUCCESS,
  RESET_STATE_REDUX,
  ORDINATION_JOURNEY_TRAIL_WEEKS_REQUEST,
  ORDINATION_JOURNEY_TRAIL_WEEKS_SUCCESS,
  ORDINATION_JOURNEY_TRAIL_WEEKS_ERROR
} from '../actions';

const INIT_STATE = {
  currentJourneyTrail: null,
  currentJourneyTrailsList: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentJourneyTrail: null,
        success: '',
      };
    case GET_JOURNEY_TRAILS_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_JOURNEY_TRAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentJourneyTrail: action.payload,
        error: '',
      };
    case GET_JOURNEY_TRAILS_ERROR:
      return {
        ...state,
        loading: false,
        currentJourneyTrail: null,
        error: action.payload.message,
      };
    case LIST_JOURNEY_TRAILS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_JOURNEY_TRAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentJourneyTrailsList: action.payload,
        error: '',
      };
    case LIST_JOURNEY_TRAILS_ERROR:
      return {
        ...state,
        loading: false,
        currentJourneyTrailsList: null,
        error: action.payload.message,
      };
    case ADD_JOURNEY_TRAILS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_JOURNEY_TRAILS_SUCCESS: {
      const allJourneyTrails = [...state.currentJourneyTrailsList, action.payload];

      return {
        ...state,
        currentJourneyTrailsList: allJourneyTrails,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_JOURNEY_TRAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_JOURNEY_TRAILS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_JOURNEY_TRAILS_SUCCESS: {
      const journeyTrailsUpdated = state.currentJourneyTrailsList.map((journeyTrails) => {
        if (journeyTrails.id === action.payload.journeyTrails.id) {
          return action.payload.journeyTrails;
        }
        return journeyTrails;
      });

      return {
        ...state,
        currentJourneyTrailsList: journeyTrailsUpdated,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ORDINATION_JOURNEY_TRAIL_WEEKS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ORDINATION_JOURNEY_TRAIL_WEEKS_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.payload,
        currentJourneyTrailsList: null,
        error: '',
      };
    }
    case ORDINATION_JOURNEY_TRAIL_WEEKS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
