import {
  LIST_SCORE_REQUEST,
  LIST_SCORE_SUCCESS,
  LIST_SCORE_ERROR,
} from '../actions';

export const listScoreRequest = () => ({
  type: LIST_SCORE_REQUEST,
});
export const listScoreSuccess = (cycles) => ({
  type: LIST_SCORE_SUCCESS,
  payload: cycles,
});
export const listScoreError = (message) => ({
  type: LIST_SCORE_ERROR,
  payload: { message },
});
