import { all, call, put, takeLatest } from 'redux-saga/effects';

import api, { apiDev } from '../../services/api';
import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PROFILE_IMAGE_REQUEST,
  GET_CONFIGURATIONS_REQUEST,
  RESET_DB_TESTS_REQUEST
} from "../actions";
import {
  changePasswordSuccess,
  changePasswordError,
  getConfigurationsError,
  getConfigurationsSuccess,
  changeProfileImageSuccess,
  changeProfileImageError,
  resetDbTestsSuccess,
  resetDbTestsError
} from "./actions";

const changePasswordAsync = async (id, data) =>
  api
    .patch(`backoffice/${id}/change-password`, {
      ...data,
    })
    .then((response) => response)
    .catch((error) => error);

function* changePassword({ payload }) {
  const { id, data } = payload;
  try {
    const res = yield call(changePasswordAsync, id, data);
    if (!res.response) {
      yield put(changePasswordSuccess(res.data.data));
    } else {
      yield put(changePasswordError(res.response.data));
    }
  } catch (error) {
    yield put(changePasswordError(...error.response.data));
  }
}

function* getConfigurationsById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `administrators/${id}`);
    yield put(getConfigurationsSuccess(response.data.data));
  } catch (error) {
    yield put(getConfigurationsError(error));
  }
}

const addProfileImageAsync = async (data) =>
  api
    .post(`administrators/upload/avatar`, {
      ...data,
    })
    .then((response) => response)
    .catch((error) => error);

function* changeProfileImage({ payload }) {
  const { data } = payload;
  try {
    const res = yield call(addProfileImageAsync, data);
    if (!res.response) {
      yield put(changeProfileImageSuccess(res.data));
    } else {
      yield put(changeProfileImageError(res.response.data));
    }
  } catch (error) {
    yield put(changeProfileImageError(error));
  }
}

const resetDbTestsAsync = async () =>
  apiDev
    .post(`/reset-db-dev`)
    .then((response) => response)
    .catch((error) => error);

function* resetDbTests() {
  try {
    const res = yield call(resetDbTestsAsync);
    if (!res.response) {
      yield put(resetDbTestsSuccess(res.data));
    } else {
      yield put(resetDbTestsError(res.response.data));
    }
  } catch (error) {
    yield put(resetDbTestsError(error));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(CHANGE_PASSWORD_REQUEST, changePassword)]);
  yield all([takeLatest(CHANGE_PROFILE_IMAGE_REQUEST, changeProfileImage)]);
  yield all([takeLatest(GET_CONFIGURATIONS_REQUEST, getConfigurationsById)]);
  yield all([takeLatest(RESET_DB_TESTS_REQUEST, resetDbTests)]);
}
