import {
  LIST_CLASS_CYCLE_REQUEST,
  LIST_CLASS_CYCLE_SUCCESS,
  LIST_CLASS_CYCLE_ERROR,
  RESET_STATE_ALL_APPLICATION,
  ADD_CLASS_CYCLE_REQUEST,
  ADD_CLASS_CYCLE_SUCCESS,
  ADD_CLASS_CYCLE_ERROR,
  EDIT_CLASS_CYCLE_REQUEST,
  EDIT_CLASS_CYCLE_SUCCESS,
  EDIT_CLASS_CYCLE_ERROR,
  RESET_STATE_REDUX
} from '../../actions';

const INIT_STATE = {
  currentClassCycleList: null,
  className: null,
  loading: false,
  success: '',
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        success: '',
      };
    case LIST_CLASS_CYCLE_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_CLASS_CYCLE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentClassCycleList: action.payload,
        error: '',
        className: action.className
      };
    case LIST_CLASS_CYCLE_ERROR:
      return {
        ...state,
        loading: false,
        currentClassCycleList: null,
        error: action.payload.message,
        className: null,
      };
    case ADD_CLASS_CYCLE_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_CLASS_CYCLE_SUCCESS: {
      const allClassCycles = [...state.currentClassCycleList, action.payload];

      return {
        ...state,
        currentClassCycleList: allClassCycles,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case ADD_CLASS_CYCLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_CLASS_CYCLE_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_CLASS_CYCLE_SUCCESS: {
      const allClassCycles = [...state.currentClassCycleList, action.payload];

      return {
        ...state,
        currentClassCycleList: allClassCycles,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case EDIT_CLASS_CYCLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
