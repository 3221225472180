import {
  LIST_JOURNEYS_REQUEST,
  LIST_JOURNEYS_SUCCESS,
  LIST_JOURNEYS_ERROR,
  GET_JOURNEYS_REQUEST,
  GET_JOURNEYS_SUCCESS,
  GET_JOURNEYS_ERROR,
  ADD_JOURNEYS_REQUEST,
  ADD_JOURNEYS_SUCCESS,
  ADD_JOURNEYS_ERROR,
  EDIT_JOURNEYS_REQUEST,
  EDIT_JOURNEYS_SUCCESS,
  EDIT_JOURNEYS_ERROR
} from '../actions';

export const getJourneysRequest = (id) => ({
  type: GET_JOURNEYS_REQUEST,
  payload: { id },
});
export const getJourneysSuccess = (journeys) => ({
  type: GET_JOURNEYS_SUCCESS,
  payload: journeys,
});
export const getJourneysError = (message) => ({
  type: GET_JOURNEYS_ERROR,
  payload: { message },
});

export const listJourneysRequest = () => ({
  type: LIST_JOURNEYS_REQUEST,
});
export const listJourneysSuccess = (journeys) => ({
  type: LIST_JOURNEYS_SUCCESS,
  payload: journeys,
});
export const listJourneysError = (message) => ({
  type: LIST_JOURNEYS_ERROR,
  payload: { message },
});

export const addJourneysRequest = (journeys) => ({
  type: ADD_JOURNEYS_REQUEST,
  payload: { journeys },
});
export const addJourneysSuccess = (journeys) => ({
  type: ADD_JOURNEYS_SUCCESS,
  payload: journeys,
});
export const addJourneysError = (message) => ({
  type: ADD_JOURNEYS_ERROR,
  payload: { message },
});

export const editJourneysRequest = (journeys) => ({
  type: EDIT_JOURNEYS_REQUEST,
  payload: { journeys },
});
export const editJourneysSuccess = (journeys) => ({
  type: EDIT_JOURNEYS_SUCCESS,
  payload: { journeys },
});
export const editJourneysError = (message) => ({
  type: EDIT_JOURNEYS_ERROR,
  payload: { message },
});

