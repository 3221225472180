import {
  LIST_COORDINATOR_REQUEST,
  LIST_COORDINATOR_SUCCESS,
  LIST_COORDINATOR_ERROR,
  GET_COORDINATOR_REQUEST,
  GET_COORDINATOR_SUCCESS,
  GET_COORDINATOR_ERROR,
  ADD_COORDINATOR_REQUEST,
  ADD_COORDINATOR_SUCCESS,
  ADD_COORDINATOR_ERROR,
  EDIT_COORDINATOR_REQUEST,
  EDIT_COORDINATOR_SUCCESS,
  EDIT_COORDINATOR_ERROR,
  EDIT_ACTIVE_COORDINATOR_REQUEST,
  EDIT_ACTIVE_COORDINATOR_SUCCESS,
  EDIT_ACTIVE_COORDINATOR_ERROR,
} from '../actions';

export const getCoordinatorRequest = (id) => ({
  type: GET_COORDINATOR_REQUEST,
  payload: { id },
});
export const getCoordinatorSuccess = (coordinator) => ({
  type: GET_COORDINATOR_SUCCESS,
  payload: coordinator,
});
export const getCoordinatorError = (message) => ({
  type: GET_COORDINATOR_ERROR,
  payload: { message },
});

export const listCoordinatorRequest = () => ({
  type: LIST_COORDINATOR_REQUEST,
});
export const listCoordinatorSuccess = (coordinators) => ({
  type: LIST_COORDINATOR_SUCCESS,
  payload: coordinators,
});
export const listCoordinatorError = (message) => ({
  type: LIST_COORDINATOR_ERROR,
  payload: { message },
});

export const addCoordinatorRequest = (coordinator) => ({
  type: ADD_COORDINATOR_REQUEST,
  payload: { coordinator },
});
export const addCoordinatorSuccess = (coordinator) => ({
  type: ADD_COORDINATOR_SUCCESS,
  payload: coordinator,
});
export const addCoordinatorError = (message) => ({
  type: ADD_COORDINATOR_ERROR,
  payload: { message },
});

export const editCoordinatorRequest = (coordinator) => ({
  type: EDIT_COORDINATOR_REQUEST,
  payload: { coordinator },
});
export const editCoordinatorSuccess = (coordinator) => ({
  type: EDIT_COORDINATOR_SUCCESS,
  payload: { coordinator },
});
export const editCoordinatorError = (message) => ({
  type: EDIT_COORDINATOR_ERROR,
  payload: { message },
});

export const editActiveCoordinatorRequest = (id, active) => ({
  type: EDIT_ACTIVE_COORDINATOR_REQUEST,
  payload: { id, active },
});
export const editActiveCoordinatorSuccess = (id, coordinator) => ({
  type: EDIT_ACTIVE_COORDINATOR_SUCCESS,
  payload: { id, coordinator },
});
export const editActiveCoordinatorError = (message) => ({
  type: EDIT_ACTIVE_COORDINATOR_ERROR,
  payload: { message },
});
