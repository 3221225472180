import {
  LIST_PREREQUISITES_REQUEST,
  LIST_PREREQUISITES_SUCCESS,
  LIST_PREREQUISITES_ERROR,
  GET_PREREQUISITES_REQUEST,
  GET_PREREQUISITES_SUCCESS,
  GET_PREREQUISITES_ERROR,
  ADD_PREREQUISITES_REQUEST,
  ADD_PREREQUISITES_SUCCESS,
  ADD_PREREQUISITES_ERROR,
  EDIT_PREREQUISITES_REQUEST,
  EDIT_PREREQUISITES_SUCCESS,
  EDIT_PREREQUISITES_ERROR
} from '../actions';

export const getPrerequisitesRequest = (id) => ({
  type: GET_PREREQUISITES_REQUEST,
  payload: { id },
});
export const getPrerequisitesSuccess = (prerequisites) => ({
  type: GET_PREREQUISITES_SUCCESS,
  payload: prerequisites,
});
export const getPrerequisitesError = (message) => ({
  type: GET_PREREQUISITES_ERROR,
  payload: { message },
});

export const listPrerequisitesRequest = () => ({
  type: LIST_PREREQUISITES_REQUEST,
});
export const listPrerequisitesSuccess = (prerequisites) => ({
  type: LIST_PREREQUISITES_SUCCESS,
  payload: prerequisites,
});
export const listPrerequisitesError = (message) => ({
  type: LIST_PREREQUISITES_ERROR,
  payload: { message },
});

export const addPrerequisitesRequest = (prerequisites) => ({
  type: ADD_PREREQUISITES_REQUEST,
  payload: { prerequisites },
});
export const addPrerequisitesSuccess = (prerequisites) => ({
  type: ADD_PREREQUISITES_SUCCESS,
  payload: prerequisites,
});
export const addPrerequisitesError = (message) => ({
  type: ADD_PREREQUISITES_ERROR,
  payload: { message },
});

export const editPrerequisitesRequest = (prerequisites) => ({
  type: EDIT_PREREQUISITES_REQUEST,
  payload: { prerequisites },
});
export const editPrerequisitesSuccess = (prerequisites) => ({
  type: EDIT_PREREQUISITES_SUCCESS,
  payload: { prerequisites },
});
export const editPrerequisitesError = (message) => ({
  type: EDIT_PREREQUISITES_ERROR,
  payload: { message },
});

