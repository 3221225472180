import { toast } from 'react-toastify';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  LIST_WRITINGS_REQUEST,
  GET_WRITINGS_REQUEST,
  ADD_WRITINGS_REQUEST,
  EDIT_WRITINGS_REQUEST,
  SEND_WRITINGS_REQUEST,
  EDIT_ACTIVE_WRITINGS_REQUEST,
  EDIT_WRITINGS_VINCULATE_CRITERIONS_REQUEST,
  IMPORT_WRITINGS_REQUEST,
  SEND_RATINGS_WRITINGS_REQUEST,
  REMOVE_IMPORT_WRITINGS_REQUEST,
  LIST_WRITINGS_REPORTS_REQUEST,
  ADD_CRITERION_WRITINGS_REQUEST,
  EDIT_CRITERION_WRITINGS_REQUEST,
  ACCEPT_WRITINGS_REQUEST,
  RECUSE_WRITINGS_REQUEST,
} from '../actions';
import {
  listWritingsSuccess,
  listWritingsError,
  getWritingsSuccess,
  getWritingsError,
  addWritingsSuccess,
  addWritingsError,
  editWritingsSuccess,
  editWritingsError,
  editActiveWritingsSuccess,
  editActiveWritingsError,
  sendWritingsSuccess,
  sendWritingsError,
  editWritingsVinculateCriterionsSuccess,
  editWritingsVinculateCriterionsError,
  importWritingsSuccess,
  importWritingsError,
  removeImportWritingsSuccess,
  removeImportWritingsError,
  listWritingsReportsSuccess,
  listWritingsReportsError,
  addCriterionWritingsSuccess,
  addCriterionWritingsError,
  editCriterionWritingsSuccess,
  editCriterionWritingsError,
  acceptWritingsSuccess,
  acceptWritingsError,
  recuseWritingsSuccess,
  recuseWritingsError,
  sendRatingsWritingsSuccess,
  sendRatingsWritingsError,
} from './actions';

function* getWritingsById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `writings/${id}`);
    yield put(getWritingsSuccess(response.data.data));
  } catch (error) {
    yield put(getWritingsError(error));
  }
}

function* listWritings() {
  try {
    const response = yield call(api.get, `writings`);
    yield put(listWritingsSuccess(response.data.data));
  } catch (error) {
    yield put(listWritingsError(error));
  }
}

const addWritingsAsync = async (completeWritings) =>
  api
    .post(`writings`, {
      ...completeWritings,
    })
    .then((response) => response)
    .catch((error) => error);

const editWritingsAsync = async (completeWritings) =>
  api
    .put(`writings/${completeWritings.id}`, {
      ...completeWritings,
    })
    .then((response) => response)
    .catch((error) => error);

function* addWritings({ payload }) {
  try {
    const { writings } = payload;
    const completeWritings = { ...writings };
    const res = yield call(addWritingsAsync, completeWritings);
    if (!res.response) {
      const responseValue = res.data.data;

      yield put(
        addWritingsSuccess({ ...responseValue, active: true, files: [] })
      );
    } else {
      yield put(addWritingsError(res.response.data));
    }
  } catch (error) {
    yield put(addWritingsError(error));
  }
}

function* editWritings({ payload }) {
  try {
    const { writings } = payload;
    const completeWritings = { ...writings };
    const res = yield call(editWritingsAsync, completeWritings);
    if (!res.response) {
      const responseValue = res.data.data;

      yield put(
        editWritingsSuccess({ ...responseValue, active: true, files: [] })
      );
    } else {
      yield put(editWritingsError(res.response.data));
    }
  } catch (error) {
    yield put(editWritingsError(error));
  }
}

function* editActiveWritings({ payload }) {
  try {
    const { id, active } = payload;
    const response = yield call(
      api.put,
      `writings/${id}/activate/${active ? '1' : '0'}`
    );
    const responseValue = response.data.data;

    yield put(editActiveWritingsSuccess(responseValue));
  } catch (error) {
    yield put(editActiveWritingsError(error));
  }
}

function* sendActiveWritings({ payload }) {
  try {
    const { id } = payload;
    yield call(api.post, `writings/${id}/sent`);

    yield put(sendWritingsSuccess(id));
  } catch (error) {
    yield put(sendWritingsError(error));
  }
}

const editWritingsVinculateCriterionsAsync = async (criterionList) =>
  api
    .post(`writings/criterions`, {
      ...criterionList,
    })
    .then((response) => response)
    .catch((error) => error);

function* editWritingsVinculateCriterions({ payload }) {
  try {
    const { criterionList } = payload;
    const res = yield call(editWritingsVinculateCriterionsAsync, criterionList);
    if (!res.response) {
      yield put(
        editWritingsVinculateCriterionsSuccess(
          criterionList.writing_id,
          res.data.data
        )
      );
    } else {
      yield put(editWritingsVinculateCriterionsError(res.response.data));
    }
  } catch (error) {
    yield put(editWritingsVinculateCriterionsError(error));
  }
}

const importWritingsAsync = async (files) =>
  api
    .post(`writings/upload`, files)
    .then((response) => response)
    .catch((error) => error);

function* importWritings({ payload }) {
  try {
    const { files } = payload;
    const res = yield call(importWritingsAsync, files);
    if (!res.response) {
      yield put(importWritingsSuccess(res.data));
      toast.success(`Arquivo(s) incluído(s) com sucesso!`);
    } else {
      yield put(importWritingsError(res.response.data));
      toast.error(`Falha ao incluír Arquivo(s)!`);
    }
  } catch (error) {
    yield put(importWritingsError(error));
    toast.error(`Falha ao incluír Arquivo(s)!`);
  }
}

const removeImportWritingsAsync = async (id) =>
  api
    .delete(`files/${id}`)
    .then((response) => response)
    .catch((error) => error);

function* removeImportWritings({ payload }) {
  const { writingId, id } = payload;
  try {
    yield put(removeImportWritingsSuccess(writingId, id));
    const res = yield call(removeImportWritingsAsync, id);
    if (!res.response) {
      toast.success(`Arquivo removido com sucesso!`);
    } else {
      yield put(removeImportWritingsError(writingId, id));
      toast.error(`Falha ao remover Arquivo!`);
    }
  } catch (error) {
    yield put(removeImportWritingsError(writingId, id));
    toast.error(`Falha ao remover Arquivo!`);
  }
}

function* listWritingsReports({ payload }) {
  try {
    const { classId, cycle } = payload;
    const response = yield call(
      api.get,
      `students/reports/writing-performance/classes/${classId}/cycle/${cycle}`
    );
    yield put(listWritingsReportsSuccess(response.data.data));
  } catch (error) {
    yield put(listWritingsReportsError(error));
  }
}

const addCriterionWritingsAsync = async (completeWritings) =>
  api
    .post(`criterions/writings/students`, {
      ...completeWritings,
    })
    .then((response) => response)
    .catch((error) => error);

function* addCriterionWritings({ payload }) {
  try {
    const { criterionWritings } = payload;
    const res = yield call(addCriterionWritingsAsync, criterionWritings);
    if (!res.response) {
      const responseValue = res.data.message;

      yield put(addCriterionWritingsSuccess(responseValue));
    } else {
      yield put(addCriterionWritingsError(res.response.data));
    }
  } catch (error) {
    yield put(addCriterionWritingsError(error));
  }
}

const editCriterionWritingsAsync = async (completeWritings) =>
  api
    .put(`criterions/writings/students`, {
      ...completeWritings,
    })
    .then((response) => response)
    .catch((error) => error);

function* editCriterionWritings({ payload }) {
  try {
    const { criterionWritings } = payload;
    const res = yield call(editCriterionWritingsAsync, criterionWritings);
    if (!res.response) {
      const responseValue = res.data.message;

      yield put(editCriterionWritingsSuccess(responseValue));
    } else {
      yield put(editCriterionWritingsError(res.response.data));
    }
  } catch (error) {
    yield put(editCriterionWritingsError(error));
  }
}

const acceptWritingsAsync = async (completeWritings) =>
  api
    .put(`writings/students/correction`, {
      ...completeWritings,
    })
    .then((response) => response)
    .catch((error) => error);

function* acceptWritings({ payload }) {
  try {
    const { writings } = payload;
    const res = yield call(acceptWritingsAsync, writings);
    if (!res.response) {
      const responseValue = res.data.message;

      yield put(acceptWritingsSuccess(responseValue));
    } else {
      yield put(acceptWritingsError(res.response.data));
    }
  } catch (error) {
    yield put(acceptWritingsError(error));
  }
}

const recuseWritingsAsync = async (completeWritings) =>
  api
    .put(`writings/students/correction`, {
      ...completeWritings,
    })
    .then((response) => response)
    .catch((error) => error);

function* recuseWritings({ payload }) {
  try {
    const { writings } = payload;
    const res = yield call(recuseWritingsAsync, writings);
    if (!res.response) {
      const responseValue = res.data.message;

      yield put(recuseWritingsSuccess(responseValue));
    } else {
      yield put(recuseWritingsError(res.response.data));
    }
  } catch (error) {
    yield put(recuseWritingsError(error));
  }
}

const sendRatingsWritingsAsync = async (writingStudents) =>
  api
    .post(`writings/students/correction/all`, writingStudents)
    .then((response) => response)
    .catch((error) => error);

function* sendRatingsWritings({ payload }) {
  try {
    const { writingStudents } = payload;
    const res = yield call(sendRatingsWritingsAsync, writingStudents);
    if (!res.response) {
      yield put(sendRatingsWritingsSuccess(res.data));
      toast.success(res.data.message);
    } else {
      yield put(sendRatingsWritingsError(res.response.data));
    }
  } catch (error) {
    yield put(sendRatingsWritingsError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_WRITINGS_REQUEST, listWritings),
    takeLatest(GET_WRITINGS_REQUEST, getWritingsById),
    takeLatest(GET_WRITINGS_REQUEST, getWritingsById),
    takeLatest(ACCEPT_WRITINGS_REQUEST, acceptWritings),
    takeLatest(RECUSE_WRITINGS_REQUEST, recuseWritings),
    takeLatest(LIST_WRITINGS_REPORTS_REQUEST, listWritingsReports),
    takeLatest(ADD_WRITINGS_REQUEST, addWritings),
    takeLatest(
      EDIT_WRITINGS_VINCULATE_CRITERIONS_REQUEST,
      editWritingsVinculateCriterions
    ),
    takeLatest(EDIT_WRITINGS_REQUEST, editWritings),
    takeLatest(EDIT_ACTIVE_WRITINGS_REQUEST, editActiveWritings),
    takeLatest(SEND_WRITINGS_REQUEST, sendActiveWritings),
    takeLatest(IMPORT_WRITINGS_REQUEST, importWritings),
    takeLatest(SEND_RATINGS_WRITINGS_REQUEST, sendRatingsWritings),
    takeEvery(REMOVE_IMPORT_WRITINGS_REQUEST, removeImportWritings),
    takeLatest(ADD_CRITERION_WRITINGS_REQUEST, addCriterionWritings),
    takeLatest(EDIT_CRITERION_WRITINGS_REQUEST, editCriterionWritings),
  ]);
}
