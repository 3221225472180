import {
  RESET_STUDENT_PASSWORD,
  RESET_STUDENT_PASSWORD_SUCCESS,
  RESET_STUDENT_PASSWORD_ERROR,
  RESET_STATE_ALL_APPLICATION,
  FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_REQUEST,
  FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_SUCCESS,
  FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_ERROR,
  CONFIRM_STUDENT_ACCOUNT_REQUEST,
  CONFIRM_STUDENT_ACCOUNT_SUCCESS,
  CONFIRM_STUDENT_ACCOUNT_ERROR
} from '../../actions';

const INIT_STATE = {
  forgotUserMail: '',
  success: '',
  password: '',
  token: '',
  loading: false,
  error: '',
  email: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_REQUEST:
      return { ...state, loading: true, error: '', email: '' };
    case FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        email: action.payload.email,
        error: '',
      };
    case FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        email: '',
        error: action.payload.message,
      };
    case RESET_STUDENT_PASSWORD:
      return { ...state, loading: true, error: '', success: '' };
    case RESET_STUDENT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        token: '',
        error: '',
      };
    case RESET_STUDENT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        password: '',
        token: '',
        error: action.payload.message,
        success: '',
      };
    case CONFIRM_STUDENT_ACCOUNT_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case CONFIRM_STUDENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        error: '',
      };
    case CONFIRM_STUDENT_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
