import {
  LIST_NETWORK_COORDINATOR_REQUEST,
  LIST_NETWORK_COORDINATOR_SUCCESS,
  LIST_NETWORK_COORDINATOR_ERROR,
  GET_NETWORK_COORDINATOR_REQUEST,
  GET_NETWORK_COORDINATOR_SUCCESS,
  GET_NETWORK_COORDINATOR_ERROR,
  ADD_NETWORK_COORDINATOR_REQUEST,
  ADD_NETWORK_COORDINATOR_SUCCESS,
  ADD_NETWORK_COORDINATOR_ERROR,
  EDIT_NETWORK_COORDINATOR_REQUEST,
  EDIT_NETWORK_COORDINATOR_SUCCESS,
  EDIT_NETWORK_COORDINATOR_ERROR,
  EDIT_ACTIVE_NETWORK_COORDINATOR_REQUEST,
  EDIT_ACTIVE_NETWORK_COORDINATOR_SUCCESS,
  EDIT_ACTIVE_NETWORK_COORDINATOR_ERROR,
} from '../actions';

export const getNetworkCoordinatorRequest = (id) => ({
  type: GET_NETWORK_COORDINATOR_REQUEST,
  payload: { id },
});
export const getNetworkCoordinatorSuccess = (networkCoordinator) => ({
  type: GET_NETWORK_COORDINATOR_SUCCESS,
  payload: networkCoordinator,
});
export const getNetworkCoordinatorError = (message) => ({
  type: GET_NETWORK_COORDINATOR_ERROR,
  payload: { message },
});

export const listNetworkCoordinatorRequest = () => ({
  type: LIST_NETWORK_COORDINATOR_REQUEST,
});
export const listNetworkCoordinatorSuccess = (networkCoordinators) => ({
  type: LIST_NETWORK_COORDINATOR_SUCCESS,
  payload: networkCoordinators,
});
export const listNetworkCoordinatorError = (message) => ({
  type: LIST_NETWORK_COORDINATOR_ERROR,
  payload: { message },
});

export const addNetworkCoordinatorRequest = (networkCoordinator) => ({
  type: ADD_NETWORK_COORDINATOR_REQUEST,
  payload: { networkCoordinator },
});
export const addNetworkCoordinatorSuccess = (networkCoordinator) => ({
  type: ADD_NETWORK_COORDINATOR_SUCCESS,
  payload: networkCoordinator,
});
export const addNetworkCoordinatorError = (message) => ({
  type: ADD_NETWORK_COORDINATOR_ERROR,
  payload: { message },
});

export const editNetworkCoordinatorRequest = (networkCoordinator) => ({
  type: EDIT_NETWORK_COORDINATOR_REQUEST,
  payload: { networkCoordinator },
});
export const editNetworkCoordinatorSuccess = (networkCoordinator) => ({
  type: EDIT_NETWORK_COORDINATOR_SUCCESS,
  payload: { networkCoordinator },
});
export const editNetworkCoordinatorError = (message) => ({
  type: EDIT_NETWORK_COORDINATOR_ERROR,
  payload: { message },
});

export const editActiveNetworkCoordinatorRequest = (id, active) => ({
  type: EDIT_ACTIVE_NETWORK_COORDINATOR_REQUEST,
  payload: { id, active },
});
export const editActiveNetworkCoordinatorSuccess = (
  id,
  networkCoordinator
) => ({
  type: EDIT_ACTIVE_NETWORK_COORDINATOR_SUCCESS,
  payload: { id, networkCoordinator },
});
export const editActiveNetworkCoordinatorError = (message) => ({
  type: EDIT_ACTIVE_NETWORK_COORDINATOR_ERROR,
  payload: { message },
});
