import {
  LIST_EXAM_EXTERNALS_REQUEST,
  LIST_EXAM_EXTERNALS_SUCCESS,
  LIST_EXAM_EXTERNALS_ERROR,
  ADD_EXAM_EXTERNALS_REQUEST,
  ADD_EXAM_EXTERNALS_SUCCESS,
  ADD_EXAM_EXTERNALS_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  currentExamExternalsList: [],
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentExamExternalsList: [],
        success: '',
      };
    case LIST_EXAM_EXTERNALS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_EXAM_EXTERNALS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentExamExternalsList: action.payload,
        error: '',
      };
    case LIST_EXAM_EXTERNALS_ERROR:
      return {
        ...state,
        loading: false,
        currentExamExternalsList: null,
        error: action.payload.message,
      };
    case ADD_EXAM_EXTERNALS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_EXAM_EXTERNALS_SUCCESS: {
      const allExamExternals = [
        ...state.currentExamExternalsList,
        action.payload,
      ];

      return {
        ...state,
        currentExamExternalsList: allExamExternals,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_EXAM_EXTERNALS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
