import {
  LIST_CRITERIONS_GROUPS_REQUEST,
  LIST_CRITERIONS_GROUPS_SUCCESS,
  LIST_CRITERIONS_GROUPS_ERROR,
  GET_CRITERIONS_GROUPS_REQUEST,
  GET_CRITERIONS_GROUPS_SUCCESS,
  GET_CRITERIONS_GROUPS_ERROR,
  ADD_CRITERIONS_GROUPS_REQUEST,
  ADD_CRITERIONS_GROUPS_SUCCESS,
  ADD_CRITERIONS_GROUPS_ERROR,
  EDIT_CRITERIONS_GROUPS_REQUEST,
  EDIT_CRITERIONS_GROUPS_SUCCESS,
  EDIT_CRITERIONS_GROUPS_ERROR,
  EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_REQUEST,
  EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_SUCCESS,
  EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_ERROR,
  GET_CRITERIONS_GROUPS_TYPES_REQUEST,
  GET_CRITERIONS_GROUPS_TYPES_SUCCESS,
  GET_CRITERIONS_GROUPS_TYPES_ERROR,
} from '../actions';

export const getCriterionsGroupsRequest = (id) => ({
  type: GET_CRITERIONS_GROUPS_REQUEST,
  payload: { id },
});
export const getCriterionsGroupsSuccess = (criterionGroup) => ({
  type: GET_CRITERIONS_GROUPS_SUCCESS,
  payload: criterionGroup,
});
export const getCriterionsGroupsError = (message) => ({
  type: GET_CRITERIONS_GROUPS_ERROR,
  payload: { message },
});

export const listCriterionsGroupsRequest = () => ({
  type: LIST_CRITERIONS_GROUPS_REQUEST,
});
export const listCriterionsGroupsSuccess = (criterionsGroups) => ({
  type: LIST_CRITERIONS_GROUPS_SUCCESS,
  payload: criterionsGroups,
});
export const listCriterionsGroupsError = (message) => ({
  type: LIST_CRITERIONS_GROUPS_ERROR,
  payload: { message },
});

export const addCriterionsGroupsRequest = (criterionGroup) => ({
  type: ADD_CRITERIONS_GROUPS_REQUEST,
  payload: { criterionGroup },
});
export const addCriterionsGroupsSuccess = (criterionGroup) => ({
  type: ADD_CRITERIONS_GROUPS_SUCCESS,
  payload: criterionGroup,
});
export const addCriterionsGroupsError = (message) => ({
  type: ADD_CRITERIONS_GROUPS_ERROR,
  payload: { message },
});

export const editCriterionsGroupsRequest = (criterionGroup) => ({
  type: EDIT_CRITERIONS_GROUPS_REQUEST,
  payload: { criterionGroup },
});
export const editCriterionsGroupsSuccess = (criterionGroup) => ({
  type: EDIT_CRITERIONS_GROUPS_SUCCESS,
  payload: { criterionGroup },
});
export const editCriterionsGroupsError = (message) => ({
  type: EDIT_CRITERIONS_GROUPS_ERROR,
  payload: { message },
});

export const editCriterionsGroupsVinculateCriterionsRequest = (
  id,
  criterionList
) => ({
  type: EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_REQUEST,
  payload: { id, criterionList },
});
export const editCriterionsGroupsVinculateCriterionsSuccess = (message) => ({
  type: EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_SUCCESS,
  payload: { message },
});
export const editCriterionsGroupsVinculateCriterionsError = (message) => ({
  type: EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_ERROR,
  payload: { message },
});

export const getCriterionsGroupsTypesRequest = () => ({
  type: GET_CRITERIONS_GROUPS_TYPES_REQUEST,
});
export const getCriterionsGroupsTypesSuccess = (id) => ({
  type: GET_CRITERIONS_GROUPS_TYPES_SUCCESS,
  payload: id,
});
export const getCriterionsGroupsTypesError = (message) => ({
  type: GET_CRITERIONS_GROUPS_TYPES_ERROR,
  payload: { message },
});
