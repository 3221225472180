import { all, call, put, takeLatest } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '../../services/api';
import {
  LIST_EXAM_PRACTICES_REQUEST,
  LIST_EXAM_PRACTICES_TYPES_REQUEST,
  LIST_EXAM_PRACTICES_REPORTS_REQUEST,
  EXPORT_EXAM_PRACTICES_REPORTS_REQUEST,
  LIST_EXAM_PRACTICES_SUBJECTS_REQUEST,
  VINCULATE_EXAM_PRACTICES_SUBJECTS_REQUEST,
  VINCULATE_EXAM_PRACTICES_QUESTIONS_REQUEST,
  GET_EXAM_PRACTICES_REQUEST,
  ADD_EXAM_PRACTICES_REQUEST,
  EDIT_EXAM_PRACTICES_REQUEST,
  EDIT_ACTIVE_EXAM_PRACTICES_REQUEST,
  IMPORT_EXAM_PRACTICES_REQUEST,
  REMOVE_IMPORT_EXAM_PRACTICES_REQUEST,
  SEND_EXAM_PRACTICE_REQUEST,
} from '../actions';
import {
  listExamPracticesSuccess,
  listExamPracticesError,
  listExamPracticesReportsSuccess,
  listExamPracticesReportsError,
  listExamPracticesSubjectsSuccess,
  listExamPracticesSubjectsError,
  vinculateExamPracticesSubjectsSuccess,
  vinculateExamPracticesSubjectsError,
  vinculateExamPracticesQuestionsSuccess,
  vinculateExamPracticesQuestionsError,
  getExamPracticesSuccess,
  getExamPracticesError,
  addExamPracticesSuccess,
  addExamPracticesError,
  editExamPracticesSuccess,
  editExamPracticesError,
  editActiveExamPracticesSuccess,
  editActiveExamPracticesError,
  exportExamPracticesReportsSuccess,
  exportExamPracticesReportsError,
  listExamPracticesTypesSuccess,
  listExamPracticesTypesError,
  importExamPracticesSuccess,
  importExamPracticesError,
  removeImportExamPracticesSuccess,
  removeImportExamPracticesError,
  sendExamPracticeSuccess,
  sendExamPracticeError,
} from './actions';
import { saveData } from '../../helpers/downloadFile';

function* getExamPracticesById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `exam-practices/${id}`);
    yield put(getExamPracticesSuccess(response.data.data));
  } catch (error) {
    yield put(getExamPracticesError(error));
  }
}

function* listExamPractices() {
  try {
    const response = yield call(api.get, `exam-practices/classes`);
    yield put(listExamPracticesSuccess(response.data.data));
  } catch (error) {
    yield put(listExamPracticesError(error));
  }
}

function* listExamPracticesTypes() {
  try {
    const response = yield call(api.get, `exam-practice-types`);
    yield put(listExamPracticesTypesSuccess(response.data.data));
  } catch (error) {
    yield put(listExamPracticesTypesError(error));
  }
}

function* listExamPracticesReports({ payload }) {
  try {
    const { classId, cycle } = payload;
    const response = yield call(
      api.get,
      `students/reports/exam-practice-performance/classes/${classId}/cycle/${cycle}`
    );
    yield put(listExamPracticesReportsSuccess(response.data.data));
  } catch (error) {
    yield put(listExamPracticesReportsError(error));
  }
}

function* listExamPracticesSubjects() {
  try {
    const response = yield call(api.get, 'exam-subjects');
    yield put(listExamPracticesSubjectsSuccess(response.data.data));
  } catch (error) {
    yield put(listExamPracticesSubjectsError(error));
  }
}

function* vinculateExamPracticesSubjects({ payload }) {
  try {
    const { subjects, isExternalQuestion, examPracticeData } = payload;
    let previousExamPracticesSubjects = null;
    if (examPracticeData && examPracticeData.exam_subjects.length > 0) {
      const res = yield call(
        api.get,
        `exam-practices/${subjects.exam_practice_id}/subjects`
      );
      previousExamPracticesSubjects = res.data.data;
    }
    yield call(api.post, 'exam-practices/subjects', subjects);
    if (isExternalQuestion) {
      const response = yield call(
        api.get,
        `exam-practices/${subjects.exam_practice_id}/subjects`
      );
      if (previousExamPracticesSubjects) {
        // eslint-disable-next-line no-restricted-syntax
        for (const examPracticeSubject of response.data.data) {
          const matchExamPracticeSubject = previousExamPracticesSubjects.find(
            (data) =>
              data.exam_subject_id === examPracticeSubject.exam_subject_id
          );
          if (matchExamPracticeSubject) {
            const questions = matchExamPracticeSubject.external_questions.map(
              (question) => {
                return {
                  id: question.external_question_id,
                  ordination: question.ordination,
                };
              }
            );
            const externalQuestions = {
              exam_practice_subject_id: examPracticeSubject.id,
              external_questions: questions,
            };
            if (questions.length > 0) {
              yield call(
                api.post,
                'exam-practices/subjects/external-questions',
                externalQuestions
              );
            }
          }
        }
      }
      const updatedQuestions = yield call(
        api.get,
        `exam-practices/${subjects.exam_practice_id}/subjects`
      );
      yield put(
        vinculateExamPracticesSubjectsSuccess(
          updatedQuestions.data.data,
          isExternalQuestion
        )
      );
    } else {
      yield put(vinculateExamPracticesSubjectsSuccess(subjects));
    }
  } catch (error) {
    yield put(vinculateExamPracticesSubjectsError(error));
  }
}

const addExamPracticesAsync = async (completeExamPractices) =>
  api
    .post(`exam-practices`, {
      ...completeExamPractices,
    })
    .then((response) => response)
    .catch((error) => error);

const editExamPracticesAsync = async (completeExamPractices) =>
  api
    .put(`exam-practices/${completeExamPractices.id}`, {
      ...completeExamPractices,
    })
    .then((response) => response)
    .catch((error) => error);

function* addExamPractices({ payload }) {
  try {
    const { examPractices } = payload;
    const completeExamPractices = { ...examPractices };
    const res = yield call(addExamPracticesAsync, completeExamPractices);
    if (!res.response) {
      const responseValue = res.data.data;
      const formatedExamPractices = {
        name: responseValue.name,
        cycle_is: completeExamPractices.cycle_is,
        exam_practice_type_id: responseValue.exam_practice_type_id,
        active: responseValue.active,
        id: responseValue.id,
      };
      yield put(addExamPracticesSuccess(formatedExamPractices));
    } else {
      yield put(addExamPracticesError(res.response.data));
    }
  } catch (error) {
    yield put(addExamPracticesError(error));
  }
}

function* editExamPractices({ payload }) {
  try {
    const { examPractices } = payload;
    const completeExamPractices = { ...examPractices };
    const res = yield call(editExamPracticesAsync, completeExamPractices);
    if (!res.response) {
      const responseValue = res.data.data;

      const formatedExamPractices = {
        name: responseValue.name,
        cycle_is: completeExamPractices.cycle_is,
        exam_practice_type_id: responseValue.exam_practice_type_id,
        active: responseValue.active,
        id: responseValue.id,
      };
      yield put(editExamPracticesSuccess(formatedExamPractices));
    } else {
      yield put(editExamPracticesError(res.response.data));
    }
  } catch (error) {
    yield put(editExamPracticesError(error));
  }
}

function* editActiveExamPractices({ payload }) {
  try {
    const { id, active } = payload;
    const response = yield call(
      api.put,
      `exam-practices/${id}/activate/${active ? '1' : '0'}`
    );
    const responseValue = response.data.data;
    yield put(editActiveExamPracticesSuccess(id, responseValue.active));
  } catch (error) {
    yield put(editActiveExamPracticesError(error));
  }
}

function* exportExamPracticesReports({ payload }) {
  try {
    const { classId, className, examPracticeId, examPracticeName } = payload;
    const response = yield call(
      api.get,
      `/classes/${classId}/students/exam-practices/${examPracticeId}/export?examPracticeName=${examPracticeName}&className=${className}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    saveData(
      response.data,
      `${examPracticeId}_${examPracticeName}_${className}.xlsx`
    );
    yield put(exportExamPracticesReportsSuccess());
  } catch (error) {
    yield put(exportExamPracticesReportsError(error));
  }
}

const importExamPracticesAsync = async (files) =>
  api
    .post(`exam-practices/upload`, files)
    .then((response) => response)
    .catch((error) => error);

function* importExamPractices({ payload }) {
  try {
    const { files } = payload;
    const res = yield call(importExamPracticesAsync, files);
    if (!res.response) {
      yield put(importExamPracticesSuccess(res.data));
      toast.success(`Arquivo(s) incluído(s) com sucesso!`);
    } else {
      yield put(importExamPracticesError(res.response.data));
      toast.error(`Falha ao incluír Arquivo(s)!`);
    }
  } catch (error) {
    yield put(importExamPracticesError(error));
    toast.error(`Falha ao incluír Arquivo(s)!`);
  }
}

const removeImportExamPracticesAsync = async (id) =>
  api
    .delete(`files/${id}`)
    .then((response) => response)
    .catch((error) => error);

function* removeImportExamPractices({ payload }) {
  const { examPracticeId, id } = payload;
  try {
    yield put(removeImportExamPracticesSuccess(examPracticeId, id));
    const res = yield call(removeImportExamPracticesAsync, id);
    if (!res.response) {
      toast.success(`Arquivo removido com sucesso!`);
    } else {
      yield put(removeImportExamPracticesError(examPracticeId, id));
      toast.error(`Falha ao remover Arquivo!`);
    }
  } catch (error) {
    yield put(removeImportExamPracticesError(examPracticeId, id));
    toast.error(`Falha ao remover Arquivo!`);
  }
}

function* vinculateExamPracticesQuestions({ payload }) {
  try {
    const { questions, isLastSubject, questionsForPdf, answersForEdit } =
      payload;
    yield call(
      api.post,
      'exam-practices/subjects/external-questions',
      questions
    );

    if (isLastSubject) {
      yield call(api.post, 'exam-practices/subjects/pdf', questionsForPdf);
    }

    if (answersForEdit) {
      yield call(
        api.put,
        `exam-practices/${questionsForPdf.exam_practice_id}/subjects/answers`,
        answersForEdit
      );
    }

    yield put(vinculateExamPracticesQuestionsSuccess(questions, isLastSubject));
  } catch (error) {
    yield put(vinculateExamPracticesQuestionsError(error));
  }
}

function* sendExamPractice({ payload }) {
  try {
    const { id } = payload;
    const res = yield call(api.post, `exam-practices/${id}/sent`);
    if (!res.response) {
      const examPractice = res.data;
      toast.success(`${examPractice.message}`);
      yield put(sendExamPracticeSuccess(id, examPractice.data.pivot.sent_at));
    } else {
      yield put(sendExamPracticeError(res.response.data));
    }
  } catch (error) {
    yield put(sendExamPracticeError(error.response.data));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_EXAM_PRACTICES_REQUEST, listExamPractices),
    takeLatest(IMPORT_EXAM_PRACTICES_REQUEST, importExamPractices),
    takeLatest(REMOVE_IMPORT_EXAM_PRACTICES_REQUEST, removeImportExamPractices),
    takeLatest(LIST_EXAM_PRACTICES_TYPES_REQUEST, listExamPracticesTypes),
    takeLatest(LIST_EXAM_PRACTICES_REPORTS_REQUEST, listExamPracticesReports),
    takeLatest(
      EXPORT_EXAM_PRACTICES_REPORTS_REQUEST,
      exportExamPracticesReports
    ),
    takeLatest(LIST_EXAM_PRACTICES_SUBJECTS_REQUEST, listExamPracticesSubjects),
    takeLatest(
      VINCULATE_EXAM_PRACTICES_SUBJECTS_REQUEST,
      vinculateExamPracticesSubjects
    ),
    takeLatest(
      VINCULATE_EXAM_PRACTICES_QUESTIONS_REQUEST,
      vinculateExamPracticesQuestions
    ),
    takeLatest(GET_EXAM_PRACTICES_REQUEST, getExamPracticesById),
    takeLatest(ADD_EXAM_PRACTICES_REQUEST, addExamPractices),
    takeLatest(EDIT_EXAM_PRACTICES_REQUEST, editExamPractices),
    takeLatest(EDIT_ACTIVE_EXAM_PRACTICES_REQUEST, editActiveExamPractices),
    takeLatest(SEND_EXAM_PRACTICE_REQUEST, sendExamPractice),
  ]);
}
