import {
  LIST_NEWS_REQUEST,
  LIST_NEWS_SUCCESS,
  LIST_NEWS_ERROR,
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS,
  GET_NEWS_ERROR,
  GET_NEWS_DESCRIPTION_REQUEST,
  GET_NEWS_DESCRIPTION_SUCCESS,
  GET_NEWS_DESCRIPTION_ERROR,
  ADD_NEWS_REQUEST,
  ADD_NEWS_SUCCESS,
  ADD_NEWS_ERROR,
  EDIT_NEWS_REQUEST,
  EDIT_NEWS_SUCCESS,
  EDIT_NEWS_ERROR,
  EDIT_ACTIVE_NEWS_REQUEST,
  EDIT_ACTIVE_NEWS_SUCCESS,
  EDIT_ACTIVE_NEWS_ERROR,
  EDIT_FIXED_NEWS_REQUEST,
  EDIT_FIXED_NEWS_SUCCESS,
  EDIT_FIXED_NEWS_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
  REMOVE_FILE_NEW_ERROR,
  REMOVE_FILE_NEW_SUCCESS,
  REMOVE_FILE_NEW_REQUEST
} from '../actions';

const INIT_STATE = {
  currentNews: null,
  currentNewsList: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentNews: null,
        success: '',
      };
    case GET_NEWS_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentNews: action.payload,
        error: '',
      };
    case GET_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        currentNews: null,
        error: action.payload.message,
      };
    case GET_NEWS_DESCRIPTION_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_NEWS_DESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        currentNews: action.payload,
        error: '',
      };
    case GET_NEWS_DESCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
        currentNews: null,
        error: action.payload.message,
      };
    case LIST_NEWS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentNewsList: action.payload,
        error: '',
      };
    case LIST_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        currentNewsList: null,
        error: action.payload.message,
      };
    case ADD_NEWS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_NEWS_SUCCESS: {
      const allNews = [...state.currentNewsList, action.payload];

      return {
        ...state,
        currentNewsList: allNews,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_NEWS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_NEWS_SUCCESS: {
      const newsUpdated = state.currentNewsList.map((news) => {
        if (news.id === action.payload.news.id) {
          return action.payload.news;
        }
        return news;
      });

      return {
        ...state,
        currentNewsList: newsUpdated,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case EDIT_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_ACTIVE_NEWS_REQUEST:
      return { ...state, loading: false, error: '' };
    case EDIT_ACTIVE_NEWS_SUCCESS: {
      const newsUpdated = state.currentNewsList.map((news) => {
        if (news.id === action.payload.id) {
          news.active = action.payload.news.active;
        }
        return news;
      });

      return {
        ...state,
        currentNewsList: newsUpdated,
        success: action.payload,
        loading: false,
        error: '',
      };
    }
    case EDIT_ACTIVE_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
      case EDIT_FIXED_NEWS_REQUEST:
        return { ...state, loading: false, error: '' };
      case EDIT_FIXED_NEWS_SUCCESS: {
        const newsUpdated = state.currentNewsList.map((news) => {
          if (news.id === action.payload.id) {
            return action.payload.news;
          }
          return news;
        });

        return {
          ...state,
          currentNewsList: newsUpdated,
          success: action.payload,
          loading: false,
          error: '',
        };
      }
      case EDIT_FIXED_NEWS_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
        };
    case REMOVE_FILE_NEW_REQUEST:
      return { ...state };
    case REMOVE_FILE_NEW_SUCCESS: {
      const { id } = action.payload;
      const updatedNew = {
        ...state.currentNews,
        files: state.currentNews.files.map((file) => {
          if (file.id === id) {
            return { ...file, removed: true };
          }
          return file;
        })
      };
      return {
        ...state,
        currentNews: updatedNew,
      };
    }
    case REMOVE_FILE_NEW_ERROR: {
      const { id } = action.payload;
      const updatedNew = {
        ...state.currentNews,
        files: state.currentNews.files.map((file) => {
          if (file.id === id) {
            return { ...file, removed: true };
          }
          return file;
        })
      };
      return {
        ...state,
        currentNews: updatedNew,
      };
    }
    default:
      return { ...state };
  }
};
