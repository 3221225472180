/* eslint-disable no-unused-vars */
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import authStudentSagas from './auth/student/saga';
import schoolSagas from './schools/saga';
import classesSagas from './classes/saga';
import cyclesSagas from './cycles/saga';
import coordinatorsSagas from './coordinators/saga';
import managersSagas from './managers/saga';
import networkCoordinatorsSagas from './network-coordinators/saga';
import teachersSagas from './teachers/saga';
import studentsSagas from './students/saga';
import journeyTrailsSagas from './journey-trails/saga';
import videosSagas from './videos/saga';
import criterionsSagas from './criterions/saga';
import criterionsGroupsSagas from './criterions-groups/saga';
import journeysSagas from './journeys/saga';
import subjectsSagas from './subjects/saga';
import prerequisitesSagas from './prerequisites/saga';
import subareasSagas from './subareas/saga';
import activitiesSagas from './activities/saga';
import examPracticesSagas from './exam-practices/saga';
import examExternalsSagas from './exam-externals/saga';
import examsSagas from './exams/saga';
import writingsSagas from './writings/saga';
import configurationsSagas from './configurations/saga';
import dashboardSagas from './dashboard/saga';
import newsSagas from './news/saga';
import feedbacksSagas from './news/feedback/saga';
import newTypesSagas from './news/types/saga';
import questionDbSagas from './question-db/saga';
import subjectPerformanceSagas from './subject-performance/saga';
import studyDiagnosisSagas from './study-diagnosis/saga';
import incidencesSagas from './incidences/saga';
import classesTypesSagas from './classes/types/saga';
import classesAssessmentTypesSagas from './classes/assessment-types/saga';
import classCyclesSagas from './classes/cycles/saga';
import scoresSagas from './scores/saga';
import eventsSagas from './events/saga';
import eventRepeatsSagas from './events/eventRepeats/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    authStudentSagas(),
    schoolSagas(),
    criterionsSagas(),
    criterionsGroupsSagas(),
    classesSagas(),
    cyclesSagas(),
    examPracticesSagas(),
    examExternalsSagas(),
    coordinatorsSagas(),
    managersSagas(),
    networkCoordinatorsSagas(),
    teachersSagas(),
    writingsSagas(),
    studentsSagas(),
    questionDbSagas(),
    journeyTrailsSagas(),
    videosSagas(),
    journeysSagas(),
    subjectsSagas(),
    prerequisitesSagas(),
    subareasSagas(),
    activitiesSagas(),
    examsSagas(),
    configurationsSagas(),
    dashboardSagas(),
    newsSagas(),
    feedbacksSagas(),
    newTypesSagas(),
    studyDiagnosisSagas(),
    subjectPerformanceSagas(),
    incidencesSagas(),
    classesTypesSagas(),
    classesAssessmentTypesSagas(),
    classCyclesSagas(),
    scoresSagas(),
    eventsSagas(),
    eventRepeatsSagas(),
  ]);
}
