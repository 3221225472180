import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  GET_CONFIGURATIONS_REQUEST,
  GET_CONFIGURATIONS_SUCCESS,
  GET_CONFIGURATIONS_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
  CHANGE_PROFILE_IMAGE_REQUEST,
  CHANGE_PROFILE_IMAGE_SUCCESS,
  CHANGE_PROFILE_IMAGE_ERROR,
  RESET_DB_TESTS_REQUEST,
  RESET_DB_TESTS_SUCCESS,
  RESET_DB_TESTS_ERROR
} from "../actions";

const INIT_STATE = {
  currentConfig: null,
  loading: false,
  success: '',
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        currentConfig: null,
        loading: false,
        success: '',
        error: '',
      };
    case GET_CONFIGURATIONS_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentConfig: action.payload,
        error: null,
      };
    case GET_CONFIGURATIONS_ERROR:
      return {
        ...state,
        loading: false,
        currentConfig: null,
        error: action.payload.message,
      };
    case CHANGE_PASSWORD_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        success: action.payload.message,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case CHANGE_PROFILE_IMAGE_REQUEST:
      return { ...state, error: '', success: '' };
    case CHANGE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        error: '',
        success: action.payload.message,
      };
    case CHANGE_PROFILE_IMAGE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        success: '',
      };
    case RESET_DB_TESTS_REQUEST:
      return { ...state, error: '', success: '' };
    case RESET_DB_TESTS_SUCCESS:
      return {
        ...state,
        error: '',
        success: action.payload,
      };
    case RESET_DB_TESTS_ERROR:
      return {
        ...state,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
