import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGIN_SCHOOL,
  LOGIN_CLASS,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  FORGOT_PASSWORD_CHECK_TOKEN_REQUEST,
  FORGOT_PASSWORD_CHECK_TOKEN_SUCCESS,
  FORGOT_PASSWORD_CHECK_TOKEN_ERROR,
  LEAVE_ACCESS,
  RELOAD_PASSWORD_SEND_MAIL_REQUEST,
  RELOAD_PASSWORD_SEND_MAIL_SUCCESS,
  RELOAD_PASSWORD_SEND_MAIL_ERROR,
} from '../actions';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});
export const loginSchool = (schoolId, history, isAccess, multipleAccess) => ({
  type: LOGIN_SCHOOL,
  payload: { schoolId, history, isAccess, multipleAccess },
});
export const loginClass = (schoolId, classId, history) => ({
  type: LOGIN_CLASS,
  payload: { schoolId, classId, history },
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const forgotPasswordCheckTokenRequest = (token) => ({
  type: FORGOT_PASSWORD_CHECK_TOKEN_REQUEST,
  payload: { token },
});

export const forgotPasswordCheckTokenSuccess = (email) => ({
  type: FORGOT_PASSWORD_CHECK_TOKEN_SUCCESS,
  payload: { email },
});

export const forgotPasswordCheckTokenError = (message) => ({
  type: FORGOT_PASSWORD_CHECK_TOKEN_ERROR,
  payload: { message },
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const resetPassword = ({
  email,
  token,
  password,
  passwordConfirmation,
  history,
}) => ({
  type: RESET_PASSWORD,
  payload: { email, token, password, passwordConfirmation, history },
});
export const resetPasswordSuccess = (message) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: message,
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const leaveAccess = (history, isBreadcrumb) => ({
  type: LEAVE_ACCESS,
  payload: { history, isBreadcrumb },
});

export const reloadPasswordSendMailRequest = (id) => ({
  type: RELOAD_PASSWORD_SEND_MAIL_REQUEST,
  payload: { id },
});
export const reloadPasswordSendMailSuccess = (message) => ({
  type: RELOAD_PASSWORD_SEND_MAIL_SUCCESS,
  payload: { message },
});
export const reloadPasswordSendMailError = (message) => ({
  type: RELOAD_PASSWORD_SEND_MAIL_ERROR,
  payload: { message },
});
