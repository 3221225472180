import {
  LIST_PREREQUISITES_REQUEST,
  LIST_PREREQUISITES_SUCCESS,
  LIST_PREREQUISITES_ERROR,
  RESET_STATE_ALL_APPLICATION,
  GET_PREREQUISITES_REQUEST,
  GET_PREREQUISITES_SUCCESS,
  GET_PREREQUISITES_ERROR,
  ADD_PREREQUISITES_REQUEST,
  ADD_PREREQUISITES_SUCCESS,
  ADD_PREREQUISITES_ERROR,
  EDIT_PREREQUISITES_REQUEST,
  EDIT_PREREQUISITES_SUCCESS,
  RESET_STATE_REDUX
} from '../actions';

const INIT_STATE = {
  currentPrerequisite: null,
  currentPrerequisitesList: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentPrerequisite: null,
        success: '',
      };
    case GET_PREREQUISITES_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_PREREQUISITES_SUCCESS:
      return {
        ...state,
        loading: false,
        currentPrerequisite: action.payload,
        error: '',
      };
    case GET_PREREQUISITES_ERROR:
      return {
        ...state,
        loading: false,
        currentPrerequisite: null,
        error: action.payload.message,
      };
    case LIST_PREREQUISITES_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_PREREQUISITES_SUCCESS:
      return {
        ...state,
        loading: false,
        currentPrerequisitesList: action.payload,
        error: '',
      };
    case LIST_PREREQUISITES_ERROR:
      return {
        ...state,
        loading: false,
        currentPrerequisitesList: null,
        error: action.payload.message,
      };
    case ADD_PREREQUISITES_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_PREREQUISITES_SUCCESS: {
      const allPrerequisites = [...state.currentPrerequisitesList, action.payload];

      return {
        ...state,
        currentPrerequisitesList: allPrerequisites,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_PREREQUISITES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_PREREQUISITES_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_PREREQUISITES_SUCCESS: {
      const prerequisitesUpdated = state.currentPrerequisitesList.map((prerequisites) => {
        if (prerequisites.id === action.payload.prerequisites.id) {
          return action.payload.prerequisites;
        }
        return prerequisites;
      });

      return {
        ...state,
        currentPrerequisitesList: prerequisitesUpdated,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    default:
      return { ...state };
  }
};
