import {
  LIST_QUESTION_DB_YEARS_REQUEST,
  LIST_QUESTION_DB_YEARS_SUCCESS,
  LIST_QUESTION_DB_YEARS_ERROR,
  LIST_QUESTION_DB_CATEGORIES_REQUEST,
  LIST_QUESTION_DB_CATEGORIES_SUCCESS,
  LIST_QUESTION_DB_CATEGORIES_ERROR,
  LIST_QUESTION_DB_INSTITUTIONS_REQUEST,
  LIST_QUESTION_DB_INSTITUTIONS_SUCCESS,
  LIST_QUESTION_DB_INSTITUTIONS_ERROR,
  LIST_QUESTION_DB_SKILLS_REQUEST,
  LIST_QUESTION_DB_SKILLS_SUCCESS,
  LIST_QUESTION_DB_SKILLS_ERROR,
  LIST_QUESTION_DB_SUBJECTS_REQUEST,
  LIST_QUESTION_DB_SUBJECTS_SUCCESS,
  LIST_QUESTION_DB_SUBJECTS_ERROR,
  LIST_QUESTION_DB_QUESTIONS_REQUEST,
  LIST_QUESTION_DB_QUESTIONS_SUCCESS,
  LIST_QUESTION_DB_QUESTIONS_ERROR,
  LIST_CATEGORIES_CHILDRENS_REQUEST,
  LIST_CATEGORIES_CHILDRENS_SUCCESS,
  LIST_CATEGORIES_CHILDRENS_ERROR,
  SET_FIRST_CATEGORY,
  SET_EMPTY_CATEGORY,
  RESET_FIRST_CATEGORY,
  CHECK_QUESTION_REQUEST,
  CHECK_INITIAL_QUESTION_REQUEST,
} from '../actions';

export const listQuestionDbYearsRequest = () => ({
  type: LIST_QUESTION_DB_YEARS_REQUEST,
});
export const listQuestionDbYearsSuccess = (years) => ({
  type: LIST_QUESTION_DB_YEARS_SUCCESS,
  payload: { years },
});
export const listQuestionDbYearsError = (message) => ({
  type: LIST_QUESTION_DB_YEARS_ERROR,
  payload: { message },
});

export const listQuestionDbCategoriesRequest = () => ({
  type: LIST_QUESTION_DB_CATEGORIES_REQUEST,
});
export const listQuestionDbCategoriesSuccess = (categories) => ({
  type: LIST_QUESTION_DB_CATEGORIES_SUCCESS,
  payload: { categories },
});
export const listQuestionDbCategoriesError = (message) => ({
  type: LIST_QUESTION_DB_CATEGORIES_ERROR,
  payload: { message },
});

export const listQuestionDbInstitutionsRequest = () => ({
  type: LIST_QUESTION_DB_INSTITUTIONS_REQUEST,
});
export const listQuestionDbInstitutionsSuccess = (institutions) => ({
  type: LIST_QUESTION_DB_INSTITUTIONS_SUCCESS,
  payload: { institutions },
});
export const listQuestionDbInstitutionsError = (message) => ({
  type: LIST_QUESTION_DB_INSTITUTIONS_ERROR,
  payload: { message },
});

export const listQuestionDbSkillsRequest = () => ({
  type: LIST_QUESTION_DB_SKILLS_REQUEST,
});
export const listQuestionDbSkillsSuccess = (skills) => ({
  type: LIST_QUESTION_DB_SKILLS_SUCCESS,
  payload: { skills },
});
export const listQuestionDbSkillsError = (message) => ({
  type: LIST_QUESTION_DB_SKILLS_ERROR,
  payload: { message },
});

export const listQuestionDbSubjectsRequest = () => ({
  type: LIST_QUESTION_DB_SUBJECTS_REQUEST,
});
export const listQuestionDbSubjectsSuccess = (subjects) => ({
  type: LIST_QUESTION_DB_SUBJECTS_SUCCESS,
  payload: { subjects },
});
export const listQuestionDbSubjectsError = (message) => ({
  type: LIST_QUESTION_DB_SUBJECTS_ERROR,
  payload: { message },
});

export const listQuestionDbQuestionsRequest = (url = null, endpoint = '') => ({
  type: LIST_QUESTION_DB_QUESTIONS_REQUEST,
  payload: { url, endpoint },
});
export const listQuestionDbQuestionsSuccess = (questions, listOptions) => ({
  type: LIST_QUESTION_DB_QUESTIONS_SUCCESS,
  payload: { questions, listOptions },
});
export const listQuestionDbQuestionsError = (message) => ({
  type: LIST_QUESTION_DB_QUESTIONS_ERROR,
  payload: { message },
});

export const listCategoriesChildrensRequest = (categoryId, level) => ({
  type: LIST_CATEGORIES_CHILDRENS_REQUEST,
  payload: { categoryId, level },
});
export const listCategoriesChildrensSuccess = (categories, level) => ({
  type: LIST_CATEGORIES_CHILDRENS_SUCCESS,
  payload: { categories, level },
});
export const listCategoriesChildrensError = () => ({
  type: LIST_CATEGORIES_CHILDRENS_ERROR,
});

export const setFirstCategory = (subjectId) => ({
  type: SET_FIRST_CATEGORY,
  payload: { subjectId },
});
export const setEmptyCategory = (level) => ({
  type: SET_EMPTY_CATEGORY,
  payload: { level },
});
export const resetFirstCategory = () => ({
  type: RESET_FIRST_CATEGORY,
});

export const checkQuestionRequest = (id) => ({
  type: CHECK_QUESTION_REQUEST,
  payload: { id },
});

export const checkInitialQuestionRequest = (questions) => ({
  type: CHECK_INITIAL_QUESTION_REQUEST,
  payload: { questions },
});
