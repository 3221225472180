import { all, call, put, takeLatest } from 'redux-saga/effects';
import { currentUser } from '../../constants/defaultValues';

import api from '../../services/api';
import {
  ADD_CLASS_REQUEST,
  EDIT_ACTIVE_CLASS_REQUEST,
  EDIT_CLASS_REQUEST,
  EDIT_CLASS_VINCULATE_SUBAREA_REQUEST,
  EDIT_CLASS_VINCULATE_SUBJECT_REQUEST,
  EDIT_CLASS_VINCULATE_PREREQUISITE_REQUEST,
  GET_CLASS_REQUEST,
  LIST_CLASS_JOURNEY_REQUEST,
  LIST_CLASS_PREREQUISITES_REQUEST,
  LIST_CLASS_REQUEST,
  GET_CLASS_WITH_STUDY_TIME_REQUEST,
} from '../actions';
import {
  addClassError,
  addClassSuccess,
  editClassError,
  editClassSuccess,
  editClassVinculateSubareaError,
  editClassVinculateSubareaSuccess,
  editClassVinculateSubjectError,
  editClassVinculateSubjectSuccess,
  editClassVinculatePrerequisiteError,
  editClassVinculatePrerequisiteSuccess,
  getClassError,
  getClassSuccess,
  listClassError,
  listClassJourneyError,
  listClassJourneySuccess,
  listClassSuccess,
  listClassPrerequisitesError,
  listClassPrerequisitesSuccess,
  editActiveClassSuccess,
  editActiveClassError,
  getClassWithStudyTimeSuccess,
  getClassWithStudyTimeError,
} from './actions';

function* getClassById({ payload }) {
  try {
    const { id, cycle } = payload;
    let filterString = '';
    if (cycle) {
      filterString = `?cycle=${cycle}`;
    }
    const response = yield call(api.get, `classes/${id}${filterString}`);
    yield put(getClassSuccess(response.data.data));
  } catch (error) {
    yield put(getClassError(error));
  }
}

function* getClassWithStudyTime({ payload }) {
  try {
    const { cycle, classId, studentId } = payload;

    const response = yield call(api.get, `classes/cycle/${cycle}?classId=${classId}&studentId=${studentId}`);
    yield put(getClassWithStudyTimeSuccess(response.data.data));
  } catch (error) {
    yield put(getClassWithStudyTimeError(error));
  }
}

function* listClass() {
  try {
    const response = yield call(
      api.get,
      `classes/school/${
        currentUser?.uid?.schools?.id ?? currentUser?.uid?.classes?.school_id
      }`
    );
    yield put(listClassSuccess(response.data.data));
  } catch (error) {
    yield put(listClassError(error));
  }
}

function* listClassJourney() {
  try {
    const response = yield call(api.get, 'classes/journeys/all');
    yield put(listClassJourneySuccess(response.data.data));
  } catch (error) {
    yield put(listClassJourneyError(error));
  }
}

function* listClassPrerequisites({ payload }) {
  try {
    const { subjectId } = payload;
    const response = yield call(
      api.get,
      `prerequisites/classes/subjects/${subjectId}`
    );
    yield put(listClassPrerequisitesSuccess(response.data.data));
  } catch (error) {
    yield put(listClassPrerequisitesError(error));
  }
}

const addClassAsync = async (completeClass) =>
  api
    .post(`classes`, {
      ...completeClass,
    })
    .then((response) => response)
    .catch((error) => error);

const editClassAsync = async (completeClass) =>
  api
    .put(`classes/${completeClass.id}`, {
      ...completeClass,
    })
    .then((response) => response)
    .catch((error) => error);

const addClassLogoAsync = async (completeClass) =>
  api
    .post(`classes/upload/avatar`, {
      ...completeClass.file,
    })
    .then((response) => response)
    .catch((error) => error);

function* addClass({ payload }) {
  try {
    const { classValue } = payload;
    const res = yield call(addClassAsync, classValue);
    if (!res.response) {
      const responseValue = res.data.data;
      if (classValue.file) {
        classValue.file.class_id = responseValue.id;
        yield call(addClassLogoAsync, classValue);
      }
      const formattedClass = {
        name: responseValue.name,
        active: responseValue.active,
        id: responseValue.id,
        code: responseValue.code,
        students_count: '0',
      };
      yield put(addClassSuccess(formattedClass));
    } else {
      yield put(addClassError(res.response.data));
    }
  } catch (error) {
    yield put(addClassError(error));
  }
}

function* editClass({ payload }) {
  try {
    const { classValue } = payload;
    const res = yield call(editClassAsync, classValue);
    if (!res.response) {
      const responseValue = res.data.data;
      if (classValue.file) {
        classValue.file.class_id = responseValue.id;
        yield call(addClassLogoAsync, classValue);
      }
      const formattedClass = {
        name: responseValue.name,
        active: responseValue.active,
        id: responseValue.id,
        code: responseValue.code,
        students_count: '',
      };

      yield put(editClassSuccess(formattedClass));
    } else {
      yield put(editClassError(res.response.data));
    }
  } catch (error) {
    yield put(editClassError(error));
  }
}

function* editActiveClass({ payload }) {
  try {
    const { id, active } = payload;
    const response = yield call(
      api.put,
      `classes/${id}/activate/${active ? '1' : '0'}`
    );

    const responseValue = response.data.data;
    const formattedClass = {
      name: responseValue.name,
      active: responseValue.active,
      id: responseValue.id,
      code: responseValue.code,
      students_count: '',
    };
    yield put(editActiveClassSuccess(id, formattedClass));
  } catch (error) {
    yield put(editActiveClassError(error));
  }
}

function* editClassVinculateSubarea({ payload }) {
  try {
    const { id, subareaList } = payload;
    const response = yield call(
      api.post,
      `classes/${id}/subareas`,
      subareaList
    );
    const responseValue = response.data.data;
    yield put(editClassVinculateSubareaSuccess(id, responseValue));
  } catch (error) {
    yield put(editClassVinculateSubareaError(error));
  }
}

const editClassVinculateSubjectAsync = async (id, subjectList) =>
  api
    .post(`subjects/classes/${id}`, {
      ...subjectList,
    })
    .then((response) => response)
    .catch((error) => error);

function* editClassVinculateSubject({ payload }) {
  try {
    const { id, subjectList } = payload;
    const res = yield call(editClassVinculateSubjectAsync, id, subjectList);
    if (!res.response) {
      yield put(editClassVinculateSubjectSuccess(res.data.message));
    } else {
      yield put(editClassVinculateSubjectError(res.response.data));
    }
  } catch (error) {
    yield put(editClassVinculateSubjectError(error));
  }
}

const editClassVinculatePrerequisiteAsync = async (prerequisiteList) =>
  api
    .post(`prerequisites/classes`, {
      ...prerequisiteList,
    })
    .then((response) => response)
    .catch((error) => error);

function* editClassVinculatePrerequisite({ payload }) {
  try {
    const { prerequisiteList } = payload;
    const res = yield call(editClassVinculatePrerequisiteAsync, prerequisiteList);
    if (!res.response) {
      yield put(editClassVinculatePrerequisiteSuccess(res.data.data));
    } else {
      yield put(editClassVinculatePrerequisiteError(res.response.data));
    }
  } catch (error) {
    yield put(editClassVinculatePrerequisiteError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_CLASS_JOURNEY_REQUEST, listClassJourney),
    takeLatest(LIST_CLASS_PREREQUISITES_REQUEST, listClassPrerequisites),
    takeLatest(LIST_CLASS_REQUEST, listClass),
    takeLatest(GET_CLASS_REQUEST, getClassById),
    takeLatest(GET_CLASS_WITH_STUDY_TIME_REQUEST, getClassWithStudyTime),
    takeLatest(ADD_CLASS_REQUEST, addClass),
    takeLatest(EDIT_CLASS_REQUEST, editClass),
    takeLatest(EDIT_ACTIVE_CLASS_REQUEST, editActiveClass),
    takeLatest(EDIT_CLASS_VINCULATE_SUBAREA_REQUEST, editClassVinculateSubarea),
    takeLatest(EDIT_CLASS_VINCULATE_SUBJECT_REQUEST, editClassVinculateSubject),
    takeLatest(
      EDIT_CLASS_VINCULATE_PREREQUISITE_REQUEST,
      editClassVinculatePrerequisite
    ),
  ]);
}
