import {
  LIST_CYCLE_REQUEST,
  LIST_CYCLE_SUCCESS,
  LIST_CYCLE_ERROR,
} from '../actions';

export const listCycleRequest = () => ({
  type: LIST_CYCLE_REQUEST,
});
export const listCycleSuccess = (cycles) => ({
  type: LIST_CYCLE_SUCCESS,
  payload: cycles,
});
export const listCycleError = (message) => ({
  type: LIST_CYCLE_ERROR,
  payload: { message },
});
