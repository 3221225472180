import {
  LIST_EVENT_REPEATS_REQUEST,
  LIST_EVENT_REPEATS_SUCCESS,
  LIST_EVENT_REPEATS_ERROR,
} from '../../actions';

export const listEventRepeatsRequest = () => ({
  type: LIST_EVENT_REPEATS_REQUEST,
});
export const listEventRepeatsSuccess = (eventRepeats) => ({
  type: LIST_EVENT_REPEATS_SUCCESS,
  payload: eventRepeats,
});
export const listEventRepeatsError = (message) => ({
  type: LIST_EVENT_REPEATS_ERROR,
  payload: { message },
});
