import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  LIST_MANAGER_REQUEST,
  GET_MANAGER_REQUEST,
  ADD_MANAGER_REQUEST,
  EDIT_MANAGER_REQUEST,
  EDIT_ACTIVE_MANAGER_REQUEST,
} from '../actions';
import {
  listManagerSuccess,
  listManagerError,
  getManagerSuccess,
  getManagerError,
  addManagerSuccess,
  addManagerError,
  editManagerSuccess,
  editManagerError,
  editActiveManagerSuccess,
  editActiveManagerError,
} from './actions';
import { UserRole } from '../../helpers/authHelper';
import { currentUser } from '../../constants/defaultValues';
import { getChildrenRecursiveIds } from '../../utils/getChildrenRecursiveIds';

function* getManagerById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `administrators/${id}`);
    yield put(getManagerSuccess(response.data.data));
  } catch (error) {
    yield put(getManagerError(error));
  }
}

const addManagersAsync = async (completeManager) =>
  api
    .post(`backoffice/register`, {
      ...completeManager,
    })
    .then((response) => response)
    .catch((error) => error);

const editManagersAsync = async (completeManager) =>
  api
    .put(`administrators/${completeManager.id}`, {
      ...completeManager,
    })
    .then((response) => response)
    .catch((error) => error);

function* listManager() {
  try {
    const response = yield call(
      api.get,
      `administrators/permission-level/${UserRole.Manager}${
        currentUser.role !== UserRole.Admin
          ? `?schoolId=${getChildrenRecursiveIds(currentUser).join(',')}`
          : ''
      }`
    );

    yield put(listManagerSuccess(response.data.data));
  } catch (error) {
    yield put(listManagerError(error));
  }
}

function* addManager({ payload }) {
  try {
    const { manager } = payload;
    const completeManager = { ...manager, actionType: 'edit' };
    const res = yield call(addManagersAsync, completeManager);
    if (!res.response) {
      const responseValue = res.data.data;
      const formatedManager = {
        name: responseValue.name,
        active: responseValue.active,
        id: responseValue.id,
        schools: responseValue.schools,
        email: responseValue.email,
      };
      yield put(addManagerSuccess(formatedManager));
    } else {
      yield put(addManagerError(res.response.data));
    }
  } catch (error) {
    yield put(addManagerError(error));
  }
}

function* editManager({ payload }) {
  try {
    const { manager } = payload;
    const completeManager = { ...manager, actionType: 'edit' };
    const res = yield call(editManagersAsync, completeManager);
    if (!res.response) {
      const responseValue = res.data.data;
      const formatedManager = {
        name: responseValue.name,
        active: responseValue.active,
        id: responseValue.id,
        schools: responseValue.schools,
        email: responseValue.email,
      };
      yield put(editManagerSuccess(formatedManager));
    } else {
      yield put(editManagerError(res.response.data));
    }
  } catch (error) {
    yield put(editManagerError(error));
  }
}

function* editActiveManager({ payload }) {
  try {
    const { id, active } = payload;
    const response = yield call(api.put, `administrators/${id}`, {
      active,
      actionType: 'activation',
    });
    const responseValue = response.data.data;
    const formatedManager = {
      name: responseValue.name,
      active: responseValue.active,
      id: responseValue.id,
      schools: responseValue.schools,
      email: responseValue.email,
    };
    yield put(editActiveManagerSuccess(id, formatedManager));
  } catch (error) {
    yield put(editActiveManagerError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_MANAGER_REQUEST, listManager),
    takeLatest(GET_MANAGER_REQUEST, getManagerById),
    takeLatest(ADD_MANAGER_REQUEST, addManager),
    takeLatest(EDIT_MANAGER_REQUEST, editManager),
    takeLatest(EDIT_ACTIVE_MANAGER_REQUEST, editActiveManager),
  ]);
}
