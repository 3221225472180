import {
  LIST_CLASS_CYCLE_REQUEST,
  LIST_CLASS_CYCLE_SUCCESS,
  LIST_CLASS_CYCLE_ERROR,
  ADD_CLASS_CYCLE_REQUEST,
  ADD_CLASS_CYCLE_SUCCESS,
  ADD_CLASS_CYCLE_ERROR,
  EDIT_CLASS_CYCLE_REQUEST,
  EDIT_CLASS_CYCLE_SUCCESS,
  EDIT_CLASS_CYCLE_ERROR,
} from '../../actions';

export const listClassCycleRequest = (classId) => ({
  type: LIST_CLASS_CYCLE_REQUEST,
  classId
});
export const listClassCycleSuccess = (classCycles) => ({
  type: LIST_CLASS_CYCLE_SUCCESS,
  payload: classCycles,
});
export const listClassCycleError = (message) => ({
  type: LIST_CLASS_CYCLE_ERROR,
  payload: { message },
});
export const addClassCycleRequest = (cycle) => ({
  type: ADD_CLASS_CYCLE_REQUEST,
  payload: { cycle },
});
export const addClassCycleSuccess = (cycle) => ({
  type: ADD_CLASS_CYCLE_SUCCESS,
  payload: cycle,
});
export const addClassCycleError = (message) => ({
  type: ADD_CLASS_CYCLE_ERROR,
  payload: { message },
});

export const editClassCycleRequest = (cycle) => ({
  type: EDIT_CLASS_CYCLE_REQUEST,
  payload: { cycle },
});
export const editClassCycleSuccess = (cycle) => ({
  type: EDIT_CLASS_CYCLE_SUCCESS,
  payload: cycle,
});
export const editClassCycleError = (message) => ({
  type: EDIT_CLASS_CYCLE_ERROR,
  payload: { message },
});
