import {
  LIST_MANAGER_REQUEST,
  LIST_MANAGER_SUCCESS,
  LIST_MANAGER_ERROR,
  GET_MANAGER_REQUEST,
  GET_MANAGER_SUCCESS,
  GET_MANAGER_ERROR,
  ADD_MANAGER_REQUEST,
  ADD_MANAGER_SUCCESS,
  ADD_MANAGER_ERROR,
  EDIT_MANAGER_REQUEST,
  EDIT_MANAGER_SUCCESS,
  EDIT_MANAGER_ERROR,
  EDIT_ACTIVE_MANAGER_REQUEST,
  EDIT_ACTIVE_MANAGER_SUCCESS,
  EDIT_ACTIVE_MANAGER_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  currentManager: null,
  currentManagerList: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentManager: null,
        success: '',
      };
    case GET_MANAGER_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentManager: action.payload,
        error: '',
      };
    case GET_MANAGER_ERROR:
      return {
        ...state,
        loading: false,
        currentManager: null,
        error: action.payload.message,
      };
    case LIST_MANAGER_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentManagerList: action.payload,
        error: '',
      };
    case LIST_MANAGER_ERROR:
      return {
        ...state,
        loading: false,
        currentManagerList: null,
        error: action.payload.message,
      };
    case ADD_MANAGER_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_MANAGER_SUCCESS: {
      const allManagers = [...state.currentManagerList, action.payload];

      return {
        ...state,
        currentManagerList: allManagers,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case ADD_MANAGER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_MANAGER_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_MANAGER_SUCCESS: {
      const managersUpdated = state.currentManagerList.map((manager) => {
        if (manager.id === action.payload.manager.id) {
          return action.payload.manager;
        }
        return manager;
      });

      return {
        ...state,
        currentManagerList: managersUpdated,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case EDIT_MANAGER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_ACTIVE_MANAGER_REQUEST:
      return { ...state, loading: false, error: '', success: '' };
    case EDIT_ACTIVE_MANAGER_SUCCESS: {
      const managersUpdated = state.currentManagerList.map((manager) => {
        if (manager.id === action.payload.id) {
          return action.payload.manager;
        }
        return manager;
      });

      return {
        ...state,
        currentManagerList: managersUpdated,
        success: action.payload,
        loading: false,
        error: '',
      };
    }
    case EDIT_ACTIVE_MANAGER_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
