import {
  LIST_EXAM_REQUEST,
  LIST_EXAM_SUCCESS,
  LIST_EXAM_ERROR,
  GET_EXAM_REQUEST,
  GET_EXAM_SUCCESS,
  GET_EXAM_ERROR,
  ADD_EXAM_REQUEST,
  ADD_EXAM_SUCCESS,
  ADD_EXAM_ERROR,
  EDIT_EXAM_REQUEST,
  EDIT_EXAM_SUCCESS,
  EDIT_EXAM_ERROR,
  EDIT_ACTIVE_EXAM_REQUEST,
  EDIT_ACTIVE_EXAM_SUCCESS,
  EDIT_ACTIVE_EXAM_ERROR,
  GENERATE_FILES_EXAM_REQUEST,
  GENERATE_FILES_EXAM_SUCCESS,
  GENERATE_FILES_EXAM_ERROR,
} from '../actions';

export const getExamRequest = (id) => ({
  type: GET_EXAM_REQUEST,
  payload: { id },
});
export const getExamSuccess = (exam) => ({
  type: GET_EXAM_SUCCESS,
  payload: exam,
});
export const getExamError = (message) => ({
  type: GET_EXAM_ERROR,
  payload: { message },
});

export const listExamRequest = () => ({
  type: LIST_EXAM_REQUEST,
});
export const listExamSuccess = (activities) => ({
  type: LIST_EXAM_SUCCESS,
  payload: activities,
});
export const listExamError = (message) => ({
  type: LIST_EXAM_ERROR,
  payload: { message },
});

export const addExamRequest = (exam) => ({
  type: ADD_EXAM_REQUEST,
  payload: { exam },
});
export const addExamSuccess = (exam) => ({
  type: ADD_EXAM_SUCCESS,
  payload: exam,
});
export const addExamError = (message) => ({
  type: ADD_EXAM_ERROR,
  payload: { message },
});

export const editExamRequest = (exam) => ({
  type: EDIT_EXAM_REQUEST,
  payload: { exam },
});
export const editExamSuccess = (exam) => ({
  type: EDIT_EXAM_SUCCESS,
  payload: { exam },
});
export const editExamError = (message) => ({
  type: EDIT_EXAM_ERROR,
  payload: { message },
});

export const editActiveExamRequest = (id, active) => ({
  type: EDIT_ACTIVE_EXAM_REQUEST,
  payload: { id, active },
});
export const editActiveExamSuccess = (id, exam) => ({
  type: EDIT_ACTIVE_EXAM_SUCCESS,
  payload: { id, exam },
});
export const editActiveExamError = (message) => ({
  type: EDIT_ACTIVE_EXAM_ERROR,
  payload: { message },
});

export const generateFilesExamRequest = (exam) => ({
  type: GENERATE_FILES_EXAM_REQUEST,
  payload: { exam },
});
export const generateFilesExamSuccess = (exam) => ({
  type: GENERATE_FILES_EXAM_SUCCESS,
  payload: exam,
});
export const generateFilesExamError = (message) => ({
  type: GENERATE_FILES_EXAM_ERROR,
  payload: { message },
});
