import {
  LIST_JOURNEYS_REQUEST,
  LIST_JOURNEYS_SUCCESS,
  LIST_JOURNEYS_ERROR,
  RESET_STATE_ALL_APPLICATION,
  GET_JOURNEYS_REQUEST,
  GET_JOURNEYS_SUCCESS,
  GET_JOURNEYS_ERROR,
  ADD_JOURNEYS_REQUEST,
  ADD_JOURNEYS_SUCCESS,
  ADD_JOURNEYS_ERROR,
  EDIT_JOURNEYS_REQUEST,
  EDIT_JOURNEYS_SUCCESS,
  RESET_STATE_REDUX
} from '../actions';

const INIT_STATE = {
  currentJourney: null,
  currentJourneysList: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentJourney: null,
        success: '',
      };
    case GET_JOURNEYS_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_JOURNEYS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentJourney: action.payload,
        error: '',
      };
    case GET_JOURNEYS_ERROR:
      return {
        ...state,
        loading: false,
        currentJourney: null,
        error: action.payload.message,
      };
    case LIST_JOURNEYS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_JOURNEYS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentJourneysList: action.payload,
        error: '',
      };
    case LIST_JOURNEYS_ERROR:
      return {
        ...state,
        loading: false,
        currentJourneysList: null,
        error: action.payload.message,
      };
    case ADD_JOURNEYS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_JOURNEYS_SUCCESS: {
      const allJourneys = [...state.currentJourneysList, action.payload];

      return {
        ...state,
        currentJourneysList: allJourneys,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_JOURNEYS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_JOURNEYS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_JOURNEYS_SUCCESS: {
      const journeysUpdated = state.currentJourneysList.map((journeys) => {
        if (journeys.id === action.payload.journeys.id) {
          return action.payload.journeys;
        }
        return journeys;
      });

      return {
        ...state,
        currentJourneysList: journeysUpdated,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    default:
      return { ...state };
  }
};
