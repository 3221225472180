import {
  LIST_EXAM_EXTERNALS_REQUEST,
  LIST_EXAM_EXTERNALS_SUCCESS,
  LIST_EXAM_EXTERNALS_ERROR,
  ADD_EXAM_EXTERNALS_REQUEST,
  ADD_EXAM_EXTERNALS_SUCCESS,
  ADD_EXAM_EXTERNALS_ERROR,
} from '../actions';

export const listExamExternalsRequest = (cycleIs, subareaId) => ({
  type: LIST_EXAM_EXTERNALS_REQUEST,
  payload: { cycleIs, subareaId }
});
export const listExamExternalsSuccess = (examExternals) => ({
  type: LIST_EXAM_EXTERNALS_SUCCESS,
  payload: examExternals,
});
export const listExamExternalsError = (message) => ({
  type: LIST_EXAM_EXTERNALS_ERROR,
  payload: { message },
});

export const addExamExternalsRequest = (examExternals) => ({
  type: ADD_EXAM_EXTERNALS_REQUEST,
  payload: { examExternals },
});
export const addExamExternalsSuccess = (examExternals) => ({
  type: ADD_EXAM_EXTERNALS_SUCCESS,
  payload: examExternals,
});
export const addExamExternalsError = (message) => ({
  type: ADD_EXAM_EXTERNALS_ERROR,
  payload: { message },
});
