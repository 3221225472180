import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import { LIST_STUDY_DIAGNOSIS_REQUEST } from '../actions';
import { listStudyDiagnosisSuccess, listStudyDiagnosisError } from './actions';

const getStudyDiagnosisAsync = (classId, cycleIs, subjectId) =>
  api
    .get(
      `/students/reports/diagnosis/autonomy/classes/${classId}/subject/${subjectId}/cycle/${cycleIs}`
    )
    .then((response) => response)
    .catch((error) => error);

function* listStudyDiagnosis({ payload }) {
  try {
    const { classId, cycleIs, subjectId } = payload;

    const res = yield call(getStudyDiagnosisAsync, classId, cycleIs, subjectId);

    if (!res.response) {
      yield put(listStudyDiagnosisSuccess(res.data.data));
    } else {
      yield put(listStudyDiagnosisError(res.response.data));
    }
  } catch (error) {
    yield put(listStudyDiagnosisError(error));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(LIST_STUDY_DIAGNOSIS_REQUEST, listStudyDiagnosis)]);
}
