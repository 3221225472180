import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../services/api';

import {
  LIST_EXAM_EXTERNALS_REQUEST,
  ADD_EXAM_EXTERNALS_REQUEST,
} from '../actions';
import {
  listExamExternalsSuccess,
  listExamExternalsError,
  addExamExternalsSuccess,
  addExamExternalsError,
} from './actions';
import getApiErrors from '../../helpers/getApiErrors';

function* listExamExternalsStudents({ payload }) {
  const { cycleIs, subareaId } = payload;

  try {
    const response = yield call(api.get, `exam-externals/students/cycle/${cycleIs}/subareas/${subareaId}`);
    yield put(listExamExternalsSuccess(response.data.data));
  } catch (error) {
    yield put(listExamExternalsError(error));
  }
}

const addExamExternalsAsync = async (completeExamExternals) =>
  api
    .post(`exam-externals`, {
      ...completeExamExternals,
    })
    .then((response) => response)
    .catch((error) => error);

function* addExamExternals({ payload }) {
  try {
    const { examExternals } = payload;
    const res = yield call(addExamExternalsAsync, examExternals);
    if (!res.response) {
      const responseValue = res.data.data;
      yield put(addExamExternalsSuccess(responseValue));
      toast.success(responseValue);
    } else {
      const errors = res.response.data;
      yield put(addExamExternalsError(errors));
      getApiErrors(errors);
    }
  } catch (error) {
    yield put(addExamExternalsError(error));
    getApiErrors(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_EXAM_EXTERNALS_REQUEST, listExamExternalsStudents),
    takeLatest(ADD_EXAM_EXTERNALS_REQUEST, addExamExternals),
  ]);
}
