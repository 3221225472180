import {
  LIST_INCIDENCES_REQUEST,
  LIST_INCIDENCES_SUCCESS,
  LIST_INCIDENCES_ERROR,
  GET_INCIDENCES_REQUEST,
  GET_INCIDENCES_SUCCESS,
  GET_INCIDENCES_ERROR,
} from "../actions";

export const getIncidencesRequest = (id) => ({
  type: GET_INCIDENCES_REQUEST,
  payload: { id },
});
export const getIncidencesSuccess = (incidences) => ({
  type: GET_INCIDENCES_SUCCESS,
  payload: incidences,
});
export const getIncidencesError = (message) => ({
  type: GET_INCIDENCES_ERROR,
  payload: { message },
});

export const listIncidencesRequest = () => ({
  type: LIST_INCIDENCES_REQUEST,
});
export const listIncidencesSuccess = (incidences) => ({
  type: LIST_INCIDENCES_SUCCESS,
  payload: incidences,
});
export const listIncidencesError = (message) => ({
  type: LIST_INCIDENCES_ERROR,
  payload: { message },
});
