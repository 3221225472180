import { all, call, put, takeLatest } from 'redux-saga/effects';

import api, { apiQuestionDb } from '../../services/api';
import {
  LIST_ACTIVITY_REQUEST,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_EXAM_REQUEST,
  ADD_ACTIVITY_REQUEST,
  EDIT_ACTIVITY_REQUEST,
  EDIT_ACTIVE_ACTIVITY_REQUEST,
  ORDINATE_ACTIVITY_REQUEST,
} from '../actions';
import {
  listActivitySuccess,
  listActivityError,
  getActivitySuccess,
  getActivityError,
  getActivityExamSuccess,
  getActivityExamError,
  addActivitySuccess,
  addActivityError,
  editActivitySuccess,
  editActivityError,
  editActiveActivitySuccess,
  editActiveActivityError,
  ordinateActivitySuccess,
  ordinateActivityError,
} from './actions';
import { currentUser } from '../../constants/defaultValues';

function* getActivityById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `activities/${id}`);
    yield put(getActivitySuccess(response.data.data));
  } catch (error) {
    yield put(getActivityError(error));
  }
}

const getActivityExamByIdAsync = async (id) =>
  apiQuestionDb
    .get(`/questions/${id}/0`)
    .then((response) => response)
    .catch((error) => error);

function* getActivityExamById({ payload }) {
  try {
    const { id } = payload;
    const res = yield call(getActivityExamByIdAsync, id);
    if (!res.response) {
      yield put(getActivityExamSuccess(res.data.data));
    } else {
      yield put(getActivityExamError(res.response.data));
    }
  } catch (error) {
    yield put(getActivityExamError(error));
  }
}

function* listActivity() {
  try {
    const response = yield call(
      api.get,
      `activities/${
        currentUser?.uid?.schools?.id ?? currentUser?.uid?.classes?.school_id
      }/all`
    );
    const allActivities = response.data.data;
    const onlyActivity = allActivities.filter((activity) => {
      return !activity.is_exam;
    });
    yield put(listActivitySuccess(onlyActivity));
  } catch (error) {
    yield put(listActivityError(error));
  }
}

const addActivityAsync = async (completeActivity) =>
  api
    .post(`activities`, {
      ...completeActivity,
    })
    .then((response) => response)
    .catch((error) => error);

const editActivityAsync = async (completeActivity) =>
  api
    .put(`activities/${completeActivity.id}`, {
      ...completeActivity,
    })
    .then((response) => response)
    .catch((error) => error);

function* addActivity({ payload }) {
  try {
    const { activity } = payload;
    const completeActivity = { ...activity, actionType: 'edit' };
    const res = yield call(addActivityAsync, completeActivity);
    if (!res.response) {
      const responseValue = res.data.data;
      const formatedActivity = {
        title: responseValue.title,
        subtitle: responseValue.subtitle,
        description: responseValue.description,
        active: responseValue.active,
        is_exam: false,
        id: responseValue.id,
        subareas: [
          {
            id: responseValue.subareas[0].id,
            name: responseValue.subareas[0].name,
          },
        ],
      };
      yield put(addActivitySuccess(formatedActivity));
    } else {
      yield put(addActivityError(res.response.data));
    }
  } catch (error) {
    yield put(addActivityError(error));
  }
}

function* editActivity({ payload }) {
  try {
    const { activity } = payload;
    const completeActivity = { ...activity, actionType: 'edit' };
    const res = yield call(editActivityAsync, completeActivity);
    if (!res.response) {
      const responseValue = res.data.data;
      const formatedActivity = {
        title: responseValue.title,
        subtitle: responseValue.subtitle,
        description: responseValue.description,
        active: responseValue.active,
        is_exam: false,
        id: responseValue.id,
        subareas: [
          {
            id: responseValue.subareas[0].id,
            name: responseValue.subareas[0].name,
          },
        ],
      };
      yield put(editActivitySuccess(formatedActivity));
    } else {
      yield put(editActivityError(res.response.data));
    }
  } catch (error) {
    yield put(editActivityError(error));
  }
}

function* editActiveActivity({ payload }) {
  try {
    const { id, active } = payload;
    const response = yield call(api.put, `activities/${id}`, {
      active,
      actionType: 'activation',
    });
    const responseValue = response.data.data;
    const formatedActivity = {
      title: responseValue.title,
      subtitle: responseValue.subtitle,
      description: responseValue.description,
      is_exam: false,
      active: responseValue.active,
      id: responseValue.id,
      subareas: [
        {
          id: responseValue.subareas[0].id,
          name: responseValue.subareas[0].name,
        },
      ],
    };
    yield put(editActiveActivitySuccess(id, formatedActivity));
  } catch (error) {
    yield put(editActiveActivityError(error));
  }
}

const ordinateActivityAsync = async (completeActivity) =>
  api
    .post(`activities/ordination`, {
      ...completeActivity,
    })
    .then((response) => response)
    .catch((error) => error);

function* ordinateActivity({ payload }) {
  try {
    const { activity } = payload;
    const completeActivity = { data: activity };
    const res = yield call(ordinateActivityAsync, completeActivity);
    if (!res.response) {
      yield put(ordinateActivitySuccess(res.data));
    } else {
      yield put(ordinateActivityError(res.response.data));
    }
  } catch (error) {
    yield put(ordinateActivityError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_ACTIVITY_REQUEST, listActivity),
    takeLatest(GET_ACTIVITY_REQUEST, getActivityById),
    takeLatest(GET_ACTIVITY_EXAM_REQUEST, getActivityExamById),
    takeLatest(ADD_ACTIVITY_REQUEST, addActivity),
    takeLatest(ORDINATE_ACTIVITY_REQUEST, ordinateActivity),
    takeLatest(EDIT_ACTIVITY_REQUEST, editActivity),
    takeLatest(EDIT_ACTIVE_ACTIVITY_REQUEST, editActiveActivity),
  ]);
}
