import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  ADD_SUBJECTS_REQUEST,
  EDIT_SUBJECT_VINCULATE_PREREQUISITE_REQUEST,
  EDIT_SUBJECTS_REQUEST,
  GET_SUBJECTS_REQUEST,
  LIST_SUBJECTS_REQUEST,
  LIST_ALL_SUBJECTS_REQUEST,
  ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST,
} from '../actions';
import {
  listSubjectsSuccess,
  listSubjectsError,
  addSubjectsError,
  addSubjectsSuccess,
  editSubjectsError,
  editSubjectsSuccess,
  getSubjectsError,
  getSubjectsSuccess,
  editSubjectVinculatePrerequisiteSuccess,
  editSubjectVinculatePrerequisiteError,
  listAllSubjectsSuccess,
  listAllSubjectsError,
  addSubjectDiagnosticAssessmentsSuccess,
  addSubjectDiagnosticAssessmentsError,
  editSubjectDiagnosticAssessmentsSuccess,
  editSubjectDiagnosticAssessmentsError,
  listSubjectDiagnosticAssessmentsSuccess,
  listSubjectDiagnosticAssessmentsError,
  getSubjectDiagnosticAssessmentsSuccess,
  getSubjectDiagnosticAssessmentsError,
} from './actions';
import { currentUser } from '../../constants/defaultValues';

function* listSubjects({ payload }) {

  try {
    const { cycleIs, studentId } = payload;
    const classId =
      currentUser.uid.schools?.active_class?.id ??
      currentUser?.uid?.classes?.id;

    let filter = '';

    if (studentId) {
      filter = `?cycleIs=${cycleIs}&studentId=${studentId}`;
    }

    const endpointSubjectList = classId
      ? `subjects/classes/${classId}${filter}`
      : 'subjects';
    const response = yield call(api.get, endpointSubjectList);
    if (classId) {
      let res = response.data.data;
      if (filter) {
        res = res.map((subject) => {
          return {
            ...subject,
            class_subject_id: subject.class_subject_id,
            id: subject.id,
          };
        });
      } else {
        res = res.map((subject) => {
          return {
                ...subject,
                class_subject_id: subject.id,
                id: subject.subject_id,
              };
            });
      }
      yield put(listSubjectsSuccess(res));
    } else {
      yield put(listSubjectsSuccess(response.data.data));
    }
  } catch (error) {
    yield put(listSubjectsError(error));
  }
}

function* listAllSubjects() {
  try {
    const response = yield call(api.get, 'subjects');
    yield put(listAllSubjectsSuccess(response.data.data));
  } catch (error) {
    yield put(listAllSubjectsError(error));
  }
}

function* getSubjectsById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `subjects/${id}`);
    yield put(getSubjectsSuccess(response.data.data));
  } catch (error) {
    yield put(getSubjectsError(error));
  }
}

const addSubjectsAsync = async (completeSubjects) =>
  api
    .post(`subjects`, {
      ...completeSubjects,
    })
    .then((response) => response)
    .catch((error) => error);

const editSubjectsAsync = async (completeSubjects) =>
  api
    .put(`subjects/${completeSubjects.id}`, {
      ...completeSubjects,
    })
    .then((response) => response)
    .catch((error) => error);

const addSubjectsImageAsync = async (subjects) =>
  api
    .post(`subjects/upload`, {
      ...subjects.file,
    })
    .then((response) => response)
    .catch((error) => error);

function* addSubjects({ payload }) {
  try {
    const { subjects } = payload;
    const completeSubjects = { ...subjects, actionType: 'edit' };
    const res = yield call(addSubjectsAsync, completeSubjects);
    if (!res.response) {
      const responseValue = res.data.data;
      if (completeSubjects.file) {
        completeSubjects.file.subject_id = responseValue.id;
        yield call(addSubjectsImageAsync, completeSubjects);
      }
      yield put(addSubjectsSuccess(responseValue));
    } else {
      yield put(addSubjectsError(res.response.data));
    }
  } catch (error) {
    yield put(addSubjectsError(error));
  }
}

function* editSubjects({ payload }) {
  try {
    const { subjects } = payload;
    const completeSubjects = { ...subjects, actionType: 'edit' };
    const res = yield call(editSubjectsAsync, completeSubjects);
    if (!res.response) {
      const responseValue = res.data.data;
      if (completeSubjects.file) {
        completeSubjects.file.subject_id = responseValue.id;
        yield call(addSubjectsImageAsync, completeSubjects);
      }
      yield put(editSubjectsSuccess(responseValue));
    } else {
      yield put(editSubjectsError(res.response.data));
    }
  } catch (error) {
    yield put(editSubjectsError(error));
  }
}

const editSubjectVinculatePrerequisiteAsync = async (id, prerequisiteList) =>
  api
    .post(`prerequisites/subjects/${id}`, {
      ...prerequisiteList,
    })
    .then((response) => response)
    .catch((error) => error);

function* editSubjectVinculatePrerequisite({ payload }) {
  try {
    const { id, prerequisiteList } = payload;
    const res = yield call(
      editSubjectVinculatePrerequisiteAsync,
      id,
      prerequisiteList
    );
    if (!res.response) {
      yield put(editSubjectVinculatePrerequisiteSuccess(res.data.message));
    } else {
      yield put(editSubjectVinculatePrerequisiteError(res.response.data));
    }
  } catch (error) {
    yield put(editSubjectVinculatePrerequisiteError(error));
  }
}

function* listSubjectDiagnosticAssessments({ payload }) {
  try {
    const { classId } = payload;
    const response = yield call(
      api.get,
      `diagnostic-assessments/classes/${classId}`
    );
    yield put(listSubjectDiagnosticAssessmentsSuccess(response.data.data));
  } catch (error) {
    yield put(listSubjectDiagnosticAssessmentsError(error));
  }
}

function* getSubjectDiagnosticAssessments({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `diagnostic-assessments/${id}`);
    yield put(getSubjectDiagnosticAssessmentsSuccess(response.data.data));
  } catch (error) {
    yield put(getSubjectDiagnosticAssessmentsError(error));
  }
}

const addSubjectDiagnosticAssessmentsAsync = async (diagnosticAssessment) =>
  api
    .post(`diagnostic-assessments`, {
      ...diagnosticAssessment,
    })
    .then((response) => response)
    .catch((error) => error);

function* addSubjectDiagnosticAssessments({ payload }) {
  try {
    const { diagnosticAssessment } = payload;
    const res = yield call(
      addSubjectDiagnosticAssessmentsAsync,
      diagnosticAssessment
    );
    if (!res.response) {
      // add id
      yield put(addSubjectDiagnosticAssessmentsSuccess(res.data.data));
    } else {
      yield put(addSubjectDiagnosticAssessmentsError(res.response.data));
    }
  } catch (error) {
    yield put(editSubjectVinculatePrerequisiteError(error));
  }
}

const editSubjectDiagnosticAssessmentsAsync = async (diagnosticAssessment) =>
  api
    .put(`diagnostic-assessments/${diagnosticAssessment.id}`, {
      ...diagnosticAssessment,
    })
    .then((response) => response)
    .catch((error) => error);

function* editSubjectDiagnosticAssessments({ payload }) {
  try {
    const { diagnosticAssessment } = payload;
    const res = yield call(
      editSubjectDiagnosticAssessmentsAsync,
      diagnosticAssessment
    );
    if (!res.response) {
      // add id
      yield put(editSubjectDiagnosticAssessmentsSuccess(res.data.data));
    } else {
      yield put(editSubjectDiagnosticAssessmentsError(res.response.data));
    }
  } catch (error) {
    yield put(editSubjectVinculatePrerequisiteError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_SUBJECTS_REQUEST, listSubjects),
    takeLatest(LIST_ALL_SUBJECTS_REQUEST, listAllSubjects),
    takeLatest(GET_SUBJECTS_REQUEST, getSubjectsById),
    takeLatest(ADD_SUBJECTS_REQUEST, addSubjects),
    takeLatest(EDIT_SUBJECTS_REQUEST, editSubjects),
    takeLatest(
      LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST,
      listSubjectDiagnosticAssessments
    ),
    takeLatest(
      EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST,
      editSubjectDiagnosticAssessments
    ),
    takeLatest(
      ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST,
      addSubjectDiagnosticAssessments
    ),
    takeLatest(
      GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST,
      getSubjectDiagnosticAssessments
    ),
    takeLatest(
      EDIT_SUBJECT_VINCULATE_PREREQUISITE_REQUEST,
      editSubjectVinculatePrerequisite
    ),
  ]);
}
