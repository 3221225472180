/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* COMMON */

export const RESET_CURRENT_VALUE = 'RESET_CURRENT_VALUE';
export const RESET_STATE_REDUX = 'RESET_STATE_REDUX';
export const RESET_STATE_ALL_APPLICATION = 'RESET_STATE_ALL_APPLICATION';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGIN_SCHOOL = 'LOGIN_SCHOOL';
export const LOGIN_CLASS = 'LOGIN_CLASS';
export const CHANGE_CLASS_IMPERSONATOR = 'CHANGE_CLASS_IMPERSONATOR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RELOAD_PASSWORD_SEND_MAIL_REQUEST =
  'RELOAD_PASSWORD_SEND_MAIL_REQUEST';
export const RELOAD_PASSWORD_SEND_MAIL_SUCCESS =
  'RELOAD_PASSWORD_SEND_MAIL_SUCCESS';
export const RELOAD_PASSWORD_SEND_MAIL_ERROR =
  'RELOAD_PASSWORD_SEND_MAIL_ERROR';
export const FORGOT_PASSWORD_CHECK_TOKEN_REQUEST =
  'FORGOT_PASSWORD_CHECK_TOKEN_REQUEST';
export const FORGOT_PASSWORD_CHECK_TOKEN_SUCCESS =
  'FORGOT_PASSWORD_CHECK_TOKEN_SUCCESS';
export const FORGOT_PASSWORD_CHECK_TOKEN_ERROR =
  'FORGOT_PASSWORD_CHECK_TOKEN_ERROR';
export const IMPERSONATE_REQUEST = 'IMPERSONATE_REQUEST';
export const IMPERSONATE_SUCCESS = 'IMPERSONATE_SUCCESS';
export const IMPERSONATE_ERROR = 'IMPERSONATE_ERROR';
export const CONFIRM_STUDENT_ACCOUNT_REQUEST =
  'CONFIRM_STUDENT_ACCOUNT_REQUEST';
export const CONFIRM_STUDENT_ACCOUNT_SUCCESS =
  'CONFIRM_STUDENT_ACCOUNT_SUCCESS';
export const CONFIRM_STUDENT_ACCOUNT_ERROR = 'CONFIRM_STUDENT_ACCOUNT_ERROR';
export const LEAVE_ACCESS = 'LEAVE_ACCESS';

/* AUTH STUDENT */

export const RESET_STUDENT_PASSWORD = 'RESET_STUDENT_PASSWORD';
export const RESET_STUDENT_PASSWORD_SUCCESS = 'RESET_STUDENT_PASSWORD_SUCCESS';
export const RESET_STUDENT_PASSWORD_ERROR = 'RESET_STUDENT_PASSWORD_ERROR';
export const FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_REQUEST =
  'FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_REQUEST';
export const FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_SUCCESS =
  'FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_SUCCESS';
export const FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_ERROR =
  'FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_ERROR';

export const RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_REQUEST = 'RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_REQUEST';
export const RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_SUCCESS = 'RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_SUCCESS';
export const RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_ERROR = 'RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* SCHOOLS */
export const GET_SCHOOL_REQUEST = 'GET_SCHOOL_REQUEST';
export const GET_SCHOOL_SUCCESS = 'GET_SCHOOL_SUCCESS';
export const GET_SCHOOL_ERROR = 'GET_SCHOOL_ERROR';
export const GET_SCHOOL_PARENTS_REQUEST = 'GET_SCHOOL_PARENTS_REQUEST';
export const GET_SCHOOL_PARENTS_SUCCESS = 'GET_SCHOOL_PARENTS_SUCCESS';
export const GET_SCHOOL_PARENTS_ERROR = 'GET_SCHOOL_PARENTS_ERROR';
export const GET_SCHOOL_TYPES_REQUEST = 'GET_SCHOOL_TYPES_REQUEST';
export const GET_SCHOOL_TYPES_SUCCESS = 'GET_SCHOOL_TYPES_SUCCESS';
export const GET_SCHOOL_TYPES_ERROR = 'GET_SCHOOL_TYPES_ERROR';
export const LIST_SCHOOL_REQUEST = 'LIST_SCHOOL_REQUEST';
export const LIST_SCHOOL_SUCCESS = 'LIST_SCHOOL_SUCCESS';
export const LIST_SCHOOL_ERROR = 'LIST_SCHOOL_ERROR';
export const ADD_SCHOOL_REQUEST = 'ADD_SCHOOL_REQUEST';
export const ADD_SCHOOL_SUCCESS = 'ADD_SCHOOL_SUCCESS';
export const ADD_SCHOOL_ERROR = 'ADD_SCHOOL_ERROR';
export const EDIT_SCHOOL_REQUEST = 'EDIT_SCHOOL_REQUEST';
export const EDIT_SCHOOL_SUCCESS = 'EDIT_SCHOOL_SUCCESS';
export const EDIT_SCHOOL_ERROR = 'EDIT_SCHOOL_ERROR';
export const EDIT_ACTIVE_SCHOOL_REQUEST = 'EDIT_ACTIVE_SCHOOL_REQUEST';
export const EDIT_ACTIVE_SCHOOL_SUCCESS = 'EDIT_ACTIVE_SCHOOL_SUCCESS';
export const EDIT_ACTIVE_SCHOOL_ERROR = 'EDIT_ACTIVE_SCHOOL_ERROR';

/* STUDENTS */
export const GET_STUDENT_REQUEST = 'GET_STUDENT_REQUEST';
export const GET_STUDENT_SUCCESS = 'GET_STUDENT_SUCCESS';
export const GET_STUDENT_ERROR = 'GET_STUDENT_ERROR';
export const LIST_STUDENT_REQUEST = 'LIST_STUDENT_REQUEST';
export const LIST_STUDENT_SUCCESS = 'LIST_STUDENT_SUCCESS';
export const LIST_STUDENT_ERROR = 'LIST_STUDENT_ERROR';
export const LIST_STUDENT_WITH_DELETED_REQUEST =
  'LIST_STUDENT_WITH_DELETED_REQUEST';
export const LIST_STUDENT_WITH_DELETED_SUCCESS =
  'LIST_STUDENT_WITH_DELETED_SUCCESS';
export const LIST_STUDENT_WITH_DELETED_ERROR =
  'LIST_STUDENT_WITH_DELETED_ERROR';
export const EDIT_ACTIVE_STUDENT_REQUEST = 'EDIT_ACTIVE_STUDENT_REQUEST';
export const EDIT_ACTIVE_STUDENT_SUCCESS = 'EDIT_ACTIVE_STUDENT_SUCCESS';
export const EDIT_ACTIVE_STUDENT_ERROR = 'EDIT_ACTIVE_STUDENT_ERROR';
export const IMPORT_STUDENT_REQUEST = 'IMPORT_STUDENT_REQUEST';
export const IMPORT_STUDENT_SUCCESS = 'IMPORT_STUDENT_SUCCESS';
export const IMPORT_STUDENT_ERROR = 'IMPORT_STUDENT_ERROR';
export const CHECK_STUDENT_REQUEST = 'CHECK_STUDENT_REQUEST';
export const CHECK_ALL_STUDENT_REQUEST = 'CHECK_ALL_STUDENT_REQUEST';
export const UNCHECK_ALL_STUDENT_REQUEST = 'UNCHECK_ALL_STUDENT_REQUEST';
export const GET_STUDY_ROUTINE_REQUEST = 'GET_STUDY_ROUTINE_REQUEST';
export const GET_STUDY_ROUTINE_SUCCESS = 'GET_STUDY_ROUTINE_SUCCESS';
export const GET_STUDY_ROUTINE_ERROR = 'GET_STUDY_ROUTINE_ERROR';

/* CLASSES */

export const LIST_CLASS_REQUEST = 'LIST_CLASS_REQUEST';
export const LIST_CLASS_SUCCESS = 'LIST_CLASS_SUCCESS';
export const LIST_CLASS_ERROR = 'LIST_CLASS_ERROR';
export const LIST_CLASS_JOURNEY_REQUEST = 'LIST_CLASS_JOURNEY_REQUEST';
export const LIST_CLASS_JOURNEY_SUCCESS = 'LIST_CLASS_JOURNEY_SUCCESS';
export const LIST_CLASS_JOURNEY_ERROR = 'LIST_CLASS_JOURNEY_ERROR';
export const GET_CLASS_REQUEST = 'GET_CLASS_REQUEST';
export const GET_CLASS_SUCCESS = 'GET_CLASS_SUCCESS';
export const GET_CLASS_ERROR = 'GET_CLASS_ERROR';
export const ADD_CLASS_REQUEST = 'ADD_CLASS_REQUEST';
export const ADD_CLASS_SUCCESS = 'ADD_CLASS_SUCCESS';
export const ADD_CLASS_ERROR = 'ADD_CLASS_ERROR';
export const GET_CLASS_WITH_STUDY_TIME_REQUEST =
  'GET_CLASS_WITH_STUDY_TIME_REQUEST';
export const GET_CLASS_WITH_STUDY_TIME_SUCCESS =
  'GET_CLASS_WITH_STUDY_TIME_SUCCESS';
export const GET_CLASS_WITH_STUDY_TIME_ERROR =
  'GET_CLASS_WITH_STUDY_TIME_ERROR';
export const EDIT_CLASS_REQUEST = 'EDIT_CLASS_REQUEST';
export const EDIT_CLASS_SUCCESS = 'EDIT_CLASS_SUCCESS';
export const EDIT_CLASS_ERROR = 'EDIT_CLASS_ERROR';
export const EDIT_ACTIVE_CLASS_REQUEST = 'EDIT_ACTIVE_CLASS_REQUEST';
export const EDIT_ACTIVE_CLASS_SUCCESS = 'EDIT_ACTIVE_CLASS_SUCCESS';
export const EDIT_ACTIVE_CLASS_ERROR = 'EDIT_ACTIVE_CLASS_ERROR';
export const EDIT_CLASS_VINCULATE_SUBAREA_REQUEST =
  'EDIT_CLASS_VINCULATE_SUBAREA_REQUEST';
export const EDIT_CLASS_VINCULATE_SUBAREA_SUCCESS =
  'EDIT_CLASS_VINCULATE_SUBAREA_SUCCESS';
export const EDIT_CLASS_VINCULATE_SUBAREA_ERROR =
  'EDIT_CLASS_VINCULATE_SUBAREA_ERROR';
export const VINCULATE_SUBAREA = 'VINCULATE_SUBAREA';
export const RESET_VINCULATE_SUBAREA = 'RESET_VINCULATE_SUBAREA';
export const EDIT_CLASS_VINCULATE_SUBJECT_REQUEST =
  'EDIT_CLASS_VINCULATE_SUBJECT_REQUEST';
export const EDIT_CLASS_VINCULATE_SUBJECT_SUCCESS =
  'EDIT_CLASS_VINCULATE_SUBJECT_SUCCESS';
export const EDIT_CLASS_VINCULATE_SUBJECT_ERROR =
  'EDIT_CLASS_VINCULATE_SUBJECT_ERROR';
export const VINCULATE_SUBJECT = 'VINCULATE_SUBJECT';
export const RESET_VINCULATE_SUBJECT = 'RESET_VINCULATE_SUBJECT';
export const EDIT_CLASS_VINCULATE_PREREQUISITE_REQUEST =
  'EDIT_CLASS_VINCULATE_PREREQUISITE_REQUEST';
export const EDIT_CLASS_VINCULATE_PREREQUISITE_SUCCESS =
  'EDIT_CLASS_VINCULATE_PREREQUISITE_SUCCESS';
export const EDIT_CLASS_VINCULATE_PREREQUISITE_ERROR =
  'EDIT_CLASS_VINCULATE_PREREQUISITE_ERROR';
export const LIST_CLASS_PREREQUISITES_REQUEST =
  'LIST_CLASS_PREREQUISITES_REQUEST';
export const LIST_CLASS_PREREQUISITES_SUCCESS =
  'LIST_CLASS_PREREQUISITES_SUCCESS';
export const LIST_CLASS_PREREQUISITES_ERROR = 'LIST_CLASS_PREREQUISITES_ERROR';
export const LIST_CLASS_PREREQUISITES_CLEAR = 'LIST_CLASS_PREREQUISITES_CLEAR';
export const LIST_CLASS_IMPERSONATE_REQUEST = 'LIST_CLASS_IMPERSONATE_REQUEST';
export const LIST_CLASS_IMPERSONATE_SUCCESS = 'LIST_CLASS_IMPERSONATE_SUCCESS';
export const LIST_CLASS_IMPERSONATE_ERROR = 'LIST_CLASS_IMPERSONATE_ERROR';

/* CLASS_STUDENTS */
export const DELETE_CLASS_STUDENT_REQUEST = 'DELETE_CLASS_STUDENT_REQUEST';
export const DELETE_CLASS_STUDENT_SUCCESS = 'DELETE_CLASS_STUDENT_SUCCESS';
export const DELETE_CLASS_STUDENT_ERROR = 'DELETE_CLASS_STUDENT_ERROR';

/* COORDINATORS */

export const LIST_COORDINATOR_REQUEST = 'LIST_COORDINATOR_REQUEST';
export const LIST_COORDINATOR_SUCCESS = 'LIST_COORDINATOR_SUCCESS';
export const LIST_COORDINATOR_ERROR = 'LIST_COORDINATOR_ERROR';
export const GET_COORDINATOR_REQUEST = 'GET_COORDINATOR_REQUEST';
export const GET_COORDINATOR_SUCCESS = 'GET_COORDINATOR_SUCCESS';
export const GET_COORDINATOR_ERROR = 'GET_COORDINATOR_ERROR';
export const ADD_COORDINATOR_REQUEST = 'ADD_COORDINATOR_REQUEST';
export const ADD_COORDINATOR_SUCCESS = 'ADD_COORDINATOR_SUCCESS';
export const ADD_COORDINATOR_ERROR = 'ADD_COORDINATOR_ERROR';
export const EDIT_COORDINATOR_REQUEST = 'EDIT_COORDINATOR_REQUEST';
export const EDIT_COORDINATOR_SUCCESS = 'EDIT_COORDINATOR_SUCCESS';
export const EDIT_COORDINATOR_ERROR = 'EDIT_COORDINATOR_ERROR';
export const EDIT_ACTIVE_COORDINATOR_REQUEST =
  'EDIT_ACTIVE_COORDINATOR_REQUEST';
export const EDIT_ACTIVE_COORDINATOR_SUCCESS =
  'EDIT_ACTIVE_COORDINATOR_SUCCESS';
export const EDIT_ACTIVE_COORDINATOR_ERROR = 'EDIT_ACTIVE_COORDINATOR_ERROR';

/* COORDINATORS */

export const LIST_NETWORK_COORDINATOR_REQUEST =
  'LIST_NETWORK_COORDINATOR_REQUEST';
export const LIST_NETWORK_COORDINATOR_SUCCESS =
  'LIST_NETWORK_COORDINATOR_SUCCESS';
export const LIST_NETWORK_COORDINATOR_ERROR = 'LIST_NETWORK_COORDINATOR_ERROR';
export const GET_NETWORK_COORDINATOR_REQUEST =
  'GET_NETWORK_COORDINATOR_REQUEST';
export const GET_NETWORK_COORDINATOR_SUCCESS =
  'GET_NETWORK_COORDINATOR_SUCCESS';
export const GET_NETWORK_COORDINATOR_ERROR = 'GET_NETWORK_COORDINATOR_ERROR';
export const ADD_NETWORK_COORDINATOR_REQUEST =
  'ADD_NETWORK_COORDINATOR_REQUEST';
export const ADD_NETWORK_COORDINATOR_SUCCESS =
  'ADD_NETWORK_COORDINATOR_SUCCESS';
export const ADD_NETWORK_COORDINATOR_ERROR = 'ADD_NETWORK_COORDINATOR_ERROR';
export const EDIT_NETWORK_COORDINATOR_REQUEST =
  'EDIT_NETWORK_COORDINATOR_REQUEST';
export const EDIT_NETWORK_COORDINATOR_SUCCESS =
  'EDIT_NETWORK_COORDINATOR_SUCCESS';
export const EDIT_NETWORK_COORDINATOR_ERROR = 'EDIT_NETWORK_COORDINATOR_ERROR';
export const EDIT_ACTIVE_NETWORK_COORDINATOR_REQUEST =
  'EDIT_ACTIVE_NETWORK_COORDINATOR_REQUEST';
export const EDIT_ACTIVE_NETWORK_COORDINATOR_SUCCESS =
  'EDIT_ACTIVE_NETWORK_COORDINATOR_SUCCESS';
export const EDIT_ACTIVE_NETWORK_COORDINATOR_ERROR =
  'EDIT_ACTIVE_NETWORK_COORDINATOR_ERROR';

/* MANAGERS */

export const LIST_MANAGER_REQUEST = 'LIST_MANAGER_REQUEST';
export const LIST_MANAGER_SUCCESS = 'LIST_MANAGER_SUCCESS';
export const LIST_MANAGER_ERROR = 'LIST_MANAGER_ERROR';
export const GET_MANAGER_REQUEST = 'GET_MANAGER_REQUEST';
export const GET_MANAGER_SUCCESS = 'GET_MANAGER_SUCCESS';
export const GET_MANAGER_ERROR = 'GET_MANAGER_ERROR';
export const ADD_MANAGER_REQUEST = 'ADD_MANAGER_REQUEST';
export const ADD_MANAGER_SUCCESS = 'ADD_MANAGER_SUCCESS';
export const ADD_MANAGER_ERROR = 'ADD_MANAGER_ERROR';
export const EDIT_MANAGER_REQUEST = 'EDIT_MANAGER_REQUEST';
export const EDIT_MANAGER_SUCCESS = 'EDIT_MANAGER_SUCCESS';
export const EDIT_MANAGER_ERROR = 'EDIT_MANAGER_ERROR';
export const EDIT_ACTIVE_MANAGER_REQUEST = 'EDIT_ACTIVE_MANAGER_REQUEST';
export const EDIT_ACTIVE_MANAGER_SUCCESS = 'EDIT_ACTIVE_MANAGER_SUCCESS';
export const EDIT_ACTIVE_MANAGER_ERROR = 'EDIT_ACTIVE_MANAGER_ERROR';

/* TEACHERS */

export const LIST_TEACHER_REQUEST = 'LIST_TEACHER_REQUEST';
export const LIST_TEACHER_SUCCESS = 'LIST_TEACHER_SUCCESS';
export const LIST_TEACHER_ERROR = 'LIST_TEACHER_ERROR';
export const LIST_TEACHER_SUBJECT_REQUEST = 'LIST_TEACHER_SUBJECT_REQUEST';
export const LIST_TEACHER_SUBJECT_SUCCESS = 'LIST_TEACHER_SUBJECT_SUCCESS';
export const LIST_TEACHER_SUBJECT_ERROR = 'LIST_TEACHER_SUBJECT_ERROR';
export const GET_TEACHER_REQUEST = 'GET_TEACHER_REQUEST';
export const GET_TEACHER_SUCCESS = 'GET_TEACHER_SUCCESS';
export const GET_TEACHER_ERROR = 'GET_TEACHER_ERROR';
export const ADD_TEACHER_REQUEST = 'ADD_TEACHER_REQUEST';
export const ADD_TEACHER_SUCCESS = 'ADD_TEACHER_SUCCESS';
export const ADD_TEACHER_ERROR = 'ADD_TEACHER_ERROR';
export const EDIT_TEACHER_REQUEST = 'EDIT_TEACHER_REQUEST';
export const EDIT_TEACHER_SUCCESS = 'EDIT_TEACHER_SUCCESS';
export const EDIT_TEACHER_ERROR = 'EDIT_TEACHER_ERROR';
export const EDIT_ACTIVE_TEACHER_REQUEST = 'EDIT_ACTIVE_TEACHER_REQUEST';
export const EDIT_ACTIVE_TEACHER_SUCCESS = 'EDIT_ACTIVE_TEACHER_SUCCESS';
export const EDIT_ACTIVE_TEACHER_ERROR = 'EDIT_ACTIVE_TEACHER_ERROR';
export const LIST_TEACHER_ACTIVITY_REQUEST = 'LIST_TEACHER_ACTIVITY_REQUEST';
export const LIST_TEACHER_ACTIVITY_SUCCESS = 'LIST_TEACHER_ACTIVITY_SUCCESS';
export const LIST_TEACHER_ACTIVITY_ERROR = 'LIST_TEACHER_ACTIVITY_ERROR';
export const CONCLUDE_TEACHER_ACTIVITY_REQUEST =
  'CONCLUDE_TEACHER_ACTIVITY_REQUEST';
export const CONCLUDE_TEACHER_ACTIVITY_SUCCESS =
  'CONCLUDE_TEACHER_ACTIVITY_SUCCESS';
export const CONCLUDE_TEACHER_ACTIVITY_ERROR =
  'CONCLUDE_TEACHER_ACTIVITY_ERROR';
export const IMPORT_TEACHER_ACTIVITY_REQUEST =
  'IMPORT_TEACHER_ACTIVITY_REQUEST';
export const IMPORT_TEACHER_ACTIVITY_SUCCESS =
  'IMPORT_TEACHER_ACTIVITY_SUCCESS';
export const IMPORT_TEACHER_ACTIVITY_ERROR = 'IMPORT_TEACHER_ACTIVITY_ERROR';
export const REMOVE_IMPORT_TEACHER_ACTIVITY_REQUEST =
  'REMOVE_IMPORT_TEACHER_ACTIVITY_REQUEST';
export const REMOVE_IMPORT_TEACHER_ACTIVITY_SUCCESS =
  'REMOVE_IMPORT_TEACHER_ACTIVITY_SUCCESS';
export const REMOVE_IMPORT_TEACHER_ACTIVITY_ERROR =
  'REMOVE_IMPORT_TEACHER_ACTIVITY_ERROR';
export const EDIT_TEACHER_VINCULATE_SUBJECT_REQUEST =
  'EDIT_TEACHER_VINCULATE_SUBJECT_REQUEST';
export const EDIT_TEACHER_VINCULATE_SUBJECT_SUCCESS =
  'EDIT_TEACHER_VINCULATE_SUBJECT_SUCCESS';
export const EDIT_TEACHER_VINCULATE_SUBJECT_ERROR =
  'EDIT_TEACHER_VINCULATE_SUBJECT_ERROR';
export const ADD_TEACHER_LINK_REQUEST = 'ADD_TEACHER_LINK_REQUEST';
export const ADD_TEACHER_LINK_SUCCESS = 'ADD_TEACHER_LINK_SUCCESS';
export const ADD_TEACHER_LINK_ERROR = 'ADD_TEACHER_LINK_ERROR';
export const REMOVE_TEACHER_LINK_REQUEST = 'REMOVE_TEACHER_LINK_REQUEST';
export const REMOVE_TEACHER_LINK_SUCCESS = 'REMOVE_TEACHER_LINK_SUCCESS';
export const REMOVE_TEACHER_LINK_ERROR = 'REMOVE_TEACHER_LINK_ERROR';

/* ACTIVITIES */

export const LIST_ACTIVITY_REQUEST = 'LIST_ACTIVITY_REQUEST';
export const LIST_ACTIVITY_SUCCESS = 'LIST_ACTIVITY_SUCCESS';
export const LIST_ACTIVITY_ERROR = 'LIST_ACTIVITY_ERROR';
export const GET_ACTIVITY_REQUEST = 'GET_ACTIVITY_REQUEST';
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export const GET_ACTIVITY_ERROR = 'GET_ACTIVITY_ERROR';
export const GET_ACTIVITY_EXAM_REQUEST = 'GET_ACTIVITY_EXAM_REQUEST';
export const GET_ACTIVITY_EXAM_SUCCESS = 'GET_ACTIVITY_EXAM_SUCCESS';
export const GET_ACTIVITY_EXAM_ERROR = 'GET_ACTIVITY_EXAM_ERROR';
export const ADD_ACTIVITY_REQUEST = 'ADD_ACTIVITY_REQUEST';
export const ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS';
export const ADD_ACTIVITY_ERROR = 'ADD_ACTIVITY_ERROR';
export const EDIT_ACTIVITY_REQUEST = 'EDIT_ACTIVITY_REQUEST';
export const EDIT_ACTIVITY_SUCCESS = 'EDIT_ACTIVITY_SUCCESS';
export const EDIT_ACTIVITY_ERROR = 'EDIT_ACTIVITY_ERROR';
export const EDIT_ACTIVE_ACTIVITY_REQUEST = 'EDIT_ACTIVE_ACTIVITY_REQUEST';
export const EDIT_ACTIVE_ACTIVITY_SUCCESS = 'EDIT_ACTIVE_ACTIVITY_SUCCESS';
export const EDIT_ACTIVE_ACTIVITY_ERROR = 'EDIT_ACTIVE_ACTIVITY_ERROR';
export const ORDINATE_ACTIVITY_REQUEST = 'ORDINATE_ACTIVITY_REQUEST';
export const ORDINATE_ACTIVITY_SUCCESS = 'ORDINATE_ACTIVITY_SUCCESS';
export const ORDINATE_ACTIVITY_ERROR = 'ORDINATE_ACTIVITY_ERROR';

/* EXAMS */

export const LIST_EXAM_REQUEST = 'LIST_EXAM_REQUEST';
export const LIST_EXAM_SUCCESS = 'LIST_EXAM_SUCCESS';
export const LIST_EXAM_ERROR = 'LIST_EXAM_ERROR';
export const GET_EXAM_REQUEST = 'GET_EXAM_REQUEST';
export const GET_EXAM_SUCCESS = 'GET_EXAM_SUCCESS';
export const GET_EXAM_ERROR = 'GET_EXAM_ERROR';
export const ADD_EXAM_REQUEST = 'ADD_EXAM_REQUEST';
export const ADD_EXAM_SUCCESS = 'ADD_EXAM_SUCCESS';
export const ADD_EXAM_ERROR = 'ADD_EXAM_ERROR';
export const EDIT_EXAM_REQUEST = 'EDIT_EXAM_REQUEST';
export const EDIT_EXAM_SUCCESS = 'EDIT_EXAM_SUCCESS';
export const EDIT_EXAM_ERROR = 'EDIT_EXAM_ERROR';
export const EDIT_ACTIVE_EXAM_REQUEST = 'EDIT_ACTIVE_EXAM_REQUEST';
export const EDIT_ACTIVE_EXAM_SUCCESS = 'EDIT_ACTIVE_EXAM_SUCCESS';
export const EDIT_ACTIVE_EXAM_ERROR = 'EDIT_ACTIVE_EXAM_ERROR';
export const ADD_ACTIVITY_EXAM_REQUEST = 'ADD_ACTIVITY_EXAM_REQUEST';
export const ADD_ACTIVITY_EXAM_SUCCESS = 'ADD_ACTIVITY_EXAM_SUCCESS';
export const ADD_ACTIVITY_EXAM_ERROR = 'ADD_ACTIVITY_EXAM_ERROR';
export const EDIT_ACTIVITY_EXAM_REQUEST = 'EDIT_ACTIVITY_EXAM_REQUEST';
export const EDIT_ACTIVITY_EXAM_SUCCESS = 'EDIT_ACTIVITY_EXAM_SUCCESS';
export const EDIT_ACTIVITY_EXAM_ERROR = 'EDIT_ACTIVITY_EXAM_ERROR';
export const GENERATE_FILES_EXAM_REQUEST = 'GENERATE_FILES_EXAM_REQUEST';
export const GENERATE_FILES_EXAM_SUCCESS = 'GENERATE_FILES_EXAM_SUCCESS';
export const GENERATE_FILES_EXAM_ERROR = 'GENERATE_FILES_EXAM_ERROR';

/* JOURNEY */

export const LIST_JOURNEYS_REQUEST = 'LIST_JOURNEYS_REQUEST';
export const LIST_JOURNEYS_SUCCESS = 'LIST_JOURNEYS_SUCCESS';
export const LIST_JOURNEYS_ERROR = 'LIST_JOURNEYS_ERROR';
export const GET_JOURNEYS_REQUEST = 'GET_JOURNEYS_REQUEST';
export const GET_JOURNEYS_SUCCESS = 'GET_JOURNEYS_SUCCESS';
export const GET_JOURNEYS_ERROR = 'GET_JOURNEYS_ERROR';
export const ADD_JOURNEYS_REQUEST = 'ADD_JOURNEYS_REQUEST';
export const ADD_JOURNEYS_SUCCESS = 'ADD_JOURNEYS_SUCCESS';
export const ADD_JOURNEYS_ERROR = 'ADD_JOURNEYS_ERROR';
export const EDIT_JOURNEYS_REQUEST = 'EDIT_JOURNEYS_REQUEST';
export const EDIT_JOURNEYS_SUCCESS = 'EDIT_JOURNEYS_SUCCESS';
export const EDIT_JOURNEYS_ERROR = 'EDIT_JOURNEYS_ERROR';

/* JOURNEY TRAIL */

export const LIST_JOURNEY_TRAILS_REQUEST = 'LIST_JOURNEY_TRAILS_REQUEST';
export const LIST_JOURNEY_TRAILS_SUCCESS = 'LIST_JOURNEY_TRAILS_SUCCESS';
export const LIST_JOURNEY_TRAILS_ERROR = 'LIST_JOURNEY_TRAILS_ERROR';
export const GET_JOURNEY_TRAILS_REQUEST = 'GET_JOURNEY_TRAILS_REQUEST';
export const GET_JOURNEY_TRAILS_SUCCESS = 'GET_JOURNEY_TRAILS_SUCCESS';
export const GET_JOURNEY_TRAILS_ERROR = 'GET_JOURNEY_TRAILS_ERROR';
export const ADD_JOURNEY_TRAILS_REQUEST = 'ADD_JOURNEY_TRAILS_REQUEST';
export const ADD_JOURNEY_TRAILS_SUCCESS = 'ADD_JOURNEY_TRAILS_SUCCESS';
export const ADD_JOURNEY_TRAILS_ERROR = 'ADD_JOURNEY_TRAILS_ERROR';
export const EDIT_JOURNEY_TRAILS_REQUEST = 'EDIT_JOURNEY_TRAILS_REQUEST';
export const EDIT_JOURNEY_TRAILS_SUCCESS = 'EDIT_JOURNEY_TRAILS_SUCCESS';
export const EDIT_JOURNEY_TRAILS_ERROR = 'EDIT_JOURNEY_TRAILS_ERROR';
export const ORDINATION_JOURNEY_TRAIL_WEEKS_REQUEST =
  'ORDINATION_JOURNEY_TRAIL_WEEKS_REQUEST';
export const ORDINATION_JOURNEY_TRAIL_WEEKS_SUCCESS =
  'ORDINATION_JOURNEY_TRAIL_WEEKS_SUCCESS';
export const ORDINATION_JOURNEY_TRAIL_WEEKS_ERROR =
  'ORDINATION_JOURNEY_TRAIL_WEEKS_ERROR';

/* ACTIVITIES */

export const LIST_VIDEO_REQUEST = 'LIST_VIDEO_REQUEST';
export const LIST_VIDEO_SUCCESS = 'LIST_VIDEO_SUCCESS';
export const LIST_VIDEO_ERROR = 'LIST_VIDEO_ERROR';
export const GET_VIDEO_REQUEST = 'GET_VIDEO_REQUEST';
export const GET_VIDEO_SUCCESS = 'GET_VIDEO_SUCCESS';
export const GET_VIDEO_ERROR = 'GET_VIDEO_ERROR';
export const ADD_VIDEO_REQUEST = 'ADD_VIDEO_REQUEST';
export const ADD_VIDEO_SUCCESS = 'ADD_VIDEO_SUCCESS';
export const ADD_VIDEO_ERROR = 'ADD_VIDEO_ERROR';
export const EDIT_VIDEO_REQUEST = 'EDIT_VIDEO_REQUEST';
export const EDIT_VIDEO_SUCCESS = 'EDIT_VIDEO_SUCCESS';
export const EDIT_VIDEO_ERROR = 'EDIT_VIDEO_ERROR';
export const EDIT_ACTIVE_VIDEO_REQUEST = 'EDIT_ACTIVE_VIDEO_REQUEST';
export const EDIT_ACTIVE_VIDEO_SUCCESS = 'EDIT_ACTIVE_VIDEO_SUCCESS';
export const EDIT_ACTIVE_VIDEO_ERROR = 'EDIT_ACTIVE_VIDEO_ERROR';
export const ORDINATE_VIDEO_REQUEST = 'ORDINATE_VIDEO_REQUEST';
export const ORDINATE_VIDEO_SUCCESS = 'ORDINATE_VIDEO_SUCCESS';
export const ORDINATE_VIDEO_ERROR = 'ORDINATE_VIDEO_ERROR';

/* SUBJECT */

export const LIST_SUBJECTS_REQUEST = 'LIST_SUBJECTS_REQUEST';
export const LIST_SUBJECTS_SUCCESS = 'LIST_SUBJECTS_SUCCESS';
export const LIST_SUBJECTS_ERROR = 'LIST_SUBJECTS_ERROR';
export const GET_SUBJECTS_REQUEST = 'GET_SUBJECTS_REQUEST';
export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS';
export const GET_SUBJECTS_ERROR = 'GET_SUBJECTS_ERROR';
export const ADD_SUBJECTS_REQUEST = 'ADD_SUBJECTS_REQUEST';
export const ADD_SUBJECTS_SUCCESS = 'ADD_SUBJECTS_SUCCESS';
export const ADD_SUBJECTS_ERROR = 'ADD_SUBJECTS_ERROR';
export const EDIT_SUBJECTS_REQUEST = 'EDIT_SUBJECTS_REQUEST';
export const EDIT_SUBJECTS_SUCCESS = 'EDIT_SUBJECTS_SUCCESS';
export const EDIT_SUBJECTS_ERROR = 'EDIT_SUBJECTS_ERROR';
export const EDIT_SUBJECT_VINCULATE_PREREQUISITE_REQUEST =
  'EDIT_SUBJECT_VINCULATE_PREREQUISITE_REQUEST';
export const EDIT_SUBJECT_VINCULATE_PREREQUISITE_SUCCESS =
  'EDIT_SUBJECT_VINCULATE_PREREQUISITE_SUCCESS';
export const EDIT_SUBJECT_VINCULATE_PREREQUISITE_ERROR =
  'EDIT_SUBJECT_VINCULATE_PREREQUISITE_ERROR';
export const VINCULATE_PREREQUISITE = 'VINCULATE_PREREQUISITE';
export const RESET_VINCULATE_PREREQUISITE = 'RESET_VINCULATE_PREREQUISITE';
export const LIST_ALL_SUBJECTS_REQUEST = 'LIST_ALL_SUBJECTS_REQUEST';
export const LIST_ALL_SUBJECTS_SUCCESS = 'LIST_ALL_SUBJECTS_SUCCESS';
export const LIST_ALL_SUBJECTS_ERROR = 'LIST_ALL_SUBJECTS_ERROR';
export const ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST =
  'ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST';
export const ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_SUCCESS =
  'ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_SUCCESS';
export const ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_ERROR =
  'ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_ERROR';
export const EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST =
  'EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST';
export const EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_SUCCESS =
  'EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_SUCCESS';
export const EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_ERROR =
  'VINCULATE_SUBJECT_DIAGNOSTIC_ASSESSMENTS_ERROR';
export const LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST =
  'LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST';
export const LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_SUCCESS =
  'LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_SUCCESS';
export const LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_ERROR =
  'LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_ERROR';
export const GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST =
  'GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST';
export const GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_SUCCESS =
  'GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_SUCCESS';
export const GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_ERROR =
  'GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_ERROR';

/* INCIDENCES */

export const LIST_INCIDENCES_REQUEST = 'LIST_INCIDENCES_REQUEST';
export const LIST_INCIDENCES_SUCCESS = 'LIST_INCIDENCES_SUCCESS';
export const LIST_INCIDENCES_ERROR = 'LIST_INCIDENCES_ERROR';
export const GET_INCIDENCES_REQUEST = 'GET_INCIDENCES_REQUEST';
export const GET_INCIDENCES_SUCCESS = 'GET_INCIDENCES_SUCCESS';
export const GET_INCIDENCES_ERROR = 'GET_INCIDENCES_ERROR';

/* PREREQUISITE */

export const LIST_PREREQUISITES_REQUEST = 'LIST_PREREQUISITES_REQUEST';
export const LIST_PREREQUISITES_SUCCESS = 'LIST_PREREQUISITES_SUCCESS';
export const LIST_PREREQUISITES_ERROR = 'LIST_PREREQUISITES_ERROR';
export const GET_PREREQUISITES_REQUEST = 'GET_PREREQUISITES_REQUEST';
export const GET_PREREQUISITES_SUCCESS = 'GET_PREREQUISITES_SUCCESS';
export const GET_PREREQUISITES_ERROR = 'GET_PREREQUISITES_ERROR';
export const ADD_PREREQUISITES_REQUEST = 'ADD_PREREQUISITES_REQUEST';
export const ADD_PREREQUISITES_SUCCESS = 'ADD_PREREQUISITES_SUCCESS';
export const ADD_PREREQUISITES_ERROR = 'ADD_PREREQUISITES_ERROR';
export const EDIT_PREREQUISITES_REQUEST = 'EDIT_PREREQUISITES_REQUEST';
export const EDIT_PREREQUISITES_SUCCESS = 'EDIT_PREREQUISITES_SUCCESS';
export const EDIT_PREREQUISITES_ERROR = 'EDIT_PREREQUISITES_ERROR';

/* SUBAREAS */

export const LIST_SUBAREA_REQUEST = 'LIST_SUBAREA_REQUEST';
export const LIST_SUBAREA_SUCCESS = 'LIST_SUBAREA_SUCCESS';
export const LIST_SUBAREA_ERROR = 'LIST_SUBAREA_ERROR';
export const GET_SUBAREA_REQUEST = 'GET_SUBAREA_REQUEST';
export const GET_SUBAREA_SUCCESS = 'GET_SUBAREA_SUCCESS';
export const GET_SUBAREA_ERROR = 'GET_SUBAREA_ERROR';
export const ADD_SUBAREA_REQUEST = 'ADD_SUBAREA_REQUEST';
export const ADD_SUBAREA_SUCCESS = 'ADD_SUBAREA_SUCCESS';
export const ADD_SUBAREA_ERROR = 'ADD_SUBAREA_ERROR';
export const EDIT_SUBAREA_REQUEST = 'EDIT_SUBAREA_REQUEST';
export const EDIT_SUBAREA_SUCCESS = 'EDIT_SUBAREA_SUCCESS';
export const EDIT_SUBAREA_ERROR = 'EDIT_SUBAREA_ERROR';
export const EDIT_ACTIVE_SUBAREA_REQUEST = 'EDIT_ACTIVE_SUBAREA_REQUEST';
export const EDIT_ACTIVE_SUBAREA_SUCCESS = 'EDIT_ACTIVE_SUBAREA_SUCCESS';
export const EDIT_ACTIVE_SUBAREA_ERROR = 'EDIT_ACTIVE_SUBAREA_ERROR';
export const IMPORT_SUBAREA_REQUEST = 'IMPORT_SUBAREA_REQUEST';
export const IMPORT_SUBAREA_SUCCESS = 'IMPORT_SUBAREA_SUCCESS';
export const IMPORT_SUBAREA_ERROR = 'IMPORT_SUBAREA_ERROR';

/* CRITERIONS */

export const LIST_CRITERION_REQUEST = 'LIST_CRITERION_REQUEST';
export const LIST_CRITERION_SUCCESS = 'LIST_CRITERION_SUCCESS';
export const LIST_CRITERION_ERROR = 'LIST_CRITERION_ERROR';
export const GET_CRITERION_REQUEST = 'GET_CRITERION_REQUEST';
export const GET_CRITERION_SUCCESS = 'GET_CRITERION_SUCCESS';
export const GET_CRITERION_ERROR = 'GET_CRITERION_ERROR';
export const ADD_CRITERION_REQUEST = 'ADD_CRITERION_REQUEST';
export const ADD_CRITERION_SUCCESS = 'ADD_CRITERION_SUCCESS';
export const ADD_CRITERION_ERROR = 'ADD_CRITERION_ERROR';
export const EDIT_CRITERION_REQUEST = 'EDIT_CRITERION_REQUEST';
export const EDIT_CRITERION_SUCCESS = 'EDIT_CRITERION_SUCCESS';
export const EDIT_CRITERION_ERROR = 'EDIT_CRITERION_ERROR';

/* CRITERIONS_GROUPS */

export const LIST_CRITERIONS_GROUPS_REQUEST = 'LIST_CRITERIONS_GROUPS_REQUEST';
export const LIST_CRITERIONS_GROUPS_SUCCESS = 'LIST_CRITERIONS_GROUPS_SUCCESS';
export const LIST_CRITERIONS_GROUPS_ERROR = 'LIST_CRITERIONS_GROUPS_ERROR';
export const GET_CRITERIONS_GROUPS_REQUEST = 'GET_CRITERIONS_GROUPS_REQUEST';
export const GET_CRITERIONS_GROUPS_SUCCESS = 'GET_CRITERIONS_GROUPS_SUCCESS';
export const GET_CRITERIONS_GROUPS_ERROR = 'GET_CRITERIONS_GROUPS_ERROR';
export const ADD_CRITERIONS_GROUPS_REQUEST = 'ADD_CRITERIONS_GROUPS_REQUEST';
export const ADD_CRITERIONS_GROUPS_SUCCESS = 'ADD_CRITERIONS_GROUPS_SUCCESS';
export const ADD_CRITERIONS_GROUPS_ERROR = 'ADD_CRITERIONS_GROUPS_ERROR';
export const EDIT_CRITERIONS_GROUPS_REQUEST = 'EDIT_CRITERIONS_GROUPS_REQUEST';
export const EDIT_CRITERIONS_GROUPS_SUCCESS = 'EDIT_CRITERIONS_GROUPS_SUCCESS';
export const EDIT_CRITERIONS_GROUPS_ERROR = 'EDIT_CRITERIONS_GROUPS_ERROR';
export const EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_REQUEST =
  'EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_REQUEST';
export const EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_SUCCESS =
  'EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_SUCCESS';
export const EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_ERROR =
  'EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_ERROR';
export const GET_CRITERIONS_GROUPS_TYPES_REQUEST =
  'GET_CRITERIONS_GROUPS_TYPES_REQUEST';
export const GET_CRITERIONS_GROUPS_TYPES_SUCCESS =
  'GET_CRITERIONS_GROUPS_TYPES_SUCCESS';
export const GET_CRITERIONS_GROUPS_TYPES_ERROR =
  'GET_CRITERIONS_GROUPS_TYPES_ERROR';

/* CYCLES */

export const LIST_CYCLE_REQUEST = 'LIST_CYCLE_REQUEST';
export const LIST_CYCLE_SUCCESS = 'LIST_CYCLE_SUCCESS';
export const LIST_CYCLE_ERROR = 'LIST_CYCLE_ERROR';

/* SCORES */

export const LIST_SCORE_REQUEST = 'LIST_SCORE_REQUEST';
export const LIST_SCORE_SUCCESS = 'LIST_SCORE_SUCCESS';
export const LIST_SCORE_ERROR = 'LIST_SCORE_ERROR';

/* CLASS_CYCLES */

export const LIST_CLASS_CYCLE_REQUEST = 'LIST_CLASS_CYCLE_REQUEST';
export const LIST_CLASS_CYCLE_SUCCESS = 'LIST_CLASS_CYCLE_SUCCESS';
export const LIST_CLASS_CYCLE_ERROR = 'LIST_CLASS_CYCLE_ERROR';
export const ADD_CLASS_CYCLE_REQUEST = 'ADD_CLASS_CYCLE_REQUEST';
export const ADD_CLASS_CYCLE_SUCCESS = 'ADD_CLASS_CYCLE_SUCCESS';
export const ADD_CLASS_CYCLE_ERROR = 'ADD_CLASS_CYCLE_ERROR';
export const EDIT_CLASS_CYCLE_REQUEST = 'EDIT_CLASS_CYCLE_REQUEST';
export const EDIT_CLASS_CYCLE_SUCCESS = 'EDIT_CLASS_CYCLE_SUCCESS';
export const EDIT_CLASS_CYCLE_ERROR = 'EDIT_CLASS_CYCLE_ERROR';

/* CLASS_TYPES */

export const LIST_CLASS_TYPE_REQUEST = 'LIST_CLASS_TYPE_REQUEST';
export const LIST_CLASS_TYPE_SUCCESS = 'LIST_CLASS_TYPE_SUCCESS';
export const LIST_CLASS_TYPE_ERROR = 'LIST_CLASS_TYPE_ERROR';

/* CLASS_ASSESSMENT_TYPES */

export const LIST_CLASS_ASSESSMENT_TYPE_REQUEST =
  'LIST_CLASS_ASSESSMENT_TYPE_REQUEST';
export const LIST_CLASS_ASSESSMENT_TYPE_SUCCESS =
  'LIST_CLASS_ASSESSMENT_TYPE_SUCCESS';
export const LIST_CLASS_ASSESSMENT_TYPE_ERROR =
  'LIST_CLASS_ASSESSMENT_TYPE_ERROR';

/* CONFIGURATIONS */

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PROFILE_IMAGE_REQUEST = 'CHANGE_PROFILE_IMAGE_REQUEST';
export const CHANGE_PROFILE_IMAGE_SUCCESS = 'CHANGE_PROFILE_IMAGE_SUCCESS';
export const CHANGE_PROFILE_IMAGE_ERROR = 'CHANGE_PROFILE_IMAGE_ERROR';
export const GET_CONFIGURATIONS_REQUEST = 'GET_CONFIGURATIONS_REQUEST';
export const GET_CONFIGURATIONS_SUCCESS = 'GET_CONFIGURATIONS_SUCCESS';
export const GET_CONFIGURATIONS_ERROR = 'GET_CONFIGURATIONS_ERROR';
export const RESET_DB_TESTS_REQUEST = 'RESET_DB_TESTS_REQUEST';
export const RESET_DB_TESTS_SUCCESS = 'RESET_DB_TESTS_SUCCESS';
export const RESET_DB_TESTS_ERROR = 'RESET_DB_TESTS_ERROR';

/* NEWS */

export const LIST_NEWS_REQUEST = 'LIST_NEWS_REQUEST';
export const LIST_NEWS_SUCCESS = 'LIST_NEWS_SUCCESS';
export const LIST_NEWS_ERROR = 'LIST_NEWS_ERROR';
export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_ERROR = 'GET_NEWS_ERROR';
export const GET_NEWS_DESCRIPTION_REQUEST = 'GET_NEWS_DESCRIPTION_REQUEST';
export const GET_NEWS_DESCRIPTION_SUCCESS = 'GET_NEWS_DESCRIPTION_SUCCESS';
export const GET_NEWS_DESCRIPTION_ERROR = 'GET_NEWS_DESCRIPTION_ERROR';
export const ADD_NEWS_REQUEST = 'ADD_NEWS_REQUEST';
export const ADD_NEWS_SUCCESS = 'ADD_NEWS_SUCCESS';
export const ADD_NEWS_ERROR = 'ADD_NEWS_ERROR';
export const EDIT_NEWS_REQUEST = 'EDIT_NEWS_REQUEST';
export const EDIT_NEWS_SUCCESS = 'EDIT_NEWS_SUCCESS';
export const EDIT_NEWS_ERROR = 'EDIT_NEWS_ERROR';
export const EDIT_ACTIVE_NEWS_REQUEST = 'EDIT_ACTIVE_NEWS_REQUEST';
export const EDIT_ACTIVE_NEWS_SUCCESS = 'EDIT_ACTIVE_NEWS_SUCCESS';
export const EDIT_ACTIVE_NEWS_ERROR = 'EDIT_ACTIVE_NEWS_ERROR';
export const ADD_ACTIVITY_NEWS_REQUEST = 'ADD_ACTIVITY_NEWS_REQUEST';
export const ADD_ACTIVITY_NEWS_SUCCESS = 'ADD_ACTIVITY_NEWS_SUCCESS';
export const ADD_ACTIVITY_NEWS_ERROR = 'ADD_ACTIVITY_NEWS_ERROR';
export const EDIT_ACTIVITY_NEWS_REQUEST = 'EDIT_ACTIVITY_NEWS_REQUEST';
export const EDIT_ACTIVITY_NEWS_SUCCESS = 'EDIT_ACTIVITY_NEWS_SUCCESS';
export const EDIT_ACTIVITY_NEWS_ERROR = 'EDIT_ACTIVITY_NEWS_ERROR';
export const EDIT_FIXED_NEWS_REQUEST = 'EDIT_FIXED_NEWS_REQUEST';
export const EDIT_FIXED_NEWS_SUCCESS = 'EDIT_FIXED_NEWS_SUCCESS';
export const EDIT_FIXED_NEWS_ERROR = 'EDIT_FIXED_NEWS_ERROR';

export const REMOVE_FILE_NEW_REQUEST = 'REMOVE_FILE_NEW_REQUEST';
export const REMOVE_FILE_NEW_SUCCESS = 'REMOVE_FILE_NEW_SUCCESS';
export const REMOVE_FILE_NEW_ERROR = 'REMOVE_FILE_NEW_ERROR';

export const LIST_FEEDBACK_REQUEST = 'LIST_FEEDBACK_REQUEST';
export const LIST_FEEDBACK_SUCCESS = 'LIST_FEEDBACK_SUCCESS';
export const LIST_FEEDBACK_ERROR = 'LIST_FEEDBACK_ERROR';
export const ADD_OR_EDIT_FEEDBACK_REQUEST = 'ADD_OR_EDIT_FEEDBACK_REQUEST';
export const ADD_OR_EDIT_FEEDBACK_SUCCESS = 'ADD_OR_EDIT_FEEDBACK_SUCCESS';
export const ADD_OR_EDIT_FEEDBACK_ERROR = 'ADD_OR_EDIT_FEEDBACK_ERROR';
export const REMOVE_FILE_FEEDBACK_REQUEST = 'REMOVE_FILE_FEEDBACK_REQUEST';
export const REMOVE_FILE_FEEDBACK_SUCCESS = 'REMOVE_FILE_FEEDBACK_SUCCESS';
export const REMOVE_FILE_FEEDBACK_ERROR = 'REMOVE_FILE_FEEDBACK_ERROR';
export const REMOVE_FEEDBACK_REQUEST = 'REMOVE_FEEDBACK_REQUEST';
export const REMOVE_FEEDBACK_SUCCESS = 'REMOVE_FEEDBACK_SUCCESS';
export const REMOVE_FEEDBACK_ERROR = 'REMOVE_FEEDBACK_ERROR';

/* NEW_TYPES */

export const LIST_NEW_TYPE_REQUEST = 'LIST_NEW_TYPE_REQUEST';
export const LIST_NEW_TYPE_SUCCESS = 'LIST_NEW_TYPE_SUCCESS';
export const LIST_NEW_TYPE_ERROR = 'LIST_NEW_TYPE_ERROR';

/* EXAM PRACTICES */
export const LIST_EXAM_PRACTICES_REQUEST = 'LIST_EXAM_PRACTICES_REQUEST';
export const LIST_EXAM_PRACTICES_SUCCESS = 'LIST_EXAM_PRACTICES_SUCCESS';
export const LIST_EXAM_PRACTICES_ERROR = 'LIST_EXAM_PRACTICES_ERROR';
export const LIST_EXAM_PRACTICES_SUBJECTS_REQUEST =
  'LIST_EXAM_PRACTICES_SUBJECTS_REQUEST';
export const LIST_EXAM_PRACTICES_SUBJECTS_SUCCESS =
  'LIST_EXAM_PRACTICES_SUBJECTS_SUCCESS';
export const LIST_EXAM_PRACTICES_SUBJECTS_ERROR =
  'LIST_EXAM_PRACTICES_SUBJECTS_ERROR';
export const VINCULATE_EXAM_PRACTICES_SUBJECTS_REQUEST =
  'VINCULATE_EXAM_PRACTICES_SUBJECTS_REQUEST';
export const VINCULATE_EXAM_PRACTICES_SUBJECTS_SUCCESS =
  'VINCULATE_EXAM_PRACTICES_SUBJECTS_SUCCESS';
export const VINCULATE_EXAM_PRACTICES_SUBJECTS_ERROR =
  'VINCULATE_EXAM_PRACTICES_SUBJECTS_ERROR';
export const GET_EXAM_PRACTICES_REQUEST = 'GET_EXAM_PRACTICES_REQUEST';
export const GET_EXAM_PRACTICES_SUCCESS = 'GET_EXAM_PRACTICES_SUCCESS';
export const GET_EXAM_PRACTICES_ERROR = 'GET_EXAM_PRACTICES_ERROR';
export const GET_EXAM_PRACTICES_DESCRIPTION_REQUEST =
  'GET_EXAM_PRACTICES_DESCRIPTION_REQUEST';
export const GET_EXAM_PRACTICES_DESCRIPTION_SUCCESS =
  'GET_EXAM_PRACTICES_DESCRIPTION_SUCCESS';
export const GET_EXAM_PRACTICES_DESCRIPTION_ERROR =
  'GET_EXAM_PRACTICES_DESCRIPTION_ERROR';
export const ADD_EXAM_PRACTICES_REQUEST = 'ADD_EXAM_PRACTICES_REQUEST';
export const ADD_EXAM_PRACTICES_SUCCESS = 'ADD_EXAM_PRACTICES_SUCCESS';
export const ADD_EXAM_PRACTICES_ERROR = 'ADD_EXAM_PRACTICES_ERROR';
export const EDIT_EXAM_PRACTICES_REQUEST = 'EDIT_EXAM_PRACTICES_REQUEST';
export const EDIT_EXAM_PRACTICES_SUCCESS = 'EDIT_EXAM_PRACTICES_SUCCESS';
export const EDIT_EXAM_PRACTICES_ERROR = 'EDIT_EXAM_PRACTICES_ERROR';
export const EDIT_ACTIVE_EXAM_PRACTICES_REQUEST =
  'EDIT_ACTIVE_EXAM_PRACTICES_REQUEST';
export const EDIT_ACTIVE_EXAM_PRACTICES_SUCCESS =
  'EDIT_ACTIVE_EXAM_PRACTICES_SUCCESS';
export const EDIT_ACTIVE_EXAM_PRACTICES_ERROR =
  'EDIT_ACTIVE_EXAM_PRACTICES_ERROR';
export const LIST_EXAM_PRACTICES_REPORTS_REQUEST =
  'LIST_EXAM_PRACTICES_REPORTS_REQUEST';
export const LIST_EXAM_PRACTICES_REPORTS_SUCCESS =
  'LIST_EXAM_PRACTICES_REPORTS_SUCCESS';
export const LIST_EXAM_PRACTICES_REPORTS_ERROR =
  'LIST_EXAM_PRACTICES_REPORTS_ERROR';
export const EXPORT_EXAM_PRACTICES_REPORTS_REQUEST =
  'EXPORT_EXAM_PRACTICES_REPORTS_REQUEST';
export const EXPORT_EXAM_PRACTICES_REPORTS_SUCCESS =
  'EXPORT_EXAM_PRACTICES_REPORTS_SUCCESS';
export const EXPORT_EXAM_PRACTICES_REPORTS_ERROR =
  'EXPORT_EXAM_PRACTICES_REPORTS_ERROR';
export const LIST_EXAM_PRACTICES_TYPES_REQUEST =
  'LIST_EXAM_PRACTICES_TYPES_REQUEST';
export const LIST_EXAM_PRACTICES_TYPES_SUCCESS =
  'LIST_EXAM_PRACTICES_TYPES_SUCCESS';
export const LIST_EXAM_PRACTICES_TYPES_ERROR =
  'LIST_EXAM_PRACTICES_TYPES_ERROR';
export const IMPORT_EXAM_PRACTICES_REQUEST = 'IMPORT_EXAM_PRACTICES_REQUEST';
export const IMPORT_EXAM_PRACTICES_SUCCESS = 'IMPORT_EXAM_PRACTICES_SUCCESS';
export const IMPORT_EXAM_PRACTICES_ERROR = 'IMPORT_EXAM_PRACTICES_ERROR';
export const REMOVE_IMPORT_EXAM_PRACTICES_REQUEST =
  'REMOVE_IMPORT_EXAM_PRACTICES_REQUEST';
export const REMOVE_IMPORT_EXAM_PRACTICES_SUCCESS =
  'REMOVE_IMPORT_EXAM_PRACTICES_SUCCESS';
export const REMOVE_IMPORT_EXAM_PRACTICES_ERROR =
  'REMOVE_IMPORT_EXAM_PRACTICES_ERROR';
export const VINCULATE_EXAM_PRACTICES_QUESTIONS_REQUEST =
  'VINCULATE_EXAM_PRACTICES_QUESTIONS_REQUEST';
export const VINCULATE_EXAM_PRACTICES_QUESTIONS_SUCCESS =
  'VINCULATE_EXAM_PRACTICES_QUESTIONS_SUCCESS';
export const VINCULATE_EXAM_PRACTICES_QUESTIONS_ERROR =
  'VINCULATE_EXAM_PRACTICES_QUESTIONS_ERROR';
export const SEND_EXAM_PRACTICE_REQUEST = 'SEND_EXAM_PRACTICE_REQUEST';
export const SEND_EXAM_PRACTICE_SUCCESS = 'SEND_EXAM_PRACTICE_SUCCESS';
export const SEND_EXAM_PRACTICE_ERROR = 'SEND_EXAM_PRACTICE_ERROR';

/* EXAM EXTERNALS */
export const LIST_EXAM_EXTERNALS_REQUEST = 'LIST_EXAM_EXTERNALS_REQUEST';
export const LIST_EXAM_EXTERNALS_SUCCESS = 'LIST_EXAM_EXTERNALS_SUCCESS';
export const LIST_EXAM_EXTERNALS_ERROR = 'LIST_EXAM_EXTERNALS_ERROR';
export const ADD_EXAM_EXTERNALS_REQUEST = 'ADD_EXAM_EXTERNALS_REQUEST';
export const ADD_EXAM_EXTERNALS_SUCCESS = 'ADD_EXAM_EXTERNALS_SUCCESS';
export const ADD_EXAM_EXTERNALS_ERROR = 'ADD_EXAM_EXTERNALS_ERROR';

/* QUESTION_DB */
export const LIST_QUESTION_DB_SUBJECTS_REQUEST =
  'LIST_QUESTION_DB_SUBJECTS_REQUEST';
export const LIST_QUESTION_DB_SUBJECTS_SUCCESS =
  'LIST_QUESTION_DB_SUBJECTS_SUCCESS';
export const LIST_QUESTION_DB_SUBJECTS_ERROR =
  'LIST_QUESTION_DB_SUBJECTS_ERROR';
export const LIST_QUESTION_DB_INSTITUTIONS_REQUEST =
  'LIST_QUESTION_DB_INSTITUTIONS_REQUEST';
export const LIST_QUESTION_DB_INSTITUTIONS_SUCCESS =
  'LIST_QUESTION_DB_INSTITUTIONS_SUCCESS';
export const LIST_QUESTION_DB_INSTITUTIONS_ERROR =
  'LIST_QUESTION_DB_INSTITUTIONS_ERROR';
export const LIST_QUESTION_DB_YEARS_REQUEST = 'LIST_QUESTION_DB_YEARS_REQUEST';
export const LIST_QUESTION_DB_YEARS_SUCCESS = 'LIST_QUESTION_DB_YEARS_SUCCESS';
export const LIST_QUESTION_DB_YEARS_ERROR = 'LIST_QUESTION_DB_YEARS_ERROR';
export const LIST_QUESTION_DB_SKILLS_REQUEST =
  'LIST_QUESTION_DB_SKILLS_REQUEST';
export const LIST_QUESTION_DB_SKILLS_SUCCESS =
  'LIST_QUESTION_DB_SKILLS_SUCCESS';
export const LIST_QUESTION_DB_SKILLS_ERROR = 'LIST_QUESTION_DB_SKILLS_ERROR';
export const LIST_QUESTION_DB_CATEGORIES_REQUEST =
  'LIST_QUESTION_DB_CATEGORIES_REQUEST';
export const LIST_QUESTION_DB_CATEGORIES_SUCCESS =
  'LIST_QUESTION_DB_CATEGORIES_SUCCESS';
export const LIST_QUESTION_DB_CATEGORIES_ERROR =
  'LIST_QUESTION_DB_CATEGORIES_ERROR';
export const LIST_QUESTION_DB_QUESTIONS_REQUEST =
  'LIST_QUESTION_DB_QUESTIONS_REQUEST';
export const LIST_QUESTION_DB_QUESTIONS_SUCCESS =
  'LIST_QUESTION_DB_QUESTIONS_SUCCESS';
export const LIST_QUESTION_DB_QUESTIONS_ERROR =
  'LIST_QUESTION_DB_QUESTIONS_ERROR';
export const LIST_CATEGORIES_CHILDRENS_REQUEST =
  'LIST_CATEGORIES_CHILDRENS_REQUEST';
export const LIST_CATEGORIES_CHILDRENS_SUCCESS =
  'LIST_CATEGORIES_CHILDRENS_SUCCESS';
export const LIST_CATEGORIES_CHILDRENS_ERROR =
  'LIST_CATEGORIES_CHILDRENS_ERROR';
export const SET_FIRST_CATEGORY = 'SET_FIRST_CATEGORY';
export const SET_EMPTY_CATEGORY = 'SET_EMPTY_CATEGORY';
export const RESET_FIRST_CATEGORY = 'RESET_FIRST_CATEGORY';
export const CHECK_QUESTION_REQUEST = 'CHECK_QUESTION_REQUEST';
export const CHECK_INITIAL_QUESTION_REQUEST = 'CHECK_INITIAL_QUESTION_REQUEST';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';

/* DASHBOARD METRICS */

export const GET_METRICS_REQUEST = 'GET_METRICS_REQUEST';
export const GET_METRICS_SUCCESS = 'GET_METRICS_SUCCESS';
export const GET_METRICS_ERROR = 'GET_METRICS_ERROR';

export const GET_SUBJECT_CATEGORIZATION_REQUEST =
  'GET_SUBJECT_CATEGORIZATION_REQUEST';
export const GET_SUBJECT_CATEGORIZATION_SUCCESS =
  'GET_SUBJECT_CATEGORIZATION_SUCCESS';
export const GET_SUBJECT_CATEGORIZATION_ERROR =
  'GET_SUBJECT_CATEGORIZATION_ERROR';

export const GET_RANK_TIME_REQUEST = 'GET_RANK_TIME_REQUEST';
export const GET_RANK_TIME_SUCCESS = 'GET_RANK_TIME_SUCCESS';
export const GET_RANK_TIME_ERROR = 'GET_RANK_TIME_ERROR';
export const GET_RANK_ACTIVITIES_REQUEST = 'GET_RANK_ACTIVITIES_REQUEST';
export const GET_RANK_ACTIVITIES_SUCCESS = 'GET_RANK_ACTIVITIES_SUCCESS';
export const GET_RANK_ACTIVITIES_ERROR = 'GET_RANK_ACTIVITIES_ERROR';
export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_ERROR = 'GET_COMMENTS_ERROR';
export const GET_WEEKLY_PROGRESS_REQUEST = 'GET_WEEKLY_PROGRESS_REQUEST';
export const GET_WEEKLY_PROGRESS_SUCCESS = 'GET_WEEKLY_PROGRESS_SUCCESS';
export const GET_WEEKLY_PROGRESS_ERROR = 'GET_WEEKLY_PROGRESS_ERROR';
export const GET_COMMUNITY_STATUS_REQUEST = 'GET_COMMUNITY_STATUS_REQUEST';
export const GET_COMMUNITY_STATUS_SUCCESS = 'GET_COMMUNITY_STATUS_SUCCESS';
export const GET_COMMUNITY_STATUS_ERROR = 'GET_COMMUNITY_STATUS_ERROR';
export const DELETE_COMMENTS_REQUEST = 'DELETE_COMMENTS_REQUEST';
export const DELETE_COMMENTS_SUCCESS = 'DELETE_COMMENTS_SUCCESS';
export const DELETE_COMMENTS_ERROR = 'DELETE_COMMENTS_ERROR';

export const GET_STUDENT_COMMITMENT_ROUTINE_REQUEST =
  'GET_STUDENT_COMMITMENT_ROUTINE_REQUEST';
export const GET_STUDENT_COMMITMENT_ROUTINE_SUCCESS =
  'GET_STUDENT_COMMITMENT_ROUTINE_SUCCESS';
export const GET_STUDENT_COMMITMENT_ROUTINE_ERROR =
  'GET_STUDENT_COMMITMENT_ROUTINE_ERROR';

/* SUBJECT PERFORMANCE REPORT */

export const LIST_SUBJECT_PERFORMANCE_REQUEST =
  'LIST_SUBJECT_PERFORMANCE_REQUEST';
export const LIST_SUBJECT_PERFORMANCE_SUCCESS =
  'LIST_SUBJECT_PERFORMANCE_SUCCESS';
export const LIST_SUBJECT_PERFORMANCE_ERROR = 'LIST_SUBJECT_PERFORMANCE_ERROR';

/* STUDY DIAGNOSIS REPORT */

export const LIST_STUDY_DIAGNOSIS_REQUEST = 'LIST_STUDY_DIAGNOSIS_REQUEST';
export const LIST_STUDY_DIAGNOSIS_SUCCESS = 'LIST_STUDY_DIAGNOSIS_SUCCESS';
export const LIST_STUDY_DIAGNOSIS_ERROR = 'LIST_STUDY_DIAGNOSIS_ERROR';

/* WRITINGS */
export const LIST_WRITINGS_REQUEST = 'LIST_WRITINGS_REQUEST';
export const LIST_WRITINGS_SUCCESS = 'LIST_WRITINGS_SUCCESS';
export const LIST_WRITINGS_ERROR = 'LIST_WRITINGS_ERROR';
export const GET_WRITINGS_REQUEST = 'GET_WRITINGS_REQUEST';
export const GET_WRITINGS_SUCCESS = 'GET_WRITINGS_SUCCESS';
export const GET_WRITINGS_ERROR = 'GET_WRITINGS_ERROR';
export const ADD_WRITINGS_REQUEST = 'ADD_WRITINGS_REQUEST';
export const ADD_WRITINGS_SUCCESS = 'ADD_WRITINGS_SUCCESS';
export const ADD_WRITINGS_ERROR = 'ADD_WRITINGS_ERROR';
export const EDIT_WRITINGS_REQUEST = 'EDIT_WRITINGS_REQUEST';
export const EDIT_WRITINGS_SUCCESS = 'EDIT_WRITINGS_SUCCESS';
export const EDIT_WRITINGS_ERROR = 'EDIT_WRITINGS_ERROR';
export const EDIT_ACTIVE_WRITINGS_REQUEST = 'EDIT_ACTIVE_WRITINGS_REQUEST';
export const EDIT_ACTIVE_WRITINGS_SUCCESS = 'EDIT_ACTIVE_WRITINGS_SUCCESS';
export const EDIT_ACTIVE_WRITINGS_ERROR = 'EDIT_ACTIVE_WRITINGS_ERROR';
export const SEND_WRITINGS_REQUEST = 'SEND_WRITINGS_REQUEST';
export const SEND_WRITINGS_SUCCESS = 'SEND_WRITINGS_SUCCESS';
export const SEND_WRITINGS_ERROR = 'SEND_WRITINGS_ERROR';
export const EDIT_WRITINGS_VINCULATE_CRITERIONS_REQUEST =
  'EDIT_WRITINGS_VINCULATE_CRITERIONS_REQUEST';
export const EDIT_WRITINGS_VINCULATE_CRITERIONS_SUCCESS =
  'EDIT_WRITINGS_VINCULATE_CRITERIONS_SUCCESS';
export const EDIT_WRITINGS_VINCULATE_CRITERIONS_ERROR =
  'EDIT_WRITINGS_VINCULATE_CRITERIONS_ERROR';
export const IMPORT_WRITINGS_REQUEST = 'IMPORT_WRITINGS_REQUEST';
export const IMPORT_WRITINGS_SUCCESS = 'IMPORT_WRITINGS_SUCCESS';
export const IMPORT_WRITINGS_ERROR = 'IMPORT_WRITINGS_ERROR';
export const REMOVE_IMPORT_WRITINGS_REQUEST = 'REMOVE_IMPORT_WRITINGS_REQUEST';
export const REMOVE_IMPORT_WRITINGS_SUCCESS = 'REMOVE_IMPORT_WRITINGS_SUCCESS';
export const REMOVE_IMPORT_WRITINGS_ERROR = 'REMOVE_IMPORT_WRITINGS_ERROR';
export const LIST_WRITINGS_REPORTS_REQUEST = 'LIST_WRITINGS_REPORTS_REQUEST';
export const LIST_WRITINGS_REPORTS_SUCCESS = 'LIST_WRITINGS_REPORTS_SUCCESS';
export const LIST_WRITINGS_REPORTS_ERROR = 'LIST_WRITINGS_REPORTS_ERROR';
export const ADD_CRITERION_WRITINGS_REQUEST = 'ADD_CRITERION_WRITINGS_REQUEST';
export const ADD_CRITERION_WRITINGS_SUCCESS = 'ADD_CRITERION_WRITINGS_SUCCESS';
export const ADD_CRITERION_WRITINGS_ERROR = 'ADD_CRITERION_WRITINGS_ERROR';
export const EDIT_CRITERION_WRITINGS_REQUEST =
  'EDIT_CRITERION_WRITINGS_REQUEST';
export const EDIT_CRITERION_WRITINGS_SUCCESS =
  'EDIT_CRITERION_WRITINGS_SUCCESS';
export const EDIT_CRITERION_WRITINGS_ERROR = 'EDIT_CRITERION_WRITINGS_ERROR';
export const RECUSE_WRITINGS_REQUEST = 'RECUSE_WRITINGS_REQUEST';
export const RECUSE_WRITINGS_SUCCESS = 'RECUSE_WRITINGS_SUCCESS';
export const RECUSE_WRITINGS_ERROR = 'RECUSE_WRITINGS_ERROR';
export const ACCEPT_WRITINGS_REQUEST = 'ACCEPT_WRITINGS_REQUEST';
export const ACCEPT_WRITINGS_SUCCESS = 'ACCEPT_WRITINGS_SUCCESS';
export const ACCEPT_WRITINGS_ERROR = 'ACCEPT_WRITINGS_ERROR';
export const SET_WRITINGS_MODAL_VALUES = 'SET_WRITINGS_MODAL_VALUES';
export const SEND_RATINGS_WRITINGS_REQUEST = 'SEND_RATINGS_WRITINGS_REQUEST';
export const SEND_RATINGS_WRITINGS_SUCCESS = 'SEND_RATINGS_WRITINGS_SUCCESS';
export const SEND_RATINGS_WRITINGS_ERROR = 'SEND_RATINGS_WRITINGS_ERROR';

/* EVENTS */

export const LIST_EVENTS_REQUEST = 'LIST_EVENTS_REQUEST';
export const LIST_EVENTS_SUCCESS = 'LIST_EVENTS_SUCCESS';
export const LIST_EVENTS_ERROR = 'LIST_EVENTS_ERROR';
export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR';
export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_ERROR = 'ADD_EVENT_ERROR';
export const EDIT_EVENT_REQUEST = 'EDIT_EVENT_REQUEST';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_ERROR = 'EDIT_EVENT_ERROR';
export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';
export const SET_EDITED = 'SET_EDITED';

/* EVENT_REPEATS */

export const LIST_EVENT_REPEATS_REQUEST = 'LIST_EVENT_REPEATS_REQUEST';
export const LIST_EVENT_REPEATS_SUCCESS = 'LIST_EVENT_REPEATS_SUCCESS';
export const LIST_EVENT_REPEATS_ERROR = 'LIST_EVENT_REPEATS_ERROR';
