import {
  LIST_TEACHER_REQUEST,
  LIST_TEACHER_SUCCESS,
  LIST_TEACHER_ERROR,
  LIST_TEACHER_SUBJECT_REQUEST,
  LIST_TEACHER_SUBJECT_SUCCESS,
  LIST_TEACHER_SUBJECT_ERROR,
  GET_TEACHER_REQUEST,
  GET_TEACHER_SUCCESS,
  GET_TEACHER_ERROR,
  ADD_TEACHER_REQUEST,
  ADD_TEACHER_SUCCESS,
  ADD_TEACHER_ERROR,
  EDIT_TEACHER_REQUEST,
  EDIT_TEACHER_SUCCESS,
  EDIT_TEACHER_ERROR,
  EDIT_ACTIVE_TEACHER_REQUEST,
  EDIT_ACTIVE_TEACHER_SUCCESS,
  EDIT_ACTIVE_TEACHER_ERROR,
  LIST_TEACHER_ACTIVITY_REQUEST,
  LIST_TEACHER_ACTIVITY_SUCCESS,
  LIST_TEACHER_ACTIVITY_ERROR,
  CONCLUDE_TEACHER_ACTIVITY_REQUEST,
  CONCLUDE_TEACHER_ACTIVITY_SUCCESS,
  CONCLUDE_TEACHER_ACTIVITY_ERROR,
  IMPORT_TEACHER_ACTIVITY_REQUEST,
  IMPORT_TEACHER_ACTIVITY_SUCCESS,
  IMPORT_TEACHER_ACTIVITY_ERROR,
  REMOVE_IMPORT_TEACHER_ACTIVITY_REQUEST,
  REMOVE_IMPORT_TEACHER_ACTIVITY_SUCCESS,
  REMOVE_IMPORT_TEACHER_ACTIVITY_ERROR,
  EDIT_TEACHER_VINCULATE_SUBJECT_REQUEST,
  EDIT_TEACHER_VINCULATE_SUBJECT_SUCCESS,
  EDIT_TEACHER_VINCULATE_SUBJECT_ERROR,
  ADD_TEACHER_LINK_REQUEST,
  ADD_TEACHER_LINK_SUCCESS,
  ADD_TEACHER_LINK_ERROR,
  REMOVE_TEACHER_LINK_REQUEST,
  REMOVE_TEACHER_LINK_SUCCESS,
  REMOVE_TEACHER_LINK_ERROR,
} from '../actions';

export const getTeacherRequest = (id) => ({
  type: GET_TEACHER_REQUEST,
  payload: { id },
});
export const getTeacherSuccess = (teacher) => ({
  type: GET_TEACHER_SUCCESS,
  payload: teacher,
});
export const getTeacherError = (message) => ({
  type: GET_TEACHER_ERROR,
  payload: { message },
});

export const listTeacherRequest = () => ({
  type: LIST_TEACHER_REQUEST,
});
export const listTeacherSuccess = (teachers) => ({
  type: LIST_TEACHER_SUCCESS,
  payload: teachers,
});
export const listTeacherError = (message) => ({
  type: LIST_TEACHER_ERROR,
  payload: { message },
});

export const listTeacherSubjectRequest = (schoolId) => ({
  type: LIST_TEACHER_SUBJECT_REQUEST,
  payload: { schoolId },
});
export const listTeacherSubjectSuccess = (subjects) => ({
  type: LIST_TEACHER_SUBJECT_SUCCESS,
  payload: subjects,
});
export const listTeacherSubjectError = (message) => ({
  type: LIST_TEACHER_SUBJECT_ERROR,
  payload: { message },
});

export const addTeacherRequest = (teacher) => ({
  type: ADD_TEACHER_REQUEST,
  payload: { teacher },
});
export const addTeacherSuccess = (teacher) => ({
  type: ADD_TEACHER_SUCCESS,
  payload: teacher,
});
export const addTeacherError = (message) => ({
  type: ADD_TEACHER_ERROR,
  payload: { message },
});

export const editTeacherRequest = (teacher) => ({
  type: EDIT_TEACHER_REQUEST,
  payload: { teacher },
});
export const editTeacherSuccess = (teacher) => ({
  type: EDIT_TEACHER_SUCCESS,
  payload: { teacher },
});
export const editTeacherError = (message) => ({
  type: EDIT_TEACHER_ERROR,
  payload: { message },
});

export const editActiveTeacherRequest = (id, active) => ({
  type: EDIT_ACTIVE_TEACHER_REQUEST,
  payload: { id, active },
});
export const editActiveTeacherSuccess = (id, teacher) => ({
  type: EDIT_ACTIVE_TEACHER_SUCCESS,
  payload: { id, teacher },
});
export const editActiveTeacherError = (message) => ({
  type: EDIT_ACTIVE_TEACHER_ERROR,
  payload: { message },
});

export const listTeacherActivityRequest = (subjectId, cycleIs) => ({
  type: LIST_TEACHER_ACTIVITY_REQUEST,
  payload: { subjectId, cycleIs },
});
export const listTeacherActivitySuccess = (teachersActivities) => ({
  type: LIST_TEACHER_ACTIVITY_SUCCESS,
  payload: teachersActivities,
});
export const listTeacherActivityError = (message) => ({
  type: LIST_TEACHER_ACTIVITY_ERROR,
  payload: { message },
});

export const concludeTeacherActivityRequest = (
  subareaId,
  activityId,
  concludedAt,
  studyTimeframe
) => ({
  type: CONCLUDE_TEACHER_ACTIVITY_REQUEST,
  payload: { subareaId, activityId, concludedAt, studyTimeframe },
});
export const concludeTeacherActivitySuccess = (
  subareaId,
  activityId,
  concludedAt
) => ({
  type: CONCLUDE_TEACHER_ACTIVITY_SUCCESS,
  payload: { subareaId, activityId, concludedAt },
});
export const concludeTeacherActivityError = (
  subareaId,
  activityId,
  concludedAt
) => ({
  type: CONCLUDE_TEACHER_ACTIVITY_ERROR,
  payload: { subareaId, activityId, concludedAt },
});

export const importTeacherActivityRequest = (files) => ({
  type: IMPORT_TEACHER_ACTIVITY_REQUEST,
  payload: { files },
});
export const importTeacherActivitySuccess = (message) => ({
  type: IMPORT_TEACHER_ACTIVITY_SUCCESS,
  payload: message,
});
export const importTeacherActivityError = (message) => ({
  type: IMPORT_TEACHER_ACTIVITY_ERROR,
  payload: { message },
});

export const removeImportTeacherActivityRequest = (subareaId, id) => ({
  type: REMOVE_IMPORT_TEACHER_ACTIVITY_REQUEST,
  payload: { subareaId, id },
});
export const removeImportTeacherActivitySuccess = (subareaId, id) => ({
  type: REMOVE_IMPORT_TEACHER_ACTIVITY_SUCCESS,
  payload: { subareaId, id },
});
export const removeImportTeacherActivityError = (subareaId, id) => ({
  type: REMOVE_IMPORT_TEACHER_ACTIVITY_ERROR,
  payload: { subareaId, id },
});
export const editTeacherVinculateSubjectRequest = (id, subjectList) => ({
  type: EDIT_TEACHER_VINCULATE_SUBJECT_REQUEST,
  payload: { id, subjectList },
});
export const editTeacherVinculateSubjectSuccess = (message) => ({
  type: EDIT_TEACHER_VINCULATE_SUBJECT_SUCCESS,
  payload: { message },
});
export const editTeacherVinculateSubjectError = (message) => ({
  type: EDIT_TEACHER_VINCULATE_SUBJECT_ERROR,
  payload: { message },
});

export const addTeacherLinkRequest = (subareaId, link) => ({
  type: ADD_TEACHER_LINK_REQUEST,
  payload: { subareaId, link },
});
export const addTeacherLinkSuccess = (subareaId, link) => ({
  type: ADD_TEACHER_LINK_SUCCESS,
  payload: { subareaId, link },
});
export const addTeacherLinkError = (message) => ({
  type: ADD_TEACHER_LINK_ERROR,
  payload: { message },
});

export const removeTeacherLinkRequest = (subareaId, id) => ({
  type: REMOVE_TEACHER_LINK_REQUEST,
  payload: { subareaId, id },
});
export const removeTeacherLinkSuccess = (subareaId, id) => ({
  type: REMOVE_TEACHER_LINK_SUCCESS,
  payload: { subareaId, id },
});
export const removeTeacherLinkError = (message) => ({
  type: REMOVE_TEACHER_LINK_ERROR,
  payload: { message },
});
