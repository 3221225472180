import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import api from '../../../services/api';
import {
  confirmStudentAccountError,
  confirmStudentAccountSuccess,
  forgotStudentPasswordCheckTokenError,
  forgotStudentPasswordCheckTokenSuccess,
  resetStudentPasswordError,
  resetStudentPasswordSuccess
} from './actions';
import {
  CONFIRM_STUDENT_ACCOUNT_REQUEST,
  FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_REQUEST,
  RESET_STUDENT_PASSWORD
} from '../../actions';

const forgotStudentPasswordCheckTokenAsync = async (token) => {
  return api
    .post(`auth/check-token`, {
      token,
    })
    .then((response) => response)
    .catch((error) => error);
};

function* forgotStudentPasswordCheckToken({ payload }) {
  const { token } = payload;
  try {
    const res = yield call(forgotStudentPasswordCheckTokenAsync, token);
    if (!res.response) {
      yield put(forgotStudentPasswordCheckTokenSuccess(res.data.data.email));
    } else {
      yield put(forgotStudentPasswordCheckTokenError(res.response.data.error || res.response.data.message));
    }
  } catch (error) {
    yield put(forgotStudentPasswordCheckTokenError(error));
  }
}

export function* watchForgotStudentPasswordCheckToken() {
  yield takeEvery(
    FORGOT_STUDENT_PASSWORD_CHECK_TOKEN_REQUEST,
    forgotStudentPasswordCheckToken
  );
}

const resetStudentPasswordAsync = async (
  email,
  token,
  password,
  passwordConfirmation
) => {
  return api
    .post(`auth/reset`, {
      email,
      token,
      password,
      password_confirmation: passwordConfirmation,
    })
    .then((response) => response)
    .catch((error) => error);
};

function* resetStudentPassword({ payload }) {
  const { email, token, password, passwordConfirmation } = payload;
  try {
    const res = yield call(
      resetStudentPasswordAsync,
      email,
      decodeURIComponent(token),
      password,
      passwordConfirmation
    );
    if (!res.response) {
      yield put(resetStudentPasswordSuccess(res.data));
    } else {
      yield put(resetStudentPasswordError(res.response.data.error || res.response.data.message));
    }
  } catch (error) {
    yield put(resetStudentPasswordError(error));
  }
}

const confirmStudentAccountAsync = async (studentId, studentByApp) => {
  return api
    .patch(`/auth/${studentId}/confirm-account?studentByApp=${studentByApp}`)
    .then((response) => response)
    .catch((error) => error);
};

function* confirmStudentAccount({ payload }) {
  const { studentId, studentByApp } = payload;
  try {
    const res = yield call(confirmStudentAccountAsync, studentId, studentByApp);
    if (!res.response) {
      yield put(confirmStudentAccountSuccess(res.data.message));
    } else {
      yield put(confirmStudentAccountError(res.response.data.error));
    }
  } catch (error) {
    yield put(confirmStudentAccountError(error));
  }
}

export function* watchStudentResetPassword() {
  yield takeEvery(RESET_STUDENT_PASSWORD, resetStudentPassword);
}

export function* watchConfirmStudentAccount() {
  yield takeEvery(CONFIRM_STUDENT_ACCOUNT_REQUEST, confirmStudentAccount);
}

export default function* rootSaga() {
  yield all([
    fork(watchStudentResetPassword),
    fork(watchForgotStudentPasswordCheckToken),
    fork(watchConfirmStudentAccount),
  ]);
}
