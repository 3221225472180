import {
  LIST_ACTIVITY_REQUEST,
  LIST_ACTIVITY_SUCCESS,
  LIST_ACTIVITY_ERROR,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_ERROR,
  GET_ACTIVITY_EXAM_REQUEST,
  GET_ACTIVITY_EXAM_SUCCESS,
  GET_ACTIVITY_EXAM_ERROR,
  ADD_ACTIVITY_REQUEST,
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_ERROR,
  EDIT_ACTIVITY_REQUEST,
  EDIT_ACTIVITY_SUCCESS,
  EDIT_ACTIVITY_ERROR,
  EDIT_ACTIVE_ACTIVITY_REQUEST,
  EDIT_ACTIVE_ACTIVITY_SUCCESS,
  EDIT_ACTIVE_ACTIVITY_ERROR,
  ORDINATE_ACTIVITY_REQUEST,
  ORDINATE_ACTIVITY_SUCCESS,
  ORDINATE_ACTIVITY_ERROR,
} from '../actions';

export const getActivityRequest = (id) => ({
  type: GET_ACTIVITY_REQUEST,
  payload: { id },
});
export const getActivitySuccess = (activity) => ({
  type: GET_ACTIVITY_SUCCESS,
  payload: activity,
});
export const getActivityError = (message) => ({
  type: GET_ACTIVITY_ERROR,
  payload: { message },
});
export const getActivityExamRequest = (id) => ({
  type: GET_ACTIVITY_EXAM_REQUEST,
  payload: { id },
});
export const getActivityExamSuccess = (activity) => ({
  type: GET_ACTIVITY_EXAM_SUCCESS,
  payload: activity,
});
export const getActivityExamError = (message) => ({
  type: GET_ACTIVITY_EXAM_ERROR,
  payload: { message },
});

export const listActivityRequest = () => ({
  type: LIST_ACTIVITY_REQUEST,
});
export const listActivitySuccess = (activities) => ({
  type: LIST_ACTIVITY_SUCCESS,
  payload: activities,
});
export const listActivityError = (message) => ({
  type: LIST_ACTIVITY_ERROR,
  payload: { message },
});

export const addActivityRequest = (activity) => ({
  type: ADD_ACTIVITY_REQUEST,
  payload: { activity },
});
export const addActivitySuccess = (activity) => ({
  type: ADD_ACTIVITY_SUCCESS,
  payload: activity,
});
export const addActivityError = (message) => ({
  type: ADD_ACTIVITY_ERROR,
  payload: { message },
});

export const editActivityRequest = (activity) => ({
  type: EDIT_ACTIVITY_REQUEST,
  payload: { activity },
});
export const editActivitySuccess = (activity) => ({
  type: EDIT_ACTIVITY_SUCCESS,
  payload: { activity },
});
export const editActivityError = (message) => ({
  type: EDIT_ACTIVITY_ERROR,
  payload: { message },
});

export const editActiveActivityRequest = (id, active) => ({
  type: EDIT_ACTIVE_ACTIVITY_REQUEST,
  payload: { id, active },
});
export const editActiveActivitySuccess = (id, activity) => ({
  type: EDIT_ACTIVE_ACTIVITY_SUCCESS,
  payload: { id, activity },
});
export const editActiveActivityError = (message) => ({
  type: EDIT_ACTIVE_ACTIVITY_ERROR,
  payload: { message },
});

export const ordinateActivityRequest = (activity) => ({
  type: ORDINATE_ACTIVITY_REQUEST,
  payload: { activity },
});
export const ordinateActivitySuccess = (message) => ({
  type: ORDINATE_ACTIVITY_SUCCESS,
  payload: message,
});
export const ordinateActivityError = (message) => ({
  type: ORDINATE_ACTIVITY_ERROR,
  payload: { message },
});
