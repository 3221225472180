import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import { LIST_SUBJECT_PERFORMANCE_REQUEST } from '../actions';
import {
  listSubjectPerformanceSuccess,
  listSubjectPerformanceError,
} from './actions';

const getSubjectPerformanceAsync = (classId, cycleIs, filter) =>
  api
    .get(
      `students/reports/subject-performance/classes/${classId}/cycle/${cycleIs}${filter}`
    )
    .then((response) => response)
    .catch((error) => error);

function* listSubjectPerformance({ payload }) {
  try {
    const { classId, cycleIs, studentId } = payload;

    let filter = '';

    if (studentId) {
      filter = `/?studentId=${studentId}`;
    }

    const response = yield call(
      getSubjectPerformanceAsync,
      classId,
      cycleIs,
      filter
    );

    const { data, isClass } = response.data;

    yield put(listSubjectPerformanceSuccess(data, isClass));
  } catch (error) {
    yield put(listSubjectPerformanceError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_SUBJECT_PERFORMANCE_REQUEST, listSubjectPerformance),
  ]);
}
