import {
  LIST_SUBAREA_REQUEST,
  LIST_SUBAREA_SUCCESS,
  LIST_SUBAREA_ERROR,
  GET_SUBAREA_REQUEST,
  GET_SUBAREA_SUCCESS,
  GET_SUBAREA_ERROR,
  ADD_SUBAREA_REQUEST,
  ADD_SUBAREA_SUCCESS,
  ADD_SUBAREA_ERROR,
  EDIT_SUBAREA_REQUEST,
  EDIT_SUBAREA_SUCCESS,
  EDIT_SUBAREA_ERROR,
  EDIT_ACTIVE_SUBAREA_REQUEST,
  EDIT_ACTIVE_SUBAREA_SUCCESS,
  EDIT_ACTIVE_SUBAREA_ERROR,
  IMPORT_SUBAREA_REQUEST,
  IMPORT_SUBAREA_SUCCESS,
  IMPORT_SUBAREA_ERROR,
} from '../actions';

export const getSubareaRequest = (id) => ({
  type: GET_SUBAREA_REQUEST,
  payload: { id },
});
export const getSubareaSuccess = (subarea) => ({
  type: GET_SUBAREA_SUCCESS,
  payload: subarea,
});
export const getSubareaError = (message) => ({
  type: GET_SUBAREA_ERROR,
  payload: { message },
});

export const listSubareaRequest = () => ({
  type: LIST_SUBAREA_REQUEST,
});
export const listSubareaSuccess = (subareas) => ({
  type: LIST_SUBAREA_SUCCESS,
  payload: subareas,
});
export const listSubareaError = (message) => ({
  type: LIST_SUBAREA_ERROR,
  payload: { message },
});

export const addSubareaRequest = (subarea) => ({
  type: ADD_SUBAREA_REQUEST,
  payload: { subarea },
});
export const addSubareaSuccess = (subarea) => ({
  type: ADD_SUBAREA_SUCCESS,
  payload: subarea,
});
export const addSubareaError = (message) => ({
  type: ADD_SUBAREA_ERROR,
  payload: { message },
});

export const editSubareaRequest = (subarea) => ({
  type: EDIT_SUBAREA_REQUEST,
  payload: { subarea },
});
export const editSubareaSuccess = (subarea) => ({
  type: EDIT_SUBAREA_SUCCESS,
  payload: { subarea },
});
export const editSubareaError = (message) => ({
  type: EDIT_SUBAREA_ERROR,
  payload: { message },
});

export const editActiveSubareaRequest = (id, active) => ({
  type: EDIT_ACTIVE_SUBAREA_REQUEST,
  payload: { id, active },
});
export const editActiveSubareaSuccess = (id, subarea) => ({
  type: EDIT_ACTIVE_SUBAREA_SUCCESS,
  payload: { id, subarea },
});
export const editActiveSubareaError = (message) => ({
  type: EDIT_ACTIVE_SUBAREA_ERROR,
  payload: { message },
});

export const importSubareaRequest = (files) => ({
  type: IMPORT_SUBAREA_REQUEST,
  payload: { files },
});
export const importSubareaSuccess = (message) => ({
  type: IMPORT_SUBAREA_SUCCESS,
  payload: message,
});
export const importSubareaError = (message) => ({
  type: IMPORT_SUBAREA_ERROR,
  payload: { message },
});
