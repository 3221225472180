import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  ADD_JOURNEY_TRAILS_REQUEST,
  EDIT_JOURNEY_TRAILS_REQUEST,
  GET_JOURNEY_TRAILS_REQUEST,
  LIST_JOURNEY_TRAILS_REQUEST,
  ORDINATION_JOURNEY_TRAIL_WEEKS_REQUEST
} from '../actions';
import {
  listJourneyTrailsSuccess,
  listJourneyTrailsError,
  addJourneyTrailsError,
  addJourneyTrailsSuccess,
  editJourneyTrailsError,
  editJourneyTrailsSuccess,
  getJourneyTrailsError,
  getJourneyTrailsSuccess,
  ordinationJourneyTrailWeeksError,
  ordinationJourneyTrailWeeksSuccess
} from './actions';

function* listJourneyTrails() {
  try {
    const response = yield call(api.get, 'journey-trails');
    yield put(listJourneyTrailsSuccess(response.data.data));
  } catch (error) {
    yield put(listJourneyTrailsError(error));
  }
}

function* getJourneyTrailsById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `journey-trails/${id}`);
    yield put(getJourneyTrailsSuccess(response.data.data));
  } catch (error) {
    yield put(getJourneyTrailsError(error));
  }
}

const addJourneyTrailsAsync = async (completeJourneyTrails) =>
  api
    .post(`journey-trails`, {
      ...completeJourneyTrails,
    })
    .then((response) => response)
    .catch((error) => error);

const editJourneyTrailsAsync = async (completeJourneyTrails) =>
  api
    .put(`journey-trails/${completeJourneyTrails.id}`, {
      ...completeJourneyTrails,
    })
    .then((response) => response)
    .catch((error) => error);

function* addJourneyTrails({ payload }) {
  try {
    const { journeyTrails } = payload;
    const completeJourneyTrails = { ...journeyTrails, actionType: 'edit' };
    const res = yield call(addJourneyTrailsAsync, completeJourneyTrails);
    if (!res.response) {
      const responseValue = res.data.data;
      yield put(addJourneyTrailsSuccess(responseValue));
    } else {
      yield put(addJourneyTrailsError(res.response.data));
    }
  } catch (error) {
    yield put(addJourneyTrailsError(error));
  }
}

function* editJourneyTrails({ payload }) {
  try {
    const { journeyTrails } = payload;
    const completeJourneyTrails = { ...journeyTrails, actionType: 'edit' };
    const res = yield call(editJourneyTrailsAsync, completeJourneyTrails);
    if (!res.response) {
      const responseValue = res.data.data;
      yield put(editJourneyTrailsSuccess(responseValue));
    } else {
      yield put(editJourneyTrailsError(res.response.data));
    }
  } catch (error) {
    yield put(editJourneyTrailsError(error));
  }
}

const ordinationJourneyTrailWeeksAsync = async (completeJourneyTrails) =>
  api
    .post(`journey-trails/ordination-weeks`, {
      ...completeJourneyTrails,
    })
    .then((response) => response)
    .catch((error) => error);

function* ordinationJourneyTrailWeeks({ payload }) {
  try {
    const { journeyTrails } = payload;
    const completeJourneyTrails = { data: journeyTrails };
    const res = yield call(ordinationJourneyTrailWeeksAsync, completeJourneyTrails);
    if (!res.response) {
      yield put(ordinationJourneyTrailWeeksSuccess(res.data));
    } else {
      yield put(ordinationJourneyTrailWeeksError(res.response.data));
    }
  } catch (error) {
    yield put(ordinationJourneyTrailWeeksError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_JOURNEY_TRAILS_REQUEST, listJourneyTrails),
    takeLatest(GET_JOURNEY_TRAILS_REQUEST, getJourneyTrailsById),
    takeLatest(ADD_JOURNEY_TRAILS_REQUEST, addJourneyTrails),
    takeLatest(EDIT_JOURNEY_TRAILS_REQUEST, editJourneyTrails),
    takeLatest(ORDINATION_JOURNEY_TRAIL_WEEKS_REQUEST, ordinationJourneyTrailWeeks),
  ]);
}
