import {
  LIST_EVENTS_REQUEST,
  LIST_EVENTS_SUCCESS,
  LIST_EVENTS_ERROR,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_ERROR,
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_ERROR,
  EDIT_EVENT_REQUEST,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  SET_EDITED,
} from '../actions';

const INIT_STATE = {
  currentEvent: null,
  edited: false,
  currentEventList: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentEvent: null,
        success: '',
      };
    case SET_EDITED:
      return { ...state, edited: action.payload.isEdited };
    case GET_EVENT_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentEvent: action.payload,
        error: '',
      };
    case GET_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        currentEvent: null,
        error: action.payload.message,
      };
    case LIST_EVENTS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentEventList: action.payload,
        error: '',
      };
    case LIST_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        currentEventList: null,
        error: action.payload.message,
      };
    case ADD_EVENT_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_EVENT_SUCCESS: {
      const allEvents = [...state.currentEventList, action.payload];
      return {
        ...state,
        currentEventList: allEvents,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_EVENT_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_EVENT_SUCCESS: {
      const eventsUpdated = state.currentEventList.map((event) => {
        if (event.id === action.payload.event.id) {
          return action.payload.event;
        }
        return event;
      });
      return {
        ...state,
        currentEventList: eventsUpdated,
        edited: true,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case EDIT_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case DELETE_EVENT_REQUEST:
      return { ...state, error: '' };
    case DELETE_EVENT_SUCCESS:
      const eventsUpdated = state.currentEventList.filter((event) => {
        return event.id !== action.payload.id;
      });

      return {
        ...state,
        currentEventList: eventsUpdated,
        error: '',
      };
    case DELETE_EVENT_ERROR:
      return {
        ...state,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
