import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import { LIST_CYCLE_REQUEST } from '../actions';
import {
  listCycleSuccess,
  listCycleError,
} from './actions';

function* listCycle() {
  try {
    const response = yield call(api.get, 'cycles');
    yield put(listCycleSuccess(response.data.data));
  } catch (error) {
    yield put(listCycleError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_CYCLE_REQUEST, listCycle),
  ]);
}
