import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { currentUser } from '../../constants/defaultValues';

import api from '../../services/api';
import {
  LIST_NEWS_REQUEST,
  GET_NEWS_REQUEST,
  ADD_NEWS_REQUEST,
  EDIT_NEWS_REQUEST,
  EDIT_ACTIVE_NEWS_REQUEST,
  EDIT_FIXED_NEWS_REQUEST,
  GET_NEWS_DESCRIPTION_REQUEST,
  REMOVE_FILE_NEW_REQUEST
} from '../actions';
import {
  listNewsSuccess,
  listNewsError,
  getNewsSuccess,
  getNewsError,
  addNewsSuccess,
  addNewsError,
  editNewsSuccess,
  editNewsError,
  editActiveNewsSuccess,
  editActiveNewsError,
  editFixedNewsSuccess,
  editFixedNewsError,
  getNewsDescriptionSuccess,
  getNewsDescriptionError,
  removeFileNewSuccess,
  removeFileNewError
} from './actions';

function* getNewsById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `news/${id}`);
    yield put(getNewsSuccess(response.data.data));
  } catch (error) {
    yield put(getNewsError(error));
  }
}

const getNewsDescriptionByIdAsync = async (id) =>
  api
    .get(`news/${id}/description`)
    .then((response) => response)
    .catch((error) => error);

function* getNewsDescriptionById({ payload }) {
  try {
    const { id } = payload;
    const res = yield call(getNewsDescriptionByIdAsync, id);
    if (!res.response) {
      yield put(getNewsDescriptionSuccess(res.data.data));
    } else {
      yield put(getNewsDescriptionError(res.response.data));
    }
  } catch (error) {
    yield put(getNewsDescriptionError(error));
  }
}

function* listNews() {
  try {
    const classId =
      currentUser.uid.schools?.active_class?.id ??
      currentUser?.uid?.classes?.id;
    const schoolId =
      currentUser?.uid?.schools?.id ??
      currentUser?.uid?.classes?.school_id;
    const response = yield call(
      api.get, classId ? `news/classes/${classId}` : `news/school/${schoolId}`
    );
    yield put(listNewsSuccess(response.data.data));
  } catch (error) {
    yield put(listNewsError(error));
  }
}

const addNewsAsync = async (completeNews) =>
  api
    .post(`news`, {
      ...completeNews,
    })
    .then((response) => response)
    .catch((error) => error);

const editNewsAsync = async (completeNews) =>
  api
    .put(`news/${completeNews.id}`, {
      ...completeNews,
    })
    .then((response) => response)
    .catch((error) => error);

const addNewsFilesAsync = async (news) =>
  api
    .post(`news/upload`, {
      ...news.files,
    })
    .then((response) => response)
    .catch((error) => error);

function* addNews({ payload }) {
  try {
    const { news } = payload;
    const completeNews = { ...news };
    const res = yield call(addNewsAsync, completeNews);
    if (!res.response) {
      const responseValue = res.data.data;
      if (completeNews.files) {
        completeNews.files.new_id = responseValue.id;
        yield call(addNewsFilesAsync, completeNews);
      }
      const formattedNews = {
        id: responseValue.id,
        title: responseValue.title,
        subtitle: responseValue.subtitle,
        publish_at: responseValue.publish_at,
        description: responseValue.description,
        classes: responseValue.classes,
        active: responseValue.active,
      };
      yield put(addNewsSuccess(formattedNews));
    } else {
      yield put(addNewsError(res.response.data));
    }
  } catch (error) {
    yield put(addNewsError(error));
  }
}

function* editNews({ payload }) {
  try {
    const { news } = payload;
    const completeNews = { ...news };
    const res = yield call(editNewsAsync, completeNews);
    if (!res.response) {
      const responseValue = res.data.data;
      if (completeNews.files) {
        completeNews.files.new_id = responseValue.id;
        yield call(addNewsFilesAsync, completeNews);
      }
      const formattedNews = {
        id: responseValue.id,
        title: responseValue.title,
        subtitle: responseValue.subtitle,
        publish_at: responseValue.publish_at,
        description: responseValue.description,
        active: responseValue.active,
        classes: responseValue.classes,
      };
      yield put(editNewsSuccess(formattedNews));
    } else {
      yield put(editNewsError(res.response.data));
    }
  } catch (error) {
    yield put(editNewsError(error));
  }
}

function* editActiveNews({ payload }) {
  try {
    const { id, active } = payload;
    const response = yield call(
      api.put,
      `news/${id}/activate/${active ? '1' : '0'}`
    );
    const responseValue = response.data.data;
    const formattedNews = {
      id: responseValue.id,
      title: responseValue.title,
      subtitle: responseValue.subtitle,
      publish_at: responseValue.publish_at,
      description: responseValue.description,
      active: responseValue.active,
    };
    yield put(editActiveNewsSuccess(id, formattedNews));
  } catch (error) {
    yield put(editActiveNewsError(error));
  }
}

function* editFixedNews({ payload }) {
  try {
    const { id, fixed } = payload;
    const response = yield call(
      api.put,
      `news/${id}/fixation/${fixed ? 1 : 0}`
    );
    const responseValue = response.data.data;
    const formattedNews = {
      id: responseValue.id,
      title: responseValue.title,
      subtitle: responseValue.subtitle,
      publish_at: responseValue.publish_at,
      description: responseValue.description,
      active: responseValue.active,
      fixed: responseValue.fixed,
    };
    yield put(editFixedNewsSuccess(id, formattedNews));
  } catch (error) {
    yield put(editFixedNewsError(error));
  }
}

const removeFileNewAsync = async (id) =>
  api
    .delete(`files/${id}`)
    .then((response) => response)
    .catch((error) => error);

function* removeFileNew({ payload }) {
  const { id } = payload;
  try {
    yield put(removeFileNewSuccess(id));
    const res = yield call(removeFileNewAsync, id);
    if (!res.response) {
      toast.success(`Arquivo removido com sucesso!`);
    } else {
      yield put(removeFileNewError(id));
      toast.error(`Falha ao remover Arquivo!`);
    }
  } catch (error) {
    yield put(removeFileNewError(id));
    toast.error(`Falha ao remover Arquivo!`);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_NEWS_REQUEST, listNews),
    takeLatest(GET_NEWS_REQUEST, getNewsById),
    takeLatest(GET_NEWS_DESCRIPTION_REQUEST, getNewsDescriptionById),
    takeLatest(ADD_NEWS_REQUEST, addNews),
    takeLatest(EDIT_NEWS_REQUEST, editNews),
    takeLatest(EDIT_ACTIVE_NEWS_REQUEST, editActiveNews),
    takeLatest(EDIT_FIXED_NEWS_REQUEST, editFixedNews),
    takeEvery(REMOVE_FILE_NEW_REQUEST, removeFileNew),
  ]);
}
