import {
  LIST_CRITERION_REQUEST,
  LIST_CRITERION_SUCCESS,
  LIST_CRITERION_ERROR,
  GET_CRITERION_REQUEST,
  GET_CRITERION_SUCCESS,
  GET_CRITERION_ERROR,
  ADD_CRITERION_REQUEST,
  ADD_CRITERION_SUCCESS,
  ADD_CRITERION_ERROR,
  EDIT_CRITERION_REQUEST,
  EDIT_CRITERION_SUCCESS,
  EDIT_CRITERION_ERROR,
} from '../actions';

export const getCriterionRequest = (id) => ({
  type: GET_CRITERION_REQUEST,
  payload: { id },
});
export const getCriterionSuccess = (criterion) => ({
  type: GET_CRITERION_SUCCESS,
  payload: criterion,
});
export const getCriterionError = (message) => ({
  type: GET_CRITERION_ERROR,
  payload: { message },
});

export const listCriterionRequest = () => ({
  type: LIST_CRITERION_REQUEST,
});
export const listCriterionSuccess = (criterions) => ({
  type: LIST_CRITERION_SUCCESS,
  payload: criterions,
});
export const listCriterionError = (message) => ({
  type: LIST_CRITERION_ERROR,
  payload: { message },
});

export const addCriterionRequest = (criterion) => ({
  type: ADD_CRITERION_REQUEST,
  payload: { criterion },
});
export const addCriterionSuccess = (criterion) => ({
  type: ADD_CRITERION_SUCCESS,
  payload: criterion,
});
export const addCriterionError = (message) => ({
  type: ADD_CRITERION_ERROR,
  payload: { message },
});

export const editCriterionRequest = (criterion) => ({
  type: EDIT_CRITERION_REQUEST,
  payload: { criterion },
});
export const editCriterionSuccess = (criterion) => ({
  type: EDIT_CRITERION_SUCCESS,
  payload: { criterion },
});
export const editCriterionError = (message) => ({
  type: EDIT_CRITERION_ERROR,
  payload: { message },
});
