import {
  LIST_EXAM_PRACTICES_REQUEST,
  LIST_EXAM_PRACTICES_SUCCESS,
  LIST_EXAM_PRACTICES_ERROR,
  LIST_EXAM_PRACTICES_REPORTS_REQUEST,
  LIST_EXAM_PRACTICES_REPORTS_SUCCESS,
  LIST_EXAM_PRACTICES_REPORTS_ERROR,
  GET_EXAM_PRACTICES_REQUEST,
  GET_EXAM_PRACTICES_SUCCESS,
  GET_EXAM_PRACTICES_ERROR,
  ADD_EXAM_PRACTICES_REQUEST,
  ADD_EXAM_PRACTICES_SUCCESS,
  ADD_EXAM_PRACTICES_ERROR,
  EDIT_EXAM_PRACTICES_REQUEST,
  EDIT_EXAM_PRACTICES_SUCCESS,
  EDIT_EXAM_PRACTICES_ERROR,
  EDIT_ACTIVE_EXAM_PRACTICES_REQUEST,
  EDIT_ACTIVE_EXAM_PRACTICES_SUCCESS,
  EDIT_ACTIVE_EXAM_PRACTICES_ERROR,
  LIST_EXAM_PRACTICES_SUBJECTS_REQUEST,
  LIST_EXAM_PRACTICES_SUBJECTS_SUCCESS,
  LIST_EXAM_PRACTICES_SUBJECTS_ERROR,
  VINCULATE_EXAM_PRACTICES_SUBJECTS_REQUEST,
  VINCULATE_EXAM_PRACTICES_SUBJECTS_SUCCESS,
  VINCULATE_EXAM_PRACTICES_SUBJECTS_ERROR,
  VINCULATE_EXAM_PRACTICES_QUESTIONS_REQUEST,
  VINCULATE_EXAM_PRACTICES_QUESTIONS_SUCCESS,
  VINCULATE_EXAM_PRACTICES_QUESTIONS_ERROR,
  EXPORT_EXAM_PRACTICES_REPORTS_REQUEST,
  EXPORT_EXAM_PRACTICES_REPORTS_SUCCESS,
  EXPORT_EXAM_PRACTICES_REPORTS_ERROR,
  LIST_EXAM_PRACTICES_TYPES_REQUEST,
  LIST_EXAM_PRACTICES_TYPES_SUCCESS,
  LIST_EXAM_PRACTICES_TYPES_ERROR,
  IMPORT_EXAM_PRACTICES_REQUEST,
  IMPORT_EXAM_PRACTICES_SUCCESS,
  IMPORT_EXAM_PRACTICES_ERROR,
  REMOVE_IMPORT_EXAM_PRACTICES_REQUEST,
  REMOVE_IMPORT_EXAM_PRACTICES_SUCCESS,
  REMOVE_IMPORT_EXAM_PRACTICES_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
  SEND_EXAM_PRACTICE_REQUEST,
  SEND_EXAM_PRACTICE_SUCCESS,
  SEND_EXAM_PRACTICE_ERROR,
} from '../actions';

const INIT_STATE = {
  currentExamPractices: null,
  currentExamPracticesList: null,
  currentExamPracticesTypesList: null,
  tempExamPracticeSubjectIds: null,
  reports: null,
  subjects: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentExamPractices: null,
        tempExamPracticeSubjectIds: null,
        success: '',
      };
    case GET_EXAM_PRACTICES_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_EXAM_PRACTICES_SUCCESS:
      return {
        ...state,
        loading: false,
        currentExamPractices: action.payload,
        error: '',
      };
    case GET_EXAM_PRACTICES_ERROR:
      return {
        ...state,
        loading: false,
        currentExamPractices: null,
        error: action.payload.message,
      };
    case LIST_EXAM_PRACTICES_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_EXAM_PRACTICES_SUCCESS:
      return {
        ...state,
        loading: false,
        currentExamPracticesList: action.payload,
        error: '',
      };
    case LIST_EXAM_PRACTICES_ERROR:
      return {
        ...state,
        loading: false,
        currentExamPracticesList: null,
        error: action.payload.message,
      };
    case LIST_EXAM_PRACTICES_REPORTS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_EXAM_PRACTICES_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: action.payload,
        error: '',
      };
    case LIST_EXAM_PRACTICES_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
        reports: null,
        error: action.payload.message,
      };
    case ADD_EXAM_PRACTICES_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_EXAM_PRACTICES_SUCCESS: {
      const allExamPractices = [
        ...state.currentExamPracticesList,
        action.payload,
      ];

      return {
        ...state,
        currentExamPracticesList: allExamPractices,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_EXAM_PRACTICES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_EXAM_PRACTICES_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_EXAM_PRACTICES_SUCCESS: {
      const ExamPracticesUpdated = state.currentExamPracticesList.map(
        (examPractices) => {
          if (examPractices.id === action.payload.examPractices.id) {
            return action.payload.examPractices;
          }
          return examPractices;
        }
      );

      return {
        ...state,
        currentExamPracticesList: ExamPracticesUpdated,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case EDIT_EXAM_PRACTICES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_ACTIVE_EXAM_PRACTICES_REQUEST:
      return { ...state, loading: false, error: '' };
    case EDIT_ACTIVE_EXAM_PRACTICES_SUCCESS: {
      const ExamPracticesUpdated = state.currentExamPracticesList.map(
        (examPractices) => {
          if (examPractices.id === action.payload.id) {
            examPractices.active = action.payload.active;
          }
          return examPractices;
        }
      );

      return {
        ...state,
        currentExamPracticesList: ExamPracticesUpdated,
        success: action.payload,
        loading: false,
        error: '',
      };
    }
    case EDIT_ACTIVE_EXAM_PRACTICES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case LIST_EXAM_PRACTICES_SUBJECTS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_EXAM_PRACTICES_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: action.payload,
        error: '',
      };
    case LIST_EXAM_PRACTICES_SUBJECTS_ERROR:
      return {
        ...state,
        loading: false,
        subjects: null,
        error: action.payload.message,
      };
    case VINCULATE_EXAM_PRACTICES_SUBJECTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        tempExamPracticeSubjectIds: null,
      };
    case VINCULATE_EXAM_PRACTICES_SUBJECTS_SUCCESS: {
      const { subjects, isExternalQuestion } = action.payload;
      if (isExternalQuestion) {
        return {
          ...state,
          tempExamPracticeSubjectIds: subjects,
          loading: false,
          error: '',
        };
      }
      return {
        ...state,
        success: action.payload,
        loading: false,
        error: '',
      };
    }
    case VINCULATE_EXAM_PRACTICES_SUBJECTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        tempExamPracticeSubjectIds: null,
      };
    case EXPORT_EXAM_PRACTICES_REPORTS_REQUEST:
      return { ...state, loading: true, error: '' };
    case EXPORT_EXAM_PRACTICES_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case EXPORT_EXAM_PRACTICES_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case LIST_EXAM_PRACTICES_TYPES_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_EXAM_PRACTICES_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        currentExamPracticesTypesList: action.payload,
        error: '',
      };
    case LIST_EXAM_PRACTICES_TYPES_ERROR:
      return {
        ...state,
        loading: false,
        currentExamPracticesTypesList: null,
        error: action.payload.message,
      };
    case IMPORT_EXAM_PRACTICES_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case IMPORT_EXAM_PRACTICES_SUCCESS: {
      return {
        ...state,
        error: '',
        success: action.payload,
      };
    }
    case IMPORT_EXAM_PRACTICES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case REMOVE_IMPORT_EXAM_PRACTICES_REQUEST:
      return { ...state, error: '', success: '' };
    case REMOVE_IMPORT_EXAM_PRACTICES_SUCCESS: {
      const { examPracticeId, id } = action.payload;
      const updatedExamPractices = state.currentExamPracticesList.map(
        (examPractice) => {
          if (examPractice.id === examPracticeId) {
            return {
              ...examPractice,
              files: examPractice.files.map((file) => {
                if (file.id === id) {
                  return { ...file, removed: true };
                }
                return file;
              }),
            };
          }
          return examPractice;
        }
      );
      return {
        ...state,
        error: '',
        currentExamPracticesList: updatedExamPractices,
        success: action.payload.message,
      };
    }
    case REMOVE_IMPORT_EXAM_PRACTICES_ERROR: {
      const { examPracticeId, id } = action.payload;
      const updatedExamPractices = state.currentExamPracticesList.map(
        (examPractice) => {
          if (examPractice.id === examPracticeId) {
            return {
              ...examPractice,
              files: examPractice.files.map((file) => {
                if (file.id === id) {
                  return { ...file, removed: false };
                }
                return file;
              }),
            };
          }
          return examPractice;
        }
      );
      return {
        ...state,
        currentExamPracticesList: updatedExamPractices,
        error: action.payload.message,
        success: '',
      };
    }
    case VINCULATE_EXAM_PRACTICES_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case VINCULATE_EXAM_PRACTICES_QUESTIONS_SUCCESS: {
      return {
        ...state,
        success: action.payload.isLastSubject,
        loading: false,
        error: '',
      };
    }
    case VINCULATE_EXAM_PRACTICES_QUESTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: null,
      };
    case SEND_EXAM_PRACTICE_REQUEST:
      return { ...state, loading: false, error: '', success: '' };
    case SEND_EXAM_PRACTICE_SUCCESS: {
      const examPracticeUpdated = state.currentExamPracticesList.map((examPractice) => {
        if (examPractice.id === action.payload.id) {
          return { ...examPractice, sent_at: action.payload.sentAt };
        }
        return examPractice;
      });

      return {
        ...state,
        currentExamPracticesList: examPracticeUpdated,
        loading: false,
        success: '',
        error: '',
      };
    }
    case SEND_EXAM_PRACTICE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
