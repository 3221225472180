import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  GET_SCHOOL_REQUEST,
  GET_SCHOOL_PARENTS_REQUEST,
  LIST_SCHOOL_REQUEST,
  ADD_SCHOOL_REQUEST,
  EDIT_SCHOOL_REQUEST,
  EDIT_ACTIVE_SCHOOL_REQUEST,
  GET_SCHOOL_TYPES_REQUEST,
} from '../actions';
import {
  getSchoolSuccess,
  getSchoolError,
  getSchoolParentsSuccess,
  getSchoolParentsError,
  getSchoolTypesSuccess,
  getSchoolTypesError,
  listSchoolSuccess,
  listSchoolError,
  addSchoolSuccess,
  addSchoolError,
  editSchoolSuccess,
  editSchoolError,
  editActiveSchoolSuccess,
  editActiveSchoolError,
} from './actions';
import { currentUser } from '../../constants/defaultValues';
import { SchoolRole, UserRole } from '../../helpers/authHelper';
import { setCurrentUser } from '../../helpers/Utils';

function updateCurrentUserSchools(newSchools) {
  setCurrentUser({
    ...currentUser,
    uid: {
      ...currentUser.uid,
      schools: {
        ...currentUser.uid.schools,
        children_recursive: newSchools,
      },
    },
  });
}

function* getSchoolById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `schools/${id}`);
    yield put(getSchoolSuccess(response.data.data));
  } catch (error) {
    yield put(getSchoolError(error));
  }
}

function* getSchoolParentsById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `schools/${id}`);
    yield put(getSchoolParentsSuccess(response.data.data.children_recursive));
  } catch (error) {
    yield put(getSchoolParentsError(error));
  }
}

function* getSchoolTypesById() {
  try {
    const response = yield call(api.get, `school-types`);
    yield put(getSchoolTypesSuccess(response.data.data));
  } catch (error) {
    yield put(getSchoolTypesError(error));
  }
}

function* listSchool({ payload }) {
  const { type } = payload;
  try {
    const endpointSchools =
      currentUser.schoolRole === SchoolRole.Master
        ? `schools${type ? `?type=${type}` : ''}`
        : `schools?id=${currentUser?.uid?.schools?.id}`;
    const response = yield call(api.get, endpointSchools);
    if (currentUser.role !== UserRole.Admin)
      updateCurrentUserSchools(response.data.data);
    yield put(listSchoolSuccess(response.data.data));
  } catch (error) {
    yield put(listSchoolError(error));
  }
}

const addSchoolAsync = async (completeSchool) =>
  api
    .post(`schools`, {
      ...completeSchool,
    })
    .then((response) => response)
    .catch((error) => error);

const editSchoolAsync = async (completeSchool) =>
  api
    .put(`schools/${completeSchool.id}`, {
      ...completeSchool,
    })
    .then((response) => response)
    .catch((error) => error);

const addSchoolLogoAsync = async (completeSchool) =>
  api
    .post(`schools/upload/avatar`, {
      ...completeSchool.file,
    })
    .then((response) => response)
    .catch((error) => error);

function* addSchool({ payload }) {
  try {
    const { school } = payload;
    const completeSchool = { ...school };
    const res = yield call(addSchoolAsync, completeSchool);
    if (!res.response) {
      if (completeSchool.file) {
        completeSchool.file.school_id = res.data.data.id;
        yield call(addSchoolLogoAsync, completeSchool);
      }
      if (currentUser.role !== UserRole.Admin) {
        const updatedSchools = yield call(
          api.get,
          `schools?id=${currentUser?.uid?.schools?.id}`
        );
        updateCurrentUserSchools(updatedSchools.data.data);
      }
      if (currentUser.schoolRole === SchoolRole.EducationSystem) {
        if (
          res.data.data.school_type.ordination === SchoolRole.EducationNetwork
        ) {
          yield put(addSchoolSuccess(res.data.data));
        } else {
          yield put(addSchoolSuccess());
        }
      } else {
        yield put(addSchoolSuccess(res.data.data));
      }
    } else {
      yield put(addSchoolError(res.response.data));
    }
  } catch (error) {
    yield put(addSchoolError(error));
  }
}

function* editSchool({ payload }) {
  try {
    const { school } = payload;
    const completeSchool = { ...school };
    const res = yield call(editSchoolAsync, completeSchool);
    if (!res.response) {
      if (completeSchool.file) {
        completeSchool.file.school_id = res.data.data.id;
        yield call(addSchoolLogoAsync, completeSchool);
      }
      yield put(editSchoolSuccess(res.data.data));
    } else {
      yield put(editSchoolError(res.response.data));
    }
  } catch (error) {
    yield put(editSchoolError(error));
  }
}

function* editActiveSchool({ payload }) {
  try {
    const { id, active } = payload;
    const response = yield call(
      api.put,
      `schools/${id}/activate/${active ? '1' : '0'}`,
      {
        active,
      }
    );
    yield put(editActiveSchoolSuccess(id, response.data.data));
  } catch (error) {
    yield put(editActiveSchoolError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_SCHOOL_REQUEST, getSchoolById),
    takeLatest(GET_SCHOOL_PARENTS_REQUEST, getSchoolParentsById),
    takeLatest(GET_SCHOOL_TYPES_REQUEST, getSchoolTypesById),
    takeLatest(LIST_SCHOOL_REQUEST, listSchool),
    takeLatest(ADD_SCHOOL_REQUEST, addSchool),
    takeLatest(EDIT_SCHOOL_REQUEST, editSchool),
    takeLatest(EDIT_ACTIVE_SCHOOL_REQUEST, editActiveSchool),
  ]);
}
