import { CHANGE_LOCALE, RESET_STATE_ALL_APPLICATION } from '../actions';
import { getCurrentLanguage } from '../../helpers/Utils';

const INIT_STATE = {
  locale: getCurrentLanguage(),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };

    default:
      return { ...state };
  }
};
