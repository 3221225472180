import {
  LIST_SCORE_REQUEST,
  LIST_SCORE_SUCCESS,
  LIST_SCORE_ERROR,
  RESET_STATE_ALL_APPLICATION,
  RESET_STATE_REDUX
} from '../actions';

const INIT_STATE = {
  currentScoreList: null,
  loading: false,
  success: '',
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        success: '',
      };
    case LIST_SCORE_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentScoreList: action.payload,
        error: '',
      };
    case LIST_SCORE_ERROR:
      return {
        ...state,
        loading: false,
        currentScoreList: null,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
