import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const apiDev = axios.create({
  baseURL: process.env.REACT_APP_API_DEV_URL,
});

export const apiQuestionDb = axios.create({
  baseURL: process.env.REACT_APP_API_QUESTION_DB_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_API_ACCESS_TOKEN}`,
  },
});

export const apiUrl = axios.create();

export default api;
