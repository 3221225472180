import {
  GET_SCHOOL_REQUEST,
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_ERROR,
  GET_SCHOOL_PARENTS_REQUEST,
  GET_SCHOOL_PARENTS_SUCCESS,
  GET_SCHOOL_PARENTS_ERROR,
  GET_SCHOOL_TYPES_REQUEST,
  GET_SCHOOL_TYPES_SUCCESS,
  GET_SCHOOL_TYPES_ERROR,
  LIST_SCHOOL_REQUEST,
  LIST_SCHOOL_SUCCESS,
  LIST_SCHOOL_ERROR,
  ADD_SCHOOL_REQUEST,
  ADD_SCHOOL_SUCCESS,
  ADD_SCHOOL_ERROR,
  EDIT_SCHOOL_REQUEST,
  EDIT_SCHOOL_SUCCESS,
  EDIT_SCHOOL_ERROR,
  EDIT_ACTIVE_SCHOOL_REQUEST,
  EDIT_ACTIVE_SCHOOL_SUCCESS,
  EDIT_ACTIVE_SCHOOL_ERROR,
} from '../actions';

export const getSchoolRequest = (id) => ({
  type: GET_SCHOOL_REQUEST,
  payload: { id },
});
export const getSchoolSuccess = (school) => ({
  type: GET_SCHOOL_SUCCESS,
  payload: school,
});
export const getSchoolError = (message) => ({
  type: GET_SCHOOL_ERROR,
  payload: { message },
});

export const getSchoolParentsRequest = (id) => ({
  type: GET_SCHOOL_PARENTS_REQUEST,
  payload: { id },
});
export const getSchoolParentsSuccess = (parents) => ({
  type: GET_SCHOOL_PARENTS_SUCCESS,
  payload: parents,
});
export const getSchoolParentsError = (message) => ({
  type: GET_SCHOOL_PARENTS_ERROR,
  payload: { message },
});

export const getSchoolTypesRequest = () => ({
  type: GET_SCHOOL_TYPES_REQUEST,
});
export const getSchoolTypesSuccess = (types) => ({
  type: GET_SCHOOL_TYPES_SUCCESS,
  payload: types,
});
export const getSchoolTypesError = (message) => ({
  type: GET_SCHOOL_TYPES_ERROR,
  payload: { message },
});

export const listSchoolRequest = (type) => ({
  type: LIST_SCHOOL_REQUEST,
  payload: { type },
});
export const listSchoolSuccess = (schools) => ({
  type: LIST_SCHOOL_SUCCESS,
  payload: schools,
});
export const listSchoolError = (message) => ({
  type: LIST_SCHOOL_ERROR,
  payload: { message },
});

export const addSchoolRequest = (school) => ({
  type: ADD_SCHOOL_REQUEST,
  payload: { school },
});
export const addSchoolSuccess = (school) => ({
  type: ADD_SCHOOL_SUCCESS,
  payload: school,
});
export const addSchoolError = (message) => ({
  type: ADD_SCHOOL_ERROR,
  payload: { message },
});

export const editSchoolRequest = (school) => ({
  type: EDIT_SCHOOL_REQUEST,
  payload: { school },
});
export const editSchoolSuccess = (school) => ({
  type: EDIT_SCHOOL_SUCCESS,
  payload: { school },
});
export const editSchoolError = (message) => ({
  type: EDIT_SCHOOL_ERROR,
  payload: { message },
});

export const editActiveSchoolRequest = (id, active) => ({
  type: EDIT_ACTIVE_SCHOOL_REQUEST,
  payload: { id, active },
});
export const editActiveSchoolSuccess = (id, school) => ({
  type: EDIT_ACTIVE_SCHOOL_SUCCESS,
  payload: { id, school },
});
export const editActiveSchoolError = (message) => ({
  type: EDIT_ACTIVE_SCHOOL_ERROR,
  payload: { message },
});
