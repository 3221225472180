import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  LIST_EVENTS_REQUEST,
  GET_EVENT_REQUEST,
  ADD_EVENT_REQUEST,
  EDIT_EVENT_REQUEST,
  DELETE_EVENT_REQUEST
} from '../actions';
import {
  listEventsSuccess,
  listEventsError,
  getEventSuccess,
  getEventError,
  addEventSuccess,
  addEventError,
  editEventSuccess,
  editEventError,
  deleteEventSuccess,
  deleteEventError,
} from './actions';
import getApiErrors from '../../helpers/getApiErrors';

function* getEventById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `events/${id}`);
    yield put(getEventSuccess(response.data.data));
  } catch (error) {
    yield put(getEventError(error));
  }
}

function* listEvents({ payload }) {
  const { cycle, classStudentId, studentId } = payload
  try {
    const response = yield call(api.get, `events/cycle/${cycle}?classStudentId=${classStudentId}&studentId=${studentId}`);
    yield put(listEventsSuccess(response.data.data));
  } catch (error) {
    yield put(listEventsError(error));
  }
}

const addEventAsync = async (event) =>
  api
    .post(`events`, {
      ...event,
    })
    .then((response) => response)
    .catch((error) => error);

const editEventAsync = async (event) =>
  api
    .put(`events/${event.id}`, {
      ...event,
    })
    .then((response) => response)
    .catch((error) => error);

function* addEvent({ payload }) {
  try {
    const { event } = payload;

    const res = yield call(addEventAsync, event);
    if (!res.response) {
      yield put(addEventSuccess(res.data.data));
      toast.success('Evento cadastrado com sucesso');
    } else {
      const errors = res.response.data;
      yield put(addEventError(errors));
      getApiErrors(errors);
    }
  } catch (error) {
    yield put(addEventError(error));
    getApiErrors(error);
  }
}

function* editEvent({ payload }) {
  try {
    const { event } = payload;
    const res = yield call(editEventAsync, event);
    if (!res.response) {
      yield put(editEventSuccess(res.data.data));
      toast.success('Evento editado com sucesso');
    } else {
      const errors = res.response.data;
      yield put(editEventError(errors));
      getApiErrors(errors);
    }
  } catch (error) {
    yield put(editEventError(error));
    getApiErrors(error);
  }
}

const deleteEventAsync = async (id) =>
  api
    .delete(`events/${id}`)
    .then((response) => response)
    .catch((error) => error);

function* deleteEvent({ payload }) {
  try {
    const { id } = payload;
    const res = yield call(deleteEventAsync, id);
    if (!res.response) {
      toast.success(`Evento removido com sucesso!`);
      yield put(deleteEventSuccess(id));
    } else {
      const errors = res.response.data;
      yield put(deleteEventError(errors));
      getApiErrors(errors);
    }
  } catch (error) {
    yield put(deleteEventError(error));
    getApiErrors(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_EVENTS_REQUEST, listEvents),
    takeLatest(GET_EVENT_REQUEST, getEventById),
    takeLatest(ADD_EVENT_REQUEST, addEvent),
    takeLatest(EDIT_EVENT_REQUEST, editEvent),
    takeLatest(DELETE_EVENT_REQUEST, deleteEvent),
  ]);
}
