import {
  RESET_CURRENT_VALUE,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

export const resetCurrentValue = () => ({
  type: RESET_CURRENT_VALUE,
});

export const resetStateRedux = () => ({
  type: RESET_STATE_REDUX,
});

export const resetStateAllApplication = () => ({
  type: RESET_STATE_ALL_APPLICATION,
});
