import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  LIST_NETWORK_COORDINATOR_REQUEST,
  GET_NETWORK_COORDINATOR_REQUEST,
  ADD_NETWORK_COORDINATOR_REQUEST,
  EDIT_NETWORK_COORDINATOR_REQUEST,
  EDIT_ACTIVE_NETWORK_COORDINATOR_REQUEST,
} from '../actions';
import {
  listNetworkCoordinatorSuccess,
  listNetworkCoordinatorError,
  getNetworkCoordinatorSuccess,
  getNetworkCoordinatorError,
  addNetworkCoordinatorSuccess,
  addNetworkCoordinatorError,
  editNetworkCoordinatorSuccess,
  editNetworkCoordinatorError,
  editActiveNetworkCoordinatorSuccess,
  editActiveNetworkCoordinatorError,
} from './actions';
import { UserRole } from '../../helpers/authHelper';
import { currentUser } from '../../constants/defaultValues';
import { getChildrenRecursiveIds } from '../../utils/getChildrenRecursiveIds';

function* getNetworkCoordinatorById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `administrators/${id}`);
    yield put(getNetworkCoordinatorSuccess(response.data.data));
  } catch (error) {
    yield put(getNetworkCoordinatorError(error));
  }
}

const addNetworkCoordinatorsAsync = async (completeCoordinator) =>
  api
    .post(`backoffice/register`, {
      ...completeCoordinator,
    })
    .then((response) => response)
    .catch((error) => error);

const editNetworkCoordinatorsAsync = async (completeCoordinator) =>
  api
    .put(`administrators/${completeCoordinator.id}`, {
      ...completeCoordinator,
    })
    .then((response) => response)
    .catch((error) => error);

function* listNetworkCoordinator() {
  try {
    const response = yield call(
      api.get,
      `administrators/permission-level/${UserRole.NetworkCoordinator}${
        currentUser.role !== UserRole.Admin
          ? `?schoolId=${getChildrenRecursiveIds(currentUser).join(',')}`
          : ''
      }`
    );

    yield put(listNetworkCoordinatorSuccess(response.data.data));
  } catch (error) {
    yield put(listNetworkCoordinatorError(error));
  }
}

function* addNetworkCoordinator({ payload }) {
  try {
    const { networkCoordinator } = payload;
    const completeCoordinator = { ...networkCoordinator, actionType: 'edit' };
    const res = yield call(addNetworkCoordinatorsAsync, completeCoordinator);
    if (!res.response) {
      const responseValue = res.data.data;
      const formatedCoordinator = {
        name: responseValue.name,
        active: responseValue.active,
        id: responseValue.id,
        schools: responseValue.schools,
        email: responseValue.email,
      };
      yield put(addNetworkCoordinatorSuccess(formatedCoordinator));
    } else {
      yield put(addNetworkCoordinatorError(res.response.data));
    }
  } catch (error) {
    yield put(addNetworkCoordinatorError(error));
  }
}

function* editNetworkCoordinator({ payload }) {
  try {
    const { networkCoordinator } = payload;
    const completeCoordinator = { ...networkCoordinator, actionType: 'edit' };
    const res = yield call(editNetworkCoordinatorsAsync, completeCoordinator);
    if (!res.response) {
      const responseValue = res.data.data;
      const formatedCoordinator = {
        name: responseValue.name,
        active: responseValue.active,
        id: responseValue.id,
        schools: responseValue.schools,
        email: responseValue.email,
      };
      yield put(editNetworkCoordinatorSuccess(formatedCoordinator));
    } else {
      yield put(editNetworkCoordinatorError(res.response.data));
    }
  } catch (error) {
    yield put(editNetworkCoordinatorError(error));
  }
}

function* editActiveNetworkCoordinator({ payload }) {
  try {
    const { id, active } = payload;
    const response = yield call(api.put, `administrators/${id}`, {
      active,
      actionType: 'activation',
    });
    const responseValue = response.data.data;
    const formatedCoordinator = {
      name: responseValue.name,
      active: responseValue.active,
      id: responseValue.id,
      schools: responseValue.schools,
      email: responseValue.email,
    };
    yield put(editActiveNetworkCoordinatorSuccess(id, formatedCoordinator));
  } catch (error) {
    yield put(editActiveNetworkCoordinatorError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_NETWORK_COORDINATOR_REQUEST, listNetworkCoordinator),
    takeLatest(GET_NETWORK_COORDINATOR_REQUEST, getNetworkCoordinatorById),
    takeLatest(ADD_NETWORK_COORDINATOR_REQUEST, addNetworkCoordinator),
    takeLatest(EDIT_NETWORK_COORDINATOR_REQUEST, editNetworkCoordinator),
    takeLatest(
      EDIT_ACTIVE_NETWORK_COORDINATOR_REQUEST,
      editActiveNetworkCoordinator
    ),
  ]);
}
