import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../../services/api';
import {
  ADD_CLASS_CYCLE_REQUEST,
  EDIT_CLASS_CYCLE_REQUEST,
  LIST_CLASS_CYCLE_REQUEST
} from '../../actions';
import {
  listClassCycleSuccess,
  listClassCycleError,
  addClassCycleError,
  addClassCycleSuccess,
  editClassCycleError,
  editClassCycleSuccess,
} from './actions';

function* listClassCycle({ classId }) {
  try {
    const response = yield call(api.get, `classes/${classId}/cycles`);
    yield put(listClassCycleSuccess(response.data.data));
  } catch (error) {
    yield put(listClassCycleError(error));
  }
}

const addClassCycleAsync = async (completeClassCycle) =>
  api
    .post(`classes/cycles`, {
      ...completeClassCycle,
    })
    .then((response) => response)
    .catch((error) => error);

function* addClassCycle({ payload }) {
  try {
    const { cycle } = payload;
    const res = yield call(addClassCycleAsync, cycle);
    if (!res.response) {
      const responseValue = res.data.data;
      yield put(addClassCycleSuccess(responseValue));
    } else {
      yield put(addClassCycleError(res.response.data));
    }
  } catch (error) {
    yield put(addClassCycleError(error));
  }
}

const editClassCycleAsync = async (completeClassCycle) =>
  api
    .put(`classes/cycles/${completeClassCycle.id}`, {
      ...completeClassCycle,
    })
    .then((response) => response)
    .catch((error) => error);

function* editClassCycle({ payload }) {
  try {
    const { cycle } = payload;
    const res = yield call(editClassCycleAsync, cycle);
    if (!res.response) {
      const responseValue = res.data.data;
      yield put(editClassCycleSuccess(responseValue));
    } else {
      yield put(editClassCycleError(res.response.data));
    }
  } catch (error) {
    yield put(editClassCycleError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_CLASS_CYCLE_REQUEST, listClassCycle),
    takeLatest(ADD_CLASS_CYCLE_REQUEST, addClassCycle),
    takeLatest(EDIT_CLASS_CYCLE_REQUEST, editClassCycle),
  ]);
}
