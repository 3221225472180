import {
  LIST_MANAGER_REQUEST,
  LIST_MANAGER_SUCCESS,
  LIST_MANAGER_ERROR,
  GET_MANAGER_REQUEST,
  GET_MANAGER_SUCCESS,
  GET_MANAGER_ERROR,
  ADD_MANAGER_REQUEST,
  ADD_MANAGER_SUCCESS,
  ADD_MANAGER_ERROR,
  EDIT_MANAGER_REQUEST,
  EDIT_MANAGER_SUCCESS,
  EDIT_MANAGER_ERROR,
  EDIT_ACTIVE_MANAGER_REQUEST,
  EDIT_ACTIVE_MANAGER_SUCCESS,
  EDIT_ACTIVE_MANAGER_ERROR,
} from '../actions';

export const getManagerRequest = (id) => ({
  type: GET_MANAGER_REQUEST,
  payload: { id },
});
export const getManagerSuccess = (manager) => ({
  type: GET_MANAGER_SUCCESS,
  payload: manager,
});
export const getManagerError = (message) => ({
  type: GET_MANAGER_ERROR,
  payload: { message },
});

export const listManagerRequest = () => ({
  type: LIST_MANAGER_REQUEST,
});
export const listManagerSuccess = (managers) => ({
  type: LIST_MANAGER_SUCCESS,
  payload: managers,
});
export const listManagerError = (message) => ({
  type: LIST_MANAGER_ERROR,
  payload: { message },
});

export const addManagerRequest = (manager) => ({
  type: ADD_MANAGER_REQUEST,
  payload: { manager },
});
export const addManagerSuccess = (manager) => ({
  type: ADD_MANAGER_SUCCESS,
  payload: manager,
});
export const addManagerError = (message) => ({
  type: ADD_MANAGER_ERROR,
  payload: { message },
});

export const editManagerRequest = (manager) => ({
  type: EDIT_MANAGER_REQUEST,
  payload: { manager },
});
export const editManagerSuccess = (manager) => ({
  type: EDIT_MANAGER_SUCCESS,
  payload: { manager },
});
export const editManagerError = (message) => ({
  type: EDIT_MANAGER_ERROR,
  payload: { message },
});

export const editActiveManagerRequest = (id, active) => ({
  type: EDIT_ACTIVE_MANAGER_REQUEST,
  payload: { id, active },
});
export const editActiveManagerSuccess = (id, manager) => ({
  type: EDIT_ACTIVE_MANAGER_SUCCESS,
  payload: { id, manager },
});
export const editActiveManagerError = (message) => ({
  type: EDIT_ACTIVE_MANAGER_ERROR,
  payload: { message },
});
