import {
  LIST_EXAM_REQUEST,
  LIST_EXAM_SUCCESS,
  LIST_EXAM_ERROR,
  GET_EXAM_REQUEST,
  GET_EXAM_SUCCESS,
  GET_EXAM_ERROR,
  ADD_EXAM_REQUEST,
  ADD_EXAM_SUCCESS,
  ADD_EXAM_ERROR,
  EDIT_EXAM_REQUEST,
  EDIT_EXAM_SUCCESS,
  EDIT_EXAM_ERROR,
  EDIT_ACTIVE_EXAM_REQUEST,
  EDIT_ACTIVE_EXAM_SUCCESS,
  EDIT_ACTIVE_EXAM_ERROR,
  GENERATE_FILES_EXAM_REQUEST,
  GENERATE_FILES_EXAM_SUCCESS,
  GENERATE_FILES_EXAM_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  currentExam: null,
  currentExamList: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentExam: null,
        success: '',
      };
    case GET_EXAM_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_EXAM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentExam: action.payload,
        error: '',
      };
    case GET_EXAM_ERROR:
      return {
        ...state,
        loading: false,
        currentExam: null,
        error: action.payload.message,
      };
    case LIST_EXAM_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_EXAM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentExamList: action.payload,
        error: '',
      };
    case LIST_EXAM_ERROR:
      return {
        ...state,
        loading: false,
        currentExamList: null,
        error: action.payload.message,
      };
    case ADD_EXAM_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_EXAM_SUCCESS: {
      const allExams = [...state.currentExamList, action.payload];

      return {
        ...state,
        currentExamList: allExams,
        loading: false,
        success: { exam: action.payload },
        error: '',
      };
    }
    case ADD_EXAM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_EXAM_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_EXAM_SUCCESS: {
      const examsUpdated = state.currentExamList.map((exam) => {
        if (exam.id === action.payload.exam.id) {
          return action.payload.exam;
        }
        return exam;
      });

      return {
        ...state,
        currentExamList: examsUpdated,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case EDIT_EXAM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_ACTIVE_EXAM_REQUEST:
      return { ...state, loading: false, error: '', success: '' };
    case EDIT_ACTIVE_EXAM_SUCCESS: {
      const examsUpdated = state.currentExamList.map((exam) => {
        if (exam.id === action.payload.id) {
          return action.payload.exam;
        }
        return exam;
      });

      return {
        ...state,
        currentExamList: examsUpdated,
        success: action.payload,
        loading: false,
        error: '',
      };
    }
    case EDIT_ACTIVE_EXAM_ERROR:
      return {
        ...state,
        success: '',
        loading: false,
        error: action.payload.message,
      };
    case GENERATE_FILES_EXAM_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case GENERATE_FILES_EXAM_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: { exam: action.payload },
        error: '',
      };
    }
    case GENERATE_FILES_EXAM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
