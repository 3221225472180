import {
  LIST_EVENTS_REQUEST,
  LIST_EVENTS_SUCCESS,
  LIST_EVENTS_ERROR,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_ERROR,
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_ERROR,
  EDIT_EVENT_REQUEST,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_ERROR,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  SET_EDITED,
} from '../actions';

export const getEventRequest = (id) => ({
  type: GET_EVENT_REQUEST,
  payload: { id },
});
export const getEventSuccess = (events) => ({
  type: GET_EVENT_SUCCESS,
  payload: events,
});
export const getEventError = (message) => ({
  type: GET_EVENT_ERROR,
  payload: { message },
});

export const listEventsRequest = (cycle, classStudentId, studentId) => ({
  type: LIST_EVENTS_REQUEST,
  payload: { cycle, classStudentId, studentId },
});
export const listEventsSuccess = (events) => ({
  type: LIST_EVENTS_SUCCESS,
  payload: events,
});
export const listEventsError = (message) => ({
  type: LIST_EVENTS_ERROR,
  payload: { message },
});

export const addEventRequest = (event) => ({
  type: ADD_EVENT_REQUEST,
  payload: { event },
});
export const addEventSuccess = (event) => ({
  type: ADD_EVENT_SUCCESS,
  payload: event,
});
export const addEventError = (message) => ({
  type: ADD_EVENT_ERROR,
  payload: { message },
});

export const editEventRequest = (event) => ({
  type: EDIT_EVENT_REQUEST,
  payload: { event },
});
export const editEventSuccess = (event) => ({
  type: EDIT_EVENT_SUCCESS,
  payload: { event },
});
export const editEventError = (message) => ({
  type: EDIT_EVENT_ERROR,
  payload: { message },
});

export const deleteEventRequest = (id) => ({
  type: DELETE_EVENT_REQUEST,
  payload: { id },
});
export const deleteEventSuccess = (id) => ({
  type: DELETE_EVENT_SUCCESS,
  payload: { id },
});
export const deleteEventError = (message) => ({
  type: DELETE_EVENT_ERROR,
  payload: { message },
});

export const setEdited = (isEdited) => ({
  type: SET_EDITED,
  payload: { isEdited },
});
