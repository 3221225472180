import {
  LIST_STUDY_DIAGNOSIS_REQUEST,
  LIST_STUDY_DIAGNOSIS_SUCCESS,
  LIST_STUDY_DIAGNOSIS_ERROR,
} from '../actions';

export const listStudyDiagnosisRequest = (classId, cycleIs, subjectId) => ({
  type: LIST_STUDY_DIAGNOSIS_REQUEST,
  payload: {classId, cycleIs, subjectId}
});
export const listStudyDiagnosisSuccess = (studyDiagnosis) => ({
  type: LIST_STUDY_DIAGNOSIS_SUCCESS,
  payload: studyDiagnosis,
});
export const listStudyDiagnosisError = (message) => ({
  type: LIST_STUDY_DIAGNOSIS_ERROR,
  payload: { message },
});


