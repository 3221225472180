import {
  LIST_SUBJECTS_REQUEST,
  LIST_SUBJECTS_SUCCESS,
  LIST_SUBJECTS_ERROR,
  RESET_STATE_ALL_APPLICATION,
  GET_SUBJECTS_REQUEST,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_ERROR,
  ADD_SUBJECTS_REQUEST,
  ADD_SUBJECTS_SUCCESS,
  ADD_SUBJECTS_ERROR,
  EDIT_SUBJECTS_REQUEST,
  EDIT_SUBJECTS_SUCCESS,
  RESET_STATE_REDUX,
  LIST_ALL_SUBJECTS_ERROR,
  LIST_ALL_SUBJECTS_SUCCESS,
  LIST_ALL_SUBJECTS_REQUEST,
  ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_SUCCESS,
  ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_ERROR,
  EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_SUCCESS,
  EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_ERROR,
  LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_SUCCESS,
  LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_ERROR,
  GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST,
  GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_SUCCESS,
  GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_ERROR,
} from '../actions';

const INIT_STATE = {
  currentSubject: null,
  currentSubjectsList: null,
  diagnosticAssessments: null,
  diagnosticAssessment: null,
  allSubjects: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentSubject: null,
        diagnosticAssessment: null,
        success: '',
      };
    case GET_SUBJECTS_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSubject: action.payload,
        error: '',
      };
    case GET_SUBJECTS_ERROR:
      return {
        ...state,
        loading: false,
        currentSubject: null,
        error: action.payload.message,
      };
    case LIST_SUBJECTS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSubjectsList: action.payload,
        error: '',
      };
    case LIST_SUBJECTS_ERROR:
      return {
        ...state,
        loading: false,
        currentSubjectsList: null,
        error: action.payload.message,
      };
    case LIST_ALL_SUBJECTS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_ALL_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allSubjects: action.payload,
        error: '',
      };
    case LIST_ALL_SUBJECTS_ERROR:
      return {
        ...state,
        loading: false,
        allSubjects: null,
        error: action.payload.message,
      };
    case ADD_SUBJECTS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_SUBJECTS_SUCCESS: {
      const allSubjects = [...state.currentSubjectsList, action.payload];

      return {
        ...state,
        currentSubjectsList: allSubjects,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_SUBJECTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_SUBJECTS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_SUBJECTS_SUCCESS: {
      const subjectsUpdated = state.currentSubjectsList.map((subjects) => {
        if (subjects.id === action.payload.subjects.id) {
          return action.payload.subjects;
        }
        return subjects;
      });

      return {
        ...state,
        currentSubjectsList: subjectsUpdated,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_SUBJECT_DIAGNOSTIC_ASSESSMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case EDIT_SUBJECT_DIAGNOSTIC_ASSESSMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        diagnosticAssessments: action.payload.diagnosticAssessments,
        loading: false,
        error: '',
      };
    }
    case LIST_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_SUCCESS: {
      return {
        ...state,
        diagnosticAssessment: action.payload.diagnosticAssessment,
        loading: false,
        error: '',
      };
    }
    case GET_SUBJECTS_DIAGNOSTIC_ASSESSMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
