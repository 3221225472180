import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  GET_METRICS_REQUEST,
  GET_SUBJECT_CATEGORIZATION_REQUEST,
  GET_RANK_TIME_REQUEST,
  GET_RANK_ACTIVITIES_REQUEST,
  GET_COMMENTS_REQUEST,
  DELETE_COMMENTS_REQUEST,
  GET_WEEKLY_PROGRESS_REQUEST,
  GET_COMMUNITY_STATUS_REQUEST,
  GET_STUDENT_COMMITMENT_ROUTINE_REQUEST,
} from '../actions';
import {
  getMetricsSuccess,
  getMetricsError,
  getSubjectCategorizationSuccess,
  getSubjectCategorizationError,
  getRankTimeSuccess,
  getRankTimeError,
  getRankActivitiesSuccess,
  getRankActivitiesError,
  getCommentsSuccess,
  getCommentsError,
  deleteCommentsSuccess,
  deleteCommentsError,
  getWeeklyProgressSuccess,
  getWeeklyProgressError,
  getCommunityStatusSuccess,
  getCommunityStatusError,
  getStudentCommitmentRoutineSuccess,
  getStudentCommitmentRoutineError
} from './actions';

const getMetricsRequestAsync = (classId) =>
  api
    .get(`dashboard/metrics/classes/${classId}`)
    .then((response) => response)
    .catch((error) => error);

const getSubjectsCategorizationRequestAsync = (cycleIs, classId, filter) =>
  api
    .get(
      `/students/reports/proficiencies/categorization/classes/${classId}/cycle/${cycleIs}${filter}`
    )
    .then((response) => response)
    .catch((error) => error);

const getSubjectRankByTimeStudiedRequestAsync = (cycleIs, classId, filter) =>
  api
    .get(
      `/activities/time-studied/classes/${classId}/cycle/${cycleIs}${filter}`
    )
    .then((response) => response)
    .catch((error) => error);

const getSubjectRankByActivitiesConcludedRequestAsync = (
  cycleIs,
  classId,
  filter
) =>
  api
    .get(`/activities/concluded/classes/${classId}/cycle/${cycleIs}${filter}`)
    .then((response) => response)
    .catch((error) => error);

const getCommentsRequestAsync = (cycleIs, classId) =>
  api
    .get(`/comments/classes/${classId}/cycle/${cycleIs}`)
    .then((response) => response)
    .catch((error) => error);

const getWeeklyProgressAsync = (classId, filter) =>
  api
    .get(`/activities/weekly-progress/classes/${classId}${filter}`)
    .then((response) => response)
    .catch((error) => error);

const getCommunityStatusAsync = (classId, cycleIs, filter) =>
  api
    .get(`/community-status/classes/${classId}/cycle/${cycleIs}${filter}`)
    .then((response) => response)
    .catch((error) => error);

function* getMetrics({ payload }) {
  try {
    const { classId } = payload;
    const resMetrics = yield call(getMetricsRequestAsync, classId);
    yield put(getMetricsSuccess({ ...resMetrics.data.data }));
  } catch (error) {
    yield put(getMetricsError(error));
  }
}

function* getSubjectCategorization({ payload }) {
  try {
    const { cycle, classId, studentId } = payload;

    let filter = '';

    if (studentId) {
      filter = `/?studentId=${studentId}`;
    }

    const resCategorization = yield call(
      getSubjectsCategorizationRequestAsync,
      cycle,
      classId,
      filter
    );
    yield put(
      getSubjectCategorizationSuccess({
        subjects_categorization: resCategorization.data.data,
      })
    );
  } catch (error) {
    yield put(getSubjectCategorizationError(error));
  }
}

function* getRankTime({ payload }) {
  try {
    const { cycle, classId, studentId } = payload;

    let filter = '';

    if (studentId) {
      filter = `/?studentId=${studentId}`;
    }

    const resRankTime = yield call(
      getSubjectRankByTimeStudiedRequestAsync,
      cycle,
      classId,
      filter
    );
    yield put(
      getRankTimeSuccess({ subjects_study_time_rank: resRankTime.data.data })
    );
  } catch (error) {
    yield put(getRankTimeError(error));
  }
}

function* getRankActivities({ payload }) {
  try {
    const { cycle, classId, studentId } = payload;

    let filter = '';

    if (studentId) {
      filter = `/?studentId=${studentId}`;
    }
    const resRankActivities = yield call(
      getSubjectRankByActivitiesConcludedRequestAsync,
      cycle,
      classId,
      filter
    );
    yield put(
      getRankActivitiesSuccess({
        subjects_activities_rank: resRankActivities.data.data,
      })
    );
  } catch (error) {
    yield put(getRankActivitiesError(error));
  }
}

function* getComments({ payload }) {
  try {
    const { cycle, classId } = payload;
    const resComments = yield call(getCommentsRequestAsync, cycle, classId);
    yield put(getCommentsSuccess({ comments: resComments.data.data }));
  } catch (error) {
    yield put(getCommentsError(error));
  }
}

function* getWeeklyProgress({ payload }) {
  try {
    const { classId, studentId } = payload;
    let filter = '';

    if (studentId) {
      filter = `/?studentId=${studentId}`;
    }

    const resWeeklyProgress = yield call(
      getWeeklyProgressAsync,
      classId,
      filter
    );
    yield put(
      getWeeklyProgressSuccess({ weekly_progress: resWeeklyProgress.data.data })
    );
  } catch (error) {
    yield put(getWeeklyProgressError(error));
  }
}

function* getCommunityStatus({ payload }) {
  try {
    const { cycle, classId, studentId } = payload;

    let filter = '';

    if (studentId) {
      filter = `/?studentId=${studentId}`;
    }

    const resCommunityStatus = yield call(
      getCommunityStatusAsync,
      classId,
      cycle,
      filter
    );
    yield put(
      getCommunityStatusSuccess({
        community_status: resCommunityStatus.data.data,
      })
    );
  } catch (error) {
    yield put(getCommunityStatusError(error));
  }
}

const getStudentCommitmentRoutineAsync = (cycle, filter) =>
  api
    .get(`/dashboard/classes/cycle/${cycle}/students/commitment-routine${filter}`)
    .then((response) => response)
    .catch((error) => error);

function* getStudentCommitmentRoutine({ payload }) {
  try {
    const { cycle, subjectId, subjectIndex } = payload;

    let filter = '';

    if (subjectId) {
      filter = `/?subjectId=${subjectId}`;
    }

    const response = yield call(
      getStudentCommitmentRoutineAsync,
      cycle,
      filter
    );
    yield put(
      getStudentCommitmentRoutineSuccess({
        student_commitment_routine: response.data.data,
        subjectIndex
      })
    );
  } catch (error) {
    yield put(getStudentCommitmentRoutineError(error));
  }
}

function* deleteComment({ payload }) {
  try {
    const { id } = payload;
    yield call(api.put, `comments/${id}`, {
      active: false,
    });
    yield put(deleteCommentsSuccess(id));
  } catch (error) {
    yield put(deleteCommentsError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_METRICS_REQUEST, getMetrics),
    takeLatest(GET_SUBJECT_CATEGORIZATION_REQUEST, getSubjectCategorization),
    takeLatest(GET_RANK_TIME_REQUEST, getRankTime),
    takeLatest(GET_RANK_ACTIVITIES_REQUEST, getRankActivities),
    takeLatest(GET_COMMENTS_REQUEST, getComments),
    takeLatest(GET_WEEKLY_PROGRESS_REQUEST, getWeeklyProgress),
    takeLatest(GET_COMMUNITY_STATUS_REQUEST, getCommunityStatus),
    takeLatest(GET_STUDENT_COMMITMENT_ROUTINE_REQUEST, getStudentCommitmentRoutine),
    takeLatest(DELETE_COMMENTS_REQUEST, deleteComment),
  ]);
}
