import {
  LIST_CRITERIONS_GROUPS_REQUEST,
  LIST_CRITERIONS_GROUPS_SUCCESS,
  LIST_CRITERIONS_GROUPS_ERROR,
  GET_CRITERIONS_GROUPS_REQUEST,
  GET_CRITERIONS_GROUPS_SUCCESS,
  GET_CRITERIONS_GROUPS_ERROR,
  ADD_CRITERIONS_GROUPS_REQUEST,
  ADD_CRITERIONS_GROUPS_SUCCESS,
  ADD_CRITERIONS_GROUPS_ERROR,
  EDIT_CRITERIONS_GROUPS_REQUEST,
  EDIT_CRITERIONS_GROUPS_SUCCESS,
  EDIT_CRITERIONS_GROUPS_ERROR,
  EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_REQUEST,
  EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_SUCCESS,
  EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_ERROR,
  GET_CRITERIONS_GROUPS_TYPES_REQUEST,
  GET_CRITERIONS_GROUPS_TYPES_SUCCESS,
  GET_CRITERIONS_GROUPS_TYPES_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  currentCriterionsGroups: null,
  currentCriterionsGroupsList: null,
  groupTypeId: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentCriterionsGroups: null,
        success: '',
      };
    case GET_CRITERIONS_GROUPS_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_CRITERIONS_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCriterionsGroups: action.payload,
        error: '',
      };
    case GET_CRITERIONS_GROUPS_ERROR:
      return {
        ...state,
        loading: false,
        currentCriterionsGroups: null,
        error: action.payload.message,
      };
    case LIST_CRITERIONS_GROUPS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_CRITERIONS_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCriterionsGroupsList: action.payload,
        error: '',
      };
    case LIST_CRITERIONS_GROUPS_ERROR:
      return {
        ...state,
        loading: false,
        currentCriterionsGroupsList: null,
        error: action.payload.message,
      };
    case ADD_CRITERIONS_GROUPS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_CRITERIONS_GROUPS_SUCCESS: {
      const allCriterions = [
        ...state.currentCriterionsGroupsList,
        action.payload,
      ];

      return {
        ...state,
        currentCriterionsGroupsList: allCriterions,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case ADD_CRITERIONS_GROUPS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_CRITERIONS_GROUPS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_CRITERIONS_GROUPS_SUCCESS: {
      const criterionsUpdated = state.currentCriterionsGroupsList.map(
        (criterion) => {
          if (criterion.id === action.payload.criterionGroup.id) {
            return action.payload.criterionGroup;
          }
          return criterion;
        }
      );

      return {
        ...state,
        currentCriterionsGroupsList: criterionsUpdated,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case EDIT_CRITERIONS_GROUPS_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    case EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: null,
      };
    }
    case EDIT_CRITERIONS_GROUPS_VINCULATE_CRITERIONS_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    case GET_CRITERIONS_GROUPS_TYPES_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_CRITERIONS_GROUPS_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        groupTypeId: action.payload,
        error: '',
      };
    case GET_CRITERIONS_GROUPS_TYPES_ERROR:
      return {
        ...state,
        loading: false,
        groupTypeId: null,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
