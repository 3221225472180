import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../services/api';
import {
  LIST_STUDENT_REQUEST,
  LIST_STUDENT_WITH_DELETED_REQUEST,
  GET_STUDENT_REQUEST,
  EDIT_ACTIVE_STUDENT_REQUEST,
  IMPORT_STUDENT_REQUEST,
  GET_STUDY_ROUTINE_REQUEST,
  RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_REQUEST,
  DELETE_CLASS_STUDENT_REQUEST,
} from '../actions';
import {
  listStudentSuccess,
  listStudentError,
  listStudentWithDeletedSuccess,
  listStudentWithDeletedError,
  getStudentSuccess,
  getStudentError,
  editActiveStudentSuccess,
  editActiveStudentError,
  importStudentError,
  importStudentSuccess,
  getStudyRoutineError,
  getStudyRoutineSuccess,
  resetStudentPasswordByAdministratorSuccess,
  resetStudentPasswordByAdministratorError,
  deleteClassStudentSuccess,
  deleteClassStudentError,
} from './actions';
import { saveData } from '../../helpers/downloadFile';
import getApiErrors from '../../helpers/getApiErrors';

function* getStudentById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `students/${id}`);
    yield put(getStudentSuccess(response.data.data));
  } catch (error) {
    yield put(getStudentError(error));
  }
}

function* listStudent() {
  try {
    const response = yield call(api.get, `administrators/students/classes`);
    yield put(
      listStudentSuccess(
        response.data.data.map((student) => {
          return { ...student, checked: false };
        })
      )
    );
  } catch (error) {
    yield put(listStudentError(error));
  }
}

function* listStudentWithDeleted() {
  try {
    const response = yield call(api.get, `administrators/students/classes/all`);
    yield put(listStudentWithDeletedSuccess(response.data.data));
  } catch (error) {
    yield put(listStudentWithDeletedError(error));
  }
}

function* editActiveStudent({ payload }) {
  try {
    const { id, active } = payload;

    if (active) {
      yield call(api.delete, `students/${id}`);
    } else {
      yield call(api.put, `administrators/students/${id}/restore`);
    }
    yield put(editActiveStudentSuccess(id, !active));
  } catch (error) {
    yield put(editActiveStudentError(error));
  }
}

function* resetStudentPasswordByAdministrator({ payload }) {
  try {
    const { id } = payload;

    const res = yield call(api.put, `administrators/students/${id}/reset`);
    if (!res.response) {
      yield put(resetStudentPasswordByAdministratorSuccess(res.data));
      toast.success(`${res.data.message}`);
    } else {
      yield put(resetStudentPasswordByAdministratorError(res.response.data));
      getApiErrors(res.response.data);
    }
  } catch (error) {
    yield put(resetStudentPasswordByAdministratorError(error));
    getApiErrors(error);
  }
}

const importStudentAsync = async (file) =>
  api
    .post(`administrators/students/import`, file)
    .then((response) => response)
    .catch((error) => error);

function* importStudent({ payload }) {
  try {
    const { file } = payload;
    const res = yield call(importStudentAsync, file);
    if (!res.response) {
      yield put(importStudentSuccess(res.data));
    } else {
      yield put(importStudentError(res.response.data));
    }
  } catch (error) {
    yield put(importStudentError(error));
  }
}

const getStudyRoutineAsync = async (body) =>
  api
    .post(`students/reports/pdf/study-routine`, body, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response)
    .catch((error) => error);

function* getStudyRoutine({ payload }) {
  try {
    const { classId, cycleIs, students } = payload;
    const body = {
      class_id: classId,
      cycle_is: cycleIs,
      students,
    };
    const res = yield call(getStudyRoutineAsync, body);
    if (!res.response) {
      saveData(
        res.data,
        `study-routine-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.zip`
      );
      yield put(getStudyRoutineSuccess(res.data));
    } else {
      const { message } = JSON.parse(
        new TextDecoder('utf-8').decode(res.response.data)
      );
      toast.error(message);
      yield put(getStudyRoutineError(message));
    }
  } catch (error) {
    yield put(getStudyRoutineError(error));
  }
}

const deleteClassStudentAsync = async (classStudentId) =>
  api
    .delete(`class-students/${classStudentId}`)
    .then((response) => response)
    .catch((error) => error);

function* deleteClassStudent({ payload }) {
  try {
    const { classStudentId } = payload;

    const res = yield call(deleteClassStudentAsync, classStudentId);
    if (!res.response) {
      const message = 'Excluído com sucesso!';
      yield put(deleteClassStudentSuccess(classStudentId, message));
    } else {
      yield put(deleteClassStudentError(res.response.data));
    }
  } catch (error) {
    yield put(deleteClassStudentError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_STUDENT_REQUEST, listStudent),
    takeLatest(LIST_STUDENT_WITH_DELETED_REQUEST, listStudentWithDeleted),
    takeLatest(GET_STUDENT_REQUEST, getStudentById),
    takeLatest(EDIT_ACTIVE_STUDENT_REQUEST, editActiveStudent),
    takeLatest(IMPORT_STUDENT_REQUEST, importStudent),
    takeLatest(GET_STUDY_ROUTINE_REQUEST, getStudyRoutine),
    takeLatest(RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_REQUEST, resetStudentPasswordByAdministrator),
    takeLatest(DELETE_CLASS_STUDENT_REQUEST, deleteClassStudent),
  ]);
}
