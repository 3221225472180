import {
  LIST_CYCLE_REQUEST,
  LIST_CYCLE_SUCCESS,
  LIST_CYCLE_ERROR,
  RESET_STATE_ALL_APPLICATION,
  RESET_STATE_REDUX
} from '../actions';

const INIT_STATE = {
  currentCycleList: null,
  loading: false,
  success: '',
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        success: '',
      };
    case LIST_CYCLE_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_CYCLE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCycleList: action.payload,
        error: '',
      };
    case LIST_CYCLE_ERROR:
      return {
        ...state,
        loading: false,
        currentCycleList: null,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
