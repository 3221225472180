import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PROFILE_IMAGE_REQUEST,
  CHANGE_PROFILE_IMAGE_SUCCESS,
  CHANGE_PROFILE_IMAGE_ERROR,
  GET_CONFIGURATIONS_REQUEST,
  GET_CONFIGURATIONS_SUCCESS,
  GET_CONFIGURATIONS_ERROR,
  RESET_DB_TESTS_REQUEST,
  RESET_DB_TESTS_SUCCESS,
  RESET_DB_TESTS_ERROR
} from "../actions";

export const changePasswordRequest = (id, data) => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload: { id, data },
});
export const changePasswordSuccess = (message) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: { message },
});
export const changePasswordError = (message) => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: { message },
});

export const changeProfileImageRequest = (data) => ({
  type: CHANGE_PROFILE_IMAGE_REQUEST,
  payload: { data },
});
export const changeProfileImageSuccess = (message) => ({
  type: CHANGE_PROFILE_IMAGE_SUCCESS,
  payload: { message },
});
export const changeProfileImageError = (message) => ({
  type: CHANGE_PROFILE_IMAGE_ERROR,
  payload: { message },
});

export const getConfigurationsRequest = (id) => ({
  type: GET_CONFIGURATIONS_REQUEST,
  payload: { id },
});
export const getConfigurationsSuccess = (configurations) => ({
  type: GET_CONFIGURATIONS_SUCCESS,
  payload: configurations,
});
export const getConfigurationsError = (message) => ({
  type: GET_CONFIGURATIONS_ERROR,
  payload: { message },
});

export const resetDbTestsRequest = () => ({
  type: RESET_DB_TESTS_REQUEST,
});
export const resetDbTestsSuccess = (message) => ({
  type: RESET_DB_TESTS_SUCCESS,
  payload: message,
});
export const resetDbTestsError = (message) => ({
  type: RESET_DB_TESTS_ERROR,
  payload: { message },
});
