import {
  LIST_VIDEO_REQUEST,
  LIST_VIDEO_SUCCESS,
  LIST_VIDEO_ERROR,
  GET_VIDEO_REQUEST,
  GET_VIDEO_SUCCESS,
  GET_VIDEO_ERROR,
  ADD_VIDEO_REQUEST,
  ADD_VIDEO_SUCCESS,
  ADD_VIDEO_ERROR,
  EDIT_VIDEO_REQUEST,
  EDIT_VIDEO_SUCCESS,
  EDIT_VIDEO_ERROR,
  EDIT_ACTIVE_VIDEO_REQUEST,
  EDIT_ACTIVE_VIDEO_SUCCESS,
  EDIT_ACTIVE_VIDEO_ERROR,
  ORDINATE_VIDEO_REQUEST,
  ORDINATE_VIDEO_SUCCESS,
  ORDINATE_VIDEO_ERROR,
} from '../actions';

export const getVideoRequest = (id) => ({
  type: GET_VIDEO_REQUEST,
  payload: { id },
});
export const getVideoSuccess = (video) => ({
  type: GET_VIDEO_SUCCESS,
  payload: video,
});
export const getVideoError = (message) => ({
  type: GET_VIDEO_ERROR,
  payload: { message },
});

export const listVideoRequest = () => ({
  type: LIST_VIDEO_REQUEST,
});
export const listVideoSuccess = (activities) => ({
  type: LIST_VIDEO_SUCCESS,
  payload: activities,
});
export const listVideoError = (message) => ({
  type: LIST_VIDEO_ERROR,
  payload: { message },
});

export const addVideoRequest = (video) => ({
  type: ADD_VIDEO_REQUEST,
  payload: { video },
});
export const addVideoSuccess = (video) => ({
  type: ADD_VIDEO_SUCCESS,
  payload: video,
});
export const addVideoError = (message) => ({
  type: ADD_VIDEO_ERROR,
  payload: { message },
});

export const editVideoRequest = (video) => ({
  type: EDIT_VIDEO_REQUEST,
  payload: { video },
});
export const editVideoSuccess = (video) => ({
  type: EDIT_VIDEO_SUCCESS,
  payload: { video },
});
export const editVideoError = (message) => ({
  type: EDIT_VIDEO_ERROR,
  payload: { message },
});

export const editActiveVideoRequest = (id, active) => ({
  type: EDIT_ACTIVE_VIDEO_REQUEST,
  payload: { id, active },
});
export const editActiveVideoSuccess = (id, video) => ({
  type: EDIT_ACTIVE_VIDEO_SUCCESS,
  payload: { id, video },
});
export const editActiveVideoError = (message) => ({
  type: EDIT_ACTIVE_VIDEO_ERROR,
  payload: { message },
});

export const ordinateVideoRequest = (video) => ({
  type: ORDINATE_VIDEO_REQUEST,
  payload: { video },
});
export const ordinateVideoSuccess = (message) => ({
  type: ORDINATE_VIDEO_SUCCESS,
  payload: message,
});
export const ordinateVideoError = (message) => ({
  type: ORDINATE_VIDEO_ERROR,
  payload: { message },
});
