import {
  LIST_EVENT_REPEATS_REQUEST,
  LIST_EVENT_REPEATS_SUCCESS,
  LIST_EVENT_REPEATS_ERROR,
  RESET_STATE_ALL_APPLICATION,
} from '../../actions';

const INIT_STATE = {
  currentEventRepeatList: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case LIST_EVENT_REPEATS_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_EVENT_REPEATS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentEventRepeatList: action.payload,
        error: '',
      };
    case LIST_EVENT_REPEATS_ERROR:
      return {
        ...state,
        loading: false,
        currentEventRepeatList: null,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
