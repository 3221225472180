import {
  LIST_STUDENT_REQUEST,
  LIST_STUDENT_SUCCESS,
  LIST_STUDENT_ERROR,
  LIST_STUDENT_WITH_DELETED_REQUEST,
  LIST_STUDENT_WITH_DELETED_SUCCESS,
  LIST_STUDENT_WITH_DELETED_ERROR,
  GET_STUDENT_REQUEST,
  GET_STUDENT_SUCCESS,
  GET_STUDENT_ERROR,
  EDIT_ACTIVE_STUDENT_REQUEST,
  EDIT_ACTIVE_STUDENT_SUCCESS,
  EDIT_ACTIVE_STUDENT_ERROR,
  IMPORT_STUDENT_REQUEST,
  IMPORT_STUDENT_SUCCESS,
  IMPORT_STUDENT_ERROR,
  CHECK_STUDENT_REQUEST,
  CHECK_ALL_STUDENT_REQUEST,
  UNCHECK_ALL_STUDENT_REQUEST,
  GET_STUDY_ROUTINE_REQUEST,
  GET_STUDY_ROUTINE_SUCCESS,
  GET_STUDY_ROUTINE_ERROR,
  RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_REQUEST,
  RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_SUCCESS,
  RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_ERROR,
  DELETE_CLASS_STUDENT_REQUEST,
  DELETE_CLASS_STUDENT_SUCCESS,
  DELETE_CLASS_STUDENT_ERROR,
} from '../actions';

export const getStudentRequest = (id) => ({
  type: GET_STUDENT_REQUEST,
  payload: { id },
});
export const getStudentSuccess = (student) => ({
  type: GET_STUDENT_SUCCESS,
  payload: student,
});
export const getStudentError = (message) => ({
  type: GET_STUDENT_ERROR,
  payload: { message },
});

export const listStudentRequest = () => ({
  type: LIST_STUDENT_REQUEST,
});
export const listStudentSuccess = (students) => ({
  type: LIST_STUDENT_SUCCESS,
  payload: students,
});
export const listStudentError = (message) => ({
  type: LIST_STUDENT_ERROR,
  payload: { message },
});

export const listStudentWithDeletedRequest = () => ({
  type: LIST_STUDENT_WITH_DELETED_REQUEST,
});
export const listStudentWithDeletedSuccess = (students) => ({
  type: LIST_STUDENT_WITH_DELETED_SUCCESS,
  payload: students,
});
export const listStudentWithDeletedError = (message) => ({
  type: LIST_STUDENT_WITH_DELETED_ERROR,
  payload: { message },
});

export const editActiveStudentRequest = (id, active) => ({
  type: EDIT_ACTIVE_STUDENT_REQUEST,
  payload: { id, active },
});
export const editActiveStudentSuccess = (id, active) => ({
  type: EDIT_ACTIVE_STUDENT_SUCCESS,
  payload: { id, active },
});
export const editActiveStudentError = (message) => ({
  type: EDIT_ACTIVE_STUDENT_ERROR,
  payload: { message },
});

export const importStudentRequest = (file) => ({
  type: IMPORT_STUDENT_REQUEST,
  payload: { file },
});
export const importStudentSuccess = (message) => ({
  type: IMPORT_STUDENT_SUCCESS,
  payload: message,
});
export const importStudentError = (message) => ({
  type: IMPORT_STUDENT_ERROR,
  payload: { message },
});

export const getStudyRoutineRequest = (classId, cycleIs, students) => ({
  type: GET_STUDY_ROUTINE_REQUEST,
  payload: { classId, cycleIs, students },
});
export const getStudyRoutineSuccess = (message) => ({
  type: GET_STUDY_ROUTINE_SUCCESS,
  payload: message,
});
export const getStudyRoutineError = (message) => ({
  type: GET_STUDY_ROUTINE_ERROR,
  payload: { message },
});

export const checkStudentRequest = (id) => ({
  type: CHECK_STUDENT_REQUEST,
  payload: { id },
});

export const checkAllStudentRequest = () => ({
  type: CHECK_ALL_STUDENT_REQUEST,
});

export const uncheckAllStudentRequest = () => ({
  type: UNCHECK_ALL_STUDENT_REQUEST,
});

export const resetStudentPasswordByAdministratorRequest = (id) => ({
  type: RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_REQUEST,
  payload: { id },
});
export const resetStudentPasswordByAdministratorSuccess = (message) => ({
  type: RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_SUCCESS,
  payload: message,
});
export const resetStudentPasswordByAdministratorError = (message) => ({
  type: RESET_STUDENT_PASSWORD_BY_ADMINISTRATOR_ERROR,
  payload: { message },
});

export const deleteClassStudentRequest = (classStudentId) => ({
  type: DELETE_CLASS_STUDENT_REQUEST,
  payload: { classStudentId },
});
export const deleteClassStudentSuccess = (classStudentId, message) => ({
  type: DELETE_CLASS_STUDENT_SUCCESS,
  payload: { classStudentId, message },
});
export const deleteClassStudentError = (message) => ({
  type: DELETE_CLASS_STUDENT_ERROR,
  payload: { message },
});
