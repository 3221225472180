import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  LIST_VIDEO_REQUEST,
  GET_VIDEO_REQUEST,
  ADD_VIDEO_REQUEST,
  EDIT_VIDEO_REQUEST,
  EDIT_ACTIVE_VIDEO_REQUEST,
  ORDINATE_VIDEO_REQUEST,
} from '../actions';
import {
  listVideoSuccess,
  listVideoError,
  getVideoSuccess,
  getVideoError,
  addVideoSuccess,
  addVideoError,
  editVideoSuccess,
  editVideoError,
  editActiveVideoSuccess,
  editActiveVideoError,
  ordinateVideoSuccess,
  ordinateVideoError,
} from './actions';

function* getVideoById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `videos/${id}`);
    yield put(getVideoSuccess(response.data.data));
  } catch (error) {
    yield put(getVideoError(error));
  }
}

function* listVideo() {
  try {
    const response = yield call(api.get, 'videos');
    const allVideos = response.data.data;
    const onlyVideo = allVideos.filter((video) => {
      return !video.is_exam;
    });
    yield put(listVideoSuccess(onlyVideo));
  } catch (error) {
    yield put(listVideoError(error));
  }
}

const addVideoAsync = async (completeVideo) =>
  api
    .post(`videos`, {
      ...completeVideo,
    })
    .then((response) => response)
    .catch((error) => error);

const editVideoAsync = async (completeVideo) =>
  api
    .put(`videos/${completeVideo.id}`, {
      ...completeVideo,
    })
    .then((response) => response)
    .catch((error) => error);

function* addVideo({ payload }) {
  try {
    const { video } = payload;
    const completeVideo = { ...video, actionType: 'edit' };
    const res = yield call(addVideoAsync, completeVideo);
    if (!res.response) {
      yield put(addVideoSuccess(res.data.data));
    } else {
      yield put(addVideoError(res.response.data));
    }
  } catch (error) {
    yield put(addVideoError(error));
  }
}

function* editVideo({ payload }) {
  try {
    const { video } = payload;
    const completeVideo = { ...video, actionType: 'edit' };
    const res = yield call(editVideoAsync, completeVideo);
    if (!res.response) {
      yield put(editVideoSuccess(res.data.data));
    } else {
      yield put(editVideoError(res.response.data));
    }
  } catch (error) {
    yield put(editVideoError(error));
  }
}

function* editActiveVideo({ payload }) {
  try {
    const { id, active } = payload;
    const res = yield call(api.put, `videos/${id}`, {
      active,
      actionType: 'activation',
    });

    if (!res.response) {
      yield put(editActiveVideoSuccess(id, res.data.data));
    } else {
      yield put(editActiveVideoError(res.response.data));
    }
  } catch (error) {
    yield put(editActiveVideoError(error));
  }
}

const ordinateVideoAsync = async (completeVideo) =>
  api
    .post(`videos/ordination`, {
      ...completeVideo,
    })
    .then((response) => response)
    .catch((error) => error);

function* ordinateVideo({ payload }) {
  try {
    const { video } = payload;
    const completeVideo = { data: video };
    const res = yield call(ordinateVideoAsync, completeVideo);
    if (!res.response) {
      yield put(ordinateVideoSuccess(res.data));
    } else {
      yield put(ordinateVideoError(res.response.data));
    }
  } catch (error) {
    yield put(ordinateVideoError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_VIDEO_REQUEST, listVideo),
    takeLatest(GET_VIDEO_REQUEST, getVideoById),
    takeLatest(ADD_VIDEO_REQUEST, addVideo),
    takeLatest(ORDINATE_VIDEO_REQUEST, ordinateVideo),
    takeLatest(EDIT_VIDEO_REQUEST, editVideo),
    takeLatest(EDIT_ACTIVE_VIDEO_REQUEST, editActiveVideo),
  ]);
}
