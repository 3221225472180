import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../../services/api';
import {
  LIST_EVENT_REPEATS_REQUEST,
} from '../../actions';
import {
  listEventRepeatsSuccess,
  listEventRepeatsError,
} from './actions';

function* listEventRepeats() {
  try {
    const response = yield call(api.get, `event-repeats`);
    yield put(listEventRepeatsSuccess(response.data.data));
  } catch (error) {
    yield put(listEventRepeatsError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_EVENT_REPEATS_REQUEST, listEventRepeats),
  ]);
}
