import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_STATE_ALL_APPLICATION,
  FORGOT_PASSWORD_CHECK_TOKEN_REQUEST,
  FORGOT_PASSWORD_CHECK_TOKEN_SUCCESS,
  FORGOT_PASSWORD_CHECK_TOKEN_ERROR,
  IMPERSONATE_REQUEST,
  IMPERSONATE_SUCCESS,
  IMPERSONATE_ERROR,
  RELOAD_PASSWORD_SEND_MAIL_REQUEST,
  RELOAD_PASSWORD_SEND_MAIL_SUCCESS,
  RELOAD_PASSWORD_SEND_MAIL_ERROR,
  CHANGE_CLASS_IMPERSONATOR,
} from '../actions';
import { getCurrentUser } from '../../helpers/Utils';
import { isAuthGuardActive, currentUser } from '../../constants/defaultValues';

const INIT_STATE = {
  currentUser: isAuthGuardActive ? currentUser : getCurrentUser(),
  forgotUserMail: '',
  success: '',
  password: '',
  token: '',
  loading: false,
  error: '',
  email: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: '',
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case FORGOT_PASSWORD_CHECK_TOKEN_REQUEST:
      return { ...state, loading: true, error: '', email: '' };
    case FORGOT_PASSWORD_CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        email: action.payload.email,
        error: '',
      };
    case FORGOT_PASSWORD_CHECK_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        email: '',
        error: action.payload.message,
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: '',
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        error: action.payload.message,
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: '', success: '' };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        token: '',
        error: '',
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        password: '',
        token: '',
        error: action.payload.message,
        success: '',
      };
    case RELOAD_PASSWORD_SEND_MAIL_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case RELOAD_PASSWORD_SEND_MAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        token: '',
        error: '',
      };
    case RELOAD_PASSWORD_SEND_MAIL_ERROR:
      return {
        ...state,
        loading: false,
        password: '',
        token: '',
        error: action.payload.message,
        success: '',
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: '' };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: '',
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case LOGOUT_USER:
      return { ...state, currentUser: null, error: '' };
    case IMPERSONATE_REQUEST:
      return { ...state, loading: true, error: '' };
    case IMPERSONATE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: '',
      };
    case IMPERSONATE_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case CHANGE_CLASS_IMPERSONATOR:
      return { ...state, loading: true, error: '' };
    default:
      return { ...state };
  }
};
