import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import authStudent from './auth/student/reducer';
import schools from './schools/reducer';
import classes from './classes/reducer';
import cycles from './cycles/reducer';
import coordinators from './coordinators/reducer';
import managers from './managers/reducer';
import networkCoordinators from './network-coordinators/reducer';
import criterions from './criterions/reducer';
import criterionsGroups from './criterions-groups/reducer';
import teachers from './teachers/reducer';
import students from './students/reducer';
import journeys from './journeys/reducer';
import journeyTrails from './journey-trails/reducer';
import examPractices from './exam-practices/reducer';
import examExternals from './exam-externals/reducer';
import videos from './videos/reducer';
import subjects from './subjects/reducer';
import prerequisites from './prerequisites/reducer';
import subareas from './subareas/reducer';
import activities from './activities/reducer';
import exams from './exams/reducer';
import configurations from './configurations/reducer';
import dashboard from './dashboard/reducer';
import news from './news/reducer';
import feedbacks from './news/feedback/reducer';
import newTypes from './news/types/reducer';
import writings from './writings/reducer';
import subjectPerformance from './subject-performance/reducer';
import studyDiagnosis from './study-diagnosis/reducer';
import questionDb from './question-db/reducer';
import incidences from './incidences/reducer';
import classTypes from './classes/types/reducer';
import classAssessmentTypes from './classes/assessment-types/reducer';
import classCycles from './classes/cycles/reducer';
import scores from './scores/reducer';
import events from './events/reducer';
import eventRepeats from './events/eventRepeats/reducer';

const reducers = combineReducers({
  menu,
  settings,
  schools,
  classes,
  coordinators,
  networkCoordinators,
  managers,
  criterions,
  writings,
  criterionsGroups,
  teachers,
  cycles,
  students,
  examPractices,
  examExternals,
  journeys,
  journeyTrails,
  videos,
  subjects,
  prerequisites,
  subareas,
  questionDb,
  authUser,
  authStudent,
  activities,
  exams,
  configurations,
  dashboard,
  news,
  feedbacks,
  newTypes,
  subjectPerformance,
  studyDiagnosis,
  incidences,
  classTypes,
  classAssessmentTypes,
  classCycles,
  scores,
  events,
  eventRepeats,
});

export default reducers;
