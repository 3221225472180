import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  LIST_COORDINATOR_REQUEST,
  GET_COORDINATOR_REQUEST,
  ADD_COORDINATOR_REQUEST,
  EDIT_COORDINATOR_REQUEST,
  EDIT_ACTIVE_COORDINATOR_REQUEST,
} from '../actions';
import {
  listCoordinatorSuccess,
  listCoordinatorError,
  getCoordinatorSuccess,
  getCoordinatorError,
  addCoordinatorSuccess,
  addCoordinatorError,
  editCoordinatorSuccess,
  editCoordinatorError,
  editActiveCoordinatorSuccess,
  editActiveCoordinatorError,
} from './actions';
import { UserRole } from '../../helpers/authHelper';
import { currentUser } from '../../constants/defaultValues';
import { getChildrenRecursiveIds } from '../../utils/getChildrenRecursiveIds';

function* getCoordinatorById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `administrators/${id}`);
    yield put(getCoordinatorSuccess(response.data.data));
  } catch (error) {
    yield put(getCoordinatorError(error));
  }
}

const addCoordinatorsAsync = async (completeCoordinator) =>
  api
    .post(`backoffice/register`, {
      ...completeCoordinator,
    })
    .then((response) => response)
    .catch((error) => error);

const editCoordinatorsAsync = async (completeCoordinator) =>
  api
    .put(`administrators/${completeCoordinator.id}`, {
      ...completeCoordinator,
    })
    .then((response) => response)
    .catch((error) => error);

function* listCoordinator() {
  try {
    const response = yield call(
      api.get,
      `administrators/permission-level/${UserRole.Coordinator}${
        currentUser.role !== UserRole.Admin
          ? `?schoolId=${getChildrenRecursiveIds(currentUser).join(',')}`
          : ''
      }`
    );

    yield put(listCoordinatorSuccess(response.data.data));
  } catch (error) {
    yield put(listCoordinatorError(error));
  }
}

function* addCoordinator({ payload }) {
  try {
    const { coordinator } = payload;
    const completeCoordinator = { ...coordinator, actionType: 'edit' };
    const res = yield call(addCoordinatorsAsync, completeCoordinator);
    if (!res.response) {
      const responseValue = res.data.data;
      const formatedCoordinator = {
        name: responseValue.name,
        active: responseValue.active,
        id: responseValue.id,
        schools: responseValue.schools,
        email: responseValue.email,
      };
      yield put(addCoordinatorSuccess(formatedCoordinator));
    } else {
      yield put(addCoordinatorError(res.response.data));
    }
  } catch (error) {
    yield put(addCoordinatorError(error));
  }
}

function* editCoordinator({ payload }) {
  try {
    const { coordinator } = payload;
    const completeCoordinator = { ...coordinator, actionType: 'edit' };
    const res = yield call(editCoordinatorsAsync, completeCoordinator);
    if (!res.response) {
      const responseValue = res.data.data;
      const formatedCoordinator = {
        name: responseValue.name,
        active: responseValue.active,
        id: responseValue.id,
        schools: responseValue.schools,
        email: responseValue.email,
      };
      yield put(editCoordinatorSuccess(formatedCoordinator));
    } else {
      yield put(editCoordinatorError(res.response.data));
    }
  } catch (error) {
    yield put(editCoordinatorError(error));
  }
}

function* editActiveCoordinator({ payload }) {
  try {
    const { id, active } = payload;
    const response = yield call(api.put, `administrators/${id}`, {
      active,
      actionType: 'activation',
    });
    const responseValue = response.data.data;
    const formatedCoordinator = {
      name: responseValue.name,
      active: responseValue.active,
      id: responseValue.id,
      schools: responseValue.schools,
      email: responseValue.email,
    };
    yield put(editActiveCoordinatorSuccess(id, formatedCoordinator));
  } catch (error) {
    yield put(editActiveCoordinatorError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_COORDINATOR_REQUEST, listCoordinator),
    takeLatest(GET_COORDINATOR_REQUEST, getCoordinatorById),
    takeLatest(ADD_COORDINATOR_REQUEST, addCoordinator),
    takeLatest(EDIT_COORDINATOR_REQUEST, editCoordinator),
    takeLatest(EDIT_ACTIVE_COORDINATOR_REQUEST, editActiveCoordinator),
  ]);
}
