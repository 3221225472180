import {
  LIST_TEACHER_REQUEST,
  LIST_TEACHER_SUCCESS,
  LIST_TEACHER_ERROR,
  LIST_TEACHER_SUBJECT_REQUEST,
  LIST_TEACHER_SUBJECT_SUCCESS,
  LIST_TEACHER_SUBJECT_ERROR,
  LIST_TEACHER_ACTIVITY_REQUEST,
  LIST_TEACHER_ACTIVITY_SUCCESS,
  LIST_TEACHER_ACTIVITY_ERROR,
  CONCLUDE_TEACHER_ACTIVITY_REQUEST,
  CONCLUDE_TEACHER_ACTIVITY_SUCCESS,
  CONCLUDE_TEACHER_ACTIVITY_ERROR,
  GET_TEACHER_REQUEST,
  GET_TEACHER_SUCCESS,
  GET_TEACHER_ERROR,
  ADD_TEACHER_REQUEST,
  ADD_TEACHER_SUCCESS,
  ADD_TEACHER_ERROR,
  ADD_TEACHER_LINK_REQUEST,
  ADD_TEACHER_LINK_SUCCESS,
  ADD_TEACHER_LINK_ERROR,
  REMOVE_TEACHER_LINK_REQUEST,
  REMOVE_TEACHER_LINK_SUCCESS,
  REMOVE_TEACHER_LINK_ERROR,
  EDIT_TEACHER_REQUEST,
  EDIT_TEACHER_SUCCESS,
  EDIT_TEACHER_ERROR,
  IMPORT_TEACHER_ACTIVITY_REQUEST,
  IMPORT_TEACHER_ACTIVITY_SUCCESS,
  IMPORT_TEACHER_ACTIVITY_ERROR,
  REMOVE_IMPORT_TEACHER_ACTIVITY_REQUEST,
  REMOVE_IMPORT_TEACHER_ACTIVITY_SUCCESS,
  REMOVE_IMPORT_TEACHER_ACTIVITY_ERROR,
  EDIT_ACTIVE_TEACHER_REQUEST,
  EDIT_ACTIVE_TEACHER_SUCCESS,
  EDIT_ACTIVE_TEACHER_ERROR,
  EDIT_TEACHER_VINCULATE_SUBJECT_REQUEST,
  EDIT_TEACHER_VINCULATE_SUBJECT_SUCCESS,
  EDIT_TEACHER_VINCULATE_SUBJECT_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  currentTeacher: null,
  currentTeacherList: null,
  activities: null,
  subjects: null,
  loading: false,
  error: '',
  success: '',
};

const updateActivities = (state, subareaId, activityId, concludedAt) => {
  return state.activities
    .map((subarea) => {
      if (subarea.id === subareaId) {
        return {
          ...subarea,
          activities: subarea.activities.map((activity) => {
            if (activity.id === activityId) {
              return {
                ...activity,
                concluded_at: concludedAt,
              };
            }
            return activity;
          }),
        };
      }
      return subarea;
    })
    .map((subarea) => {
      if (subarea.id === subareaId) {
        const totalActivities = subarea.activities.length;
        const concludedActivities =
          subarea.activities.filter(
            (activity) => activity.concluded_at
          )?.length ?? 0;
        return {
          ...subarea,
          total_percentage: (
            (concludedActivities / totalActivities) *
            100
          ).toFixed(2),
        };
      }
      return subarea;
    });
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentTeacher: null,
        success: '',
      };
    case GET_TEACHER_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_TEACHER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentTeacher: action.payload,
        error: '',
      };
    case GET_TEACHER_ERROR:
      return {
        ...state,
        loading: false,
        currentTeacher: null,
        error: action.payload.message,
      };
    case LIST_TEACHER_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_TEACHER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentTeacherList: action.payload,
        error: '',
      };
    case LIST_TEACHER_ERROR:
      return {
        ...state,
        loading: false,
        currentTeacherList: null,
        error: action.payload.message,
      };
    case LIST_TEACHER_SUBJECT_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_TEACHER_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: action.payload,
        error: '',
      };
    case LIST_TEACHER_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
        currentTeacherList: null,
        error: action.payload.message,
      };
    case LIST_TEACHER_ACTIVITY_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_TEACHER_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        activities: action.payload,
        error: '',
      };
    case LIST_TEACHER_ACTIVITY_ERROR:
      return {
        ...state,
        loading: false,
        activities: null,
        error: action.payload.message,
      };
    case CONCLUDE_TEACHER_ACTIVITY_REQUEST:
      return { ...state, loading: true, error: '' };
    case CONCLUDE_TEACHER_ACTIVITY_SUCCESS: {
      const { subareaId, activityId, concludedAt } = action.payload;
      const updatedActivities = updateActivities(
        state,
        subareaId,
        activityId,
        concludedAt
      );
      return {
        ...state,
        loading: false,
        activities: updatedActivities,
        error: '',
      };
    }
    case CONCLUDE_TEACHER_ACTIVITY_ERROR: {
      const { subareaId, activityId, concludedAt } = action.payload;
      const updatedActivities = updateActivities(
        state,
        subareaId,
        activityId,
        concludedAt
      );
      return {
        ...state,
        loading: false,
        activities: updatedActivities,
        error: action.payload.message,
      };
    }
    case ADD_TEACHER_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_TEACHER_SUCCESS: {
      const allTeachers = [...state.currentTeacherList, action.payload];

      return {
        ...state,
        currentTeacherList: allTeachers,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case ADD_TEACHER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_TEACHER_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_TEACHER_SUCCESS: {
      const teachersUpdated = state.currentTeacherList.map((teacher) => {
        if (teacher.id === action.payload.teacher.id) {
          return action.payload.teacher;
        }
        return teacher;
      });
      return {
        ...state,
        currentTeacherList: teachersUpdated,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case EDIT_TEACHER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_ACTIVE_TEACHER_REQUEST:
      return { ...state, loading: false, error: '', success: '' };
    case EDIT_ACTIVE_TEACHER_SUCCESS: {
      const teachersUpdated = state.currentTeacherList.map((teacher) => {
        if (teacher.id === action.payload.id) {
          return action.payload.teacher;
        }
        return teacher;
      });
      return {
        ...state,
        currentTeacherList: teachersUpdated,
        success: action.payload,
        loading: false,
        error: '',
      };
    }
    case EDIT_ACTIVE_TEACHER_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    case IMPORT_TEACHER_ACTIVITY_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case IMPORT_TEACHER_ACTIVITY_SUCCESS: {
      return {
        ...state,
        error: '',
        success: action.payload,
      };
    }
    case IMPORT_TEACHER_ACTIVITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case REMOVE_IMPORT_TEACHER_ACTIVITY_REQUEST:
      return { ...state, error: '', success: '' };
    case REMOVE_IMPORT_TEACHER_ACTIVITY_SUCCESS: {
      const { subareaId, id } = action.payload;
      const updatedActivities = state.activities.map((subarea) => {
        if (subarea.id === subareaId) {
          return {
            ...subarea,
            files: subarea.files.map((file) => {
              if (file.id === id) {
                return { ...file, removed: true };
              }
              return file;
            }),
          };
        }
        return subarea;
      });
      return {
        ...state,
        error: '',
        activities: updatedActivities,
        success: action.payload.message,
      };
    }
    case REMOVE_IMPORT_TEACHER_ACTIVITY_ERROR: {
      const { subareaId, id } = action.payload;
      const updatedActivities = state.activities.map((subarea) => {
        if (subarea.id === subareaId) {
          return {
            ...subarea,
            files: subarea.files.map((file) => {
              if (file.id === id) {
                return { ...file, removed: false };
              }
              return file;
            }),
          };
        }
        return subarea;
      });
      return {
        ...state,
        activities: updatedActivities,
        error: action.payload.message,
        success: '',
      };
    }
    case EDIT_TEACHER_VINCULATE_SUBJECT_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_TEACHER_VINCULATE_SUBJECT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        success: action.payload,
      };
    }
    case EDIT_TEACHER_VINCULATE_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    case ADD_TEACHER_LINK_REQUEST:
      return { ...state, error: '', success: '', loading: true };
    case ADD_TEACHER_LINK_SUCCESS: {
      const { subareaId, link } = action.payload;
      const updatedActivities = state.activities.map((subarea) => {
        if (subarea.id === subareaId) {
          return {
            ...subarea,
            links: [...subarea.links, link],
          };
        }
        return subarea;
      });

      return {
        ...state,
        error: '',
        activities: updatedActivities,
        loading: false,
        success: action.payload,
      };
    }
    case ADD_TEACHER_LINK_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        success: '',
      };
    case REMOVE_TEACHER_LINK_REQUEST:
      return { ...state, error: '', success: '', loading: true };
    case REMOVE_TEACHER_LINK_SUCCESS: {
      const { subareaId, id } = action.payload;
      const updatedActivities = state.activities.map((subarea) => {
        if (subarea.id === subareaId) {
          return {
            ...subarea,
            links: subarea.links.filter((link) => link.id !== id),
          };
        }
        return subarea;
      });

      return {
        ...state,
        loading: false,
        activities: updatedActivities,
        error: '',
        success: action.payload,
      };
    }
    case REMOVE_TEACHER_LINK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
