import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import {
  LIST_CRITERION_REQUEST,
  GET_CRITERION_REQUEST,
  ADD_CRITERION_REQUEST,
  EDIT_CRITERION_REQUEST,
} from '../actions';
import {
  listCriterionSuccess,
  listCriterionError,
  getCriterionSuccess,
  getCriterionError,
  addCriterionSuccess,
  addCriterionError,
  editCriterionSuccess,
  editCriterionError,
} from './actions';

function* getCriterionById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.get, `criterions/${id}`);
    yield put(getCriterionSuccess(response.data.data));
  } catch (error) {
    yield put(getCriterionError(error));
  }
}

const addCriterionAsync = async (completeCriterion) =>
  api
    .post(`criterions`, {
      ...completeCriterion,
    })
    .then((response) => response)
    .catch((error) => error);

const editCriterionAsync = async (completeCriterion) =>
  api
    .put(`criterions/${completeCriterion.id}`, {
      ...completeCriterion,
    })
    .then((response) => response)
    .catch((error) => error);

function* listCriterion() {
  try {
    const response = yield call(api.get, `criterions`);
    yield put(listCriterionSuccess(response.data.data));
  } catch (error) {
    yield put(listCriterionError(error));
  }
}

function* addCriterion({ payload }) {
  try {
    const { criterion } = payload;
    const completeCriterion = { ...criterion };
    const res = yield call(addCriterionAsync, completeCriterion);
    if (!res.response) {
      const responseValue = res.data.data;
      yield put(addCriterionSuccess(responseValue));
    } else {
      yield put(addCriterionError(res.response.data));
    }
  } catch (error) {
    yield put(addCriterionError(error));
  }
}

function* editCriterion({ payload }) {
  try {
    const { criterion } = payload;
    const completeCriterion = { ...criterion };
    const res = yield call(editCriterionAsync, completeCriterion);
    if (!res.response) {
      const responseValue = res.data.data;

      yield put(editCriterionSuccess(responseValue));
    } else {
      yield put(editCriterionError(res.response.data));
    }
  } catch (error) {
    yield put(editCriterionError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_CRITERION_REQUEST, listCriterion),
    takeLatest(GET_CRITERION_REQUEST, getCriterionById),
    takeLatest(ADD_CRITERION_REQUEST, addCriterion),
    takeLatest(EDIT_CRITERION_REQUEST, editCriterion),
  ]);
}
