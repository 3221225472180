import {
  GET_METRICS_REQUEST,
  GET_METRICS_SUCCESS,
  GET_METRICS_ERROR,
  GET_SUBJECT_CATEGORIZATION_REQUEST,
  GET_SUBJECT_CATEGORIZATION_SUCCESS,
  GET_SUBJECT_CATEGORIZATION_ERROR,
  GET_RANK_TIME_REQUEST,
  GET_RANK_TIME_SUCCESS,
  GET_RANK_TIME_ERROR,
  GET_RANK_ACTIVITIES_REQUEST,
  GET_RANK_ACTIVITIES_SUCCESS,
  GET_RANK_ACTIVITIES_ERROR,
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_ERROR,
  DELETE_COMMENTS_REQUEST,
  DELETE_COMMENTS_SUCCESS,
  DELETE_COMMENTS_ERROR,
  GET_WEEKLY_PROGRESS_REQUEST,
  GET_WEEKLY_PROGRESS_SUCCESS,
  GET_WEEKLY_PROGRESS_ERROR,
  GET_COMMUNITY_STATUS_REQUEST,
  GET_COMMUNITY_STATUS_SUCCESS,
  GET_COMMUNITY_STATUS_ERROR,
  GET_STUDENT_COMMITMENT_ROUTINE_REQUEST,
  GET_STUDENT_COMMITMENT_ROUTINE_SUCCESS,
  GET_STUDENT_COMMITMENT_ROUTINE_ERROR
} from '../actions';

export const getMetricsRequest = (classId) => ({
  type: GET_METRICS_REQUEST,
  payload: { classId }
});
export const getMetricsSuccess = (all) => ({
  type: GET_METRICS_SUCCESS,
  payload: all,
});
export const getMetricsError = (message) => ({
  type: GET_METRICS_ERROR,
  payload: { message },
});

export const getSubjectCategorizationRequest = (cycle, classId, studentId) => ({
  type: GET_SUBJECT_CATEGORIZATION_REQUEST,
  payload: { cycle, classId, studentId },
});
export const getSubjectCategorizationSuccess = (all) => ({
  type: GET_SUBJECT_CATEGORIZATION_SUCCESS,
  payload: all,
});
export const getSubjectCategorizationError = (message) => ({
  type: GET_SUBJECT_CATEGORIZATION_ERROR,
  payload: { message },
});

export const getRankTimeRequest = (cycle, classId, studentId) => ({
  type: GET_RANK_TIME_REQUEST,
  payload: { cycle, classId, studentId },
});
export const getRankTimeSuccess = (all) => ({
  type: GET_RANK_TIME_SUCCESS,
  payload: all,
});
export const getRankTimeError = (message) => ({
  type: GET_RANK_TIME_ERROR,
  payload: { message },
});

export const getRankActivitiesRequest = (cycle, classId, studentId) => ({
  type: GET_RANK_ACTIVITIES_REQUEST,
  payload: { cycle, classId, studentId },
});
export const getRankActivitiesSuccess = (all) => ({
  type: GET_RANK_ACTIVITIES_SUCCESS,
  payload: all,
});
export const getRankActivitiesError = (message) => ({
  type: GET_RANK_ACTIVITIES_ERROR,
  payload: { message },
});

export const getCommentsRequest = (cycle, classId) => ({
  type: GET_COMMENTS_REQUEST,
  payload: { cycle, classId },
});
export const getCommentsSuccess = (all) => ({
  type: GET_COMMENTS_SUCCESS,
  payload: all,
});
export const getCommentsError = (message) => ({
  type: GET_COMMENTS_ERROR,
  payload: { message },
});

export const deleteCommentsRequest = (id) => ({
  type: DELETE_COMMENTS_REQUEST,
  payload: { id },
});
export const deleteCommentsSuccess = (id) => ({
  type: DELETE_COMMENTS_SUCCESS,
  payload: { id },
});
export const deleteCommentsError = (message) => ({
  type: DELETE_COMMENTS_ERROR,
  payload: { message },
});

export const getWeeklyProgressRequest = (classId, studentId) => ({
  type: GET_WEEKLY_PROGRESS_REQUEST,
  payload: { classId, studentId },
});
export const getWeeklyProgressSuccess = (all) => ({
  type: GET_WEEKLY_PROGRESS_SUCCESS,
  payload: all,
});
export const getWeeklyProgressError = (message) => ({
  type: GET_WEEKLY_PROGRESS_ERROR,
  payload: { message },
});

export const getCommunityStatusRequest = (cycle, classId, studentId) => ({
  type: GET_COMMUNITY_STATUS_REQUEST,
  payload: { cycle, classId, studentId },
});
export const getCommunityStatusSuccess = (all) => ({
  type: GET_COMMUNITY_STATUS_SUCCESS,
  payload: all,
});
export const getCommunityStatusError = (message) => ({
  type: GET_COMMUNITY_STATUS_ERROR,
  payload: { message },
});

export const getStudentCommitmentRoutineRequest = (cycle, subjectId = null, subjectIndex = 0) => ({
  type: GET_STUDENT_COMMITMENT_ROUTINE_REQUEST,
  payload: { cycle, subjectId, subjectIndex },
});
export const getStudentCommitmentRoutineSuccess = (all) => ({
  type: GET_STUDENT_COMMITMENT_ROUTINE_SUCCESS,
  payload: all,
});
export const getStudentCommitmentRoutineError = (message) => ({
  type: GET_STUDENT_COMMITMENT_ROUTINE_ERROR,
  payload: { message },
});
