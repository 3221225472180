import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../services/api';
import { LIST_SCORE_REQUEST } from '../actions';
import {
  listScoreSuccess,
  listScoreError,
} from './actions';

function* listScore() {
  try {
    const response = yield call(api.get, 'scores');
    yield put(listScoreSuccess(response.data.data));
  } catch (error) {
    yield put(listScoreError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LIST_SCORE_REQUEST, listScore),
  ]);
}
