import {
  LIST_CLASS_REQUEST,
  LIST_CLASS_SUCCESS,
  LIST_CLASS_ERROR,
  LIST_CLASS_JOURNEY_REQUEST,
  LIST_CLASS_JOURNEY_SUCCESS,
  LIST_CLASS_JOURNEY_ERROR,
  LIST_CLASS_PREREQUISITES_REQUEST,
  LIST_CLASS_PREREQUISITES_SUCCESS,
  LIST_CLASS_PREREQUISITES_ERROR,
  GET_CLASS_REQUEST,
  GET_CLASS_SUCCESS,
  GET_CLASS_ERROR,
  ADD_CLASS_REQUEST,
  ADD_CLASS_SUCCESS,
  ADD_CLASS_ERROR,
  EDIT_CLASS_REQUEST,
  EDIT_CLASS_SUCCESS,
  EDIT_CLASS_ERROR,
  EDIT_ACTIVE_CLASS_REQUEST,
  EDIT_ACTIVE_CLASS_SUCCESS,
  EDIT_ACTIVE_CLASS_ERROR,
  EDIT_CLASS_VINCULATE_SUBAREA_REQUEST,
  EDIT_CLASS_VINCULATE_SUBAREA_SUCCESS,
  EDIT_CLASS_VINCULATE_SUBAREA_ERROR,
  VINCULATE_SUBAREA,
  RESET_VINCULATE_SUBAREA,
  EDIT_CLASS_VINCULATE_SUBJECT_REQUEST,
  EDIT_CLASS_VINCULATE_SUBJECT_SUCCESS,
  EDIT_CLASS_VINCULATE_SUBJECT_ERROR,
  VINCULATE_SUBJECT,
  RESET_VINCULATE_SUBJECT,
  EDIT_CLASS_VINCULATE_PREREQUISITE_REQUEST,
  EDIT_CLASS_VINCULATE_PREREQUISITE_SUCCESS,
  EDIT_CLASS_VINCULATE_PREREQUISITE_ERROR,
  GET_CLASS_WITH_STUDY_TIME_REQUEST,
  GET_CLASS_WITH_STUDY_TIME_SUCCESS,
  GET_CLASS_WITH_STUDY_TIME_ERROR
} from '../actions';

export const getClassRequest = (id, cycle) => ({
  type: GET_CLASS_REQUEST,
  payload: { id, cycle },
});
export const getClassSuccess = (classValue) => ({
  type: GET_CLASS_SUCCESS,
  payload: classValue,
});
export const getClassError = (message) => ({
  type: GET_CLASS_ERROR,
  payload: { message },
});

export const getClassWithStudyTimeRequest = (cycle, classId, studentId) => ({
  type: GET_CLASS_WITH_STUDY_TIME_REQUEST,
  payload: { cycle, classId, studentId },
});
export const getClassWithStudyTimeSuccess = (classValue) => ({
  type: GET_CLASS_WITH_STUDY_TIME_SUCCESS,
  payload: classValue,
});
export const getClassWithStudyTimeError = (message) => ({
  type: GET_CLASS_WITH_STUDY_TIME_ERROR,
  payload: { message },
});

export const listClassRequest = () => ({
  type: LIST_CLASS_REQUEST,
});
export const listClassSuccess = (classes) => ({
  type: LIST_CLASS_SUCCESS,
  payload: classes,
});
export const listClassError = (message) => ({
  type: LIST_CLASS_ERROR,
  payload: { message },
});

export const listClassJourneyRequest = () => ({
  type: LIST_CLASS_JOURNEY_REQUEST,
});
export const listClassJourneySuccess = (classes) => ({
  type: LIST_CLASS_JOURNEY_SUCCESS,
  payload: classes,
});
export const listClassJourneyError = (message) => ({
  type: LIST_CLASS_JOURNEY_ERROR,
  payload: { message },
});

export const listClassPrerequisitesRequest = (subjectId) => ({
  type: LIST_CLASS_PREREQUISITES_REQUEST,
  payload: { subjectId },
});
export const listClassPrerequisitesSuccess = (prerequisites) => ({
  type: LIST_CLASS_PREREQUISITES_SUCCESS,
  payload: { prerequisites },
});
export const listClassPrerequisitesError = (message) => ({
  type: LIST_CLASS_PREREQUISITES_ERROR,
  payload: { message },
});

export const addClassRequest = (classValue) => ({
  type: ADD_CLASS_REQUEST,
  payload: { classValue },
});
export const addClassSuccess = (classValue) => ({
  type: ADD_CLASS_SUCCESS,
  payload: classValue,
});
export const addClassError = (message) => ({
  type: ADD_CLASS_ERROR,
  payload: { message },
});

export const editClassRequest = (classValue) => ({
  type: EDIT_CLASS_REQUEST,
  payload: { classValue },
});
export const editClassSuccess = (classValue) => ({
  type: EDIT_CLASS_SUCCESS,
  payload: { classValue },
});
export const editClassError = (message) => ({
  type: EDIT_CLASS_ERROR,
  payload: { message },
});

export const editActiveClassRequest = (id, active) => ({
  type: EDIT_ACTIVE_CLASS_REQUEST,
  payload: { id, active },
});
export const editActiveClassSuccess = (id, classValue) => ({
  type: EDIT_ACTIVE_CLASS_SUCCESS,
  payload: { id, classValue },
});
export const editActiveClassError = (message) => ({
  type: EDIT_ACTIVE_CLASS_ERROR,
  payload: { message },
});

export const editClassVinculateSubareaRequest = (id, subareaList) => ({
  type: EDIT_CLASS_VINCULATE_SUBAREA_REQUEST,
  payload: { id, subareaList },
});
export const editClassVinculateSubareaSuccess = (id, subareaList) => ({
  type: EDIT_CLASS_VINCULATE_SUBAREA_SUCCESS,
  payload: { id, subareaList },
});
export const editClassVinculateSubareaError = (message) => ({
  type: EDIT_CLASS_VINCULATE_SUBAREA_ERROR,
  payload: { message },
});
export const vinculateSubarea = (subarea, subject_id) => ({
  type: VINCULATE_SUBAREA,
  payload: { subarea, subject_id },
});

export const resetVinculateSubarea = (subarea) => ({
  type: RESET_VINCULATE_SUBAREA,
  payload: subarea,
});

export const editClassVinculateSubjectRequest = (id, subjectList) => ({
  type: EDIT_CLASS_VINCULATE_SUBJECT_REQUEST,
  payload: { id, subjectList },
});
export const editClassVinculateSubjectSuccess = (message) => ({
  type: EDIT_CLASS_VINCULATE_SUBJECT_SUCCESS,
  payload: { message },
});
export const editClassVinculateSubjectError = (message) => ({
  type: EDIT_CLASS_VINCULATE_SUBJECT_ERROR,
  payload: { message },
});

export const vinculateSubject = (subject, subject_id) => ({
  type: VINCULATE_SUBJECT,
  payload: { subject, subject_id },
});

export const resetVinculateSubject = (subject) => ({
  type: RESET_VINCULATE_SUBJECT,
  payload: subject,
});

export const editClassVinculatePrerequisiteRequest = (
  id,
  prerequisiteList
) => ({
  type: EDIT_CLASS_VINCULATE_PREREQUISITE_REQUEST,
  payload: { id, prerequisiteList },
});
export const editClassVinculatePrerequisiteSuccess = (message) => ({
  type: EDIT_CLASS_VINCULATE_PREREQUISITE_SUCCESS,
  payload: { message },
});
export const editClassVinculatePrerequisiteError = (message) => ({
  type: EDIT_CLASS_VINCULATE_PREREQUISITE_ERROR,
  payload: { message },
});
