import {
  LIST_FEEDBACK_REQUEST,
  LIST_FEEDBACK_SUCCESS,
  LIST_FEEDBACK_ERROR,
  ADD_OR_EDIT_FEEDBACK_REQUEST,
  ADD_OR_EDIT_FEEDBACK_SUCCESS,
  ADD_OR_EDIT_FEEDBACK_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
  REMOVE_FILE_FEEDBACK_ERROR,
  REMOVE_FILE_FEEDBACK_SUCCESS,
  REMOVE_FILE_FEEDBACK_REQUEST,
  REMOVE_FEEDBACK_REQUEST,
  REMOVE_FEEDBACK_SUCCESS,
  REMOVE_FEEDBACK_ERROR,
} from '../../actions';

const INIT_STATE = {
  currentFeedback: null,
  currentFeedbackList: null,
  loading: false,
  fileIdDeleted: null,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return INIT_STATE;
    case LIST_FEEDBACK_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        currentFeedbackList: action.payload,
        error: '',
      };
    case LIST_FEEDBACK_ERROR:
      return {
        ...state,
        loading: false,
        currentFeedbackList: null,
        error: action.payload.message,
      };
    case ADD_OR_EDIT_FEEDBACK_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_OR_EDIT_FEEDBACK_SUCCESS: {
      const allFeedbacks = [...state.currentFeedbackList, action.payload];

      return {
        ...state,
        currentFeedbackList: allFeedbacks,
        loading: false,
        success: action.payload,
        error: '',
      };
    }
    case ADD_OR_EDIT_FEEDBACK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case REMOVE_FILE_FEEDBACK_REQUEST:
      return { ...state };
    case REMOVE_FILE_FEEDBACK_SUCCESS: {
      const { id } = action.payload;

      return {
        ...state,
        fileIdDeleted: id,
      };
    }
    case REMOVE_FILE_FEEDBACK_ERROR: {
      return { ...state, error: action.payload.message };
    }
    case REMOVE_FEEDBACK_REQUEST:
      return { ...state, error: '', success: '', loading: true };
    case REMOVE_FEEDBACK_SUCCESS: {
      const { newId } = action.payload;
      const updateFeedbacks = state.currentFeedbackList.filter((news) => news.id !== newId);

      return {
        ...state,
        loading: false,
        currentFeedbackList: updateFeedbacks,
        error: '',
        success: action.payload,
      };
    }
    case REMOVE_FEEDBACK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    default:
      return { ...state };
  }
};
