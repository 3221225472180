import {
  LIST_COORDINATOR_REQUEST,
  LIST_COORDINATOR_SUCCESS,
  LIST_COORDINATOR_ERROR,
  GET_COORDINATOR_REQUEST,
  GET_COORDINATOR_SUCCESS,
  GET_COORDINATOR_ERROR,
  ADD_COORDINATOR_REQUEST,
  ADD_COORDINATOR_SUCCESS,
  ADD_COORDINATOR_ERROR,
  EDIT_COORDINATOR_REQUEST,
  EDIT_COORDINATOR_SUCCESS,
  EDIT_COORDINATOR_ERROR,
  EDIT_ACTIVE_COORDINATOR_REQUEST,
  EDIT_ACTIVE_COORDINATOR_SUCCESS,
  EDIT_ACTIVE_COORDINATOR_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  currentCoordinator: null,
  currentCoordinatorList: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentCoordinator: null,
        success: '',
      };
    case GET_COORDINATOR_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_COORDINATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCoordinator: action.payload,
        error: '',
      };
    case GET_COORDINATOR_ERROR:
      return {
        ...state,
        loading: false,
        currentCoordinator: null,
        error: action.payload.message,
      };
    case LIST_COORDINATOR_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_COORDINATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCoordinatorList: action.payload,
        error: '',
      };
    case LIST_COORDINATOR_ERROR:
      return {
        ...state,
        loading: false,
        currentCoordinatorList: null,
        error: action.payload.message,
      };
    case ADD_COORDINATOR_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_COORDINATOR_SUCCESS: {
      const allCoordinators = [...state.currentCoordinatorList, action.payload];

      return {
        ...state,
        currentCoordinatorList: allCoordinators,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case ADD_COORDINATOR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_COORDINATOR_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_COORDINATOR_SUCCESS: {
      const CoordinatorsUpdated = state.currentCoordinatorList.map(
        (coordinator) => {
          if (coordinator.id === action.payload.coordinator.id) {
            return action.payload.coordinator;
          }
          return coordinator;
        }
      );

      return {
        ...state,
        currentCoordinatorList: CoordinatorsUpdated,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case EDIT_COORDINATOR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_ACTIVE_COORDINATOR_REQUEST:
      return { ...state, loading: false, error: '', success: '' };
    case EDIT_ACTIVE_COORDINATOR_SUCCESS: {
      const CoordinatorsUpdated = state.currentCoordinatorList.map(
        (coordinator) => {
          if (coordinator.id === action.payload.id) {
            return action.payload.coordinator;
          }
          return coordinator;
        }
      );

      return {
        ...state,
        currentCoordinatorList: CoordinatorsUpdated,
        success: action.payload,
        loading: false,
        error: '',
      };
    }
    case EDIT_ACTIVE_COORDINATOR_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
