import {
  LIST_NETWORK_COORDINATOR_REQUEST,
  LIST_NETWORK_COORDINATOR_SUCCESS,
  LIST_NETWORK_COORDINATOR_ERROR,
  GET_NETWORK_COORDINATOR_REQUEST,
  GET_NETWORK_COORDINATOR_SUCCESS,
  GET_NETWORK_COORDINATOR_ERROR,
  ADD_NETWORK_COORDINATOR_REQUEST,
  ADD_NETWORK_COORDINATOR_SUCCESS,
  ADD_NETWORK_COORDINATOR_ERROR,
  EDIT_NETWORK_COORDINATOR_REQUEST,
  EDIT_NETWORK_COORDINATOR_SUCCESS,
  EDIT_NETWORK_COORDINATOR_ERROR,
  EDIT_ACTIVE_NETWORK_COORDINATOR_REQUEST,
  EDIT_ACTIVE_NETWORK_COORDINATOR_SUCCESS,
  EDIT_ACTIVE_NETWORK_COORDINATOR_ERROR,
  RESET_STATE_REDUX,
  RESET_STATE_ALL_APPLICATION,
} from '../actions';

const INIT_STATE = {
  currentNetworkCoordinator: null,
  currentNetworkCoordinatorList: null,
  loading: false,
  error: '',
  success: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ALL_APPLICATION:
      return INIT_STATE;
    case RESET_STATE_REDUX:
      return {
        ...state,
        error: '',
        currentNetworkCoordinator: null,
        success: '',
      };
    case GET_NETWORK_COORDINATOR_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_NETWORK_COORDINATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        currentNetworkCoordinator: action.payload,
        error: '',
      };
    case GET_NETWORK_COORDINATOR_ERROR:
      return {
        ...state,
        loading: false,
        currentNetworkCoordinator: null,
        error: action.payload.message,
      };
    case LIST_NETWORK_COORDINATOR_REQUEST:
      return { ...state, loading: true, error: '' };
    case LIST_NETWORK_COORDINATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        currentNetworkCoordinatorList: action.payload,
        error: '',
      };
    case LIST_NETWORK_COORDINATOR_ERROR:
      return {
        ...state,
        loading: false,
        currentNetworkCoordinatorList: null,
        error: action.payload.message,
      };
    case ADD_NETWORK_COORDINATOR_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case ADD_NETWORK_COORDINATOR_SUCCESS: {
      const allCoordinators = [
        ...state.currentNetworkCoordinatorList,
        action.payload,
      ];

      return {
        ...state,
        currentNetworkCoordinatorList: allCoordinators,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case ADD_NETWORK_COORDINATOR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_NETWORK_COORDINATOR_REQUEST:
      return { ...state, loading: true, error: '', success: '' };
    case EDIT_NETWORK_COORDINATOR_SUCCESS: {
      const networkCoordinatorsUpdated =
        state.currentNetworkCoordinatorList.map((networkCoordinator) => {
          if (networkCoordinator.id === action.payload.networkCoordinator.id) {
            return action.payload.networkCoordinator;
          }
          return networkCoordinator;
        });

      return {
        ...state,
        currentNetworkCoordinatorList: networkCoordinatorsUpdated,
        loading: false,
        error: '',
        success: action.payload,
      };
    }
    case EDIT_NETWORK_COORDINATOR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: '',
      };
    case EDIT_ACTIVE_NETWORK_COORDINATOR_REQUEST:
      return { ...state, loading: false, error: '', success: '' };
    case EDIT_ACTIVE_NETWORK_COORDINATOR_SUCCESS: {
      const networkCoordinatorsUpdated =
        state.currentNetworkCoordinatorList.map((networkCoordinator) => {
          if (networkCoordinator.id === action.payload.id) {
            return action.payload.networkCoordinator;
          }
          return networkCoordinator;
        });

      return {
        ...state,
        currentNetworkCoordinatorList: networkCoordinatorsUpdated,
        success: action.payload,
        loading: false,
        error: '',
      };
    }
    case EDIT_ACTIVE_NETWORK_COORDINATOR_ERROR:
      return {
        ...state,
        loading: false,
        success: '',
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
